<div fxLayout="column">
    <!-- <div style="color: red">
        <p *ngIf="errorMessage"> {{ errorMessage }} </p>
    </div> -->
    <mat-form-field fxFlex>
      <mat-select (selectionChange)="onGroupChanged()" [(ngModel)]="model" placeholder="Select Org">
        <mat-option *ngFor="let org of processActivities" [value]="org.label">
          {{ org.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
</div>