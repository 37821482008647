import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { MatSnackBar } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ProfileHelperService } from '../../services/profile-helper/profile-helper.service';
import { HelpersService } from '../../services/helpers/helpers.service';
import { IProfile } from '../../interfaces/profile.interface';
import { md5 } from '../authentication/md5';
import { ContactModel, GeneralModel, WorkModel } from './profile.model';

@Component({
    selector     : 'profile',
    templateUrl  : './profile.component.html',
    styleUrls    : ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ProfileComponent implements OnInit
{
    profileId: string;
    edit = false;
    about;
    telNo: string;
    websites: string;
    emails: string;
    newPass: string;
    gitAuthToken: string;
    userLogin: string;
    confirmPass: string;
    oldPass: string;
  gitTokenId: any;
    constructor(
      public snackBar: MatSnackBar,
      private translationLoader: FuseTranslationLoaderService,
      private profileHelperService: ProfileHelperService,
      private helperService: HelpersService
    ) {
        this.helperService.getTrans('profile')
        .then((data2) => {
          this.translationLoader.loadTranslationsV2(data2);
        }).catch(err => {
          console.log(err);
        });
    }
  
    ngOnInit() {
      if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']) {
        this.profileId = JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId'];
        this.userLogin = JSON.parse(localStorage.getItem('ngStorage-profile'))['email'];
        // get the data..
        this.profileHelperService.getProfileData(this.profileId)
          .then((profileData: IProfile) => {
            this.about = profileData;
            this.getGitToken(profileData);
  
            this.about['contact'] = this.about['contact'] || ContactModel;
            this.about['general'] = this.about['general'] || GeneralModel;
            this.about['work'] = this.about['work'] || WorkModel;
  
            if (this.about['contact']['tel']) {/*  */
              this.telNo = this.about['contact']['tel'].join(', ');
            }
            if (this.about['contact']['emails']) {
              this.emails = this.about['contact']['emails'].join(', ');
            }
            if (this.about['contact']['websites']) {
              this.websites = this.about['contact']['websites'].join(', ');
            }
          }).catch(err => {
            console.log(err);
            this.snackBar.open(err || 'some error occured while getting data', 'Okay', {
              duration: 2000,
            });
          });
      } else {
        this.snackBar.open('Profile Not found', 'Okay', {
          duration: 2000,
        });
        console.log('Profile Not found');
      }
    }
  
    dateChange(event) {
      this.about.general.birthday = event['value'];
    }
  
    updateProfile() {
  
      this.about['contact']['tel'] = this.telNo.split(',') || [];
      this.about['contact']['emails'] = this.emails.split(',') || [];
      this.about['contact']['websites'] = this.websites.split(',') || [];
  
      const data = JSON.parse(JSON.stringify(this.about));
      delete data['_id'];
  
      if (this.profileId) {
        this.profileHelperService.updateProfileDoc(data)
          .then(() => {
            this.snackBar.open('Saved Data', 'Okay', {
              duration: 2000,
            });
          }).catch(err => {
            console.log(err);
            this.snackBar.open(err || 'some error occured while updating data', 'Okay', {
              duration: 2000,
            });
          });
      } else {
        this.snackBar.open('No profile id found', 'Okay', {
          duration: 2000,
        });
      }
    }
  
  
    changePassword() {
      try {
        if (this.userLogin && this.newPass && this.newPass.length > 6) {
          if (this.newPass === this.confirmPass) {
            const reqData = {
              newpass: md5(this.newPass),
              password: md5(this.oldPass),
              login: this.userLogin
            };
            this.helperService.postRequest(`api/auth/changepass`, reqData)
              .then((data) => {
                this.newPass = '';
                this.oldPass = '';
                this.confirmPass = '';
                this.snackBar.open('Password Updated', 'Okay', {
                  duration: 2000,
                });
              }).catch(err => {
                console.log(err);
                this.snackBar.open(err || 'some error occured while updating.', 'Okay', {
                  duration: 2000,
                });
              });
          } else {
            this.snackBar.open('Password do not match', 'Okay', {
              duration: 2000,
            });
          }
        } else {
          this.snackBar.open('Choose password greater than 6 letters.', 'Okay', {
            duration: 2000,
          });
        }
      } catch (err) {
        console.log(err);
        this.snackBar.open(err || 'some error occured while updating.', 'Okay', {
          duration: 2000,
        });
      }
    }
  
    getGitToken(profile) {
      if (profile && profile['gitToken']) {
        this.gitTokenId = profile['gitToken'];
        this.helperService.getRequest(`api/profile/git/${this.gitTokenId}`)
        .then((data) => {
          data = data || {};
          this.gitAuthToken = data['token'];
        }).catch(err => {
          console.log(err);
        });
      }
    }

    saveGitToken() {
      const tt = {};
      tt['gitToken'] = this.gitAuthToken;
      this.helperService.postRequest(`api/profile/git/new`, tt)
      .then(() => {
        this.helperService.openSnackBar('Token Saved');
      }).catch(err => {
        console.log(err);
      });
    }

    updateGitToken() {
      const tt = {};
      tt['gitToken'] = this.gitAuthToken;
      this.helperService.putRequest(`api/profile/git/${this.gitTokenId}`, tt)
      .then(() => {
        this.helperService.openSnackBar('Token Saved');
      }).catch(err => {
        console.log(err);
      });
    }
}
