<ng-container *ngIf="alertData && alertData.showAlert && alertData.html">
    <div class="p-12 accent-bg" [innerHtml]="alertData.html | htmlWithStyle" >

    </div>
</ng-container>
<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- VERTICAL LAYOUT 2 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-2'">
    <vertical-layout-2></vertical-layout-2>
</ng-container>
<!-- / VERTICAL LAYOUT 2 -->

<!-- VERTICAL LAYOUT 3 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-3'">
    <vertical-layout-3></vertical-layout-3>
</ng-container>
<!-- / VERTICAL LAYOUT 3 -->

<!-- HORIZONTAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
    <horizontal-layout-1></horizontal-layout-1>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->

<!-- THEME OPTIONS PANEL -->
<!-- <button mat-icon-button class="warn mat-elevation-z2 theme-options-button"
        (click)="toggleSidebarOpen('themeOptionsPanel')">
    <mat-icon>settings</mat-icon>
</button> -->

<fuse-sidebar (window:keydown)="openIssueReporter($event)" name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
    <fuse-theme-options></fuse-theme-options>
</fuse-sidebar>
<!-- / THEME OPTIONS PANEL -->

<ng-container *ngIf="!isOnline">
    <div class="glass-offline-div" fxLayoutAlign="center center" fxLayout="column">
        <mat-icon>signal_wifi_off</mat-icon>
        <h2 class="m-0">
            {{ 'global.you_are_offline_now' | translate }}
        </h2>
        <p class="m-0">
            {{ 'global.we_will_connect_you_as_soon_as_internet_is_back' | translate }}
        </p>
    </div>
</ng-container>