<!-- <div class="h-100-p w-100-p p-8" style="position: relative; overflow: hidden;"> -->
        <div class="dialog-content-wrapper" style="background: #fafafa;">
                <mat-toolbar matDialogTitle class="mat-accent m-0">
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                        <div>
                            <p style="margin:auto; font-size: 15px;"> Current Version:
                                {{ version }}
                            </p>
                        </div>
                        <span fxFlex></span>
                        <h2 style=" text-align: center; " class="m-0">
                            Change Log
                        </h2>
                        <span fxFlex></span>
                        <div>
                            <p style="margin:auto; font-size: 15px;"> Branch: {{ branch }} </p>
                        </div>
                    </div>
                </mat-toolbar>
            
                <div class="changelog p-24" style="overflow: auto;">
                    <div class="my-12">
            
                        Release notes - p24Frotntend - Version 2.3.0
            
                        <h2> Task
                        </h2>
                        <ul>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1208'>P24F-1208</a>] - secure
                                office document update from front end
                            </li>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1315'>P24F-1315</a>] - create
                                Document for Refactor code about in backed all reports generate
                            </li>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1417'>P24F-1417</a>] - feature to
                                have option based field from user - option on form view to turn on for user manage of options in
                                additional popup
                            </li>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1473'>P24F-1473</a>] - send bug
                                feature from aot pinbox24
                            </li>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1557'>P24F-1557</a>] - update
                                process transition button to have feature to not save data before transition
                            </li>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1709'>P24F-1709</a>] - Add default
                                sort for new fields in form
                            </li>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1756'>P24F-1756</a>] - script for
                                pdf to calculate page and pdf info
                            </li>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1784'>P24F-1784</a>] - Styling the
                                set password and send email things
                            </li>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1785'>P24F-1785</a>] - styling the
                                accept and reject invitation ui and workflow
                            </li>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1786'>P24F-1786</a>] - ui to show
                                pending invitations
                            </li>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1792'>P24F-1792</a>] - Create the
                                endpoint to pull instance record data from invitation process with buttons - with security by toiken
                                and instance ID to show outside authorization \
                            </li>
                        </ul>
            
                        <h2> Bug
                        </h2>
                        <ul>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1330'>P24F-1330</a>] - removing
                                files when they are replaced from mainrecorddocuments and use new file - old file have to be removed
                                from files collection and storage
                            </li>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1752'>P24F-1752</a>] - User
                                platform inviatation check for mutiple office and sockit update
                            </li>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1765'>P24F-1765</a>] - Resolving
                                error when we don&#39;t have any field selected to show on grid
                            </li>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1773'>P24F-1773</a>] - Fixing error
                                in datable mqFilter when using currentUser or date
                            </li>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1775'>P24F-1775</a>] - pdf icon on
                                grid doesnt work in firefox - polease check aot build too happy for this bug on firefox and on
                                chrome
                            </li>
                        </ul>
            
                        <h2> Enhancement
                        </h2>
                        <ul>
                            <li>[<a href='https://easyaccesstechnology.atlassian.net/browse/P24F-1774'>P24F-1774</a>] - Update new
                                datatable api to read form also from the workspace app settings
                            </li>
                        </ul>
            
                    </div>
                    <hr>
                </div>
            </div>