<div fxLayout="column" *ngIf="!field.hide || builder">

    <div fxLayout="row" *ngIf="!field.defaultEdit && !errorMessage && field.showEdit" (click)="field.defaultEdit=!field.defaultEdit">
        <button class="mr-4" mat-icon-button>
            <mat-icon>edit</mat-icon>
        </button>
        <p class="font-size-18 mt-12">
            {{ (model) }}
        </p>
    </div>
    <mat-form-field  appearance="outline" *ngIf="field.defaultEdit || errorMessage || !field.showEdit && !field.timeInInput">
        <mat-label> {{ field.title }} </mat-label>
            
        <mat-select (selectionChange)="ondateChange()" [disabled]="field.disable" [(ngModel)]="time" [placeholder]="field.title">
            <mat-option *ngFor="let time of timeSlots" [value]="time">
            {{time}}
            </mat-option>
        </mat-select>
        <div class="error-message" *ngIf="errorMessage">
                {{ errorMessage | translate  }}
            </div> 
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="field.timeInInput"  style="width: 80px; min-width: 80px;">
        <mat-label>{{field.title}}</mat-label>

        <input type="time" matInput (keyup)="ondateChange()" *ngIf="field.timeInInput"  [(ngModel)]="time" placeholder="Select Time">
    </mat-form-field>
    <div *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">

    </div>
    <!-- <div style="color: red">
        <p *ngIf="errorMessage"> {{ errorMessage }} </p>
    </div> -->
</div>