import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class AuthService {
    tokenKey = 'ngStorage-token';
    isAuthenticated: Boolean = false;
    username: Subject<string> = new Subject<string>();
    authToken: string = undefined;
    lastOfficeId;
    onUserNameChanged: BehaviorSubject<any> = new BehaviorSubject(null);
    onOfficeChange: BehaviorSubject<any> = new BehaviorSubject({});

    constructor(
        private http: HttpClient,
        private router: Router,
        private snackBar: MatSnackBar
    ) {}

    sendUsername(name: string) {
        this.username.next(name);
    }

    clearUsername() {
        this.username.next(undefined);
        this.onUserNameChanged.next(undefined);
    }

    loadUserCredentials() {
        const credentials = JSON.parse(localStorage.getItem(this.tokenKey));
        if (credentials && credentials.username !== undefined) {
            this.lastOfficeId = credentials['profile']['lastSelectedOffice'];
            this.useCredentials(credentials);
        }
    }

    storeUserCredentials(credentials: any): Promise<any> {
        return new Promise((resolve, reject) => {
            localStorage.setItem(this.tokenKey, JSON.stringify(credentials));
            this.useCredentials(credentials);

            const headers = new HttpHeaders({
                Authorization: this.authToken
            });

            this.http.get(`${environment.serverUrl}api/profile/contactData`, {
                    headers
                }).toPromise()
                .then(response => {
                    if (response['success']) {
                        localStorage.setItem('selectedLanguage', response['result']['defaultLang'] || 'en');

                        localStorage.setItem('ngStorage-profile', JSON.stringify(response['result'] || {}));
                    } else {
                        reject('Error occured while getting profile data');
                    }

                    if (
                        credentials &&
                        credentials['profile'] &&
                        credentials['profile']['lastSelectedOffice']
                    ) {
                        return this.storeOffice(
                            credentials['profile']['lastSelectedOffice']
                        );
                    } else {
                        return null;
                    }
                })
                .then(data => {
                    this.onUserNameChanged.next(
                        JSON.parse(localStorage.getItem(this.tokenKey))[
                            'username'
                        ]
                    );

                    if (!data) {
                        this.router.navigate([`/dashboard`]);
                    }
                    resolve(credentials);
                })
                .catch(err => {
                    reject(err);
                    console.log(err);
                });
        });
    }

    storeOffice(officeId: string): Promise<any> {
        return new Promise((resolve2, reject) => {
            if (officeId) {
                const headers = new HttpHeaders({
                    Authorization: this.authToken
                    // 'officeId': JSON.parse(localStorage.getItem('selectedOfficeId')) || ''
                });
                this.http
                    .get(`${environment.serverUrl}api/offices/${officeId}`, {
                        headers
                    })
                    .subscribe(
                        data => {
                            if (data['success'] && data['result']) {
                                localStorage.setItem(
                                    'selectedOfficeId',
                                    JSON.stringify(data['result'][0]['_id'])
                                );
                                localStorage.setItem(
                                    'ngStorage-selectedOffice',
                                    JSON.stringify(data['result'][0])
                                );
                                this.router.navigate([
                                    `/offices/${
                                        data['result'][0]['_id']
                                    }/dashboard`
                                ]);
                                this.onOfficeChange.next(
                                    data['result'][0]['_id']
                                );
                                resolve2(data['result'][0]);
                            } else {
                                reject(
                                    'Error occured while fetching office Data'
                                );
                            }
                        },
                        err => {
                            console.log(err);
                            reject(err);
                        }
                    );
            } else {
                reject('No office Id');
            }
        });
    }

    useCredentials(credentials: any) {
        this.isAuthenticated = true;
        this.sendUsername(credentials.username);
        this.authToken = credentials.token;
    }

    destroyUserCredentials() {
        this.authToken = undefined;
        this.clearUsername();
        this.isAuthenticated = false;
        localStorage.removeItem(this.tokenKey);
        localStorage.removeItem('ngStorage-token');
        localStorage.removeItem('ngStorage-profile');
        localStorage.removeItem('ngStorage-selectedOffice');
        localStorage.clear();
    }

    signUp(user) {
        const header = new HttpHeaders();
        header.append('Content-Type', 'application/json');
        return this.http
            .post(environment.serverUrl + 'api/registration', user, {
                headers: header
            })
            .map(res => {
                if (res['success']) {
                    return { error: false };
                } else {
                    return { error: true, message: res['error'].txt };
                }
            });
    }

    logIn(user: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let credentials;
            const header = new HttpHeaders();
            header.append('Content-Type', 'application/json');
            this.http
                .post(environment.serverUrl + 'api/auth', user, {
                    headers: header
                })
                .toPromise()
                .then(res => {
                  if (res['success'] && res['result']) {
                    credentials = {
                      profile: res['result']['profile'],
                      username: res['result']['profile']['login'],
                      token: res['result']['token'],
                      date: Date.now()
                    };
                  return this.afterLoginDone(res);
                  } else {
                    reject(res);
                  }
                })
                .then(data => {
                    resolve(credentials);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getLastSelectedOffice(): string {
        if (localStorage.getItem('ngStorage-token') &&
            localStorage.getItem('ngStorage-selectedOffice') &&
            JSON.parse(localStorage.getItem('ngStorage-token')) &&
            JSON.parse(localStorage.getItem('ngStorage-token'))['profile'] &&
            JSON.parse(localStorage.getItem('ngStorage-token'))['profile']['lastSelectedOffice']
        ) {
            return JSON.parse(localStorage.getItem('ngStorage-token'))['profile']['lastSelectedOffice'];
        }
        return '';
    }

    getLastSelectedWorkspace(): string {
        if (localStorage.getItem('ngStorage-token') &&
            localStorage.getItem('ngStorage-selectedOffice') &&
            JSON.parse(localStorage.getItem('ngStorage-token')) &&
            JSON.parse(localStorage.getItem('ngStorage-token'))['profile'] &&
            JSON.parse(localStorage.getItem('ngStorage-token'))['profile']['lastSelectedWorkspace']
        ) {
            return JSON.parse(localStorage.getItem('ngStorage-token'))['profile']['lastSelectedWorkspace'];
        }
        return '';
    }

    afterLoginDone(res): Promise<any> {
        return new Promise((resolve, reject) => {
            let credentials;
            if (res['success']) {
                credentials = {
                    profile: res['result']['profile'],
                    username: res['result']['profile']['login'],
                    token: res['result']['token'],
                    date: Date.now()
                };
                localStorage.setItem(
                    this.tokenKey,
                    JSON.stringify(credentials)
                );
                this.storeUserCredentials({
                    profile: res['result']['profile'],
                    username: res['result']['profile']['login'],
                    token: res['result']['token'],
                    date: Date.now()
                })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            } else {
                reject({ error: true, message: res['error'].txt });
            }
        });
    }

    logOut() {
        this.destroyUserCredentials();
        const loc = window.location.pathname;

        if (
            !(
                loc === '/login' ||
                loc === '/register' ||
                loc.includes('/set-password') ||
                loc.includes('/changepassword')
            )
        ) {
            this.router.navigate(['/login']);
        }
    }

    isLoggedIn(): Boolean {
        return this.isAuthenticated;
    }

    getUsername(): Observable<string> {
        return this.username.asObservable();
    }

    getToken() {
        return this.authToken;
    }

    refreshToken(): Promise<any> {
        return new Promise((resolve, reject) => {
            const isAuth = this.isAuth();
            if (!isAuth && JSON.parse(localStorage.getItem(this.tokenKey))) {
                const credentials = JSON.parse(
                    localStorage.getItem(this.tokenKey)
                );
                this.http
                    .get(`${environment.serverUrl}api/auth/refresh-token`)
                    .subscribe(
                        (response: any) => {
                            if (response['newToken']) {
                                credentials['token'] = response['newToken'];
                                credentials['date'] = Date.now();
                                localStorage.setItem(
                                    this.tokenKey,
                                    JSON.stringify(credentials)
                                );
                            }
                            resolve(response);
                        },
                        err => {
                            reject(err);
                        }
                    );
            } else {
                resolve();
            }
        });
    }

    /**
     * to check if auth is in time range or not..
     */
    isAuth(): boolean {
        if (JSON.parse(localStorage.getItem(this.tokenKey))) {
            const credentials = JSON.parse(localStorage.getItem(this.tokenKey));
            const loginTime = credentials['date'] || 0;
            const expTime = Math.floor(loginTime / 1000) + 8 * 60 * 60;
            const now = Math.floor(Date.now() / 1000);
            const timeToExpire = expTime - now;
            if (timeToExpire < 60 * 60 + 60 * 60 && loginTime) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
}
