import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DatatableComponent } from './datatable.component';
import { DatatableService } from './datatable.service';
import { FuseSharedModule } from '@fuse/shared.module';
import { ControlTemplateModule } from '../control-template/control-template.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DescriptionPopupComponent } from './description-popup/description-popup.component';
import { PrivateFilterComponent } from './private-filter/private-filter.component';
import { SideMenuEditModule } from '../side-menu-edit/side-menu-edit.module';
import { WSAppsSettingsModule } from '../../apps/ws-apps-settings/ws-apps-settings.module';
import { AfterPluginInstallModule } from '../../apps/plugin/after-plugin-install/after-plugin-install.module';
import { OfficeGlobalVarModule } from '../../apps/office-global-var/office-global-var.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EditFormPopupModule } from '../../apps/custom-registries/edit-form-popup/edit-form-popup.module';
import { DateFilterFieldModule } from '../../components/date-filter-field/date-filter-field.module';
import { LimitDecimalPlacesDigitsDirective } from '../../directives/limitDecimalPlaceDigits.directive';
import { AutofocusModule } from 'angular-autofocus-fix';
import { RecordBulkUpdateComponent } from './record-bulk-update/record-bulk-update.component';
import { DatatableActionsComponent } from './datatable-components/datatable-actions/datatable-actions.component'; 
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule,
    ControlTemplateModule,
    NgxChartsModule,
    SideMenuEditModule,
    WSAppsSettingsModule,
    AfterPluginInstallModule,
    OfficeGlobalVarModule,
    NgxDatatableModule,
    DateFilterFieldModule,
    AutofocusModule,
    TextMaskModule
    // EditFormPopupModule
  ],
  exports: [DatatableComponent],
  declarations: [
    DatatableComponent,
    DescriptionPopupComponent,
    PrivateFilterComponent,
    LimitDecimalPlacesDigitsDirective,
    RecordBulkUpdateComponent,
    DatatableActionsComponent
  ],
  providers: [
    DatatableService,
  ],
  entryComponents: [
    DescriptionPopupComponent,
    RecordBulkUpdateComponent
  ]
})
export class DatatableModule { }
