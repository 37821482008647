<div *ngIf="contactId && contactId !== 'new'">
  <div class="mx-12" fxLayout="row">
  <div fxLayout="row" class="mr-8" fxLayoutAlign="start center">
      <label for="search" class="mr-8">
        <mat-icon>search</mat-icon>
      </label>
      <mat-form-field>
        <input matInput aria-label="add_app_search_input" placeholder="{{ 'ct.search' | translate }}" [(ngModel)]="query">
      </mat-form-field>
    </div>
    <span fxFlex></span>
    <div style="margin: auto;">
      <!-- <button mat-raised-button (click)="openAddRecord()">
        {{ 'ct.add_new' | translate }}
      </button> -->
      <mat-bar-button 
        [options]="btnOpts"
        (btnClick)="openAddRecord()">
        {{ 'ct.add_new' | translate }}
      </mat-bar-button>
    </div>
  </div>
  <div fxLayout="column">
    <div style="max-height: 65vh; overflow: auto;" class="happ-list-view" *ngFor="let item of list | fullTextSearch:query:['responsibleEmail', 'signatura', 'processStatusLabel', 'processStatusId', 'managerGroup', 'registredDataTime']">
        <div class="m-4 p-4" fxLayout="row" fxLayoutAlign="start center">

            <div class="info" fxFlex FlexLayout="column">
        
                <div class="row-1" fxLayout="row" fxLayoutAlign="start center">
        
                    <div class="name" fxLayout="row" fxLayoutAlign="start center" fxFlex>
                        <img class="avatar"   ngxGravatar [email]="item.responsibleEmail" *ngIf="item.responsibleEmail"/>
                        <!-- <div class="avatar accent" >{{item.responsibleEmail}}</div> -->
                        <div fxLayout="column">
                          <span class="text-truncate" >{{item.responsibleEmail}} - ({{item.signatura}}) </span>
                          <span class="text-truncate" *ngIf="item.processStatusId" >{{ item.processStatusId }} - {{ item.processStatusLabel }} </span>
                          <span class="text-truncate" *ngIf="item.managerGroup" >Manager Group - {{ item.managerGroup }} </span>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="time">{{item.registredDataTime}}</div>
                    </div>
                </div>
        
            </div>
        
        </div>
        
    </div>
  </div>
</div>
<div *ngIf="!contactId || contactId === 'new'">
  <h2>
    {{ 'ct.first_add_record_then_field_will_be_visible' | translate }}
  </h2>
</div>