import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '../..';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import * as _ from 'lodash';
import { IProcess } from 'app/main/content/interfaces/process.interface';
import { IOfficeData } from 'app/main/content/interfaces/office.interface';

@Component({
  selector: 'app-add-record',
  templateUrl: './add-record.component.html',
  styleUrls: ['./add-record.component.scss']
})
export class AddRecordComponent implements OnInit {

  selectedReg;
  registerList;
  errorMsg = '';
  responsibleEmail: any;
  managerGroup: any;
  loading;
  field;
  officeUsersList = [];
  officeGroupsList = [];
  selectedRegister: any;

  constructor(
    public dialogRef: MatDialogRef<AddRecordComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private translationLoader: FuseTranslationLoaderService,
    private helperService: HelpersService,
    private officeHelpers: OfficeHelpersService
  ) { }

  ngOnInit() {

    this.registerList = (this.data && this.data.registerList) || [];
    this.field = (this.data && this.data.field) || [];
    this.selectedRegister = this.registerList[0]['name'];
    this.selectedReg = this.registerList[0]['_id'];
    this.responsibleEmail = (this.data && this.data.field && this.data.field.default_responsible);
    this.managerGroup = (this.data && this.data.field && this.data.field.default_group);
    this.helperService.getTrans('control-template')
    .then((data2) => {
      this.translationLoader.loadTranslationsV2(data2);
    }).catch(err => {
      console.log(err);
    });
    this.officeHelpers.getLocalOfficeData()
    .then((data: any) => {
      data.groups = data.groups.map((group) => (group && group.name));
      this.officeGroupsList = (this.field && this.field.allGroups && this.field.allGroups.length) ? this.field.allGroups : data.groups;
      this.officeGroupsList = this.officeGroupsList || [];
      this.officeUsersList = data.users;
    }).catch(err => {
      console.log(err);
    });
  }

  createNewRecord() {
    const selectedReg: IProcess = <any>_.find(this.registerList, { _id: this.selectedReg }) || {};
    const selectedUser = _.find(this.officeUsersList, {email: this.responsibleEmail});
    if (selectedReg && selectedReg._id) {
      
      const allData = this.data.addressBookData || {};
      allData['contactId'] = this.data.contactId;
      allData['responsibleEmail'] = this.responsibleEmail;
      allData['responsibleId'] = (selectedUser && selectedUser['id']);
      allData['managerGroup'] = this.managerGroup;
      this.loading = true;
      this.helperService.createRegisterRecord(selectedReg._id, allData)
      // this.helperService.postRequest(`api/reg-process/${selectedReg._id}/new`, allData)
      .then((data) => {
        const linkData = {
          src_reg: this.data.regId,
          src_rec: this.data.contactId,
          dest_reg: data.regId,
          dest_rec: data._id,
          main: true
        };
        this.helperService.createNewLink(linkData)
        .then(() => {
          this.loading = false;
          this.dialogRef.close(true);
        }).catch(err => {
          this.loading = false;
          console.log(err);
        });
      }).catch(err => {
        this.loading = false;
        console.log(err);
      });
    } else {
      this.errorMsg = 'Process not Found';
    }
  }

}
