import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { ProcessSettingsListService } from '../../process-settings-list/process-settings-list.service';
import { MatSnackBar } from '@angular/material';
import * as _ from 'lodash';
import { SelectFormField, SelectionType } from './select-form-field.model';
import { InputDialogComponent } from 'app/main/content/global-component/input-dialog/input-dialog.component';
import { MessageSettingsComponent } from '../../../../global-component/message-settings/message-settings.component';
import { HelpersService } from '../../../../services/helpers/helpers.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { TaskDescriptions } from './taskDescriptions.model';
var AddTaskComponent = /** @class */ (function () {
    function AddTaskComponent(dialogRef, dialog, data, http, snackBar, helperService, processSettingList) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        this.http = http;
        this.snackBar = snackBar;
        this.helperService = helperService;
        this.processSettingList = processSettingList;
        this.stageExc = 'init';
        this.docTypes = [
            {
                title: 'One Record Document',
                key: 'oneRecordDocument'
            },
            {
                title: 'Instance Document',
                key: 'instanceDocument'
            }
        ];
        this.selectedIndex = 0;
        this.selectFormField = {};
        this.task = {
            id: new Date().getTime(),
            name: 'Task Name',
            taskType: '',
            formType: 'main-form'
        };
        this.transitions = [];
        this.triggers = [];
        this.waitingTransitionActivity = [];
        this.messageTypeShowEmail = ['gdpr-persona-approvalRequest', 'gdpr-persona-infoProcessing', 'calendar-create-event', 'process-start-another-process'];
        this.excecutionStages = ['init', 'pending', 'leaving', 'error'];
        this.taskTypes = [];
        this.actionONTaskFormField = [];
        this.operators = [
            {
                key: '<',
                value: 'Equal to'
            },
            {
                key: '=',
                value: 'Less Than'
            },
            {
                key: '<=',
                value: 'Less Than Equal'
            },
            {
                key: '>',
                value: 'Greater Than'
            },
            {
                key: '>=',
                value: 'Greater Than Equal'
            },
            {
                key: 'in',
                value: 'Includes'
            },
            {
                key: 'between',
                value: 'Between'
            }
        ];
        this.positionFields = [];
        this.tasksWithSettings = [
            'update_register_record',
            'generate_record_report',
            'send_email',
            'cron_task',
            'check_user_in_profile',
            'user_invitation',
            'sms_one_way',
            'generate_pdf_protocol',
            'send_notification',
            'messaging_jabber',
            'doller_calc_value',
            'install_office_plugin',
            'uninstall_office_plugin',
            'create_personal_office',
            'create_user_profile',
            'invite_user_to_workspace',
            'register_rec_validation',
            'invite_user_to_office',
            'invite_instance_to_profile',
            'invitation_response_trigger',
            'jump_to_another_activity',
            'create_merge_request',
            'remove_cron_task',
            'create_branch',
            'move_position_child_records',
            'cron_task_for_actions',
            'register_record_report',
            'process_start_another_process',
            'process_create_register_record',
            'calendar_create_event'
        ];
        this.notShowAddFields = [
            'register_rec_validation'
        ];
        this.templateList = [];
        this.timeDiffParams = [
            {
                key: 's',
                value: 'Seconds'
            },
            {
                key: 'm',
                value: 'Minutes'
            },
            {
                key: 'h',
                value: 'Hours'
            },
            {
                key: 'd',
                value: 'Days'
            },
            {
                key: 'M',
                value: 'Months'
            },
            {
                key: 'y',
                value: 'Years'
            }
        ];
        this.traverse = function (obj) {
            var ng5AllFields = [];
            obj.forEach(function (tabValue) {
                if (tabValue.tabContent && typeof tabValue.tabContent === 'object') {
                    tabValue.tabContent.forEach(function (value) {
                        if (value.field || (value.data !== null && typeof value.data === 'string')) {
                            ng5AllFields.push(value);
                        }
                        if (value.children && typeof value.children === 'object') {
                            value.children.forEach(function (childValue) {
                                if (childValue.field || (childValue.data !== null && typeof childValue.data === 'string')) {
                                    ng5AllFields.push(childValue);
                                }
                                if (childValue && typeof childValue === 'object') {
                                    childValue.forEach(function (nestedChildValue) {
                                        if (nestedChildValue.field || (nestedChildValue.data !== null && typeof nestedChildValue.data === 'string')) {
                                            ng5AllFields.push(nestedChildValue);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
            return ng5AllFields;
        };
        this.selectedOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
        this.activityData = this.data['activityData'] || {};
        this.processData = this.data['processData'] || {};
        this.activityList = (this.data['processData'] && this.data['processData']['activities']) || [];
        this.statusActivityList = this.activityList.filter(function (act) { return (act && act.type === 'state' && act.kind === 'interactive'); });
        this.formType = 'main-form';
        this.selectionType = JSON.parse(JSON.stringify(SelectionType)) || [];
        this.selectFormField = JSON.parse(JSON.stringify(SelectFormField)) || {};
        this.getAllFormFields();
        this.getTemplateList();
        this.geProcessList();
        this.taskDescriptions = JSON.parse(JSON.stringify(TaskDescriptions)) || {};
        this.getRegistriesList();
        this.messageTypes = [{
                key: 'approvalRequest',
                value: 'Approval Request'
            }, {
                key: 'infoProcessing',
                value: 'Info Processing'
            }];
        this.processData['transitions'].forEach(function (trans, index) {
            if (trans['source'] && _this.activityData && trans['source'].id === _this.activityData['id']) {
                _this.transitions.push(trans);
            }
        });
        this.processData['activities'].forEach(function (activity, index) {
            if (activity['type'] === 'trigger') {
                _this.triggers.push(activity);
            }
        });
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
            this.officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
        }
        if (JSON.parse(localStorage.getItem('ngStorage-profile'))) {
            this.creatorId = JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId'] || '';
            this.creatorLogin = JSON.parse(localStorage.getItem('ngStorage-profile'))['email'] || '';
        }
    }
    AddTaskComponent.prototype.ngAfterViewInit = function () {
        if (this.data['task']) {
            this.task = this.data['task'];
        }
        this.getCustomFields();
        this.stageExc = this.data['stageExc'] || this.stageExc;
        if (this.task[this.task.taskType] && this.task[this.task.taskType]['task'] && this.task[this.task.taskType]['task']['value']) {
            this.actionONTaskFormField = this.selectFormField[this.task[this.task.taskType]['task']['value']] || [];
        }
        // task[task.taskType]['positionRegId']
        if (this.task && this.task.taskType === 'move_position_child_records') {
            if (this.task[this.task.taskType] && this.task[this.task.taskType]['positionRegId']) {
                this.positionRegChange({ value: this.task[this.task.taskType]['positionRegId'] });
            }
        }
    };
    AddTaskComponent.prototype.ngOnInit = function () {
        this.getProcessTaskTypes();
        this.selectTask(this.data.temp);
        this.getProcessMessageTypes();
        this.getWaitingTransitionActivity();
    };
    AddTaskComponent.prototype.changeTaskType = function () {
    };
    AddTaskComponent.prototype.addNewKey = function (str) {
        this.task[this.task.taskType]['params'].push({
            key: '',
            activitie: ''
        });
    };
    AddTaskComponent.prototype.save = function () {
        var temp = {
            type: this.stageExc,
            operation: this.data['operation'],
            task: this.task
        };
        if (this.task.name) {
            this.dialogRef.close(temp);
        }
        else {
            this.snackBar.open('Please Enter Task name', 'Okay', {
                duration: 2000,
            });
        }
    };
    AddTaskComponent.prototype.onChangeFormType = function () {
        if (this.task.formType === 'main-form' && this.processData && this.processData['integratedRegisterId']) {
            this.getRegisterData(this.processData['integratedRegisterId']).then(function (response) {
            }).catch(function (err) {
                console.log(err);
            });
        }
        else if (this.task.formType === 'sub-form' && this.activityData && this.activityData['selectedForm']) {
            this.getFormField(this.activityData['selectedForm']).then(function (response) {
            }).catch(function (err) {
                console.log(err);
            });
        }
    };
    AddTaskComponent.prototype.getProcessMessageTypes = function () {
        var _this = this;
        this.helperService.getRequest("api/get-message-types")
            .then(function (data) {
            _this.messageTypes = data || [];
        }).catch(function (err) {
            console.log(err);
        });
    };
    AddTaskComponent.prototype.getRegisterData = function (regId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.helperService.getRegisterData(regId)
                .then(function (regData) {
                _this.formId = regData[0]['ng5FormId'];
                _this.getFormField(regData[0]['ng5FormId'])
                    .then(function (data) {
                    resolve(regData[0]);
                }).catch(function (err) {
                    reject();
                });
            }).catch(reject);
        });
    };
    AddTaskComponent.prototype.getTemplateList = function () {
        var _this = this;
        try {
            this.helperService.getTemplateList()
                .then(function (templateList) {
                _this.templateList = templateList || [];
            }).catch(function (err) {
                console.log(err);
            });
        }
        catch (err) {
            console.log(err);
        }
    };
    AddTaskComponent.prototype.geProcessList = function () {
        var _this = this;
        this.processSettingList.getProcess().then(function (data) {
            _this.processList = data || [];
        }).catch(function (err) {
            console.log(err);
        });
    };
    AddTaskComponent.prototype.getRegistriesList = function () {
        var _this = this;
        this.helperService.getSmallRegisterList().then(function (data) {
            data = data || [];
            // remove workspace type registers
            data = data.filter(function (reg) { return (reg && reg.type !== 'workspace'); });
            _this.registriesListArr = data || [];
        }).catch(function (err) {
            console.log(err);
        });
    };
    AddTaskComponent.prototype.openInstructionPopup = function (infoKey) {
        this.helperService.openInstructionPopup(infoKey, 'translation');
    };
    AddTaskComponent.prototype.getAllFormFields = function () {
        var _this = this;
        this.getRegisterData(this.processData['integratedRegisterId']).then(function (response) {
            if (_this.activityData['selectedForm']) {
                return _this.getFormField(_this.activityData['selectedForm']);
            }
        }).catch(function (err) {
            console.log(err);
        });
    };
    AddTaskComponent.prototype.getFormField = function (formId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.getForm(formId)
                .then(function (data) {
                _this.FormField = _this.FormField || [];
                _this.FormField = _this.FormField.concat(_this.traverse(data));
                _this.positionFields = _this.FormField.filter(function (x) { return x.control_type === 'position'; });
                if (_this.task && _this.task.taskType === 'process_start_another_process') {
                    // positionFields
                    _this.task[_this.task.taskType]['positionFields'] = _this.positionFields || [];
                }
                resolve();
            })
                .catch(function (err) { reject(); });
        });
    };
    AddTaskComponent.prototype.getForm = function (formId) {
        return this.helperService.getForm(formId)
            .then(function (data) {
            return (data && data['ng5Form']);
        });
    };
    AddTaskComponent.prototype.cancel = function () {
        this.dialogRef.close();
    };
    AddTaskComponent.prototype.delete = function () {
        var _this = this;
        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
        this.confirmDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.dialogRef.close({ type: _this.stageExc, operation: 'delete' });
            }
            _this.confirmDialogRef = null;
        }, function (err) {
            console.log(err);
        });
    };
    AddTaskComponent.prototype.getProcessTaskTypes = function () {
        var _this = this;
        this.helperService.getRequest("api/process-tasks-types")
            .then(function (data) {
            _this.taskTypes = (data && data.allTasks) || [];
            // this.taskTypes.forEach(ele => {
            //     if (ele.key === this.task.taskType) {
            //         this.selectedTaskType = ele.title;
            //     }
            // });
        }).catch(function (err) {
            console.log(err);
        });
    };
    AddTaskComponent.prototype.selectTask = function (temp) {
        if (temp === void 0) { temp = {}; }
        temp = temp || {};
        this.task.taskType = temp.key || temp.taskType;
        this.selectedTaskType = temp.title || temp.name;
        this.task[this.task.taskType] = this.task[this.task.taskType] || {};
        this.task[this.task.taskType]['params'] = this.task[this.task.taskType]['params'] || [];
        if (this.task.taskType === 'inc_messages_gateway') {
            this.task[this.task.taskType]['inputKey'] = 'messageType';
            this.selectedIndex = 1;
        }
        else if (this.task.taskType === 'cron_task' || this.task.taskType === 'send_email' || this.tasksWithSettings.includes(this.task.taskType)) {
            if (this.task.taskType === 'send_email') {
                this.task[this.task.taskType]['uploadFile'] = {};
            }
            this.defaultValues(this.task.taskType);
            this.selectedIndex = 1;
            if (this.task.taskType === 'process_start_another_process') {
                // positionFields
                this.task[this.task.taskType]['positionFields'] = this.positionFields || [];
            }
        }
        else if (this.task.taskType === 'send_message') {
            this.openMessageSettings();
        }
    };
    AddTaskComponent.prototype.openMessageSettings = function () {
        var _this = this;
        var tempDialog = this.dialog.open(MessageSettingsComponent, {
            disableClose: false,
            data: {
                selectFormField: this.selectFormField,
                task: this.task,
                FormField: this.FormField,
                processData: this.processData
            },
            width: '100%',
            panelClass: 'happ-form-builder-dialog'
        });
        tempDialog.afterClosed().subscribe(function (result) {
            if (result) {
                _this.task = result || {};
            }
        }, function (err) {
            console.log(err);
        });
    };
    AddTaskComponent.prototype.defaultValues = function (messageType) {
        var _this = this;
        if (this.selectFormField[messageType]) {
            this.selectFormField[messageType] = this.selectFormField[messageType] || [];
            this.selectFormField[messageType].forEach(function (formField) {
                formField = formField || {};
                _this.task[_this.task['taskType']][formField['key']] = _this.task[_this.task['taskType']][formField['key']] || {};
                _this.task[_this.task['taskType']][formField['key']]['value'] = formField['key'];
                _this.task[_this.task['taskType']][formField['key']]['optionType'] = formField['optionType'] || 'formField';
            });
        }
    };
    AddTaskComponent.prototype.actionONTaskEvent = function (taskType) {
        var _this = this;
        this.task[this.task['taskType']]['actionOnTask'] = this.task[this.task['taskType']]['actionOnTask'] || {};
        this.actionONTaskFormField = this.selectFormField[taskType] || [];
        this.actionONTaskFormField.forEach(function (formField) {
            _this.task[_this.task['taskType']]['actionOnTask'].task_key = taskType;
            _this.task[_this.task['taskType']]['actionOnTask'][formField['key']] = _this.task[_this.task['taskType']][formField['key']] || {};
            _this.task[_this.task['taskType']]['actionOnTask'][formField['key']]['value'] = _this.task[_this.task['taskType']]['actionOnTask'][formField['key']]['value'] || formField['key'];
            _this.task[_this.task['taskType']]['actionOnTask'][formField['key']]['optionType'] = _this.task[_this.task['taskType']]['actionOnTask'][formField['key']]['optionType'] || 'formField';
        });
    };
    AddTaskComponent.prototype.addField = function (type) {
        var _this = this;
        this.inputDialogRef = this.dialog.open(InputDialogComponent, {
            disableClose: false
        });
        if (type === 'task') {
            this.inputDialogRef.componentInstance.inputPlaceholder = 'Key Name';
            this.inputDialogRef.componentInstance.confirmMessage = 'Key Name';
        }
        else {
            this.inputDialogRef.componentInstance.inputPlaceholder = 'Value Name';
            this.inputDialogRef.componentInstance.confirmMessage = 'Value Name';
        }
        this.inputDialogRef.componentInstance.inputValue = '';
        this.inputDialogRef.afterClosed().subscribe(function (result) {
            if (result && type === 'task') {
                if (_this.tasksWithSettings.includes(_this.task.taskType)) {
                    _this.selectFormField[_this.task.taskType] = _this.selectFormField[_this.task.taskType] || [];
                    _this.selectFormField[_this.task.taskType].push({
                        key: result,
                        value: result,
                        type: 'custom'
                    });
                }
                else {
                    _this.selectFormField[_this.task[_this.task.taskType]['messageType']] = _this.selectFormField[_this.task[_this.task.taskType]['messageType']] || [];
                    _this.selectFormField[_this.task[_this.task.taskType]['messageType']].push({
                        key: result,
                        value: result,
                        type: 'custom'
                    });
                }
                _this.task[_this.task['taskType']][result] = _this.task[_this.task['taskType']][result] || {};
                _this.task[_this.task['taskType']][result]['value'] = result;
                _this.task[_this.task['taskType']][result]['optionType'] = 'formField';
                _this.task[_this.task['taskType']][result]['type'] = 'custom';
            }
            else {
                _this.task[_this.task.taskType]['mappingValue'] = _this.task[_this.task.taskType]['mappingValue'] || [];
                _this.task[_this.task.taskType]['mappingValue'].push({ range: '', operator: '', value: result });
            }
            _this.inputDialogRef = null;
        });
    };
    AddTaskComponent.prototype.removeField = function (field, type) {
        if (type === 'task') {
            if (this.tasksWithSettings.includes(this.task.taskType)) {
                this.selectFormField[this.task.taskType] = this.selectFormField[this.task.taskType] || [];
                var index = _.findIndex(this.selectFormField[this.task.taskType], { key: field.key, type: 'custom' });
                if (index >= 0) {
                    this.selectFormField[this.task.taskType].splice(index, 1);
                }
                if (this.task[this.task['taskType']][field.key]) {
                    delete this.task[this.task['taskType']][field.key];
                }
            }
            else {
                this.selectFormField[this.task[this.task.taskType]['messageType']] = this.selectFormField[this.task[this.task.taskType]['messageType']] || [];
                var index = _.findIndex(this.selectFormField[this.task[this.task.taskType]['messageType']], { key: field.key, type: 'custom' });
                if (index >= 0) {
                    this.selectFormField[this.task[this.task.taskType]['messageType']].splice(index, 1);
                }
                if (this.task[this.task['taskType']][field.key]) {
                    delete this.task[this.task['taskType']][field.key];
                }
            }
        }
        else {
            this.task[this.task.taskType]['mappingValue'] = this.task[this.task.taskType]['mappingValue'] || [];
            this.task[this.task.taskType]['mappingValue'].splice(field, 1);
        }
    };
    AddTaskComponent.prototype.getCustomFields = function () {
        if (this.task && this.task['taskType'] && this.task[this.task['taskType']]) {
            if (this.tasksWithSettings.includes(this.task.taskType)) {
                this.selectFormField[this.task.taskType] = this.selectFormField[this.task.taskType] || [];
                for (var key in this.task[this.task['taskType']]) {
                    if (this.task[this.task['taskType']] && this.task[this.task['taskType']][key] && this.task[this.task['taskType']][key]['type'] === 'custom') {
                        this.selectFormField[this.task.taskType].push({
                            key: key,
                            value: key,
                            type: 'custom'
                        });
                    }
                }
            }
            else {
                this.selectFormField[this.task[this.task.taskType]['messageType']] = this.selectFormField[this.task[this.task.taskType]['messageType']] || [];
                for (var key in this.task[this.task['taskType']]) {
                    if (this.task[this.task['taskType']] && this.task[this.task['taskType']][key] && this.task[this.task['taskType']][key]['type'] === 'custom') {
                        this.selectFormField[this.task[this.task.taskType]['messageType']].push({
                            key: key,
                            value: key,
                            type: 'custom'
                        });
                    }
                }
            }
        }
    };
    AddTaskComponent.prototype.getWaitingTransitionActivity = function () {
        var _this = this;
        this.processData['transitions'].forEach(function (trans, index) {
            if (trans['source'] && trans['dest'] && _this.activityData && trans['source'].id === _this.activityData['id'] && trans['kind'] === 'waiting') {
                _this.waitingTransitionActivity.push(trans['dest']);
            }
        });
    };
    AddTaskComponent.prototype.uploadFile = function (file, uploadVar) {
        var _this = this;
        // tslint:disable-next-line:max-line-length
        // https://backend.pinbox24.com/api/files/5a79b44b672b8b7cc85c3fb7/upload?creatorId=5a780841de4c552fc25aebf4&creatorLogin=suryanshsinghstudy@gmail.com&officeId=5a79b44b672b8b7cc85c3fb7&recId=5a955afe520a273f540c609c&regId=5a892752b327af07b4e99c16
        var files = file.files;
        var formData = new FormData();
        console.log(typeof files);
        for (var i = 0; i < files.length; i++) {
            formData.append('uplFile', files[i], files[i].name);
        }
        this.helperService.uploadFiles('', formData)
            .then(function (data) {
            if (data && data.length) {
                _this.task[_this.task.taskType][uploadVar] = _this.task[_this.task.taskType][uploadVar] || {};
                _this.task[_this.task.taskType][uploadVar]['value'] = (data[0] && data[0]['_id']) || '';
                _this.fileName = (data[0] && data[0]['originalname']) || '';
            }
        }).catch(function (err) {
            console.log(err);
        });
    };
    AddTaskComponent.prototype.positionRegChange = function (eve) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var regData, processDoc, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        this.positionProcessAct = [];
                        if (!(eve && eve.value)) return [3 /*break*/, 4];
                        this.loading = true;
                        return [4 /*yield*/, this.helperService.getRegisterData(eve.value)];
                    case 1:
                        regData = _a.sent();
                        if (!(regData && regData[0] && regData[0].integratedProcessId)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.helperService.getProcess(regData[0].integratedProcessId)];
                    case 2:
                        processDoc = _a.sent();
                        processDoc = processDoc[0] || {};
                        this.positionProcessAct = processDoc['activities'] || [];
                        this.positionProcessAct = this.positionProcessAct.filter(function (act) { return (act && act.type === 'state' && act.kind === 'interactive'); });
                        _a.label = 3;
                    case 3:
                        this.loading = false;
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        console.log(err_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return AddTaskComponent;
}());
export { AddTaskComponent };
