import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormComponentService } from '../../../global-component/form-component/form-component.service';

@Component({
  selector: 'app-edit-form-popup',
  templateUrl: './edit-form-popup.component.html',
  styleUrls: ['./edit-form-popup.component.scss']
})
export class EditFormPopupComponent implements OnInit, AfterViewInit {
  
  dialogTitle: string;
  action: string;

  constructor(
    public dialogRef: MatDialogRef<EditFormPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formComponentService: FormComponentService,

  )
  {
    this.action = data.action;
    this.formComponentService.onFormDataChanged.next({
      regId: data['regId'] || '',
      saveButton: true,
      operation: this.action,
      defaultValue: data['defaultValue'],
      popupOptions: {
        dialogRef,
        // courseSteps: this.course['appList'],
        // stepNo: step
      }
    });

    if ( this.action === 'edit' )
    {
      this.dialogTitle = 'Edit';
      if (data && data['recId']) {
        this.formComponentService.onRecIdChanged.next(data['recId']);
      }
    }
    else
    {
      this.dialogTitle = 'New Record';
    }

  }

  ngOnInit()
  {
  }

  ngAfterViewInit(): void {
  }
}
