import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import * as $ from 'jquery';
import { NgSummernoteService } from '../ng-summernote/ng-summernote.service';
import { HelpersService } from '../../services/helpers/helpers.service';
import { IFormInterface } from '../../interfaces/form.interface';

@Component({
  selector: 'app-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss'],
})
export class TemplateModalComponent implements OnInit, AfterViewInit {
  @ViewChild('summernote') el: ElementRef;

  temp: string;
  summernoteHint: { mentions: any; match: RegExp; search: (keyword: any, callback: any) => void; content: (item: any) => string; }[];
  selectedFormId: any;
  officeId: any;
  regList: any[];
  temppp;
  formVar = [];
  templateData: any = {
    category: '',
    title: '',
    officeId: '',
    shortSymbol: '',
    header: {
      show: true,
      content: ''
    },
    footer: {
      show: true,
      content: ''
    },
    mainContent: {
      content: ''
    },
    printringVarialbles: {
      line: {
        header: true,
        footer: true
      },
      margin: {
        bottom: 20,
        top: 20
      },
      spacing: {
        footer: 5,
        header: 5
      }
    }
  };
  pdfURL;
  globalVars = [];
  operation;
  currentView = 'editor';

  // Editors ng-model bindings
    
  // summerdata2 = 'content';
    
  // If you want add editors bindings to some model
  // model: any = {
  //   summerdata2: this.summerdata2
  // };

  constructor(
    public dialogRef: MatDialogRef<TemplateModalComponent>,
    public snackBar: MatSnackBar, 
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private ngSummernoteService: NgSummernoteService,     
    private helperService: HelpersService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getRegisterList();
    //  get the global vars..
    const selectedOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
    if (selectedOffice && selectedOffice['users']) {
      selectedOffice['users'].forEach(ele => {
        this.globalVars.push(ele['email']);
      });
    }
    this.operation = this.data['operation'];
    if (this.operation === 'edit') {
      this.templateData = this.data.data;
      this.templateData.header = this.templateData.header || {content: '', show: true};
      this.templateData.header.content = this.templateData.header.content || '';
      this.templateData.footer = this.templateData.footer || {content: '', show: true};
      this.templateData.footer.content = this.templateData.footer.content || '';
    } else {
      this.templateData.mainContent.content = '';

      this.templateData.title = '';
      this.templateData.category = '';
      this.templateData.shortSymbol = '';
    }
  }

  downloadFile(data){
    const blob = new Blob([data], { type: 'text/pdf' });
    const url = window.URL.createObjectURL(blob);
    // window.open(url);
  }


  saveData() {
    if (!this.templateData.title && this.templateData.title.length === 0) {
      return this.snackBar.open('Fill title', 'Okey', {
        duration: 2000,
      });
    }
    this.templateData['formId'] = this.selectedFormId;
    if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
      this.templateData.officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
      if (this.operation === 'add') {
        this.helperService.createTemplate(this.templateData)
          .then(() => {
            this.dialogRef.close();
            this.snackBar.open('Saved Successfully', 'Okey', {
              duration: 2000,
            });
          }).catch(err => {
            console.log(err);
            this.snackBar.open(err || 'Error Occured while creating', 'Okey', {
              duration: 2000,
            });
          });
      } else {
        this.helperService.updateTemplate(this.data['data']['_id'], this.templateData)
          .then(() => {
            this.dialogRef.close();
            this.snackBar.open('Updated Successfully', 'Okey', {
              duration: 2000,
            });
          }).catch(err => {
            console.log(err);
            this.snackBar.open(err || 'Error occured while updating', 'Okey', {
              duration: 2000,
            });
          });
      }
    } else {
      this.snackBar.open('No office Id', 'Okey', {
        duration: 2000,
      });
    }
  }

  updatePDF() {
    if (this.data && this.data['data'] && this.data['data']['_id']) {
      this.helperService.getDocument(this.data['data']['_id'])
        .then((document) => {
          const blob = this.b64toBlob(document['base64'], 'application/pdf', null);
          const blobUrl = URL.createObjectURL(blob);
          this.pdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
        }).catch(err => {
          this.snackBar.open(err || 'Error occured while updating the template', 'Okey', {
            duration: 2000,
          });
          console.log(err);
        });
    } else {
      this.snackBar.open('No Template Id', 'Okey', {
        duration: 2000,
      });
    }
  }


  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    b64Data = b64Data || '';

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }



  formChanged() {
    // get form..
    if (this.selectedFormId) {
      this.helperService.getForm(this.selectedFormId)
        .then((formData: IFormInterface) => {
          const formVar = [];
          this.traverse(formData['ng5Form'] || [], function (k, v) {
            if (k === 'label' && v) {
              formVar.push(v.split(' ').join('_'));
            }
          });
          this.data['formVar'] = formVar;
          this.summernoteHint[0]['mentions'] = formVar;
          this.ngSummernoteService.onHintChange.next(this.summernoteHint);
        }).catch(err => {
          console.log(err);
          this.snackBar.open(err || 'Error in getting the form', 'Okey', {
            duration: 2000,
          });
        });
    } else {
      this.snackBar.open('No Form Id', 'Okey', {
        duration: 2000,
      });
    }
  }


  traverse = function (o, fn) {
    // tslint:disable-next-line:forin
    for (const i in o) {
      fn.apply(this, [i, o[i]]);
      if (o[i] !== null && typeof (o[i]) === 'object') {
        this.traverse(o[i], fn);
      }
    }
  };


  getRegisterList() {
    this.regList = [];
    this.helperService.getFormList()
    .then((formList: Array<IFormInterface>) => {
      this.regList = formList || [];
    }).catch(err => {
      this.snackBar.open(err || 'Error occurred while getting the register list', 'Okey', {
        duration: 2000,
      });
      console.log(err);
    });
  }

  ngAfterViewInit() {

    this.summernoteHint = [
      {
        mentions: this.data['formVar'] || [],
        match: /\B#(\w*)$/,
        search: function (keyword, callback) {
          callback($.grep(this.mentions, function (item: Array<any>) {
            return item.indexOf(keyword) === 0;
          }));
        },
        content: function (item) {
          return '##' + item + '## ';
        }    
      },
      {
        mentions: this.globalVars || [],
        match: /\B@(\w*)$/,
        search: function (keyword, callback) {
          callback($.grep(this.mentions, function (item: Array<any>) {
            return item.indexOf(keyword) === 0;
          }));
        },
        content: function (item) {
          return '@@' + item + '@@ ';
        }    
      }
    ];

    if (this.data.data) {
    this.selectedFormId = this.data['data']['formId'];
    }
    this.formChanged();
  }
}

// interfaces

interface TemplateForm {
  title: String;
  mainContent: {
    content: String;
  };
  shortSymbol: String;
  category: String;
  officeId: String;
}
