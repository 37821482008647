
export const ComponentMapping = {
    'academy': [],
    'addressbook': [],
    'agenda-view': [],
    'app-builder': [],
    'books': ['books_user'],
    'calendar': ['calendar'],
    'calendar-template': ['calendar'],
    'custom-registries': ['customReg', 'oneRec'],
    'dashboard': ['dashboard'],
    'department-settings': [],
    'gdpr-resources': [],
    'global-form': [],
    'global-template': [],
    'language': [],
    'office-configuration': [],
    'office-global-var': [],
    'office-list': [],
    'org-chart-settings': ['books_user'],
    'persona': ['persona'],
    'plugin': ['trans_app_setting', 'market_places'],
    'post-book': [],
    'processes': ['processState'],
    'processes-settings': ['processInstance'],
    'profile': ['profile'],
    'settings': ['trans_app_setting', 'courseSetting'],
    'users-settings': [],
    'workspace': ['workspace'],
    'workspace-management': ['workspace'],
    'ws-app-settings': [],
    '404': [],
    'ag-grid-table': [],
    'control-template': ['deliveryAddress', 'document', 'oneRec', 'ct', 'add_rec', 'ONE_REC'],
    'datatable': ['dttable'],
    'drag-and-drop': ['dtTablePrintTemplate'],
    'form-builder-model': [],
    'formBuilder': ['formBuilder'],
    'form-component': [],
    'one-recordview': ['PROCESS_PROCESSING', 'ONE_REC', 'workspace'],
    'view_profile': ['view_profile'],
    'authentication': ['auth', 'setPassword', 'global'],
    
    'toolbar': ['toolbar', 'info', 'popup', 'global'],
    'postbook': ['postbook'],
    'send_letter': ['send_letter'],
    'barcode': ['barcode'],
    'excel_import': ['excel_import'],
    'file_preview': ['file_preview'],
    'invitation': ['invitation'],
    'issueReporter': ['issueReporter'],
    'workspace_widget_settings': ['workspace_widget_settings'],
    'bulk_action': ['bulk_action'],
    'processSettings': ['processSettings']
};
