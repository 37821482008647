import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IFormInterface } from '../../interfaces/form.interface';
@Component({
  selector: 'app-field-option-update-dialog',
  templateUrl: './field-option-update-dialog.component.html',
  styleUrls: ['./field-option-update-dialog.component.scss']
})
export class FieldOptionUpdateDialogComponent implements OnInit {

  /**
   * data.fieldLabel
   * data.formData
   * data.fieldOptions
   */
  loading = false;
  
  constructor(
    private _helperService: HelpersService,
    public dialogRef: MatDialogRef<FieldOptionUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit() {
  }

  update() {
    try {
      const formData: IFormInterface = this.data.formData || {};
      formData.ng5Form = formData.ng5Form || [];
      formData.ng5Form.forEach(tab => {
        tab.tabContent = tab.tabContent || [];
      
        tab.tabContent.forEach(field => {
          if (field.category === 'radio' || field.category === 'checkbox'  ) {
            if (this.data.fieldLabel === field.label && field.options && this.data.fieldOptions.length !== field.options) {
              field.options = this.data.fieldOptions;
              this.loading = true;
              this._helperService.updateForm(formData._id, formData)
                .then((data) => {
                  this.loading = false;
                  this.dialogRef.close(this.data.fieldOptions);
                  this._helperService.openSnackBar('Update form fields');
                }).catch(err => {
                  this.loading = false;
                  console.log(err);
                  this._helperService.openSnackBar(err);
                });
            }
          } else if (field.control_type === 'section') {
            field.children = field.children || [];
            field.children.forEach(sectionChild => {
              sectionChild.forEach(sectionChildField => {
              if (sectionChildField.category === 'radio' || sectionChildField.category === 'checkbox') {
                if (this.data.fieldLabel === sectionChildField.label && sectionChildField.options && this.data.fieldOptions.length !== sectionChildField.options) {
                  sectionChildField.options = this.data.fieldOptions;
                  this.loading = true;
                  this._helperService.updateForm(formData._id, formData)
                    .then((data) => {
                      this.loading = true;
                      this.dialogRef.close(this.data.fieldOptions);
                      this._helperService.openSnackBar('Update form fields');
                    }).catch(err => {
                      this.loading = false;
                      console.log(err);
                      this._helperService.openSnackBar(err);
                    });
                }
              }

              });
            });
          }
        });
      });
    } catch (err) {
      console.log(err);
    }

  }

  addOption() {
    this._helperService.openInputPopUp('ct.enter_option_title', 'ct.confirm_message', '')
      .subscribe((data) => {
        if (data) {
          this.data.fieldOptions = this.data.fieldOptions || [];
          if (this.data.field.control_type === 'checkbox') {
            this.data.fieldOptions.push({
              'name': data,
              value: false
            });
          } else {
            this.data.fieldOptions.push({
              'title': data,
              'background': 'white',
              'color': 'gray'
            });
          }
        }
      });
  }

  formatTitle(str: string): string {
    return str.replace(/[^A-Z0-9]/ig, '_');
  }

}
