<div *ngIf="regData && !loading">
  <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title"> {{ 'workspace.apps_settings' | translate }} </span>
      <span fxFlex></span>
      <!-- <button (click)="saveData()" mat-icon-button>
        <mat-icon>done</mat-icon>
      </button> -->
      <div>
        <button *ngIf="selectedIndex === 0 || (selectedIndex === 1 && requiredDataFields && requiredDataFields.length)" (click)="saveData(selectedIndex)" class="happ-hover-button">
          Save
        </button>
      </div>
      <button (click)="dialogRef.close(null)" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div class="example-card">
    <mat-tab-group [(selectedIndex)]="selectedIndex">
      <mat-tab label="{{ 'workspace.apps_settings' | translate }}">
        <div class="p-12">
            <div fxLayout="row" class="happ-ws-settings-heading">
              <h3 class="m-0">
                {{ 'workspace.general' | translate }}
              </h3>
              <span fxFlex></span>
              <!-- <button (click)="saveData(0)" class="happ-hover-button">
                Save
              </button> -->
            </div>
            <mat-tab-group>
              <mat-tab label="General"> 
                <div>
                    <div>
                        <div class="p-12">
                          <div fxLayoutAlign="space-between center" fxLayout="row wrap">
                            <div fxFlex="45" class="happ-form-section">
                              <p>{{ 'workspace.app_name' | translate }}</p>
                              <input [(ngModel)]="appData.stepLabel" class="happ-form-input" placeholder="">
                            </div>
          
                            <div fxFlex="45" class="happ-form-section">
                              <p>{{ 'workspace.item_name' | translate }}</p>
                              <input [(ngModel)]="appData.itemName" class="happ-form-input" placeholder="">
                            </div>
                          </div>
          
                          <div fxLayoutAlign="space-between center" fxLayout="row wrap">
                            <div fxFlex="30" class="happ-form-section">
                              <p>{{ 'workspace.sort' | translate }}</p>
                              <input type="number" [(ngModel)]="appData.stepNumber" class="happ-form-input" placeholder="">
                            </div>
          
                            <div fxFlex="30" fxLayout="column">
                              <div fxLayout="column" class="h-72">
                                <p style="font-weight: 700" class="mb-4">{{ 'workspace.icon' | translate }}:</p>
                                <div matTooltip="Click to edit" style="cursor: pointer;" (click)="selectIcon()">
                                  <mat-icon class="font-size-30"> {{ appData.icon }} </mat-icon>
                                </div>
                              </div>
                            </div>

                            <div fxFlex="30" class="happ-form-section">
                              <p>{{ 'workspace.show_in_drop_down' | translate }}</p>
                              <mat-checkbox [(ngModel)]="appData.showInDropDown" > {{(appData.showInDropDown) ? 'Yes' : 'No'}} </mat-checkbox>
                            </div>
                          </div>
                          <div fxLayoutAlign="space-between center" fxLayout="row wrap">
                            <div fxFlex="98">
                                <div style="margin: auto;" class="happ-form-section">
                                  <p>{{ 'workspace.app_desc' | translate }}</p>
                                  <textarea [(ngModel)]="regData.description" class="happ-form-input h-100" placeholder=""></textarea>
                                </div>
                            </div>
                          </div>

                          <div fxLayoutAlign="space-between center" fxLayout="row wrap">
                            <div fxFlex="45" class="happ-form-section">
                              <button class="mt-20" aria-label="dttable_process_Settings" *ngIf="appData && appData.integratedProcessId"
                                mat-raised-button style="text-transform: capitalize;" style="margin-left: 12px;"
                                (click)="moveToProcessSettings(appData.integratedProcessId)" color="warn">
                                {{ 'workspace.process_settings' | translate }}
                              </button>
                            </div>
            
                            <!-- <div fxFlex="45" class="happ-form-section">
                              <p>{{ 'workspace.item_name' | translate }}</p>
                              <input [(ngModel)]="appData.itemName" class="happ-form-input" placeholder="">
                            </div> -->
                          </div>
                        </div>
                      </div>
                </div>  
              </mat-tab>
              <mat-tab label="Advance"> 
                  <div>
                      <div fxLayoutAlign="space-around center" fxLayout="row wrap">
                          <div fxFlex="45" fxLayout="column">
                            <div class="happ-form-section">
                              <p>{{ 'workspace.signature' | translate }}</p>
                              <input [(ngModel)]="regData.reg_symbol" class="happ-form-input" placeholder="">
                            </div>
                          </div>
                          <div fxFlex="45">
                            <p style="display: block; font-weight: 700; margin-bottom: 10px;">
                              {{ 'workspace.reset_signature_number' | translate }}</p>
                            <mat-radio-group [(ngModel)]="regData.reg_signature_pattern" fxLayout="column">
                              <mat-radio-button class="mr-4" class="example-radio-button" value="ss/yy/nn">
                                {{ 'workspace.yearly' | translate }}</mat-radio-button>
                              <mat-radio-button class="mr-4" class="example-radio-button" value="ss/yy/mm/nn">
                                {{ 'workspace.monthly' | translate }}</mat-radio-button>
                              <mat-radio-button class="mr-4" class="example-radio-button" value="ss/yy/mm/dd/nn">
                                {{ 'workspace.daily' | translate }}</mat-radio-button>
                            </mat-radio-group>
                          </div>
                        </div>

                        <div class="mb-8">
                          <h3 class="happ-ws-settings-heading">
                            {{ 'workspace.filters' | translate }}
                          </h3>
                          <div style="width: 96%; margin: auto;">
                            <div>
                              <p>{{ 'workspace.hard_filter' | translate }}
                                  <button (click)="openInstructionPopup('info.ws_settings_hard_filter_info')" mat-icon-button>
                                    <mat-icon>info</mat-icon>
                                  </button>

                              </p>
                              <div style="width: 96%; margin: auto;">
                                <div fxFlex="48">
                                  <input [(ngModel)]="appData.hardFilter" class="happ-form-input" placeholder="">
                                  <p class=" m-0 mt-8 font-size-16" (click)="checkMqFilter()" style="cursor: pointer; color: brown;">{{ 'workspace.validate_filter' | translate }}</p>
                                </div>
                                <div fxFlex="48">
                                  <mat-checkbox [(ngModel)]="appData.showHardFilterText">{{ 'workspace.show_hard_filter_text' | translate }}
                                  </mat-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <div style="width: 96%; margin: auto;">
                            <div>
                              <p>{{ 'workspace.default_date_filter' | translate }}
                              </p>
                              <div style="width: 96%; margin: auto;">
                                <app-date-filter-field class="m-8" [(model)]="appData['defaultDateFilter']"
                                  [fieldTitle]="'dttable.registredDataTime' | translate"></app-date-filter-field>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                        <div>
                          <h3 class="happ-ws-settings-heading">
                            {{ 'workspace.scanque' | translate }}
                          </h3>
                          <div *ngIf="regData.scanqueue" style="width: 96%; margin: auto;">
                            <div>
                              <!-- <p>{{ 'workspace.show_scanque' | translate }}</p> -->
                              <!-- <input [(ngModel)]="regData.name" class="happ-form-input" placeholder=""> -->
                              <mat-checkbox [(ngModel)]="regData.scanqueue.isChecked">{{ 'workspace.show_scanque' | translate }}
                              </mat-checkbox>
                            </div>
                            <div class="mt-12" fxLayout="row wrap">
                              <button mat-raised-button *ngFor="let scanque of scanqueList"
                                [color]="(regData.scanqueue.sqObj === scanque.email)? 'primary' : ''"
                                (click)="regData.scanqueue.sqObj = scanque.email"> {{ scanque.email }} </button>
                            </div>
                          </div>
                        </div>
                        <div class="mt-8">
                          <h3 class="happ-ws-settings-heading">
                            {{ 'workspace.actions' | translate }}
                          </h3>
                          <div style="width: 96%; margin: auto;">
                            <div fxFlex="48">
                              <mat-checkbox [(ngModel)]="appData.hideHeaderActions">{{ 'workspace.hide_header_actions' | translate }}
                              </mat-checkbox>
                            </div>
                            <div fxFlex="48">
                              <mat-checkbox [(ngModel)]="appData.showPostbook">{{ 'workspace.show_postbook' | translate }}
                              </mat-checkbox>
                            </div>
                          </div>
                          <div style="width: 96%; margin: auto;">
                            <div fxFlex="48">
                              <mat-checkbox [(ngModel)]="appData.isCalendar">{{ 'workspace.show_calendar' | translate }}
                              </mat-checkbox>
                            </div>
                            <div fxFlex="48">
                              <mat-checkbox [(ngModel)]="appData.hideEditButton">{{ 'workspace.hide_edit_button' | translate }}
                              </mat-checkbox>
                            </div>
                          </div>
                          <div style="width: 96%; margin: auto;">
                              <div fxFlex="48">
                                <mat-checkbox [(ngModel)]="appData.hideActionColumn">{{ 'workspace.hide_action_column' | translate }}
                                </mat-checkbox>
                              </div>
                              <div fxFlex="48">
                                <mat-checkbox [(ngModel)]="appData.showBulkAction">{{ 'workspace.show_bulk_action_button' | translate }}
                                </mat-checkbox>
                              </div>
                            </div>
                          <div *ngIf="appData.integratedProcessId" style="width: 96%; margin: auto;">
                            <div fxFlex="48">
                              <mat-checkbox [(ngModel)]="appData.isProcessStatusView">{{ 'workspace.default_process_status_view' | translate }}
                              </mat-checkbox>
                            </div>
                            <!-- <div fxFlex="48">
                              <mat-checkbox [(ngModel)]="appData.showBulkAction">{{ 'workspace.show_bulk_action_button' | translate }}
                              </mat-checkbox>
                            </div> -->
                          </div>
                        </div>
                        <div class="mt-8">
                          <h3 class="happ-ws-settings-heading">
                            {{ 'workspace.filters_settings' | translate }}
                          </h3>
                          <div style="width: 96%; margin: auto;">
                            <div fxFlex="48">
                              <mat-checkbox [(ngModel)]="appData.noAdvanceFiltersSelected">{{ 'workspace.show_advance_filter' | translate }}
                              </mat-checkbox>
                            </div>
                            <div *ngIf="appData" fxFlex="48">
                              <mat-checkbox [(ngModel)]="appData.hideDateRange">{{ 'workspace.hide_date_range_filter' | translate }}
                              </mat-checkbox>
                            </div>
                          </div>
                          <div style="width: 96%; margin: auto;">
                            <div fxFlex="48">
                              <mat-checkbox [(ngModel)]="appData.showSuperFilter">{{ 'workspace.show_super_filter' | translate }}
                              </mat-checkbox>
                            </div>
                            <div fxFlex="48">
                              <mat-checkbox [(ngModel)]="appData.fastSearchWithField">{{ 'workspace.fast_search_with_field' | translate }}
                              </mat-checkbox>
                            </div>
                          </div>
                          <div style="width: 96%; margin: auto;">
                            <div fxFlex="45" fxLayout="column">
                              <div class="happ-form-section">
                                <p>{{ 'workspace.date_range_filter_time_difference' | translate }}
                                  <button (click)="openInstructionPopup('dataRangeTimeDiff')" mat-icon-button>
                                    <mat-icon>info</mat-icon>
                                  </button>
                                </p>
                                <input [(ngModel)]="appData.dataRangeTimeDiff" class="happ-form-input" placeholder="Ex. 2d">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-8">
                          <h3 class="happ-ws-settings-heading">
                            {{ 'workspace.barcode_settings' | translate }}
                          </h3>
                          <div style="width: 96%; margin: auto;">
                            <div fxFlex="48">
                              <mat-checkbox [(ngModel)]="appData.barcode_single">{{ 'workspace.barcode_single' | translate }}
                              </mat-checkbox>
                            </div>
                            <div fxFlex="48">
                              <mat-checkbox [(ngModel)]="appData.barcode_package">{{ 'workspace.barcode_package' | translate }}
                              </mat-checkbox>
                            </div>
                          </div>
                          <div style="width: 96%; margin: auto;" fxLayout="row wrap">
                            <div *ngIf="appData.barcode_single" style="width: 47%;" class="happ-form-section">
                              <p>{{ 'workspace.barcode_params' | translate }}</p>
                              <textarea [(ngModel)]="appData.single_barcode_params" class="happ-form-input h-100" placeholder=""></textarea>
                            </div>
                            <div *ngIf="appData.barcode_package" style="width: 47%;" class="happ-form-section ml-12">
                              <p>{{ 'workspace.barcode_params' | translate }}</p>
                              <textarea [(ngModel)]="appData.package_barcode_params" class="happ-form-input h-100" placeholder=""></textarea>
                            </div>
                          </div>
            
                        </div>
                  </div>  
                </mat-tab>
              <mat-tab label="Form Settings">
                <div fxLayout="column">
                  <div fxFlex="row">
                    <p *ngIf="appData['formName']"> Selected Form: {{ appData['formName'] }} </p>
                    <span fxFlex></span>
                    <div>
                      <button mat-raised-button (click)="appData.formId = null" > Reset Form </button>
                    </div>
                  </div>
                  <div>
                    <div fxLayout="column">
                      <div fxayout="row">
                        <div style="margin: auto;">
                          <button (click)="addNewForm()" mat-raised-button>Add Form</button>
                        </div>
                        <span fxFlex></span>
                        <h4 style="margin: auto;"> </h4>
                        <span fxFlex></span>
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <mat-form-field class="happ-dt-table-filter-input">
                            <input matInput aria-label="record_fast_search_input" (keyup.enter)="filter(query)" placeholder="search"
                              [(ngModel)]="query">
                          </mat-form-field>
                          <button aria-label="record_fast_search_button" style="margin: auto 2px;" class="ml-8" matTooltip="search"
                            (click)="filter(query)" mat-icon-button>
                            <mat-icon style="color: black;">search</mat-icon>
                          </button>
                        </div>
                    
                      </div>
                    
                      <div>
                        <ngx-datatable [messages]="{emptyMessage: 'Brak danych', totalMessage: 'TOTAL'}" [@animateStagger]="{value:'50'}"
                          class="bootstrap" [rows]="rows" [loadingIndicator]="loadingIndicator" [columns]="requestData.columns"
                          [columnMode]="'force'" [headerHeight]="50" [externalPaging]="true" [footerHeight]="50" [rowHeight]="'auto'"
                          [count]="requestData.page.totalElements" [offset]="requestData.page.pageNumber" (sort)="onSort($event)"
                          [limit]="requestData.page.size" (page)='setPage($event)'>
                          <ngx-datatable-column *ngFor="let col of requestData.columns; let colIndex=index" fxFlex [prop]="col.prop"
                            [name]="col.title">
                    
                            <!-- Header Template Starts -->
                            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                              <div class="ml-4" (click)="sort();" style="display: inline-block; position: absolute; top: 13px;"
                                *ngIf="column.name != 'Actions'">{{column.name}}</div>
                              <div class="mr-8 w-100-p" style="position: absolute; right: 25px; display: inline-block;"
                                *ngIf="column.name == 'Actions'">
                                <span fxFlex></span>
                                <p>Akcje</p>
                              </div>
                            </ng-template>
                            <!-- Header Template Ends -->
                    
                            <!-- Row and cell Template Starts -->
                            <ng-template let-value="value" class="happ-dummy" let-rowIndex="rowIndex" let-row="row" style="color: blue;"
                              ngx-datatable-cell-template>
                    
                              <div class="ml-4" *ngIf="col.prop === 'createdAt'">
                                {{ value | date:'yyyy-MM-dd HH:mm:ss' }}
                              </div>
                              <div class="ml-4" *ngIf="col.prop === 'createdById'">
                                {{ value.login }}
                              </div>
                              <div class="p-4 mr-8 w-100-p" *ngIf="col.prop === '_id'">
                                <span fxFlex></span>
                                <button class=mr-8 color="warn" mat-raised-button (click)="deleteForm(row)">
                                  Delete
                                </button>
                                <button class=mr-8 mat-raised-button (click)="editForm(value)">
                                  Edit
                                </button>
                                <button class="mr-8" [color]="(row._id === appData['formId']) ? 'primary' : ''" mat-raised-button (click)="selectForm(row)">
                                  Select Form
                                </button>
                              </div>
                              <div class="ml-4" *ngIf="col.prop === 'title'">
                                {{ value }}
                              </div>
                            </ng-template>
                            <!-- Row and cell Template Ends -->
                          </ngx-datatable-column>
                    
                        </ngx-datatable>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Office Filters">
                <p>{{ 'workspace.ws_settings_office_filter_info' | translate }}</p>
                <div class="p-12 content" fxLayout="row">
                  <div style="height: 70vh; border-left: 1px solid rgba(200,200,200,0.6)" fusePerfectScrollbar fxFlex="49">
                      <mat-nav-list *ngIf="officeFilters && officeFilters.length">
                          <div fxLayout="row" *ngFor="let list of officeFilters; let i=index">
                            <!-- <mat-checkbox [checked]="(appData['officeFilters'] && appData['officeFilters'].includes(list.name))" style="margin: auto; margin-left: 5px;" (click)="selectOfficeFilterForApp(i, list)" ></mat-checkbox> -->
                              <div fxLayoutAlign="center center" fxLayout="row wrap" class="mat-list-item" [style.font-weight]="(selectedFilter && selectedFilter['ndivame'] === list['name'])? 600 : ''" mat-list-item>
                                  <!-- <mat-icon class="mr-4" *ngIf="(selectedFilter && selectedFilter['name'] === list['name'])">
                                      check
                                  </mat-icon> -->
                                  <p class="ml-8">
                                      {{list.name}}
                                  </p>
                                  <span fxFlex></span>
                                  <div>
                                    <button class="mr-8" mat-raised-button [color]="(appData['officeFilters'] && appData['officeFilters'].includes(list.name)) ? 'primary': ''" (click)="selectOfficeFilterForApp(i, list)" >
                                      {{ (appData['officeFilters'] && appData['officeFilters'].includes(list.name)) ? 'Unselect' : 'Select' }}
                                    </button>
                                    <button class="mr-8" mat-raised-button [color]="(selectedFilter && selectedFilter['name'] === list['name']) ? 'primary': ''" (click)="selectedFilter = list; selectedFilter.index = i" >
                                      View
                                    </button>
                                  </div>
                                </div>
                          </div>
                      </mat-nav-list>
                  </div>
                  <div fusePerfectScrollbar fxFlex="49">
                      <div fxLayout="column" *ngIf="selectedFilter">
                          <div fxLayout="row" class="toolbar px-24 py-8">
                              <h4> {{ selectedFilter.name }} </h4>
                              <span fxFlex></span>
                              <div>
                                  <div>
                                      <button mat-raised-button (click)="removeOfficeFilter()"> Delete </button>
                                      
                                    </div>
                              </div>
                          </div>
                          <div fxLayout="column" class="p-8">
                              <p>
                                  mqFilter: {{ selectedFilter.mqFilter }}
                                </p>
                                <p>
                                  Filter: {{ (selectedFilter && selectedFilter.filter | json) }}
                                </p>
                          </div>
                      </div>
                      <div fxLayoutAlign="center center" fxLayout="column" style="height: 70vh;" class="w-100-p" *ngIf="!selectedFilter">
                          <mat-icon *fuseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" class="s-128 mb-16 hint-text select-message-icon">
                              book
                          </mat-icon>
                          <h2 *fuseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" class="select-message-text hint-text">Select
                              from left</h2>
                      </div>
                  </div>
              </div>
              </mat-tab>
              <mat-tab label="Actions">
                  <div fxLayout="column">
                      <div class="toolbar px-24">
                          <div style="align-items: center;" fxFlex fxLayout="row">
                              <div>
      
                                  <mat-form-field>
                                      <input matInput placeholder="Search" [(ngModel)]="query">
                                  </mat-form-field>
                              </div>
                              <span fxFlex></span>
                              <!-- <mat-checkbox class="mx-8" [(ngModel)]="stepData.stepperActions">Use Stepper Actions</mat-checkbox> -->
                              <div style="margin:auto">
                                  <button (click)="addActions('add', 0)" color="warn" mat-raised-button>New</button>
                              </div>
                          </div>
                      </div>
                      <div class="h-400" fxLayoutAlign="center center" *ngIf="!appData.actionList || (appData.actionList && appData.actionList.length === 0)">
                          <h2>
                              No Actions Yet Please add some.
                          </h2>
                      </div>
                      <mat-list role="list" *ngIf="appData.actionList" fxFlex>
                          <mat-list-item class="mat-elevation-z1" style="margin: 10px 0px;" role="listitem" *ngFor="let list of appData.actionList | fullTextSearch:query:'default_label'; let i=index ">
                              <!-- <mat-icon class="mr-12">book</mat-icon> -->
                              <!-- <i class="mdi {{list['icon'].replace('icon', 'mdi')}}"></i> -->
                              {{ list['default_label'] }}
                              <span fxFlex></span>
                              <button mat-raised-button style="text-transform: capitalize;" class="mr-8" color="warn" mat-button (click)="deleteAction(i)">
                                      Delete
                                      </button>
                              <button mat-raised-button style="text-transform: capitalize;" mat-button (click)="addActions('edit', i)">
                                              Edit
                                              </button>
      
                              <mat-divider></mat-divider>
                          </mat-list-item>
                      </mat-list>
                  </div>
              </mat-tab>
            </mat-tab-group>
        </div>
      </mat-tab>
      <mat-tab *ngIf="requiredDataFields && requiredDataFields.length" label="{{ 'workspace.required_fields' | translate }}">
        <div fxLayout="row">
          <div style="width: 75%;">
            <div fxLayout="row" class="happ-ws-settings-heading">
              <h3 class="m-0">
                {{ 'workspace.general' | translate }}
              </h3>
              <span fxFlex></span>
              <!-- <button (click)="saveData(2)" class="happ-hover-button">
                {{ 'workspace.save' | translate }}
              </button> -->
            </div>
            <div fxLayout="row" *ngFor="let field of requiredDataFields">
              <p style="margin: auto 10px; width: 50%; font-size: 14px;" *ngIf="!field['description']">
                {{ field['processName'] }} > {{ field['actName'] }} > {{ field['name'] }} </p>
              <p style="margin: auto 10px; width: 50%; font-size: 14px;" *ngIf="field['description']">
                {{ field['description'] }} </p>
              <mat-form-field>
                <input class="w-100-p" matInput placeholder="{{ 'workspace.enter_here' | translate }}"
                  [(ngModel)]="field['value']">
              </mat-form-field>
            </div>
          </div>
          <div style="width: 25%;" class="p-12">
            <p class="hint-text font-size-18">
              {{ 'workspace.global_variables_desc' | translate }}
            </p>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="processRoles && processRoles.length" label="{{ 'workspace.process_roles' | translate }}">
        <div class="p-12">
          <app-after-plugin-install [processRoles]="processRoles"></app-after-plugin-install>
        </div>
      </mat-tab>
      <mat-tab *ngIf="gvRequiredFields && gvRequiredFields.length"
        label="{{ 'workspace.global_variables' | translate }}">
        <app-office-global-var [dialogRef]="dialogRef" [gvRequiredFields]="gvRequiredFields"></app-office-global-var>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<div *ngIf="loading" class="w-100-p h-100-p" fxLayoutAlign="center center">
  <mat-spinner></mat-spinner>
</div>