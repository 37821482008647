<div *ngIf="data" class="dialog-content-wrapper" style="background: #fafafa;">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title"> {{ (data && data.control_type && data.control_type.title) || 'toolbar.description' | translate}} </span>
      <span fxFlex></span>
      <button (click)="dialogRef.close(null)" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div *ngIf="data && data.type !== 'translation'">
    <div class="p-12" [innerHTML]="(data.value || (data.control_type && data.control_type.data) || 'No Description Provided') | htmlWithStyle">
      
    </div>
  </div>
  <div *ngIf="data && data.type === 'translation'">
    {{ data.value | translate }}
  </div>
</div>
