import * as tslib_1 from "tslib";
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { version } from 'environments/version';
import { environment } from 'environments/environment';
// Our service to handle errors (ideally in its own file)
var ErrorLogService = /** @class */ (function () {
    function ErrorLogService(
    // private _officeHelper: OfficeHelpersService,
    // private _profileHelper: ProfileHelperService,
    // private _helperService: HelpersService,
    http) {
        this.http = http;
        this.name = 'ErrorLogService';
        this.ERROR_REPORTING_REGISTER = '5cd97d2e0991d23c80fa214a';
        this.issueData = {};
        this.browserInfo = {};
        this.LOGIN_TIME_DIFF = 1000 * 60 * 60 * 8;
        this.REAUTH_TIME = 1000 * 60 * 60 * 6;
        this.SNACKBAR_DURATION = 2000;
    }
    ErrorLogService.prototype.logError = function (error) {
        var _this = this;
        this.issueData.error = (error && error.stack);
        this.issueData.version = version;
        this.issueData.current_url = window.location.href;
        this.getInitData()
            .then(function () {
            _this.browserInfo = _this.getBrowserInfo();
            _this.issueData.browserInfo = _this.browserInfo;
            _this.issueData.browserName = _this.browserInfo.browserName;
            _this.issueData.fullVersion = _this.browserInfo.fullVersion;
            if (error instanceof HttpErrorResponse) {
                _this.issueData.errorMsg = "There was an HTTP error. \n " + error.message + " Status code: " + error.status;
            }
            else if (error instanceof TypeError) {
                _this.issueData.errorMsg = "There was a Type error. \n" + error.message;
            }
            else if (error instanceof Error) {
                _this.issueData.errorMsg = "There was a general error.\n " + error.message;
            }
            else {
                _this.issueData.errorMsg = "Nobody threw an error but something happened! \n " + error;
            }
            console.warn(_this.issueData.errorMsg);
            if (environment && environment.production) {
                _this.createConsoleError(_this.issueData)
                    .catch(function (err) {
                    console.log(err);
                });
            }
            else {
                console.error(error);
            }
        }).catch(function (err) {
            console.log(err);
        });
    };
    ErrorLogService.prototype.stringifyError = function (err, filter, space) {
        var plainObject = {};
        Object.getOwnPropertyNames(err).forEach(function (key) {
            plainObject[key] = err[key];
        });
        return JSON.stringify(plainObject, filter, space);
    };
    ErrorLogService.prototype.getInitData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, err_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        _a = this.issueData;
                        return [4 /*yield*/, this.getLocalOfficeData()];
                    case 1:
                        _a.office_json = (_c.sent()) || {};
                        delete this.issueData.office_json.pluginInfo;
                        delete this.issueData.office_json.plugins;
                        _b = this.issueData;
                        return [4 /*yield*/, this.getLocalProfileData()];
                    case 2:
                        _b.logged_user = (_c.sent()) || {};
                        this.getLocalWorkspaceData();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _c.sent();
                        return [2 /*return*/, err_1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ErrorLogService.prototype.getLocalWorkspaceData = function () {
        if (localStorage.getItem('wsAppData') && JSON.parse(localStorage.getItem('wsAppData'))) {
            this.issueData.workspace_json = JSON.parse(localStorage.getItem('wsAppData'));
        }
    };
    ErrorLogService.prototype.createConsoleError = function (recData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.serverUrl + "api/consoleErrors/new", recData)
                .subscribe(function (data) {
                _this.buildBackendResponse(data, resolve, reject);
            }, function (err) { return _this.buildHttpError(err, reject); });
        });
    };
    /**
*
* @param data
* @param resolve
* @param reject
* build the success or error message from the response
*/
    ErrorLogService.prototype.buildBackendResponse = function (data, resolve, reject) {
        if (data && data['success']) {
            if (data['result']) {
                resolve(data['result']);
            }
            else {
                reject('Response is empty. Please check the configuration');
            }
        }
        else {
            reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
        }
    };
    /**
     *
     * @param err
     * @param reject
     * build the error message from the http error
     */
    ErrorLogService.prototype.buildHttpError = function (err, reject) {
        if (err && err['status'] && err['message']) {
            reject(err['status'] + '-' + err['message']);
        }
        else {
            reject('Error Occured while fetching the data');
        }
    };
    /**
   * return office data from local storage
   */
    ErrorLogService.prototype.getLocalOfficeData = function () {
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
                resolve(JSON.parse(localStorage.getItem('ngStorage-selectedOffice')));
            }
            else {
                reject('No Office Doc Found. Please Make Sure you have some Office selected');
            }
        });
    };
    ErrorLogService.prototype.getLocalProfileData = function () {
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-profile'))) {
                resolve(JSON.parse(localStorage.getItem('ngStorage-profile')));
            }
            else {
                reject('No profile Found. Please login first');
            }
        });
    };
    ErrorLogService.prototype.getBrowserInfo = function () {
        var nVer = navigator.appVersion;
        var nAgt = navigator.userAgent;
        var browserName = navigator.appName;
        var fullVersion = '' + parseFloat(navigator.appVersion);
        var majorVersion = parseInt(navigator.appVersion, 10);
        var nameOffset, verOffset, ix;
        // In Opera, the true version is after "Opera" or after "Version"
        if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
            browserName = 'Opera';
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf('Version')) !== -1) {
                fullVersion = nAgt.substring(verOffset + 8);
            }
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
            browserName = 'Microsoft Internet Explorer';
            fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after "Chrome"
        else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
            browserName = 'Chrome';
            fullVersion = nAgt.substring(verOffset + 7);
        }
        // In Safari, the true version is after "Safari" or after "Version"
        else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
            browserName = 'Safari';
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf('Version')) !== -1) {
                fullVersion = nAgt.substring(verOffset + 8);
            }
        }
        // In Firefox, the true version is after "Firefox"
        else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
            browserName = 'Firefox';
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In most other browsers, "name/version" is at the end of userAgent
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
            (verOffset = nAgt.lastIndexOf('/'))) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browserName.toLowerCase() === browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(';')) !== -1) {
            fullVersion = fullVersion.substring(0, ix);
        }
        if ((ix = fullVersion.indexOf(' ')) !== -1) {
            fullVersion = fullVersion.substring(0, ix);
        }
        majorVersion = parseInt('' + fullVersion, 10);
        if (isNaN(majorVersion)) {
            fullVersion = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }
        return {
            browserName: browserName,
            fullVersion: fullVersion,
            majorVersion: majorVersion,
            appName: navigator.appName,
            userAgent: navigator.userAgent
        };
    };
    return ErrorLogService;
}());
export { ErrorLogService };
