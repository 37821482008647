import { fuseAnimations } from '@fuse/animations';
import { WorkspaceService } from './../workspace/workspace.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from '../../services/helpers/helpers.service';
import { INg5courseSetting, IRegister } from '../../interfaces/register.interface';
import { IScanque } from '../../interfaces/scanque.interface';
import { SelectIconComponent } from '../workspace-management/select-icon/select-icon.component';
import { FormBuilderModalComponent } from '../../global-component/form-builder-modal/form-builder-modal.component';
import { IFormInterface } from '../../interfaces/form.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { OfficeHelpersService } from '../../services/office-helpers/office-helpers.service';
import { StepActionsComponent } from '../settings/reg-settings/setting-nodes/academy-course/step-actions/step-actions.component';

@Component({
  selector: 'app-ws-apps-settings',
  templateUrl: './ws-apps-settings.component.html',
  styleUrls: ['./ws-apps-settings.component.scss'],
  animations: fuseAnimations

})
export class WsAppsSettingsComponent implements OnInit {

  appData: any;
  regData: IRegister;
  regDataScanque = {};
  scanqueList: Array<IScanque>;
  loading = false;
  selectedFilter;
  processRoles = [];
  requiredDataFields = [];
  requiredData = {};
  officeId: string;
  gvRequiredFields = [];
  selectedIndex = 0;
  loadingIndicator: boolean;
  // tslint:disable-next-line:max-line-length
  requestData = { 'page': { 'size': 10, 'totalElements': 0, 'totalPages': 0, 'pageNumber': 0 }, 'columns': [{ 'prop': 'title', 'title': 'Title' }, { 'prop': 'createdAt', 'title': 'Date Created' }, { 'prop': 'createdById', 'title': 'Created By' }, { 'prop': '_id', 'title': 'Actions' }], 'sort': { 'prop': 'createdAt', 'dir': 'desc' } };
  rows: any;
  officeFilters: any;

  constructor(
    public dialogRef: MatDialogRef<WsAppsSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private translationLoader: FuseTranslationLoaderService,
    private helperService: HelpersService,
    private _officeHelper: OfficeHelpersService,
    private _workspaceService: WorkspaceService,
    private router: Router
  ) { 
    // this.translationLoader.loadTranslations(english, polish);
    this.helperService.getTrans('workspace-apps-settings')
      .then((data2) => {
        this.translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
        console.log(err);
      });
  }

  ngOnInit() {
    this.selectedIndex = this.data.tabIndex || 0;
    this.appData = this.data.appData || {};
    this.requiredDataFields = this.data.requiredDataFields || [];
    this.processRoles = this.data.processRoles || [];
    this.getRegData();
    this.getScanqueList();
    this.reRenderList();
    this.getLocalOfficeFilter();
  }

  selectedFormChanged(data: any) {
    this.appData['formId'] = (data && data._id);
    this.appData['formName'] = (data && data.title);
  }

  getRegData() {
    this.loading = true;
    this.gvRequiredFields = this.data.gvRequiredFields || [];
    this.helperService.getRegisterData(this.appData.regId)
    .then((data) => {
      this.regData = (data && data[0]) || {};
      this.regDataScanque = this.regData.scanqueue;
      this.regData.icon = this.appData.icon;
      this.regData.scanqueue = this.appData.scanqueue || this.regData.scanqueue || {};
      this.appData.stepLabel = this.appData.stepLabel || this.regData.name;
      this.appData.itemName = this.appData.itemName || this.regData.itemName;
      this.appData.integratedProcessId = this.regData.integratedProcessId;
      this.getBarcodeActionParams();
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this.helperService.openSnackBar(err || 'Error occured while getting register data');
    });
  }

  getScanqueList() {
    this.helperService.getScanqueList()
    .then((data: Array<IScanque>) => this.scanqueList = data)
    .catch(err => {
      this.helperService.openSnackBar(err);
    });
  }

  saveData(index: number) {
    if (index === 0) {
      this.saveTab1Data();
    } else if (index === 1) {
      this.saveTab2Data();
    }
  }

  saveTab2Data() {
    this.requiredDataFields = this.requiredDataFields || [];
    this.requiredDataFields.forEach((ele) => {
      if (ele['value'] !== undefined && ele['value'] !== null) {
        ele['done'] = true;
      }
    });

    this.loading = true;
    this.helperService.putRequest('api/plugin/updateRequiredFields', this.requiredDataFields)
    .then((data) => {
      this.appData['requiredDataFields'] = this.requiredDataFields || [];
      return this.updateWSApp();
    }).then((data) => {
      this.loading = false;
      this.helperService.openSnackBar('Updated');
      this.dialogRef.close(this.appData);
    }).catch(err => {
      this.loading = false;
      this.helperService.openSnackBar(err);
    });
  }

  updateWSApp(): Promise<any> {
    this.data.wsInfo = this.data.wsInfo || {};
    const wsData: IRegister = (this.data.wsInfo && this.data.wsInfo.workspace) || {};
    wsData.appList = wsData.appList || [];
    wsData.appList[this.data.wsInfo.appIndex] = this.appData;
    return this.helperService.updateRegisterData(wsData._id, {appList: wsData.appList});
  }

  saveTab1Data() {
    this.checkMqFilter();
    const temp = this.regData.scanqueue;
    if (this.appData.scanqueue) {
      delete this.regData.scanqueue;
    }

    if (!this.regDataScanque) {
      this.regData.scanqueue = temp;
    }

    this.appData.scanqueue = temp;
    this.appData.stepDescription = this.regData.description;

    delete this.regData.actions;

    this.loading = true;
    // update register..
    this.helperService.updateRegisterData(this.regData._id, this.regData)
    .then((data) => {
      return this.updateWSApp();
    }).then((data) => {
      this.loading = false;
      this.helperService.openSnackBar('app updated');
      this._workspaceService.onWorkspaceAppSettingsChanged.next({
        isChanged: true,
        appSlug: this.appData.appUrlSlug || this.appData.appSlug
      });
      this.dialogRef.close(this.appData);
    }).catch(err => {
      this.loading = false;
      this.helperService.openSnackBar(err || 'Error occured while updating the app');
    });
  }

  selectIcon(): void {
    const dialogRef = this.dialog.open(SelectIconComponent, {
      width: '700px',
      height: '600px',
      panelClass: 'happ-form-builder-dialog',
      data: { selectedIcon: this.appData.icon }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.regData.icon = result;
        this.appData.icon = result;
      }
    });
  } 

  getBarcodeActionParams() {
    this.regData.actions = this.regData.actions || [];
    this.regData.actions.map((action) => {
      if (action.application === 'app.customregistries.acceptPackageRecords' && !this.appData.package_barcode_params) {
        this.appData.package_barcode_params = (action.params && action.params.json) || '{}';
      }
      if (action.application === 'app.customregistries.barCodeRecords' && this.appData.single_barcode_params) {
        this.appData.single_barcode_params = (action.params && action.params.json) || '{}';        
      }
    });
  }


  // form selection logic  
  deleteForm(form: any) {
    if (form) {
      this.helperService.openConfirmPopUp(`popup.confirm_delete_form_message`)
      .subscribe(result => {
        if (result) {
          this.helperService.deleteForm(form['_id'])
            .then(() => {
              this.reRenderList();
              this.helperService.openSnackBar('Form Deleted');
            }).catch(err => {
              this.helperService.openSnackBar(err);
            });
        }
      }, err => {
        console.log(err);
      });
    }
  }

  reRenderList() {
    // rerender
    this.helperService.getFormOrTemplate('forms', this.requestData)
      .then((data) => {
        this.rows = data.data;
        this.requestData.page = data.page;
      }).catch(err => {
        console.log(err);
      });
  }

  setPage(pageInfo) {
    this.requestData.page.pageNumber = pageInfo.offset;
    this.loadingIndicator = true;
    this.reRenderList();
  }

  filter(query) {
    this.requestData['search'] = {
      value: query || '',
      regex: false
    };
    this.reRenderList();
  }

  onSort(event) {
    this.loadingIndicator = true;
    this.requestData['sort'] = (event.sorts && event.sorts[0]);
    this.reRenderList();
  }

  addNewForm() {
    // new
    const dialogRef = this.dialog.open(FormBuilderModalComponent, {
      width: '100%',
      data: { operation: 'add', formData: null },
      panelClass: 'happ-form-builder-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.reRenderList();
    }, err => {
      this.helperService.openSnackBar(err);
      console.log(err);
    });
  }

  editForm(form: any) {
    // edit
    this.helperService.getForm(form)
      .then((formData) => {
        const dialogRef = this.dialog.open(FormBuilderModalComponent, {
          width: '100%',
          data: { operation: 'edit', formData: formData },
          panelClass: 'happ-form-builder-dialog'
        });
        dialogRef.afterClosed().subscribe(result => {
          this.reRenderList();
        }, err => {
          this.helperService.openSnackBar(err);
          console.log(err);
        });
      }).catch(err => {
        console.log(err);
      });
  }

  selectForm(form: IFormInterface) {
    this.appData.formId = form._id;
    this.appData.formName = form.title;
  }

  
  removeOfficeFilter() {
    const index = (this.selectedFilter && this.selectedFilter.index);
    const filter = this.officeFilters[index] || {};
    this.helperService.openConfirmPopUp(`popup.confirm_delete_filter_message`)
    .subscribe((res) => {
      if (res) {
        this.officeFilters.splice(index, 1);
        this._officeHelper.updateOfficeDoc({ officeFilters: this.officeFilters })
        .then((data) => {
          this._officeHelper.refreshLocalOfficeData();
        }).catch(err => {
          console.log(err);
        });
      }
    });
  }

  selectOfficeFilterForApp(i, filter) {
    this.appData['officeFilters'] = this.appData['officeFilters'] || [];
    if (filter && this.appData['officeFilters'].includes(filter.name)) {
      this.appData['officeFilters'].splice(this.appData['officeFilters'].indexOf(filter.name), 1);
    } else if (filter){
      this.appData['officeFilters'].push(filter.name);
    }
  }

  getLocalOfficeFilter() {
    this._officeHelper.getLocalOfficeData()
    .then((officeData) => {
      console.log(officeData);
      this.officeId = officeData._id;
      this.officeFilters = officeData.officeFilters || [];
      // get only for this register...
      this.officeFilters = this.officeFilters.filter((ele) => (ele && ele.regId === this.appData.regId));
      this.officeFilters = this.officeFilters.map((ele) => {
        if (ele.filter) {
          ele.filter = JSON.parse(ele.filter);
        }
        return ele;
      });
    }).catch(err => {
      console.log(err);
    });
  }

  addActions(operation, i) {
    const dialogRef = this.dialog.open(StepActionsComponent, {
      width: '100%',
      panelClass: 'happ-form-builder-dialog',
      data: { 
        operation,
        index: i,
        processId: this.regData.integratedProcessId,
        actionList: this.appData.actionList || []
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.appData.actionList = this.appData.actionList || [];
        if (operation === 'edit') {
          this.appData.actionList[i] = result;
        } else {
          this.appData.actionList.push(result);
        }
      }
    }, err => {
      console.log(err);
    });
  }

  deleteAction(i) {
    this.appData.actionList = this.appData.actionList || [];
    this.appData.actionList.splice(i, 1);
  }

  checkMqFilter() {
    this.helperService.checkMqFilter(this.appData.hardFilter || '')
    .then(() => {
      this.helperService.openSnackBar('Valid');
    }).catch(err => {
      this.helperService.openSnackBar(err || 'Invalid');
    });
  }

  moveToProcessSettings(processId) {
    this.router.navigate([`/offices/${this.officeId}/process-settings/process-settings-list/process-settings-list/${processId}`]);
    this.dialogRef.close(null);
    }

  openInstructionPopup(infoKey: string) {

    if (infoKey === 'dataRangeTimeDiff') {
      return this.helperService.openInstructionPopup(`
  <pre>
      years	y
      quarters	Q
      months	M
      weeks	w
      days	d
      hours	h
      minutes	m
      seconds	s
  </pre>`, 'data');
    }
    this.helperService.openInstructionPopup(` <pre>
    (responsibleEmail = 'abc' OR responsibleEmail = 'xyz')
    (responsibleEmail = 'asas' AND processStatusLabel = 'asas')
    (modificatorDate <= 'Date(now)')
    (modificatorDate <= 'Date(now)-2d')
    (modificatorDate <= 'Date(2018-08-01)')
    (processStatusId IN '8, 9, 12')
    (processStatusId IN 'test, abc, zyx')
    (processStatusId NOT_IN '4,5,7')
    (employeeSurname != 'sing')
    (employeeAddress CONTAINS '/asd*/')
    (employeeAddress NOT_CONTAINS '/asd*/')
    (managerGroup IN currentUserManagerGroups)
    (userGroup IN currentUserGroups)
</pre>
<pre>
    responsibleUserId - To get responsible User Id
    responsibleEmail - To get responsible User Email
    currentUserProfileEmail - To get responsible User Email
    currentUserGroups - To get all user groups
    currentUserManagerGroups - To get all user manager groups
</pre>
<pre>
    years	y
    quarters	Q
    months	M
    weeks	w
    days	d
    hours	h
    minutes	m
    seconds	s
    milliseconds	ms
</pre>`, 'data');
  }
}
