<div *ngIf="!field.hide || builder">

    <div fxLayout="row" class="mb-12" fxLayoutAlign="space-between center ">
        <div class="file-upload">
            <label for="upload" class="file-upload__label">{{ field.title || field.label }}</label>
            <input id="upload" (change)="fileChanged(file)" class="file-upload__input" name="uplFile" #file multiple type="file">
        </div>
        <button [disabled]="loading" *ngIf="selectedFiles && selectedFiles.length > 0" mat-raised-button color="warn" (click)="uploadFile(file)">
                {{ 'ct.upload' | translate }}
        </button>
    </div>
    <br>

    <div *ngIf="!loading">
        <div style="font-size: 22px;" class="m-8 mb-24" *ngFor="let file of selectedFiles">
            {{ file }}
        </div>
    </div>
    <div *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>

    <div class="happ-table" [style.display]="(fileList && fileList.length > 0)? 'block': 'none'">
        <table fxFlex style="width: 100%" class="row-border hover custoum-datatbale display">

            <thead>
                <tr>
                    <th>{{ 'ct.creation_time' | translate }}</th>
                    <th>{{ 'ct.uploaded_by' | translate }}</th>

                    <th>{{ 'ct.original_name' | translate }}</th>
                    <th> <span fxFlex></span> {{ 'ct.action' | translate }} </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let file of fileList">
                    <td>{{ file.createdAt | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
                    <td>{{ (file.creatorLogin)? file.creatorLogin: '-' }}</td>

                    <td>{{ file.originalname }}</td>

                    <td>
                        <span fxFlex></span>
                        <button mat-raised-button class="mr-4" *ngIf="!field.hideDelete" (click)="deleteFile(file._id)">{{ 'ct.delete' | translate }}</button>
                        <button mat-raised-button color="warn" [disabled]="downloadLoading" class="mr-4" (click)="openPreview(file._id)">{{ 'ct.preview' | translate }}</button>
                        <button mat-raised-button *ngIf="field.showSaveAsMainDoc" [color]="(recordMainDocument===file._id)? 'warn': ''" (click)="saveAsMainDoc(file._id)">{{(recordMainDocument === file._id)? ('ct.remove' | translate) : ('ct.save' | translate)}} {{ 'ct.main_doc' | translate }}</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="docBase">
            <iframe [src]="docBase" style="display: none;" frameborder="0"></iframe>
        </div>

    </div>
</div>