import { Component, OnInit, ViewChild, ElementRef, HostBinding, Renderer2, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { FuseConfigService } from '@fuse/services/config.service';
import { style, animate, AnimationBuilder, AnimationPlayer } from '@angular/animations';
import { DatatableService } from '../datatable/datatable.service';
import { RecordsService } from 'app/main/content/apps/custom-registries/records/records.service';
import { fuseAnimations } from '@fuse/animations';
import { HelpersService } from '../../services/helpers/helpers.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-side-menu-edit',
  templateUrl: './side-menu-edit.component.html',
  styleUrls: ['./side-menu-edit.component.scss'],
  animations : fuseAnimations

})
export class SideMenuEditComponent implements OnInit, OnDestroy {

   
  @ViewChild('openButton') openButton;
  @ViewChild('panel') panel;
  @ViewChild('overlay') overlay: ElementRef;

  public player: AnimationPlayer;
  fuseSettings: any;
  data: any = {};
  error = [];

  onSettingsChanged: Subscription;

  @HostBinding('class.bar-closed') barClosed: boolean;
  

  constructor(
      private animationBuilder: AnimationBuilder,
      private fuseConfig: FuseConfigService,
      private renderer: Renderer2,
      private dataTableSevice: DatatableService,
      private helperService: HelpersService,
      public snackBar: MatSnackBar,
  )
  {
      this.barClosed = true;
      this.onSettingsChanged =
        this.dataTableSevice.onEditRecord
          .subscribe(
              (record) => {
                  this.data = record;
                  this.openBar();
              }
          );               
  }

  ngOnInit()
  {
      this.renderer.listen(this.overlay.nativeElement, 'click', () => {
          this.closeBar();
      });
  }

  closeBar()
  {
      this.player =
          this.animationBuilder
              .build([
                  style({transform: 'translate3d(0,0,0)'}),
                  animate('400ms ease', style({transform: 'translate3d(100%,0,0)'}))
              ]).create(this.panel.nativeElement);

      this.player.play();

      this.player.onDone(() => {
          this.barClosed = true;
      });
  }

  openBar()
  {
      this.barClosed = false;

      this.player =
          this.animationBuilder
              .build([
                  style({transform: 'translate3d(100%,0,0)'}),
                  animate('400ms ease', style({transform: 'translate3d(0,0,0)'}))
              ]).create(this.panel.nativeElement);

      this.player.play();
  }

  ngOnDestroy()
  {
      this.onSettingsChanged.unsubscribe();
  }

  saveChanges() {
    try {
      this.helperService.updateRegisterRecord(this.data['regId'], this.data['recId'], this.data['recData'])
      .then((recordUpdated) => {
        this.snackBar.open('Updated', 'done', {
          duration: 2000,
        });
        this.dataTableSevice.reRenderData.next(true);
        this.closeBar();
      }).catch(err => {
        console.log(err);
        this.snackBar.open(err || 'Error occured while updating', 'done', {
          duration: 2000,
        });
      });
    } catch (err) {
      this.snackBar.open(err || 'Error occured while updating', 'done', {
        duration: 2000,
      });
      console.log(err);
    } 
  }

  checkError(e) {
    // this.error = e;
    if (e && e['error'] && e['label']) {
      if (e && this.error && !this.error.includes(e['label'])) {
        this.error.push(e['label']);
      }
    } else {
      if (e && this.error && this.error.includes(e['label'])) {
        this.error.splice(this.error.indexOf(e['label']), 1);
      }
    }
    console.log(this.error);
  }
}
