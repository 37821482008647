<div class="pb-12 mb-12" *ngIf="!field.hide">
    <div class="happ-list-field" [class]="field.class" >
        <div class="happ-default-title happ-title">
            <!-- <mat-icon *ngIf="error" class="ml-8 mt-8 happ-error-default happ-error-icon">warning</mat-icon> -->
            <div class="w-100-p" fxLayout="column">
                    <div class="w-100-p" fxLayout="row wrap" fxLayoutAlign="center center">
                        <p *ngIf="!hideTitle" class="happ-field-title">{{ field.title }} </p>
                        <span fxFlex></span>
                    </div>
                <p *ngIf="errorMessage" class="warn-500-fg m-0 ml-8">
                    {{ errorMessage | translate  }}
                </p>
                
            </div>
        </div>
        <div class="happ-list happ-content" *ngIf="!field.multiple && !loading && field.type !== 'list'" fxLayout="{{field.flex}} wrap">
            <button class="happ-button-list" [ngClass]="{'happ-small-button': field.smallButton}" class="my-4" [style.text-align]="(field.align)? field.align : 'center'"
            mat-raised-button 
            (click)="onChange(option)" *ngFor="let option of field.options" 
            [style.background]="(((option.value && model == option.value) || (option.title && model == option.title))? '#e1f5fe': option.background)"
                [style.color]="(((option.value && model == option.value) || (option.title && model == option.title))? 'black': option.color)">
                 {{ option.title || option.value }} </button>
        </div>
        <div *ngIf="field.type === 'list'">
            <mat-list class="happ-list happ-content" *ngIf="!field.multiple && !loading" role="list" fxLayout="{{field.flex}} wrap">
                <mat-nav-list role="listitem" class="happ-list-item" fxLayoutAlign="{{(field.align)? field.align : 'center'}} center" [style.background]="option.background" [style.color]="option.color" *ngFor="let option of field.options" [style.background]="((option.value && model == option.value) || (option.title && model == option.title))? '#e1f5fe': option.background"[style.color]="((option.value && model == option.value) || (option.title && model == option.title))? 'black': option.color">
                    <a mat-list-item fxFlex style="text-decoration: none;" (click)="onChange(option)">
                        <span class="text-center">{{ option.title || option.value }}</span>                    
                    </a>
                </mat-nav-list>
            </mat-list>
        </div>
        <div fxLayoutAlign="center center" *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <div class="happ-desc" *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">
    
    </div>
</div>