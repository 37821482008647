import { HelpersService } from './../../../services/helpers/helpers.service';
import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import {FormControl} from '@angular/forms';

import {Observable} from 'rxjs/Observable';
import {startWith} from 'rxjs/operators/startWith';
import {map} from 'rxjs/operators/map';


@Component({
  selector: 'app-user-selection',
  templateUrl: './user-selection.component.html',
  styleUrls: ['./user-selection.component.scss']
})
export class UserSelectionComponent implements OnInit {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() regId;
  @Input() recId;
  @Input() builder;
  @Input() emailModel: any;
  @Output() emailModelChange: EventEmitter<Object> = new EventEmitter();
  @Input() responsibleId: any;
  @Output() responsibleIdChange: EventEmitter<Object> = new EventEmitter();
  @Input() fullNameModel: any;
  @Output() fullNameModelChange: EventEmitter<Object> = new EventEmitter();
  @Input() shortCodeModel: any;
  @Output() shortCodeModelChange: EventEmitter<Object> = new EventEmitter();
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  error = false;
  errorMessage = '';
  stateCtrl: FormControl;
  filteredNames: Array<any>;
  model;

    min_error = false;
    max_error = false;
    groups: Array<object> = [];
    users;
    allUsers = [];
    currentUser;
    constructor(
      private helperService: HelpersService
    ) { }

    onKeyPress() {
      this.emailModelChange.next(this.emailModel);
      this.fullNameModelChange.next(this.fullNameModel);
      this.shortCodeModelChange.next(this.shortCodeModel);
      if (this.field.haveRules) {
        this.onFieldValueUpdated.emit({
          label: this.field.label,
          value: this.model,
          ruleIndex: this.field.ruleIndex,
          field: this.field
        });
      }

      this.checkError();
    }

    checkError() {
      // errorValidation
      if (this.field['required'] && (!this.emailModel || this.emailModel && this.emailModel.length === 0)) {
        this.error = true;
        this.errorMessage = 'ct.field_is_required';
        if (!this.emailModel) {
          this.onError.emit({
            error: true,
            label: this.field['label']
          });
        }
      } else {
        this.error = false;
        this.errorMessage = '';
        if (this.emailModel) {
          this.onError.emit({
            error: false,
            label: this.field['label']
          });
        }
      }
    }

    getCurrentUser() {
      if (this.field.init_value === 'logged' && (this.emailModel === 'current' || this.emailModel === undefined)) {
        if (localStorage.getItem('ngStorage-profile') && JSON.parse(localStorage.getItem('ngStorage-profile'))) {
          const profile = JSON.parse(localStorage.getItem('ngStorage-profile'));
          _.findIndex(this.users, (o: any, i) => {
              if (o.id === profile['profileId']) {
                // tslint:disable-next-line:max-line-length
                // return this.currentUser = ((o['shortName'] || '') + ' ' + (o['longName'] || '') + ' <' + (o['email'] || '') + '> ' + ' - ' + (o['symbleName'] || ''));
                return this.currentUser = o;
              }
            });

          this.emailModel = this.currentUser['email'] || '';
          // this.fullNameModel = (this.currentUser['shortName'] || '') + ' ' + (this.currentUser['longName'] || '');
          // this.shortCodeModel = this.currentUser['symbleName'] || '';
          // this.onKeyPress();
          this.selectUser();
        }
      } else if (this.emailModel === '') {
        this.emailModel = '';
      }
    }

    selectUser() {
      const user = _.findIndex(this.filteredNames, { email: this.emailModel });
      if (user >= 0) {
        this.emailModel = this.filteredNames[user]['email'] || '';
        this.fullNameModel = (this.filteredNames[user]['shortName'] || '') + ' ' + (this.filteredNames[user]['longName'] || '');
        this.shortCodeModel = this.filteredNames[user]['symbleName'] || '';
        this.onKeyPress();
        // update responsibleId too..
        if (this.field.label === 'responsibleEmail' && this.recId && this.recId !== 'new') {
          this.helperService.postRequest(`api/registries/${this.regId}/record/${this.recId}/responsible-user-change`, { email: this.emailModel })
          .then((data) => {
            this.responsibleId = data['responsibleId'];
            this.responsibleIdChange.next(data['responsibleId']);
            this.helperService.openSnackBar('User Updated');
          }).catch(err => {
            this.helperService.openSnackBar(err);
            console.log(err);
          });
        }
      }
    }


  ngOnInit() {
    let groups;
    let users: Array<any>;

    this.users = users;

    const selectedOffice = localStorage.getItem('ngStorage-selectedOffice');
        if (selectedOffice) {
            const temp = JSON.parse(selectedOffice);
            groups = temp['groups'];
            users = temp['users'];
            this.users = temp['users'];

            groups = groups.filter((ele) => this.field['data_range'].includes(ele.name));

            groups.map((ele) => {
            ele['users'] =  _.intersectionBy(users, ele['users'], 'id');
            return ele;
            });

            this.allUsers = [];
            groups = groups.map((ele) => {
              ele.users.forEach((element, j) => {

                // tslint:disable-next-line:max-line-length
                // this.allUsers.push((element['shortName'] || '') + ' ' + (element['longName'] || '') + ' <' + (element['email'] || '') + '> ' + ' - ' + (element['symbleName'] || ''));
                this.allUsers.push(element);
                // tslint:disable-next-line:max-line-length
                // ele['users'][j] = ((element['shortName'] || '') + ' ' + (element['longName'] || '') + ' <' + (element['email'] || '') + '> ' + ' - ' + (element['symbleName'] || ''));
                ele['users'][j] = element;
              });
              return ele;
            });
            // make unique
            this.allUsers = _.uniqBy(this.allUsers, (v) => v.email);
            this.filteredNames = this.allUsers;
            // this.filteredNames = this._filterStates(this.emailModel);
            this.groups = groups || [];
            if (!this.builder) {
              this.getCurrentUser();
            }
        } else {
          return;
        }
      this.checkError();

  }

  private _filterStates(value: string): any {
    const filterValue = value.toLowerCase();
    return this.allUsers.filter(state => state.email.toLowerCase().indexOf(filterValue) === 0);
  }

  changed(value) {
    this.filteredNames = this._filterStates(this.emailModel);
  }

}
