import { Component, OnInit, Inject, Input } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { IProcess } from 'app/main/content/interfaces/process.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import * as _ from 'lodash';
import { IOfficeData, IOfficeUser } from 'app/main/content/interfaces/office.interface';

@Component({
  selector: 'app-after-plugin-install',
  templateUrl: './after-plugin-install.component.html',
  styleUrls: ['./after-plugin-install.component.scss']
})
export class AfterPluginInstallComponent implements OnInit {

  @Input() processRoles;
  processLists = [];
  rolesAccess = {};
  officeId: string;
  usersList: IOfficeUser[];
  officeData: IOfficeData;

  constructor(
    private helperService: HelpersService,
    public dialogRef: MatDialogRef<AfterPluginInstallComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private officeHelperService: OfficeHelpersService
  ) { }

  ngOnInit() {
    this.officeHelperService.getLocalOfficeData()
    .then((officeData: IOfficeData) => {
      this.officeData = officeData;
      this.officeId = officeData._id;
      this.usersList = officeData.users || [];
      this.checkRoles();
    })
    .catch((err) => {
      console.log(err);
    });
    this.processLists = (this.data && this.data.processRoles) || this.processRoles || [];
    // if (this.processLists[0]['id']) {
      // this.getProcess(this.processLists[0]['id']);
    // }
  }


  getProcess(processId: string) {
    this.helperService.getProcess(processId)
    .then((process: IProcess) => {
    }).catch(err => {
      console.log(err);
    });
  }

  checkRoles() {
    this.processLists.forEach((process) => {
      this.rolesAccess[process['id']] = this.rolesAccess[process['id']] || {};
      process['roles'] = process['roles'] || [];
      process['roles'].forEach((role) => {
        if (role && role['groups'] && role['groups'][0]) {
          this.usersList.forEach((user) => {
            user['groups'] = user['groups'] || [];
            const temp = _.findIndex(user['groups'], { name: role['groups'][0]['name'] });
            this.rolesAccess[process['id']][role['id']] = this.rolesAccess[process['id']][role['id']] || [];
            if (temp >= 0) {
              this.rolesAccess[process['id']][role['id']].push(user['email']);
            }
          });
        }
      });
      // this.rolesAccess[process['id']].push()
    });
  }

  addRole(process, role, user) {
    if (this.rolesAccess[process['id']] && this.rolesAccess[process['id']][role['id']] && this.rolesAccess[process['id']][role['id']].includes(user)) {
      // remove
      role['groups'] = role['groups'] || [];
      if (role['groups'][0] && role['groups'][0]['name']) {
        this.opToUser(role['groups'][0]['name'], user, 'delete');
        this.opToGroup(role['groups'][0]['name'], user, 'delete');
        const i = this.rolesAccess[process['id']][role['id']].indexOf(user);
        if (i >= 0) {
          this.rolesAccess[process['id']][role['id']].splice(i, 1);
        }
        this.helperService.updateOfficeDoc(this.officeData)
        .then((data) => {
          this.helperService.openSnackBar('Update..');
        }).catch(err => {
          this.helperService.openSnackBar(err || 'Error occured while udpating');
        });
      }
    } else {
      // add
      role['groups'] = role['groups'] || [];
      if (role['groups'][0] && role['groups'][0]['name']) {
        this.opToUser(role['groups'][0]['name'], user, 'add');
        this.opToGroup(role['groups'][0]['name'], user, 'add');
        this.rolesAccess[process['id']][role['id']].push(user);
        this.helperService.updateOfficeDoc(this.officeData)
        .then((data) => {
          this.helperService.openSnackBar('Update..');
        }).catch(err => {
          this.helperService.openSnackBar(err || 'Error occured while udpating');
        });
      }
    }
  }

  opToUser(group, user, op) {
    this.officeData.users = this.officeData.users || [];
    const userIndex = _.findIndex(this.officeData.users, { email: user });
    if (userIndex >= 0) {
      this.officeData.users[userIndex].groups = this.officeData.users[userIndex].groups || [];
      const isGroupPresent = _.findIndex(this.officeData.users[userIndex].groups, { name: group });
      if (op === 'add' && isGroupPresent < 0) {
        this.officeData.users[userIndex].groups.push(({ name: group }));
      } else if (op === 'delete' && isGroupPresent >= 0) {
        delete this.officeData.users[userIndex].groups[isGroupPresent];
      }
    }
  }

  opToGroup(group, user, op) {
    this.officeData.groups = this.officeData.groups || [];
    const groupIndex = _.findIndex(this.officeData.groups, { name: group });
    if (groupIndex >= 0) {
      const userIndex = _.findIndex(this.officeData.users, { email: user });
      const tempUser = {
        email: this.officeData.users[userIndex].email,
        id: this.officeData.users[userIndex].id
      };
      this.officeData.groups[groupIndex].users = this.officeData.groups[groupIndex].users || [];
      const isUserPresent = _.findIndex(this.officeData.groups[groupIndex].users, { email: tempUser.email });
      if (op === 'add' && isUserPresent < 0) {
        this.officeData.groups[groupIndex].users.push(tempUser);
      } else if (op === 'delete' && isUserPresent >= 0) {
        delete this.officeData.groups[groupIndex].users[isUserPresent];
      }
    }
  }
}
