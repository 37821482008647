import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact-search',
  templateUrl: './contact-search.component.html',
  styleUrls: ['./contact-search.component.scss']
})
export class ContactSearchComponent implements OnInit {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: string; 
@Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Input() companyName: string;
  @Output() companyNameChange: EventEmitter<Object> = new EventEmitter();
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  regId = '';
  resData;
  searchMultiFileds;
  showList = false;

  constructor(
    private http: HttpClient,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    // get address book regId
    if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && 
        JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps'] &&
        JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps']['office_addressbook'] && 
        JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps']['office_addressbook']['id']
    ) {
      this.regId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps']['office_addressbook']['id'];
    }
  }


  getDataFromServer(val) {
    if (this.regId) {
      const dtTableSetting = {
        columns: [
          {prop: 'companyName', title: 'Company Name'}, 
          {prop: 'companyAdress', title: 'Company Address'}, 
          {prop: 'postalcode', title: 'Postal Code'}, 
          {prop: 'companyCity', title: 'Company City'}, 
          {prop: 'nip', title: 'Nip'}, 
          {prop: 'companyContactPerson', title: 'Contact Person'},
          {prop: '_id', title: 'id'}],
        filters: {},
        officeId: (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) || '',
        regId: this.regId,
        page: {
          size: 200,
        totalElements: 0,
        totalPages: 0,
        pageNumber: 0
      },
      search: {
        regex: false,
        value: val
      },
      sort: {
        dir: 'asc',
        prop: 'companyName'
      }
    };
    this.helperService.dataTableRequest(this.regId, dtTableSetting)
    .then((data) => {
      if (data['success']) {
        this.resData = (data && data['result'] && data['result']['dttable'] && data['result']['dttable']['data']) || [];
        this.showList = true;
      }
    }).catch(err => {
      console.log(err);
    });
    } else {
      console.log('No office_addressbook id');
    }
  }

  selectVal(val) {
    this.model = (val && val._id) || '';
    // tslint:disable-next-line:max-line-length
    this.companyName = (val && ((val.companyName || '') + ' ' + (val.shortName || '') + ' ' + (val.longName || '') + ' ' + (val.companyAdress || '') + ' ' + (val.nip || ''))) || '';
    this.modelChange.next(this.model);
    this.companyNameChange.next(this.companyName);
    this.showList = false;
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
    
  }
}
