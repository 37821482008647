<!-- <mat-form-field>
  <mat-label>{{ fieldTitle }}</mat-label>
  <mat-select>
    <mat-option *ngFor="let food of foods" [value]="food.value">
      {{food.viewValue}}
    </mat-option>
  </mat-select>
</mat-form-field> -->
<!-- {{ fieldTitle }} -->
<mat-form-field style="min-width: 180px;;">
  <mat-label>{{ fieldTitle | translate }}</mat-label>
  <mat-select (selectionChange)="onChange($event)" [(ngModel)]="model" >
    <!-- <mat-option> {{ fieldTitle }} </mat-option> -->
    <mat-optgroup  *ngFor="let subHeading of dateList" [label]="subHeading.subHeading | translate">
      <mat-option *ngFor="let list of subHeading.list" [value]="list.value">
        {{ list.title | translate }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>