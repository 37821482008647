import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-record-bulk-update',
  templateUrl: './record-bulk-update.component.html',
  styleUrls: ['./record-bulk-update.component.scss']
})
export class RecordBulkUpdateComponent implements OnInit {

  reqData: any = {
    updatedDoc: [{}]
  };
  loading = false;
  
  constructor(public dialogRef: MatDialogRef<RecordBulkUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _helperService: HelpersService,
    private _translationLoader: FuseTranslationLoaderService,
  ) { }

  ngOnInit() {

    if (this.data && this.data.isTask) {
      this.reqData.actionType = 'processTask';
      this.reqData.taskObject = this.data.taskObject;
    }
    // this.reqData.regId = this.data.regId;
    this.reqData.columnFields = this.data.columnFields || [];
    this.reqData.dtTableFilter = this.data.dtTableFilter || {};
    if (this.data.mqHardFilter) {
      if (this.reqData.dtTableFilter.mqFilter) {
        this.reqData.dtTableFilter.mqFilter = `${this.reqData.dtTableFilter.mqFilter} AND ${this.data.mqHardFilter}`;
      } else {
        this.reqData.dtTableFilter.mqFilter = this.data.mqHardFilter;
      }
    }
    this._helperService.getTrans('bulk_action')
      .then((data2) => {
        this._translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
        console.log(err);
      });
  }

  confirmBulkAction() {
    this.loading = true;
    this._helperService.putRequest(`api/registries/${this.data.regId}/bulkAction`, this.reqData)
    .then((data) => {
      this._helperService.openSnackBar('Done Bulk Action');
      this.dialogRef.close(true);
      this.loading = false;
    }).catch(err => {
      this._helperService.openSnackBar(err);
      this.loading = false;
    });
  }

}
