import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgSummernoteModule } from '../ng-summernote/ng-summernote.module';
import { NgSummernoteService } from '../ng-summernote/ng-summernote.service';
import { TemplateModalComponent } from './template-modal.component';


@NgModule({
  imports: [
    CommonModule,
   FuseSharedModule,
    NgSummernoteModule
  ],
  declarations: [
    TemplateModalComponent
  ],
  providers: [
    NgSummernoteService
  ],
  exports: [
    TemplateModalComponent
  ],
  entryComponents: [
    TemplateModalComponent
  ]
})
export class TemplateModalModule { }
