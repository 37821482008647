import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FabSpinnerButtonOptions } from 'app/main/content/consts/progressButtonOptions.const';
import { MatProgressButtonOptions } from '../../mat-progress-buttons/module/mat-progress-buttons.interface';

@Component({
  selector: 'app-reg-value-selection',
  templateUrl: './reg-value-selection.component.html',
  styleUrls: ['./reg-value-selection.component.scss']
})
export class RegValueSelectionComponent implements OnInit {
  showList: boolean;
  resData: any;
  searchMultiFileds;
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model;
  @Input() translateData: any;
  @Input() model2;
  @Input() regId;
  @Input() recId;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();  
  @Output() model2Change: EventEmitter<Object> = new EventEmitter();  
  public officeId: string;
  allPushed = {};
  buttonOptions: MatProgressButtonOptions;

  constructor(
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
      this.officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] || '';
    }

    this.buttonOptions = FabSpinnerButtonOptions;
    this.buttonOptions.iconButton = true;
    this.buttonOptions.spinnerSize = 40;
    this.buttonOptions.icon = 'search';

    if (this.field.multiSelect) {
      this.getDataFromServer('');
      this.model = this.model || [];
      this.model.forEach((ele) => {
        this.allPushed[ele.key] = true;
      });
    }    
  }

  
  onChange() {
  this.modelChange.next(this.model);
    if (this.field.multiSelect) {
      this.model = this.model || [];
      this.model2 = '';
      this.model.forEach((ele) => {
        ele.value = ele.value || {};
        // tslint:disable-next-line:forin
        for (const i in ele.value) {
          this.model2 += ele.value[i] + ', ';
        }
      });
      this.model2 = this.model2.substr(0, this.model2.length - 2);
      this.model2Change.next(this.model2);
    }
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
  }


  getDataFromServer(filterVal: string) {
    const filters = {}; 
    const search = {
      regex: false
    };

    if (this.field['filters']) {
      this.field['filters'].forEach(element => {
        filters[element.key] = element.value;
      });
    }


    
    if (this.field['field_key']) {

      search['value'] = filterVal;

      
      const cols = [{
        prop: '_id',
        title: '_id'
      },
      {
        prop: 'regId',
        title: 'regId'
      }];

      if (this.field['field_to_show'] && this.field['field_to_show'].length > 0) {
        this.field['field_to_show'].forEach(ele => {
          cols.push({
            prop: ele,
            title: ele
          });
        });
      }

      const dtTableSetting = {
        columns: cols,
        filters,
        officeId: this.officeId,
        regId: this.field['regId'],
        'filterRecord': {},
        'defaultRegisterDateTimeSort': false,
        page: {
          size: 1000,
          totalElements: 0,
          totalPages: 0,
          pageNumber: 0
        },
        mqFilter: this.field['hardFilters'],
        search: search,
        sort: {
          dir: 'asc',
          prop: this.field['field_key']
        }
      };
      this.buttonOptions.active = true;
      this.helperService.dataTableRequest(this.field['regId'], dtTableSetting)
      .then((data) => {
        if (data['success']) {
          this.resData = data['data'];
        }
        this.buttonOptions.active = false;
      }).catch(err => {
        this.buttonOptions.active = false;
        console.log(err);
      });
    } else {
      console.error('Error in fields selction');
      
    }   
    this.showList = true;  
  }


  selectVal(option) {
    if (!this.field['only_display']) {
      const val = {};
    
      if (this.field['field_to_show'] && this.field['field_to_show'].length > 0) {
        this.field['field_to_show'].forEach(ele => {
          val[ele] = option[ele];
        });
      }
      if (this.field.multiSelect) {
        this.model = this.model || [];
        if (!this.allPushed[option._id]) {
          this.model.push({key: option._id, value: val});
          this.model.forEach((ele) => {
            this.allPushed[ele.key] = true;
          });
          this.onChange(); 
        } else {
          // remove
          this.model = this.model.filter(ele => ele.key !== option._id);
          this.allPushed[option._id] = false;
          this.onChange(); 
        }
      } else {
        this.model = {key: option._id, value: val};
        this.onChange(); 
        this.showList = false;
        const linkData = {
          src_reg: this.regId,
          src_rec: this.recId,
          dest_reg: option.regId,
          dest_rec: option._id,
          main: false
        };
        this.helperService.createNewLink(linkData)
        .then(() => {
          // this..active = false;
          this.helperService.openSnackBar('Record Added');
        }).catch(err => {
          console.log(err);
        });

        this.helperService.updateRegisterRecord(this.regId, this.recId, {
          parentId: option['_id']
        }).then((data) => {
        }).catch(err => {
          console.log(err);
        });
      }
      
      
    }
  }

}
