import { INg5Form, IDataTableSort, ITabContent } from '../interfaces/form.interface';

export class FormClass
{
    public readonly officeId: string;
    public readonly title: string;
    public readonly ng5Form: INg5Form | null;
    public readonly formTemplates: null[] | null;
    public readonly hideActions: boolean;
    public readonly refreshOnEdit: boolean;
    public readonly changeConfirmPopup: null;
    public readonly popupOptions: null;
    public readonly dtTableAdvanceFilters: null[] | null;
    public readonly hardFilter: string;
    public readonly showAddressBookPopup: boolean;
    public readonly disableInsert: boolean;
    public readonly dataTableLength: number;
    public readonly actionWidth: number;
    public readonly dataTableSort: IDataTableSort;
    public readonly groupBy: boolean;
    public readonly groupByField: string;
    public readonly hideUpdateAndSave: boolean;

    constructor(formData?)
    {
        formData = formData || {};
        this.officeId = formData.officeId || '';
        this.title = formData.title || '';
        this.ng5Form = formData.ng5Form || new Ng5FormClass();
        this.formTemplates = formData.formTemplates || '';
        this.hideActions = formData.hideActions || false;
        this.refreshOnEdit = formData.refreshOnEdit || false;
        this.changeConfirmPopup = formData.changeConfirmPopup || '';
        this.popupOptions = formData.popupOptions || '';
        this.dtTableAdvanceFilters = formData.dtTableAdvanceFilters || [];
        this.hardFilter = formData.hardFilter || '';
        this.showAddressBookPopup = formData.showAddressBookPopup || false;
        this.disableInsert = formData.disableInsert || false;
        this.dataTableLength = formData.dataTableLength || '';
        this.actionWidth = formData.actionWidth || '';
        this.dataTableSort = formData.dataTableSort || {};
        this.groupBy = formData.groupBy || false;
        this.groupByField = formData.groupByField || '';
        this.hideUpdateAndSave = formData.hideUpdateAndSave || false;
    }
}

export class Ng5FormClass {

    tabTitle: string;
    tabContent: ITabContent[] | null[];
    constructor(tabData?) {
        tabData = tabData || {};
        this.tabContent = tabData.tabContent || [];
        this.tabTitle = tabData.tabTitle || '';
    }
}
