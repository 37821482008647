import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  animations   : fuseAnimations
})
export class FilePreviewComponent implements OnInit, OnChanges {
  @Input() fileId;
  @Input() isScanque;
  @Input() translateData: any;
  @Input() scanqueEditUrl;
  pdfURL: any;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private router: Router,
    private helperService: HelpersService,
    private translationLoader: FuseTranslationLoaderService,

  ) { }
  
  
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

    ngOnInit() {
      this.translationLoader.loadTranslationsV2(this.translateData);

      this.pdfURL = null;
      if (this.fileId && typeof(this.fileId) === 'object') {
        this.fileId = this.fileId['id'];
      }
      this.getDocument(this.fileId);
    }

    moveTo(scanqueEditUrl) {
      this.router.navigate([scanqueEditUrl]);
    }
    getDocument(fileId: string) {
      this.helperService.getDocument(fileId)
        .then((res) => {
          const blob = this.helperService.b64toBlob(res['base64'], 'application/pdf', null);
          const blobUrl = URL.createObjectURL(blob);
          this.pdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
        }).catch(err => {
          console.log(err);
        });
    }
}
