import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnInit
{
    public confirmMessage: string;
    public inputValue: string;
    public message: string;
    public inputPlaceholder: string;

    constructor(public dialogRef: MatDialogRef<InputDialogComponent>)
    {
    }

    ngOnInit()
    {
    }

}
