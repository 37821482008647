import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { AddTaskComponent } from '../add-task.component';

@Component({
  selector: 'app-task-selection',
  templateUrl: './task-selection.component.html',
  styleUrls: ['./task-selection.component.scss']
})
export class TaskSelectionComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TaskSelectionComponent>,
  ) { }

  ngOnInit() {
  }

  openTaskSettings(temp) {
    const dialogRef = this.dialog.open(AddTaskComponent, {
      width: '100%',
      panelClass: 'happ-form-builder-dialog',
      data: { temp, task: null, operation: 'add', processData: this.data['processData'], activityData: this.data['activityData'], stageExc: null }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close(result);
      }
    }, err => {
      console.log(err);
    });
  }
}
