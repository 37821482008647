<div class="pb-12" *ngIf="!field.hide || builder">
    <div class="happ-list-field" [class]="field.class" >
        <div class="happ-default-title happ-title">
            <!-- <mat-icon *ngIf="error" class="ml-8 mt-8 happ-error-default happ-error-icon">warning</mat-icon> -->
            <div class="w-100-p" fxLayout="column">
                    <div class="w-100-p" fxLayout="row wrap" fxLayoutAlign="center center">
                        <p *ngIf="!hideTitle" class="happ-field-title">{{ field.title }} </p>
                        <span fxFlex></span>
                        <div>
                            <button class="mt-4 sml-icn-btn" *ngIf="field.showEdit && !field.userRegister" style="margin-right: -8px !important;color: #827c7c;" (click)="editOptions()" [matTooltip]="'ct.edit_options' | translate" mat-icon-button>
                                <mat-icon class="sml-icon">settings</mat-icon>
                            </button>
                        </div>
                    </div>
                <p *ngIf="errorMessage" class="warn-500-fg m-0 ml-8">
                    {{ errorMessage | translate  }}
                </p>
            </div>
        </div>
        
        <div class="happ-list happ-content" *ngIf="!field.multiple && !loading" fxLayout="{{field.flex}} wrap">
            <button [disabled]="field.showdisablecontroltype || field.disable" class="happ-button-list" [ngClass]="{'happ-small-button': field.smallButton}" class="my-4" [style.text-align]="(field.align)? field.align : 'center'"
            mat-raised-button 
            (click)="model=option.value || option.title; onChange()" *ngFor="let option of field.options" 
            [style.background]="(((option.value && model == option.value) || (option.title && model == option.title))? '#e1f5fe': option.background)"
                [style.color]="(((option.value && model == option.value) || (option.title && model == option.title))? 'black': option.color)">
                 {{ option.title || option.value }} </button>
        </div>
        <div class="happ-list happ-content" *ngIf="field.multiple && !loading" fxLayout="{{field.flex}} wrap">
            <button [disabled]="field.showdisablecontroltype || field.disable" class="happ-button-list" [ngClass]="{'happ-small-button': field.smallButton}" class="my-4" mat-raised-button [style.text-align]="(field.align)? field.align : 'center'" (click)="onChange(option.value || option.title)" role="listitem" *ngFor="let option of field.options" [style.background]="(model && (model[option.value] || model[option.title]))? '#e1f5fe': option.background"
                [style.color]="(model && model[option.value] && model[option.title])? 'black': option.color">
                {{ option.title || option.value }}
            </button>
        </div>
        <div fxLayoutAlign="center center" *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <div class="happ-desc" *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">
    
    </div>
</div>