import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router, Params } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from 'environments/environment';
import { HelpersService } from '../../services/helpers/helpers.service';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class InvitationListService implements Resolve<any>
{
    
    invitationList = [];
    invitationCountChanged: Subject<any> = new Subject<any>();


    constructor(
      private helperService: HelpersService,
      private router: Router
    )
    { }

    /**
     * The App Main Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getInvitationList()
            ]).then(
                () => {
                    resolve();
                },
                reject
            ).catch(reject);
        });
    }

    getInvitationList(): Promise<object>
    {
        return new Promise((resolve, reject) => {
            this.invitationList = [];
            this.helperService.getRequest(`api/offices/profile/invitations`)
            .then((data) => {
                this.invitationList = data || [];
                this.invitationList = this.invitationList.map((ele) => {
                    ele.buttons = this.transformSort(ele.buttons) || [];
                    return ele;
                });
                resolve(this.invitationList);
            }).catch(err => {
                console.log(err);
                resolve([]);
            });
    });
}
// invitationListService.invitationList

transformSort(array: any[]): any[] {
    if (!array || array && array.length === 0) {
      return [];
    }
    array.sort((a: any, b: any) => {
      if (a && b && a && b && a['sort'] < b['sort']) {
        return -1;
      } else if (a && b && a && b && a['sort'] > b['sort']) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }


}
