<div fxLayout="row" style="height: 70vh; width: 100%;" *ngIf="!field.hide || builder">
    <div fxFlex="30" style="margin-right: 5px;border: 1px solid rgba(200,200,200,0.8); overflow: auto;">
        <div style="background: rgb(24, 134, 183); color: white; padding: 1px 15px; min-height: 42px;">
            <h4 style="text-align: center;">
                {{ 'ct.list_of_documents' | translate }}
            </h4>
            <span fxFlex></span>
            <div style="margin: auto;">
                <button matTooltip="{{ 'ct.add_new_document' | translate }}" (click)="addNewDocument()" mat-icon-button>
                    <mat-icon style="color: white; font-size: 30px;" aria-label="Example icon-button with a heart icon">add_circle</mat-icon>
                </button>
            </div>
        </div>
        <!-- <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let oneRecordDocument of registerRecordDocument">
                <div (click)="selectPDF(oneRecordDocument.base64); selected=oneRecordDocument" fxFlex="100">
                    <span>
                        {{ oneRecordDocument.originalname }}
                    </span>
                    <span fxFlex></span>
                </div>
            </mat-list-item>
        </mat-list> -->
        <div>
        <mat-list-item class="mat-elevation-z1 happ-hover" (click)="selectPDF(oneRecordDocument.base64); selected=oneRecordDocument" *ngFor="let oneRecordDocument of registerRecordDocument" style="margin: 10px 0px;" role="listitem">
                <p style="margin: auto;" class="ml-8">
                        {{ oneRecordDocument.originalname }}
                </p>
            <span fxFlex></span>
            <!-- <button *ngIf="selected && selected._id === oneRecordDocument._id" matTooltip="{{ 'ct.set_as_main_document' | translate }}" (click)="saveAsMainDoc(selected._id)" mat-icon-button>
                <mat-icon>done</mat-icon>
            </button>

            <button matTooltip="{{ 'ct.open_document' | translate }}" (click)="selectPDF(oneRecordDocument.base64); selected=oneRecordDocument" mat-icon-button>
                <mat-icon aria-label="Example icon-button with a heart icon">arrow_forward</mat-icon>
            </button> -->
            <mat-divider></mat-divider>
        </mat-list-item>
        </div>
    </div>
    <div fxFlex="70" style="border: 1px solid rgba(200,200,200,0.8);">
        <div fxLayout="column">
            <div style="align-items: center; min-height: 42px;background: rgb(24, 134, 183); color: white; padding: 1px 15px;" fxLayout="row">
                <button *ngIf="selected && pdfURL && !field.hideDelete" (click)="deleteRecord(selected['_id'])" color="warn" mat-mini-fab>
                        <mat-icon style="color: white" aria-label="Example icon-button with a heart icon">delete</mat-icon>
                      </button>
                <span fxFlex></span>
                <h4 *ngIf="selected">
                    {{ selected.originalname }}
                </h4>
                <span fxFlex></span>
                <h4 *ngIf="(selected && selected['published'])">
                    {{ 'ct.published_template' | translate }}
                </h4>
                <button *ngIf="(selected && !selected['published'])" matTooltip="{{ 'ct.publish' | translate }}" (click)="publishDocument(selected['_id'])" mat-icon-button>
                    <mat-icon style="color: white; font-size: 30px;" aria-label="Example icon-button with a heart icon">publish</mat-icon>
                </button>
                <button *ngIf="(selected)" matTooltip="{{ 'ct.set_as_main_document' | translate }}" (click)="saveAsMainDoc(selected._id)" mat-icon-button>
                    <mat-icon style="color: white; font-size: 30px;">done</mat-icon>
                </button>
                <button *ngIf="(selected && !selected['published'])" matTooltip="{{ 'ct.update' | translate }}" (click)="updatePDF(selected['reportId'], selected._id)" mat-icon-button>
                    <mat-icon style="color: white; font-size: 30px;" aria-label="Example icon-button with a heart icon">refresh</mat-icon>
                </button>
            </div>
            <div style="height: 70vh; width: 100%;" *ngIf="!pdfURL" style="justify-content: center; align-items: center;">
                <h4 style="text-align: center;">
                    <div fxLayoutAlign="center center" fxLayout="column" style="height: 70vh;" class="w-100-p" *ngIf="!selectedProcess">
                        <mat-icon *fuseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" class="s-128 mb-16 hint-text select-message-icon">
                            book
                        </mat-icon>
                        <h2 *fuseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" class="select-message-text hint-text">{{ 'ct.select_from_left_to_preview' | translate }}</h2>
                    </div>
                </h4>
            </div>
            <div *ngIf="pdfURL">
                <iframe [src]="pdfURL" style="height: 70vh; width: 100%;" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</div>