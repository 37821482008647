import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { GlobalModule } from '@fuse/global.module';
import { MaterialModule } from '@fuse/material.module';
import { AllFieldsViewComponent } from './all-fields-view.component';
import { CalenderTemplateModule } from '../../apps/calendar-template/calendar.module';
import { ProcessGraphViewModule } from '../../components/process-graph-view/process-graph-view.module';

@NgModule({
    imports     : [
       FuseSharedModule,
        GlobalModule,
        MaterialModule,
        ProcessGraphViewModule
         
    ],
    // exports: [
    //   EditNg5Component,
    // ],
    declarations: [
        AllFieldsViewComponent,
        // FormViewComponent
    ],
    exports: [
        AllFieldsViewComponent
    ]
})
export class AllFieldsViewModule
{
}
