import { HelpersService } from './../../../services/helpers/helpers.service';
import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CreateBranchPopupComponent } from './create-branch-popup/create-branch-popup.component';

@Component({
  selector: 'app-create-branch',
  templateUrl: './create-branch.component.html',
  styleUrls: ['./create-branch.component.scss']
})
export class CreateBranchComponent implements OnInit {

  @Input() field;
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() model;
  @Input() otherInfo;
  @Input() regId;
  @Input() recId;
  @Input() signatura;
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() otherInfoChange: EventEmitter<Object> = new EventEmitter();
  
  constructor(
    public dialog: MatDialog,
    private _helperService: HelpersService
  ) { }

  ngOnInit() {
  }

  openPopup() {
    const dialogRef = this.dialog.open(CreateBranchPopupComponent, {
      width: '600px',
      height: 'max-content',
      panelClass: 'happ-form-builder-dialog',
      data: { 
        branchName: this.model, 
        signatura: this.signatura,
        otherInfo: this.otherInfo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.deleted) {
          this.model = '';
          this.otherInfo = null;
          this.modelChange.next(this.model);
          this.otherInfoChange.next(this.otherInfo);
        } else {
          this.model = result.branchName;
          this.modelChange.next(this.model);
          this.otherInfo = result;
          this.otherInfoChange.next(this.otherInfo);
        }
        const tt = {};
        tt[this.field.label] = this.model;
        tt[this.field.label + '_otherInfo'] = this.otherInfo;
        this._helperService.updateRegisterRecord(this.regId, this.recId, tt)
        .catch(err => {
          console.log(err);
        });
      }
    });
  }
}
