import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subject, merge, fromEvent, Observable, Observer } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation, FuseNavigationMenuService } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { AuthService } from './main/content/pages/authentication/auth.service';
import { MatDialog } from '@angular/material';
import { UserInfoComponent } from './main/content/pages/user-info/user-info.component';
import { SocketService } from './main/content/services/socket/socket.service';
import { Event } from './main/content/services/socket/model/event';
import { HelpersService } from './main/content/services/helpers/helpers.service';
import { PlatformIssueReporterComponent } from './main/content/global-component/platform-issue-reporter/platform-issue-reporter.component';
import { VersionCheckService } from './version-check.service';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{
    fuseConfig: any;
    isOnline = true;
    navigation: any;
    retrySocketConnect = 3;
    ioConnection: any;
    loadingItem = [{
        'id'       : 'loading',
        'title'    : 'Loading',
        'translate': 'NAV.loading',
        'type'     : 'item',
        'icon'     : 'refresh'
    }];

    // Private
    private _unsubscribeAll: Subject<any>;
    alertData: any;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private authservice: AuthService,
        public dialog: MatDialog,
        private naviagtionMenu: FuseNavigationMenuService,
        private socketService: SocketService,
        private helperService: HelpersService,
        private versionCheckService: VersionCheckService,
        private _http: HttpClient

    )
    {
        this.initIoConnection();

        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if ( this._platform.ANDROID || this._platform.IOS )
        {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.createOnline$().subscribe(isOnline => {
            console.log(isOnline);
            this.isOnline = isOnline;
        });
        this.versionCheckService.initVersionCheck(environment.versionCheckURL);
        this._fuseNavigationService.unregister('loading');
        this._fuseNavigationService.register('loading', this.loadingItem);
        this._fuseNavigationService.setCurrentNavigation('loading');
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if ( this.fuseConfig.layout.width === 'boxed' )
                {
                    this.document.body.classList.add('boxed');
                }
                else
                {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for ( let i = 0; i < this.document.body.classList.length; i++ )
                {
                    const className = this.document.body.classList[i];

                    if ( className.startsWith('theme-') )
                    {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

            if (localStorage.getItem('ngStorage-profile') && JSON.parse(localStorage.getItem('ngStorage-profile')) && 
            JSON.parse(localStorage.getItem('ngStorage-profile'))['defaultLang']) {
                localStorage.setItem('selectedLanguage', JSON.parse(localStorage.getItem('ngStorage-profile'))['defaultLang'] || this._translateService.getBrowserLang() || 'en');
            } else {
                localStorage.setItem('selectedLanguage', this._translateService.getBrowserLang() || 'en');
            }

            this._translateService.setDefaultLang(localStorage.getItem('selectedLanguage'));
            this._translateService.use(localStorage.getItem('selectedLanguage'));
    
             // Set the navigation translations
        // this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationPolish);

        this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.authservice.refreshToken().then((responseToken) => {
                this._fuseNavigationService.unregister('main');
                this.naviagtionMenu.menuItems().then( (response) => {
                    this._fuseNavigationService.register('main', response);                    
                    this._fuseNavigationService.setCurrentNavigation('main');
                }).catch( (error) => {
                    console.log(error);
                    this.authservice.logOut();
                });
            }).catch( (error) => { 
                console.log(error);
                this.authservice.logOut();
            });
        });
    
        this.authservice.onOfficeChange.subscribe((data) => {
            const office = (localStorage.getItem('ngStorage-selectedOffice')) ? JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) : null;
            this.authservice.refreshToken().then((responseToken) => {
                this._fuseNavigationService.unregister('main');
                 this.naviagtionMenu.menuItems().then( (response) => {
                    this._fuseNavigationService.register('main', response);
                    this._fuseNavigationService.setCurrentNavigation('main');
                    if (this._fuseNavigationService.getFlatNavigation(this._fuseNavigationService.getCurrentNavigation()) 
                    && this._fuseNavigationService.getFlatNavigation(this._fuseNavigationService.getCurrentNavigation()).length === 0) {
                        // means user dont have any group assigned.
                        this.dialog.open(UserInfoComponent, {
                            width: '100%',
                            height: '100%',
                            panelClass: 'happ-form-builder-dialog',
                            // disableClose: true,
                            data: { heading: 'Informacja dla użytkownika', message: 'Nie dostałeś dostępu do żadnej grupy. Proszę skontaktuj się z Administratorem biura.'}
                        });
                    }
                    if (office && office['aotFuseSetting']){
                        // this._fuseConfigService.onSettingsChanged.next(office['aotFuseSetting']);
                        this._fuseConfigService.setConfig(office['aotFuseSetting']);
                    } else{
                        // this._fuseConfigService.onSettingsChanged.next(this._fuseConfigService.defaultSettings);
                        this._fuseConfigService.resetToDefaults();
                        
                }}).catch( (error) => {
                    console.log(error);
                    this.authservice.logOut();

                });
            }).catch( (error) => { 
                console.log(error);
                this.authservice.logOut();

            });   
        });
        this.checkPlatformAlert();
    }

    
    
    checkPlatformAlert() {
        // this._http.get()
        this.alertData = {};
        this._http.get(`https://api.w4.pinbox24.com/api/platform-alert`)
        .subscribe((data: any) => {
            if (data && data.success && data.result) {
                this.alertData = data.result;
            }
        }, err => {
            console.error(err);
        });
        setInterval(() => {
            this._http.get(`https://api.w4.pinbox24.com/api/platform-alert`)
            .subscribe((data: any) => {
                if (data && data.success && data.result) {
                    this.alertData = data.result;
                }
            }, err => {
                console.error(err);
            });
        }, (1000 * 60));
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    
    private initIoConnection(): void {
        this.socketService.initSocket();
       
        this.ioConnection = this.socketService.userNotifyToUpdate()
          .subscribe(() => {
                this._fuseNavigationService.unregister('main');
                this.naviagtionMenu.menuItems().then( (response) => {
                    this._fuseNavigationService.register('main', response);
                    this._fuseNavigationService.setCurrentNavigation('main');
                }).catch( (error) => {
                    console.log(error);
                    this.authservice.logOut();

                });
        });
    
    
        this.socketService.onEvent(Event.CONNECT).subscribe(() => {
            this.authservice.refreshToken().then((responseToken) => {
                if (JSON.parse(localStorage.getItem('ngStorage-token'))){
                    this.socketService.authenticate(JSON.parse(localStorage.getItem('ngStorage-token')).token);
                    // this.appConnected();
                    console.log('connected');
                }
            }).catch( (error) => { 
                console.log(error);
                this.authservice.logOut();
            });
        });

        this.socketService.onEvent(Event.DISCONNECT)
          .subscribe(() => {
            // this.appDisconnected();
            console.log('disconnected');
            this.retrySocketConnection();
          });
    }

    retrySocketConnection() {
        try {
            if (this.retrySocketConnect) {
                console.log(`Socket Connection retry ${this.retrySocketConnect}`);
                if (JSON.parse(localStorage.getItem('ngStorage-token'))){
                    this.socketService.authenticate(JSON.parse(localStorage.getItem('ngStorage-token')).token);
                    // this.appConnected();
                    console.log('connected');
                }
                this.retrySocketConnect = this.retrySocketConnect - 1;
            }
        } catch (error) {
            console.log(error);
        }
    }

    appDisconnected() {
        this.helperService.openSnackBar('Application Disconnected', 'Okay');
        this._fuseConfigService.config = {
            layout: {
                footer: {
                    hidden: false
                },
                // navbar: {
                //     hidden: false
                // },
            }
        };
    }

    appConnected() {
        this.helperService.openSnackBar('Application Connected', 'Okay');
        this._fuseConfigService.config = {
            layout: {
                footer: {
                    hidden: true
                },
                // navbar: {
                //     hidden: true
                // },
                // navbar: {
                //     hidden: true
                // },
                // toolbar: {
                //     hidden: true
                // },
                // sidepanel: {
                //     hidden: true
                // }
            }
        };
    }

    openIssueReporter(eve: any) {
        if (eve && eve.ctrlKey && eve.altKey && eve.key === 'b') {
            eve.preventDefault();
            this.addIssue();
        }
    }


    

    addIssue(): void {
        this.dialog.open(PlatformIssueReporterComponent, {
          width: 'max-content',
          maxWidth: '80%',
          height: 'max-content',
          restoreFocus: false,
          panelClass: 'happ-form-builder-dialog',
          data: { reporterType: 'admin' }
        });
      }
      
    myFunction() {
        setInterval(() => {
            this.authservice.refreshToken().then((responseToken) => { 
            }).catch( (error) => { 
                this.authservice.logOut();
            });
        }, (60 * 60 * 1000));
    }
    createOnline$() {
        return merge<boolean>(
          fromEvent(window, 'offline').pipe(map(() => false)),
          fromEvent(window, 'online').pipe(map(() => true)),
          new Observable((sub: Observer<boolean>) => {
            sub.next(navigator.onLine);
            sub.complete();
        }));
    }
}
