import { AddTaskComponent } from './add-task.component';
import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { TaskCategorySelectionComponent } from './task-category-selection/task-category-selection.component';
import { TaskSelectionComponent } from './task-selection/task-selection.component';

@NgModule({
    imports     : [
       FuseSharedModule,
        // GlobalModule,
    ],
    declarations: [
        AddTaskComponent,
        TaskCategorySelectionComponent,
        TaskSelectionComponent
    ],
    providers   : [
    ],
    exports: [
        AddTaskComponent,
        TaskCategorySelectionComponent,
        TaskSelectionComponent
    ],
    entryComponents: [
        AddTaskComponent,
        TaskCategorySelectionComponent,
        TaskSelectionComponent
    ]
})
export class AddTaskModule
{
}
