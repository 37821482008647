import { Component, OnInit } from '@angular/core';
import { version } from 'environments/version'
import { branch } from 'environments/branch'

@Component({
  selector: 'app-system-info',
  templateUrl: './system-info.component.html',
  styleUrls: ['./system-info.component.scss']
})
export class SystemInfoComponent implements OnInit {

  version = '';
  branch = '';

  constructor() { 
    this.version = version;
    this.branch = branch;
  }

  ngOnInit() {
  }

}
