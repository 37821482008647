import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-standalone-buttons',
  templateUrl: './standalone-buttons.component.html',
  styleUrls: ['./standalone-buttons.component.scss']
})
export class StandaloneButtonsComponent implements OnInit {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model;
  @Input() regId;
  @Input() recId;
  @Input() translateData: any;
  @Output() buttonChange: EventEmitter<any> = new EventEmitter();
  @Output() updateRecord: EventEmitter<any> = new EventEmitter();
  public officeId: string;
  public buttons = [];
  constructor(public snackBar: MatSnackBar,
    private helperService: HelpersService) { }


  ngOnInit() {
    this.getButtons();
  }

  getButtons(){
    const selectedOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
    if (selectedOffice && selectedOffice['_id'] && this.model && this.model['_id'] &&  this.model['regId']){
      this.officeId = selectedOffice['_id'];
      this.helperService.getRequest(`api/reg-process/${this.model['regId']}/${this.model['_id']}/getButtons?standalonebuttons=true`)
      .then((data) => {
        this.buttons = data || [];
        this.buttons = this.transformSort(this.buttons);
      }).catch(err => {
        console.log(err);
      });
    }
  }


  onChange(button) {
    if (this.officeId && this.model['procesId'] && this.model['instanceId']  && button && button.id){   

      // this.helperService.postRequest(`api/reg-process/${this.model['regId']}/${this.model['_id']}/standalone/${button.id}`,{}).then((data) => {
      //   this.snackBar.open('All tasks are executed', 'Done', {
      //     duration: 2000,
      //   });
      // }).catch(err => {
      //   console.log(err);
      // });
      this.updateRecord.emit({
        isUpdateRecord: !button.dont_update_on_transition,
        updatedData: this.model,
        regId: this.regId,
        initBehaviour: '',
        recId: this.recId,
        buttonId: button.id,
        type: 'standalone',
        navigateTo: `oneRecordView/process/${this.model['procesId']}/instances/${this.model['instanceId']}/processing/${button.id}/${decodeURI(button.label.replace(/\s+/g, '-'))}`
      });
    }
    
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
  }

  transformSort(array: any[]): any[] {
    if (!array || array && array.length === 0) {
      return [];
    }
    array.sort((a: any, b: any) => {
      if (a && b && a && b && a['sort'] < b['sort']) {
        return -1;
      } else if (a && b && a && b && a['sort'] > b['sort']) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
