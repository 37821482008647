import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { SetDefaultLanguageService } from 'app/main/content/services/set-default-language/set-default-language.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { LanguagesModel } from 'app/main/content/models/languages.model';
import { environment } from 'environments/environment';
import { version } from 'environments/version';
import { HttpClient } from '@angular/common/http';
import { md5 } from '../md5';
import { AuthService } from '../auth.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import * as moment from 'moment';

@Component({
    selector     : 'set-password',
    templateUrl  : './set-password.component.html',
    styleUrls    : ['./set-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class SetPasswordComponent implements OnInit
{
    version: any;
    languages: any;
    selectedLanguage: any;
    email;
    setPassView = false;
    errMess = '';
    selectedLangCode;
    password;
    confirmPassword;
    setPasswordLoading = false;
    allData: any;
    dateDiff;
    loc2 = window.location.pathname;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private setDefaultLanguageService: SetDefaultLanguageService,
    private http: HttpClient,
    private router: Router,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private fuseSplashScreenService: FuseSplashScreenService,
    private helperService: HelpersService,
    private _fuseConfigService: FuseConfigService,
  ) {
    
    // Configure the layout
    this._fuseConfigService.config = {
        layout: {
            navbar   : {
                hidden: true
            },
            toolbar  : {
                hidden: true
            },
            footer   : {
                hidden: true
            },
            sidepanel: {
                hidden: true
            }
        }
    };

    this.version = version;
    this.languages = LanguagesModel;
    this.helperService.getDefaultLangs()
    .then((lang) => {
        this.languages = lang || [];
    }).catch(err => {
        console.log(err);
    });
    this.setDefaultLanguageService.setDefaultLanguage();

    this.selectedLanguage = this.languages[0];
    this.selectedLangCode = this.selectedLanguage['id'];

    if (localStorage.getItem('selectedLanguage')) {
        this.languages.forEach(lang => {
            if (lang.id === localStorage.getItem('selectedLanguage')) {
                this.selectedLanguage = lang;
          this.selectedLangCode = this.selectedLanguage['id'];

            }
        });
    }
    // this.translationLoader.loadTranslations(english, polish);
    this.helperService.getTrans('authentication')
      .then((data2) => {
        this.translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
        console.log(err);
      });
   }

  ngOnInit(): void {
    this.getButtons();
  }


  
  sendRequest() {
    const loc = window.location.pathname;
    const token = this.route.snapshot.params.token;
    if (token && !this.errMess) {
      if (loc.includes('/set-password')){
        this.setPasswordLoading = true;
        this.http.post(`${environment.serverUrl}api/auth/invitation-accept`, { token: token, password: this.password })
        .subscribe((data) => {
          this.snackBar.open('Password Set', 'Home', {
            duration: 1500,
          });
          if (data && data['success']) {
            this.authService.afterLoginDone(data)
            .then((data2) => {
              this.fuseSplashScreenService.show();
              this.setPasswordLoading = false;

                this.helperService.openSnackBar('Login Success', 'Home');

                // this.initNotification();
                // this.showNotificationPopUp((cred && cred['profile'] && cred['profile']['_id']));
                // console.clear();
                $('#splash-content').html('<p>Trwa logowanie...</p>');

                this.router.navigate(['/invitation/list']);
                    setTimeout(() => {
                        if (this.fuseSplashScreenService.isShowing) {
                            this.fuseSplashScreenService.hide();
                        }
                    }, 3500);
            }).catch(err => {
              this.setPasswordLoading = false;
              console.log(err);
            });
            // this.router.navigate(['/login']);
          } else {
            console.log(data);
          }
        }, err => {
          this.setPasswordLoading = false;
          console.log(err);
        });
      }else if (loc.includes('/changepassword')){
        this.http.post(`${environment.serverUrl}api/auth/reset/${token}`, { confirmnewpassword: md5(this.confirmPassword), newpassword: md5(this.password) })
        .subscribe((data: any) => {

          if (data && data['success']) {
            this.router.navigate(['/login']);
            this.snackBar.open('Password Set', 'Home', {
              duration: 1500,
            });
          } else {
            console.log(data);
            this.snackBar.open((data && data.error && data.error.txt), 'Home', {
              duration: 1500,
            });
          }
        }, err => {
          console.log(err);
        });
      }else{
        this.errMess = 'setPassword.path_is_not_fount';
      }
    } else {
      this.errMess = 'setPassword.no_token_found_please_try_again';
    }
  }

  checkPassword() {
    if (this.password !== this.confirmPassword) {
      this.errMess = 'setPassword.confirm_password_do_not_match';
    } else {
      this.errMess = null;
    }
  }

  getButtons() {
    const token = this.route.snapshot.params.token;
    // /auth/get-buttons/:token
    this.http.get(`${environment.serverUrl}api/auth/get-buttons/${token}`)
    .toPromise()
    .then((buttons) => {
      this.allData = (buttons && buttons['result']) || {};
      this.allData.buttons = this.allData.buttons || [];
      this.allData.buttons = this.transformSort(this.allData.buttons);
      this.email = this.allData.email;
      const d1 = moment.now();
      const d2 = moment(this.allData.expireDate);
      this.dateDiff = d2.diff(d1, 'milliseconds');
      console.log(this.dateDiff);
    }).catch(err => {
      console.log(err);
    });
  }

  buttonClick(buttonId) {
    const token = this.route.snapshot.params.token;
    const reqData =  {  
      token: token,
      password: this.password,
      // tslint:disable-next-line:radix
      buttonId: parseInt(buttonId)
    };
    this.setPasswordLoading = true;
    this.http.post(`${environment.serverUrl}api/auth/invitation-accept`, reqData)
    .toPromise()
    .then((data) => {
      this.snackBar.open('Password Set', 'Home', {
        duration: 1500,
      });
      if (data && data['success']) {
        this.authService.afterLoginDone(data)
        .then((data2) => {
          this.fuseSplashScreenService.show();
          this.setPasswordLoading = false;

            this.helperService.openSnackBar('Login Success', 'Home');

            // this.initNotification();
            // this.showNotificationPopUp((cred && cred['profile'] && cred['profile']['_id']));
            // console.clear();
            $('#splash-content').html('<p>Trwa logowanie...</p>');

            this.router.navigate(['/invitation/list']);
                setTimeout(() => {
                    if (this.fuseSplashScreenService.isShowing) {
                        this.fuseSplashScreenService.hide();
                    }
                }, 3500);
        }).catch(err => {
          this.setPasswordLoading = false;
          console.log(err);
        });
        // this.router.navigate(['/login']);
      } else {
        this.setPasswordLoading = false;
        console.log(data);
      }
    }).catch(err => {
      this.setPasswordLoading = false;
      console.log(err);
    });
  }

  
  transformSort(array: any[]): any[] {
    if (!array || array && array.length === 0) {
      return [];
    }
    array.sort((a: any, b: any) => {
      if (a && b && a && b && a['sort'] < b['sort']) {
        return -1;
      } else if (a && b && a && b && a['sort'] > b['sort']) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

  sendEmail(): void {
    try {
      const mailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.email && mailPattern.test(String(this.email).toLowerCase())) {
        this.http.post(`${environment.serverUrl}api/auth/forgot`, { email: this.email })
        .subscribe(data => {
          if (data) {
            this.snackBar.open('An Email has been send with further instructions.', 'Okay', {
              duration: 1500,
            });
            this.router.navigate(['/login']);
          } else {
            this.snackBar.open('Some Error occured', 'Okay', {
              duration: 1500,
            });
          }
        }, err => {
          this.snackBar.open(err || 'Some Error occured', 'Okay', {
            duration: 1500,
          });
        });
      } else {
        this.errMess = 'Please enter valid email';
      }
    } catch (err) {
      this.snackBar.open(err || 'Some Error occured', 'Okay', {
        duration: 1500,
      });
    }
  }

  setLanguage(lang): void
  {
      this.languages.map((ele) => {
          if (lang === ele.id) {
              this.selectedLanguage = ele;
          }
      });
      // Set the selected language for toolbar
      // this.selectedLanguage = lang;

      localStorage.setItem('selectedLanguage', this.selectedLanguage.id);
      
      this.translate.setDefaultLang(this.selectedLanguage.id);
      this.translate.use(localStorage.getItem('selectedLanguage') || this.translate.getBrowserLang() || 'en');      
  }
}
