import { Component, OnInit } from '@angular/core';
import { OfficeListService } from './office-list.service';
import { AuthService } from '../../pages/authentication/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseConfig } from 'app/fuse-config';

@Component({
  selector: 'app-office-list',
  templateUrl: './office-list.component.html',
  styleUrls: ['./office-list.component.scss']
})
export class OfficeListComponent implements OnInit {
  officeList;
  constructor(
    public officeListService: OfficeListService,
    private authService: AuthService,
    private router: Router,
    private fuseConfig2: FuseConfigService,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private _fuseConfigService: FuseConfigService,
  ) {
    this.route.data.subscribe(({ office }) => {
    }, err => {
      this.snackBar.open(err || 'Some error occured getting data', 'Okay', {
        duration: 2000,
      });
    });
  }

  ngOnInit() {
    this.officeList = this.officeListService.officeList || [];
    if (this.officeList.length === 0) {
      this._fuseConfigService.config = {
        layout: {
            navbar: {
                hidden: true
            },
            toolbar: {
                hidden: false
            },
            footer: {
                hidden: true
            },
            sidepanel: {
                hidden: true
            }
        }
    };

    }
  }

  changeOffice(office: string) {

    this.officeListService.officeChanged(office['_id'])
      .then(() => {
        return this.authService.storeOffice(office['_id']);
      })
      .then(data => {
        if (office['aotFuseSetting']) {
          this.fuseConfig2.setConfig(office['aotFuseSetting']);
        }
        else {
          this.fuseConfig2.setConfig(fuseConfig);
        }
        this.authService.onUserNameChanged.next(office['name']);
        this.router.navigate(['offices/' + office['_id'] + '/dashboard']);
      })
      .catch(err => {
        console.log(err);
        this.snackBar.open(err || 'Error occured while updating data', 'Okay', {
          duration: 2000,
        });
      });
  }

}
