import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, ChangeDetectorRef } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from '../../services/helpers/helpers.service';

@Component({
  selector: 'app-all-fields-view',
  templateUrl: './all-fields-view.component.html',
  styleUrls: ['./all-fields-view.component.scss']
})
export class AllFieldsViewComponent implements OnInit {
  
  @Input() translateData;
  @Input() tempService;
  @Input() field;
// tslint:disable-next-line:no-output-on-prefix
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  @Output() processUpdateRecord: EventEmitter<Object> = new EventEmitter();
  @Output() updateRecord: EventEmitter<Object> = new EventEmitter();
  @Output() updateAddress: EventEmitter<Object> = new EventEmitter();
  @Output() updateParentOnSave: EventEmitter<Object> = new EventEmitter();
  @Output() getRecord: EventEmitter<Object> = new EventEmitter();
 
  ckEditorConfig: any = {
    toolbar : [
      { name: 'document', groups: [ 'mode', 'document', 'doctools' ], items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
      { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
      { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
      { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
      '/',
      // tslint:disable-next-line:max-line-length
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
      // tslint:disable-next-line:max-line-length
      { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
      { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
      { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
      '/',
      { name: 'styles', items : [ 'Styles', 'Format', 'Font', 'FontSize' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
      { name: 'others', items: [ '-'  ] },
      { name: 'about', items: [ 'About' ] }
    ],
   
    'removeButtons': 'Source,Save,Templates,Find,Replace,Scayt,SelectAll'
  };

  constructor(
    private helperService: HelpersService,
    private changeDetectorRef: ChangeDetectorRef,

  ) { }

  ngOnInit() {
    this.changeDetectorRef.markForCheck();    
  }



  checkError(ele) {
    this.onError.emit(ele);
  }

  onprocessUpdateRecord(ele) {
    this.processUpdateRecord.emit(ele);
  }

  onupdateRecord(ele) {
    this.updateRecord.emit(ele);
  }
  
  onupdateAddress(ele) {
    this.updateAddress.emit(ele);
  }

  onGetRecord(ele) {
    this.getRecord.emit(ele);
  }

  onFieldValueUpdateEvent(eve) {
    try {
      this.onFieldValueUpdated.emit(eve);
      this.changeDetectorRef.detectChanges();
    } catch (err) {
      console.log(err);
    }
  }

}
