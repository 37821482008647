import { HelpersService } from './../../../../../../services/helpers/helpers.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ActionModulesInterface, ActionAppsInterface } from '../../actions/actions.interface';
import { ActionDataModel, ActionPlacements, ActionApps, ActionModules } from '../../actions/actions.model';
import { SettingsNodesService } from '../../settings-nodes.service';
import { AddTaskComponent } from 'app/main/content/apps/processes-settings/process-view/add-task/add-task.component';
import { SelectIconComponent } from 'app/main/content/apps/workspace-management/select-icon/select-icon.component';
import { TaskCategorySelectionComponent } from 'app/main/content/apps/processes-settings/process-view/add-task/task-category-selection/task-category-selection.component';

@Component({
  selector: 'app-step-actions',
  templateUrl: './step-actions.component.html',
  styleUrls: ['./step-actions.component.scss']
})
export class StepActionsComponent implements OnInit {
  context;
  regData: object;
  selectedGroup: String = 'register';  
  placementVal;
  currentView = 0;
  appEditManual = false;
  index;
  actionList = [];
  operation = 'new';
  editApp = false;
  modules: Array<ActionModulesInterface>;
  apps: Array<ActionAppsInterface>;
  placements;
  actionData;
  loading = false;

  constructor(
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<StepActionsComponent>,    
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private _helperService: HelpersService,
  ) {
    this.actionData = JSON.parse(JSON.stringify(ActionDataModel));
    this.placements = ActionPlacements;
    this.apps = ActionApps;
    this.modules = ActionModules;
  }

  ngOnInit() {
    this.operation = this.data['operation'];
    this.index = this.data['index'];
    this.actionList = this.data['actionList'];
    if (this.operation === 'edit' && this.actionList){
      this.actionData = this.actionList[this.index];
    }
    //  placement value setting.
    if (this.actionData && this.actionData.placement) {
      for (const i in this.actionData.placement) {
        if (this.actionData.placement[i]){
          this.placementVal = i;
        }
      }
    }
    this.apps.forEach(ele => {
      if (ele.app === this.actionData.application) {
        this.selectedGroup = ele.group;
      }
    });
  }

  get actionParams(): string{
    if (this.actionData && this.actionData.params){
      return JSON.stringify(this.actionData.params);
    }
  }

  set actionParams(value: string){
      try{
        if (this.actionData && this.actionData.params){
          this.actionData.params = JSON.parse(value);
        }
      } catch (e) {
        // handle json string is not validated.
        this.snackBar.open(e, 'Okay', {
          duration: 2000,
        });
      }
  }

  updateRegData() {
    for (const i in this.placements) {
      if (this.placementVal === this.placements[i]['name']) {
        this.actionData.placement[this.placements[i]['name']] = true;
      } else {
        this.actionData.placement[this.placements[i]['name']] = false;
      }
    }
    if (this.actionData.default_label) {
      this.actionData['appType'] = 'workspace';
      this.dialogRef.close(this.actionData);
    } else {
      this.snackBar.open('Please Enter Default Label', 'Okay', {
        duration: 2000,
      });
    }
  }


  async addTaskV2() {
    try {
      const processData = await this._helperService.getProcess(this.data['processId']);
      const dialogRef = this.dialog.open(TaskCategorySelectionComponent, {
        width: '100%',
        panelClass: 'happ-form-builder-dialog',
        data: { processData: processData[0], activityData: this.data['activityData'] }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.actionData.taskInfo = result;
        }
      }, err => {
        console.log(err);
      });
    } catch (err) {
      console.log(err);
    }
  }

  addTask(app) {
    let operation = 'new';
    const stageExc = 'init';
    let task;
    if (this.actionData.taskInfo) {
      operation = 'edit';
      task = this.actionData.taskInfo.task;
    } else {
      return this.addTaskV2();
    }
    this.loading = true;
    this._helperService.getProcess(this.data['processId'])
    .then((process) => {
      this.loading = false;
      const dialogRef = this.dialog.open(AddTaskComponent, {
        width: '100%',
        panelClass: 'happ-form-builder-dialog',
        data: { task: task, operation: operation, processData: (process && process[0]), activityData: null, stageExc }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.actionData.taskInfo = result;
          console.log(result);
        }
      }, err => {
        console.log(err);
      });
    }).catch(err => {
      this.loading = false;
      console.log(err);
    });
  }

  placementChanged(val) {
  }

  selectIcon(): void {
    const dialogRef = this.dialog.open(SelectIconComponent, {
      width: '700px',
      height: '600px',
      panelClass: 'happ-form-builder-dialog',
      data: { selectedIcon: this.actionData.icon || 'settings' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.actionData.icon = result;
      }
    });
  }
}
