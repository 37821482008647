import * as tslib_1 from "tslib";
import { HelpersService } from './../../services/helpers/helpers.service';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import { OnInit } from '@angular/core';
import { OfficeHelpersService } from '../../services/office-helpers/office-helpers.service';
import { version } from 'environments/version';
import { MatDialogRef } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import * as _ from 'lodash';
var PlatformIssueReporterComponent = /** @class */ (function () {
    function PlatformIssueReporterComponent(_officeHelper, _profileHelper, _helperService, dialogRef, data, _translationLoader) {
        this._officeHelper = _officeHelper;
        this._profileHelper = _profileHelper;
        this._helperService = _helperService;
        this.dialogRef = dialogRef;
        this.data = data;
        this._translationLoader = _translationLoader;
        this.FORM_ID = '5cd56583890d6e3d11c448da';
        this.issueData = {};
        this.loading = false;
        this.error = '';
        this.reporterType = 'user';
        this.browserInfo = {};
        this.labelsOptions = [];
        this.componentsOptions = [];
        this.issueTypeOptions = [];
        this.projectOptions = [];
        this.assigneeOptions = [];
        this.estimatetime = [];
        this.disabledButton = true;
    }
    PlatformIssueReporterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._helperService.getTrans('issueReporter')
            .then(function (data2) {
            _this._translationLoader.loadTranslationsV2(data2);
        }).catch(function (err) {
            console.log(err);
        });
        this.getFormFlatenFields();
        this.reporterType = (this.data && this.data.reporterType) || 'user';
        this.issueData.version = version;
        this.issueData.current_url = window.location.href;
        this.getInitData().catch(function (err) {
            console.log(err);
        });
        this.browserInfo = this._helperService.getBrowserInfo();
        this.issueData.browserInfo = this.browserInfo;
        this.issueData.browserName = this.browserInfo.browserName;
        this.issueData.fullVersion = this.browserInfo.fullVersion;
    };
    PlatformIssueReporterComponent.prototype.getInitData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, err_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        _a = this.issueData;
                        return [4 /*yield*/, this._officeHelper.getLocalOfficeData()];
                    case 1:
                        _a.office_json = _c.sent();
                        _b = this.issueData;
                        return [4 /*yield*/, this._profileHelper.getLocalProfileData()];
                    case 2:
                        _b.logged_user = _c.sent();
                        this.getLocalWorkspaceData();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _c.sent();
                        return [2 /*return*/, err_1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PlatformIssueReporterComponent.prototype.getLocalWorkspaceData = function () {
        if (localStorage.getItem('wsAppData') && JSON.parse(localStorage.getItem('wsAppData'))) {
            this.issueData.workspace_json = JSON.parse(localStorage.getItem('wsAppData'));
        }
    };
    PlatformIssueReporterComponent.prototype.checkRequired = function () {
        if (this.issueData.priority && this.issueData.summary) {
            this.disabledButton = false;
        }
        else {
            this.disabledButton = true;
        }
    };
    PlatformIssueReporterComponent.prototype.saveIssue = function () {
        var _this = this;
        this.loading = true;
        this.error = '';
        this._helperService.postRequest("api/bug_reports", this.issueData)
            .then(function () {
            _this.loading = false;
            _this._helperService.openSnackBar('Issue Reported');
            _this.dialogRef.close(null);
        }).catch(function (err) {
            _this.loading = false;
            _this.error = err;
            console.log(err);
        });
    };
    PlatformIssueReporterComponent.prototype.getFormFlatenFields = function () {
        var _this = this;
        this._helperService.getForm(this.FORM_ID)
            .then(function (formData) {
            return _this._helperService.getFormFlatField(formData.ng5Form);
        }).then(function (flattenFields) {
            _this.formFields = flattenFields || [];
            _this.projectOptions = _this.getFieldOptions('projectName') || [];
            _this.labelsOptions = _this.getFieldOptions('labels') || [];
            _this.componentsOptions = _this.getFieldOptions('components') || [];
            _this.issueTypeOptions = _this.getFieldOptions('issueType') || [];
            _this.assigneeOptions = _this.getFieldOptions('assignee') || [];
            _this.estimatetime = _this.getFieldOptions('estTime') || [];
        }).catch(function (err) {
            _this._helperService.openSnackBar(err);
        });
    };
    PlatformIssueReporterComponent.prototype.getFieldOptions = function (fieldLabel) {
        this.formFields = this.formFields || [];
        var field = _.find(this.formFields, function (o) { return (o && o.label === fieldLabel && o.category !== "input"); });
        return (field && field.options) || [];
    };
    return PlatformIssueReporterComponent;
}());
export { PlatformIssueReporterComponent };
