import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { HelpersService } from '../../services/helpers/helpers.service';

@Injectable()
export class ThemeOptionExtensionService {

  constructor(
    private helperService: HelpersService     
  ) { }

    saveChanges(fuseSettings) {
        return new Promise((resolve, reject) => {

            const selectedOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
            let officeId;
            if (selectedOffice) {
            officeId = selectedOffice['_id'];
            
            const temp = {
                'aotFuseSetting': fuseSettings
            };
            this.helperService.patchRequest(`api/offices/${officeId}`, temp)
            .then(data => {
                selectedOffice['aotFuseSetting'] = fuseSettings;
                localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(selectedOffice));
                resolve();
            }).catch(reject);
         } 
        });
    }
}
    