import { NgModule } from '@angular/core';
import { WsAppsSettingsComponent } from './ws-apps-settings.component';
import { FuseSharedModule } from '@fuse/shared.module';
// import { GlobalModule } from 'app/core/modules/global.module';
import { AfterPluginInstallModule } from '../plugin/after-plugin-install/after-plugin-install.module';
import { OfficeGlobalVarModule } from '../office-global-var/office-global-var.module';
import { FormListModule } from '../../global-component/form-list/form-list.module';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { StepActionModule } from '../settings/reg-settings/setting-nodes/academy-course/step-actions/step-actions.module';
import { ProcessSettingsListService } from '../processes-settings/process-settings-list/process-settings-list.service';
import { DateFilterFieldModule } from '../../components/date-filter-field/date-filter-field.module';

@NgModule({
    imports     : [
        CommonModule,
        FuseSharedModule,
        AfterPluginInstallModule,
        OfficeGlobalVarModule,
        // FormListModule,
        NgxDatatableModule,
        StepActionModule,
        DateFilterFieldModule
    ],
    declarations: [
        WsAppsSettingsComponent
    ],
    providers   : [
        // WorkspaceManagementService
        ProcessSettingsListService
    ],
    exports: [
        WsAppsSettingsComponent
    ],
    entryComponents: [
        WsAppsSettingsComponent
    ]
})
export class WSAppsSettingsModule
{
}
