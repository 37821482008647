import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelpersService } from '../../../services/helpers/helpers.service';

@Component({
  selector: 'app-org-selection',
  templateUrl: './org-selection.component.html',
  styleUrls: ['./org-selection.component.scss']
})
export class OrgSelectionComponent implements OnInit {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: string; 
@Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() onError: EventEmitter<Object> = new EventEmitter();
    
  officeOrg = [];
  error: boolean;
  errorMessage: string;
  orgId: any;
  processActivities = [];
  constructor(
    private helperService: HelpersService
  ) { }

  onGroupChanged() {
    this.modelChange.next(this.model);

    if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
      this.error = true;
      this.errorMessage = 'Pole jest obowiązkowe';
      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      } else {
        this.errorMessage = '';
        this.error = false;
        this.onError.emit({
          error: false,
          label: this.field['label']
        });
      }
    }
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
  }

  ngOnInit() {
    this.onGroupChanged();
    if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps']) {
      this.orgId = (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps']['org-structure'] && 
                    JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps']['org-structure']['id']) || '';
        this.helperService.getProcess(this.orgId)
        .then(processData => {
          this.processActivities = (processData && processData[0] && processData[0]['activities']) || [];
          // filter process activities..
          this.processActivities = this.processActivities.filter(org => org['type'] === 'department') || [];
          // org['type'] === 'department'
        }).catch(err => {
          console.log(err);
        });
    } else {
      console.warn('no office user');
    }

  }

}
