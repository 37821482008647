<div class="mb-16" *ngIf="!loading">
    <!-- <ck-editor name="editor1" (keyup.ctrl.enter)="keyTest($event)" [(ngModel)]="comment" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor> -->
    <!-- <textarea matInput [placeholder]="'ct.enter_comment' | translate" (keyup.enter)="createNewComment()" class="w-100-p h-80" style="font-size: 18px" [(ngModel)]="comment"></textarea> -->
    <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'ct.enter_comment' | translate}}</mat-label>
        <textarea  rows="4" matInput [placeholder]="'ct.enter_comment' | translate" fxFlex style="resize: none;" (keyup.enter)="createNewComment()"  [(ngModel)]="comment"></textarea>
    </mat-form-field>
  <div *ngIf="!loading && commentList && commentList.length">
    <!-- comments list -->
    <!-- {{ commentList | json }} -->
    <div class="h-400" style="overflow: auto;">
      <h3> {{ 'ct.comments' | translate }} </h3>
      <div class="p-4 mb-4 px-8 happ-comment-list" fxLayout="row" *ngFor="let comment of commentList; let i=index">
        <div fxLayout="row">
          <img class="avatar my-4" *ngIf="comment && comment.creator" ngxGravatar [email]="comment.creator" >
          <div fxLayout="column">
            <p class="m-0 font-size-14" mat-line> {{ comment.description }} </p>
            <p class="m-0 font-size-12 text-hint"> {{comment.creator}} ( {{ comment.creatingTime | date: 'yyyy:MM:dd HH:mm:ss' }} ) </p>
          </div>
        </div>
        <span fxFlex></span>
        <button *ngIf="comment && this.currentUser && comment.creatorId === this.currentUser.id" (click)="removeComment(comment, i)" mat-icon-button>
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div fxLayoutAlign="center center" *ngIf="!loading && !commentList || (commentList && commentList.length === 0)">
    <!-- no comment info -->
    <h3> {{ 'ct.no_comment_yet' | translate }} </h3>
  </div>
</div>
<div class="w-100-p" fxLayoutAlign="center center" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>