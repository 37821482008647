import { Injectable } from '@angular/core';
import { IProfile } from '../../interfaces/profile.interface';
import { HelpersService } from '../helpers/helpers.service';
import { environment } from 'environments/environment';

@Injectable()
export class ProfileHelperService {

  profileData: IProfile;

  constructor(
    private helperService: HelpersService
  ) { }

  getProfileData(profileId: string): Promise<IProfile> {
    return new Promise((resolve, reject) => {
      if (profileId) {
        this.helperService.getRequest(`api/profile/${profileId}`)
        .then(resolve).catch(reject);
      } else {
        reject('No profile Found. Please pass profileId first');
      }
    });
  }

  getLocalProfileData(): Promise<IProfile> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-profile'))) {
        this.profileData = JSON.parse(localStorage.getItem('ngStorage-profile'));
        resolve(JSON.parse(localStorage.getItem('ngStorage-profile')));
      } else {
        reject('No profile Found. Please login first');
      }
    });
  }

  getProfileId(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.profileData && this.profileData['profileId']) {
        resolve(this.profileData['profileId']);
      } else {
        reject('No profile Found. Please login first');
      }
    });
  }

  getProfileEmail(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.profileData && this.profileData['profileEmail']) {
        resolve(this.profileData['profileEmail']);
      } else {
        reject('No profile Found. Please login first');
      }
    });
  }

  refreshProfileContactData() {
    this.helperService.getRequest(`api/profile/contactData`)
    .then((contactData) => {
      localStorage.setItem('ngStorage-profile', JSON.stringify(contactData || {})
    );
    }).catch(err => {
      console.log(err);
    });
  }

  getUserProfile(profileId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (profileId) {
        this.helperService.getRequest(`api/profile/${profileId}`)
        .then(resolve).catch(reject);
      } else {
        reject('No profile id provided');
      }
    });
  }

  updateProfile(profileId: string, updatedData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (profileId && updatedData) {
        this.helperService.patchRequest(`api/profile/${profileId}`, updatedData)
        .then(resolve)
        .catch(reject);
      } else {
        reject('No profile id or data provided');
      }
    });
  }

  updateProfileDoc(data): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']) {
        this.helperService.patchRequest(`api/profile/${JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']}`, data)
        .then(resolve).catch(reject);
      }
    });
  }

  getLoggedOfficeUser(): Promise<any> {
    return new Promise((resolve, reject) => {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.helperService.getRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/logged-user-profile`)
          .then(resolve).catch(reject);
        } else {
            reject('Office id not found');
        }
    });
}
}
