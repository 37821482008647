<div style="overflow: auto; max-height: 100vh;" class="dialog-content-wrapper">
    <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title"> {{ 'bulk_action.bulk_action' | translate }} </span>
        <span fxFlex></span>
        <button (click)="dialogRef.close(null)" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  
    <div class="p-24" style="overflow: auto;" fxLayout="column">
      <div *ngIf="data && !data.isTask" fxLayout="row" class="mr-16 mb-16">
        <p style="margin: auto;" class="mr-8 font-size-18 ml-4">
          {{ 'bulk_action.action_type' | translate }}
        </p>
        <div fxLayout="row">
          <div>  
            <button class="mr-8" [color]="(reqData.actionType==='update')? 'primary' : ''" mat-raised-button (click)="reqData.actionType='update'" > {{ 'bulk_action.update' | translate }} </button>
          </div>
          <div>  
            <button mat-raised-button [color]="(reqData.actionType==='delete')? 'primary' : ''" (click)="reqData.actionType='delete'" > {{ 'bulk_action.delete' | translate }} </button>
          </div>
        </div>
      </div>
      <div fxLayout="column">
        <p *ngIf="reqData.actionType === 'delete'" class="font-size-22" style="color: brown" >
          {{ 'bulk_action.you_are_about_to_delete' | translate }} {{ data.recCount }} {{ 'bulk_action.records_from_the_register' | translate }} {{ data.regName }}
        </p>
        <p *ngIf="reqData.actionType === 'update'" class="font-size-22" style="color: brown" >
          {{ 'bulk_action.you_are_about_to_update' | translate }} {{ data.recCount }} {{ 'bulk_action.records_from_the_register' | translate }} {{ data.regName }}
        </p>
        <p *ngIf="reqData.actionType === 'processTask'" class="font-size-22" style="color: brown" >
          {{ 'bulk_action.you_are_about_to_execute' | translate }} {{ (data && data.taskObject && data.taskObject.name) }} {{ 'bulk_action.on' | translate }} {{ data.recCount }} {{ 'bulk_action.records_from_the_register' | translate }} {{ data.regName }}
        </p>
        <div *ngIf="reqData.actionType === 'update'" fxLayout="column">
          <div fxLayoutGap="10px" *ngFor="let filter of reqData.updatedDoc; let i=index" fxLayout="row">
            <mat-form-field fxFlex="35" appearance="outline" fxFlex>
              <mat-label> {{ 'bulk_action.key_name' | translate }} </mat-label>
              <input type="text" autofocus="true" matInput [(ngModel)]="filter.keyName">
            </mat-form-field>
            <mat-form-field fxFlex="35" appearance="outline" fxFlex>
              <mat-label> {{ 'bulk_action.value' | translate }} </mat-label>
              <input type="text" autofocus="true" matInput [(ngModel)]="filter.value">
            </mat-form-field>
            <div fxFlex="25">
              <button class="mt-12" (click)="reqData.updatedDoc.push({})" mat-icon-button>
                <mat-icon>add</mat-icon>
              </button>
              <button class="mt-12" (click)="reqData.updatedDoc.splice(i, 1)" mat-icon-button>
                <mat-icon>remove</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-24 pt-20 pb-20">
      <span fxFlex></span>
      <button (click)="dialogRef.close(null)" aria-label="custom_app_cancel_button" mat-button
        class="mr-4">{{ 'bulk_action.cancel' | translate }}</button>
      <button mat-raised-button [disabled]="loading || (reqData && !reqData.actionType)" aria-label="custom_app_create_button" (click)="confirmBulkAction()"
        color="primary">{{ 'bulk_action.confirm' | translate }}</button>
    </div>
  </div>