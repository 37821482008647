/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./group-selection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "@angular/flex-layout/extended";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./group-selection.component";
import * as i12 from "../../../../../../@fuse/services/translation-loader.service";
var styles_GroupSelectionComponent = [i0.styles];
var RenderType_GroupSelectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GroupSelectionComponent, data: {} });
export { RenderType_GroupSelectionComponent as RenderType_GroupSelectionComponent };
function View_GroupSelectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["class", "happ-button-list"], ["mat-raised-button", ""], ["role", "listitem"]], [[4, "background", null], [4, "color", null], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onChange(_v.context.$implicit.name, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "happ-small-button": 0 }), i1.ɵdid(3, 671744, null, 0, i4.DefaultLayoutAlignDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutAlignStyleBuilder], i5.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(4, 933888, null, 0, i6.DefaultClassDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i3.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { "happ-small-button": 0 }), i1.ɵdid(6, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(7, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = "happ-button-list"; var currVal_5 = _ck(_v, 2, 0, _co.field.smallButton); _ck(_v, 1, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵinlineInterpolate(1, "", (_co.field.align ? _co.field.align : "center"), " center"); _ck(_v, 3, 0, currVal_6); var currVal_7 = _ck(_v, 5, 0, _co.field.smallButton); var currVal_8 = "happ-button-list"; _ck(_v, 4, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.model && _co.model.includes(_v.context.$implicit.name)) ? "gray" : "white"); var currVal_1 = ((_co.model && _co.model.includes(_v.context.$implicit.name)) ? "white" : "black"); var currVal_2 = (i1.ɵnov(_v, 6).disabled || null); var currVal_3 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_9 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_9); }); }
export function View_GroupSelectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "happ-list"]], null, null, null, null, null)), i1.ɵdid(6, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupSelectionComponent_1)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.field.flex, " wrap"); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.officeGroups; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.field.title; _ck(_v, 4, 0, currVal_1); }); }
export function View_GroupSelectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-group-selection", [], null, null, null, View_GroupSelectionComponent_0, RenderType_GroupSelectionComponent)), i1.ɵdid(1, 638976, null, 0, i11.GroupSelectionComponent, [i12.FuseTranslationLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GroupSelectionComponentNgFactory = i1.ɵccf("app-group-selection", i11.GroupSelectionComponent, View_GroupSelectionComponent_Host_0, { field: "field", builder: "builder", translateData: "translateData", model: "model" }, { onFieldValueUpdated: "onFieldValueUpdated", modelChange: "modelChange", onError: "onError" }, []);
export { GroupSelectionComponentNgFactory as GroupSelectionComponentNgFactory };
