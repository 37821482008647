import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatDialog } from '@angular/material';

import { FieldOptionUpdateDialogComponent } from 'app/main/content/components/field-option-update-dialog/field-option-update-dialog.component';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() model: object;
  @Input() translateData: any;
  @Input() builder;
  @Input() dtTable;
  @Input() formData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  error = false;
  errorMessage = '';
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public dialog: MatDialog,

  ) { }

  
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }
  
  editOptions() {
    const dialogRef = this.dialog.open(FieldOptionUpdateDialogComponent, {
      width: '850px',
      height: 'max-content',
      data: { fieldLabel: this.field.label, formData: this.formData, fieldOptions: JSON.parse(JSON.stringify(this.field.options || [])) || [], field: this.field },
      panelClass: 'happ-form-builder-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.field.options = result;
      }
    }); 
  }

  ngOnInit() {
    this.translationLoader.loadTranslationsV2(this.translateData);
    if (this.field['required'] && (this.model === null || this.model === undefined || (this.model && Object.keys(this.model).length === 0))) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    }
    this.model = this.model || {}; 
  }

  onChange() {
    this.modelChange.next(this.model);
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
    if (this.field['required'] && (this.model === null || this.model === undefined || (this.model && Object.keys(this.model).length === 0))) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    } else {
      this.errorMessage = '';
      this.error = false;
      this.onError.emit({
        error: false,
        label: this.field['label']
      });
    }
  }

  ngAfterViewInit() {

    this.model = this.model || {};
    // tslint:disable-next-line:forin
    for (const i in this.model) {
      const index = _.findIndex(this.field['options'], { name: i });
      if (index === -1) {
        delete this.model[i];
      }
    }

    if (this.field['options']) {
      this.field['options'].forEach(option => {
        if (!this.model[option['name']]) {
          this.model[option['name']] = false;
        }
      });
    }
    this.model = this.model || {};
    this.onChange();
  }



}
