<div fxLayout="column">
  <!-- <div style="color: red">
      <p *ngIf="errorMessage"> {{ errorMessage }} </p>
  </div> -->
  <!-- <mat-form-field fxFlex>
    <mat-select (selectionChange)="onGroupChanged()" [(ngModel)]="model" placeholder="Select Group">
      <mat-option *ngFor="let group of officeGroups" [value]="group.name">
        {{ group.name }}
      </mat-option>
    </mat-select>
  </mat-form-field> -->
  <div>
    <p> {{ field.title }} </p>
    <!-- <mat-checkbox *ngFor="let group of officeGroups"  > {{ group.name }} </mat-checkbox> -->
    <div class="happ-list" fxLayout="{{field.flex}} wrap">
        <button class="happ-button-list" mat-raised-button [ngClass]="{'happ-small-button': field.smallButton}" fxLayoutAlign="{{(field.align)? field.align : 'center'}} center" (click)="onChange(group.name, i)" role="listitem" *ngFor="let group of officeGroups; let i=index" 
            [style.background]="(model && model.includes(group.name))? 'gray': 'white'" [style.color]="(model && model.includes(group.name))? 'white': 'black'">
            {{ group.name }}
        </button>
    </div>
  </div>
</div>
