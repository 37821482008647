import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { WorkspaceManagementService } from '../workspace-management.service';
import { SelectIconComponent } from '../select-icon/select-icon.component';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FormClass } from 'app/main/content/classes/form.class';
import * as _ from 'lodash'; 
import { IProfile, IProfileOffice } from 'app/main/content/interfaces/profile.interface';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationMenuService } from 'app/navigation/navigation';
import { locale as english } from '../i18n/en';
import { locale as polish } from '../i18n/pl';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CustomRegisterModel } from 'app/main/content/models/custom-register.model';


@Component({
  selector: 'app-create-workspace',
  templateUrl: './create-workspace.component.html',
  styleUrls: ['./create-workspace.component.scss']
})
export class CreateWorkspaceComponent implements OnInit {

  
  workspaceData;
  tempDataAccess: any;
  officeGroupList: any;
  customRegister: any;
  officeId: any;
  loading = false;
  tempGroupAccess: any;
  registerTypes: any;
  profileOffices: Array<IProfileOffice>;

  constructor(
    public dialog: MatDialog,
    public workspaceService: WorkspaceManagementService,
    public dialogRef: MatDialogRef<CreateWorkspaceComponent>,
    private helperService: HelpersService,
    public snackBar: MatSnackBar,
    private router: Router,
    // private fuseNavigationService: FuseNavigationService,
    // private naviagtionMenu: FuseNavigationMenuService,
    private translationLoader: FuseTranslationLoaderService,

  ) { }

  ngOnInit() {
    // this.translationLoader.loadTranslations(english, polish);
    this.helperService.getTrans('workspace-management')
      .then((data2) => {
        this.translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
        console.log(err);
      });
    this.workspaceData = JSON.parse(JSON.stringify(CustomRegisterModel));
    this.workspaceData.icon = 'apps';
    // this.getRegisterTypes();
    this.addDashboard();
    this.addUser();
    this.getOfficeGroups();
  }

  
  selectIcon(): void {
    const dialogRef = this.dialog.open(SelectIconComponent, {
      width: '700px',
      height: '600px',
      panelClass: 'happ-form-builder-dialog',
      data: { selectedIcon: this.workspaceData.icon }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.workspaceData.icon = result;
      }
    });
  }
  
  getRegisterTypes() {
    this.helperService.getRequest(`api/registries/rgister-types`)
    .then((data) => {
      this.registerTypes = data || [];
    }).catch(err => {
      console.log(err);
    });
  }

  getOfficeGroups() {
    if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
      this.officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] || '';
      this.workspaceData.officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] || '';
      this.officeGroupList = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['groups'] || [];
      this.makeGroupAndDataAccess();
    } else {
      console.log('no Office found');
    }
  }                                                   


  makeGroupAndDataAccess() {
    this.workspaceData.groupAccess = this.workspaceData.groupAccess || [];
    this.tempGroupAccess = this.tempGroupAccess || {};
    this.tempDataAccess = this.tempDataAccess || {};
    this.officeGroupList.forEach(group => {
      group['access'] = 'Yes';
      group['show'] = 'allrecords';
      this.tempDataAccess[group.name] = 'allrecords';
      this.tempGroupAccess[group.name] = 'Yes';
      this.workspaceData.groupAccess.push(group);
      this.workspaceData.dataGroupsAccess.push(group);
    });
  }

  saveGroupAccess(group) {
    this.workspaceData.groupAccess = this.workspaceData.groupAccess || [];
    const index = _.findIndex(this.workspaceData.groupAccess, { 'name': group.name });
    if (index >= 0) {
      this.workspaceData.groupAccess[index]['access'] = this.tempGroupAccess[group.name];
    }
  }

  saveDataAccess(group) {
    this.workspaceData.dataGroupsAccess = this.workspaceData.dataGroupsAccess || [];
    const index = _.findIndex(this.workspaceData.dataGroupsAccess, { 'name': group.name });
    if (index >= 0) {
      this.workspaceData.dataGroupsAccess[index]['show'] = this.tempDataAccess[group.name];
    }
  }

  createWorkspace() {
    this.addDashboard();
    // value="ss/yy/mm/dd/nn">SYMBOL/YYYY/MM/DD/AUTONUMBER
    this.workspaceData.reg_signature_pattern = 'ss/yy/mm/dd/nn';
    this.workspaceData.reg_symbol = 'wp';
    this.workspaceData.reg_sign1 = 'ss';
    this.workspaceData.reg_sign2 = 'yy';
    this.workspaceData.reg_sign3 = 'mm';
    this.workspaceData.reg_sign4 = 'dd';
    this.workspaceData.reg_sign5 = 'nn';
    this.workspaceData.reg_reset = 'dd';


    this.workspaceData.type = 'workspace';

    const allData = {};

    this.loading = true;
    this.createNewForm(this.workspaceData.name || '')
    .then(data => {
      if (data) {
        allData['formId'] = data['_id'] || '';
        this.workspaceData.ng5FormId = data['_id'] || '';
        return this.helperService.postRequest('api/offices/work-space/new', this.workspaceData);
      } else {
        throw new Error('Unable to create form');
      }
    })
    .then(res => {
      this.loading = false;
      allData['regId'] = res['_id'] || '';
      allData['slug'] = res['slug'] || '';
      this.snackBar.open('Workspace created.', 'Okay', {
        duration: 2000,
      });
      // this.
      this.dialogRef.close(true);
      this.router.navigate([`/workspace/${allData['slug']}`]);
    })
    .catch(err => {
      this.loading = false;
      console.log(err);
      this.snackBar.open(err || 'Error occured while Creating Workspace', 'Okay', {
        duration: 2000,
      });
    });
  }

  createNewForm(regTitle: string): Promise<any> {
    const formData: FormClass = new FormClass({ title: regTitle + '_form' });
    return this.helperService.createForm(formData);
  }

  addUser() {
    if (JSON.parse(localStorage.getItem('ngStorage-profile'))) {
      const profile: IProfile = JSON.parse(localStorage.getItem('ngStorage-profile')) || {};
      this.profileOffices = profile.offices || [];
      this.workspaceData.users = [
        {
          'userEmail': profile.email,
          'userId': profile.profileId,
          'role': 'admin'
        }
      ];
    }
  }

  addDashboard() {
    this.workspaceData['appList'] = [
      {
        'stepLabel': 'Dashboard',
        'stepNumber': 0,
        'stepTime': 0,
        'type': 'dashboard',
        'appSlug': 'dashboard',
        'stepDescription': this.workspaceData.description,
        'regId': '',
        'formId': '',
        'reportType': 'stepper',
        'report': '',
        'actionList': [],
        'stepperActions': false,
        'orParams': [],
        'andParams': [],
        'ansFilter': []
      }];
  }

}
