import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar, MatDialog } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-file-upload-key',
  templateUrl: './file-upload-key.component.html',
  styleUrls: ['./file-upload-key.component.scss']
})
export class FileUploadKeyComponent implements OnInit, OnDestroy, OnChanges {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() recId: string;
  @Input() parentId: string;
  @Input() regId: string;
  @Input() model: Object;
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() recordCreated: EventEmitter<Object> = new EventEmitter();
   
  officeId;
  loading = false;
  creatorId;
  fileName;
  fileDoc;
  creatorLogin;
  fileList;
  selectedFile;
  docBase;
  fileId;
  fileType;
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    private helperService: HelpersService,
    private translationLoader: FuseTranslationLoaderService,
  ) { }

  
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

  ngOnDestroy() {
    this.selectedFile = '';
  }

  ngOnInit() {
    this.translationLoader.loadTranslationsV2(this.translateData);

    this.selectedFile = {};
    // this.parentId = (this.parentId === 'new') ? null : this.parentId;

    const office = (localStorage.getItem('ngStorage-selectedOffice')) ? JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) : null;
    if (office) {
      this.officeId = office['_id'];
    }

    const profile = (localStorage.getItem('ngStorage-profile')) ? JSON.parse(localStorage.getItem('ngStorage-profile')) : null;


    if (profile) {
      this.creatorId = profile['profileId'];
      this.creatorLogin = profile['email'];
    }

    if (this.model) {
      if (this.field.uploadAsBase64) {
        this.docBase = this.model;
        this.fileName = ' ';
        this.fileType = 'image';
      } else {
        this.openPreview(this.model['id']);
      }
    }
  }

  
  updateData() {
    const temp = {};
    temp[this.field.label] = this.model;
    this.helperService.updateRegisterRecord(this.regId, this.parentId || this.recId, temp)
    .then((data) => {
    }).catch(err => {
      console.log(err);
    });
  }

  createNewRec() {
    return new Promise((resolve, reject) => {
      if ((!this.recId || this.recId === 'new') && !this.parentId) {
        // create new record
        const temp = {};
        temp[this.field.label] = this.model;
        this.helperService.createRegisterRecord(this.regId, temp)
        .then((data) => { 
          this.recId = data._id;
          this.recordCreated.emit(data);
          resolve();
        }).catch(reject);
      } else {
        this.updateData();
      }
    });
  }

  uploadFile(file) {
    if (this.field.uploadAsBase64) {
      return this.uploadBase64(file);
    }
    const files = file.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++ ) {
      formData.append('uplFile', files[i], files[i].name);
    }
    this.loading = true;
    // tslint:disable-next-line:max-line-length
    this.helperService.postRequest(`api/files/${this.officeId}/upload?creatorId=${this.creatorId}&creatorLogin=${this.creatorLogin}&officeId=${this.officeId}&recId=${this.recId}&regId=${this.regId}`, formData)
    .then((data) => {
      // this.fetchData();
      this.loading = false;
      if (data.length) {
        // update the value
        this.selectedFile = {};
        // tslint:disable-next-line:max-line-length
        this.model = { id: (data && data[0] && data[0]['_id'])  || '', fileWithKey : true , imgHeight :  this.field.imgHeight || '100%' , imgWidth : this.field.imgWidth || '100%' };
        this.modelChange.next(this.model);
        // this.loading = true;
        this.openPreview(this.model['id']);
        this.createNewRec()
        .then((data2) => {
          this.loading = false;
          console.log(data2);
        }).catch(err => {
          this.loading = false;
          console.log(err);
        });
      }
    }).catch(err => {
      console.log(err);
    });
  }

  uploadBase64(file: any) {
    const file2 = file.files[0];
    const reader = new FileReader();
    this.loading = true;
    reader.readAsDataURL(file2);
    reader.onload = () => {
      this.model = reader.result;
      this.modelChange.next(this.model);
      this.loading = false;
      this.createNewRec()
        .then((data2) => {
          this.docBase = reader.result;
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          console.log(err);
        });
    };
  }

  fetchData( ) {
    if (this.recId && this.regId) {
      this.helperService.getRequest(`api/files/${this.officeId}/list?officeId=${this.officeId}&recId=${this.recId}&regId=${this.regId}&type=Cases`)
      .then((data) => {
        this.fileList = data || [];
      }).catch(err => {
        console.log(err);
      });
    }
  }

  
  fileChanged(file) {
    const files = file.files;
    this.selectedFile[this.field['label']] = (files[0] && files[0]['name'] ) || '';
  }

  openPreview(id: string): void {
    this.fileId = id;
    this.helperService.getRequest(`api/files/${id}/getBase64File`)
    .then((data) => {
        if (data) {
          this.fileName = data.originalname;
            if (data['mimetype']) {
            if (data['mimetype'].includes('image')) {
              this.fileType = 'image';
            } else if (data['mimetype'].includes('pdf')) {
              this.fileType = 'pdf';
            }
          }
          this.fileDoc = data;
          const base64 = data['base64'];
          const blob = this.b64toBlob(base64, data['mimetype'], null);
          const blobUrl = URL.createObjectURL(blob);
          this.docBase =  this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
      }
    }).catch(err => {
      console.log(err);
      this.helperService.openSnackBar(err);
    });
  }
  
  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  downloadFile() {
    this.helperService.downloadDocument(this.fileDoc);
  }

  deleteFile() {
    if (this.field.uploadAsBase64) {
      this.model = null;
      this.updateData();
      this.helperService.openSnackBar('File Deleted');
      this.fileName = null;
    } else {
      this.helperService.openConfirmPopUp('popup.confirm_delete_file')
      .subscribe((data) => {
        if (data) {
          this.helperService.deleteRequest(`api/files/${this.officeId}/${this.fileId}`)
          .then(() => {
            // this.fetchData();
            this.model = null;
            this.updateData();
            this.helperService.openSnackBar('File Deleted');
            this.fileName = null;
          }).catch(err => {
            console.log(err);
            this.helperService.openSnackBar(err);
          });
        }
      });
    }
  }
}
