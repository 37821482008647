import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AppRouting } from './app.routing';
import { Login2Component } from './main/content/pages/authentication/login-2/login-2.component';
import { AuthService } from './main/content/pages/authentication/auth.service';
import { ActionService } from './main/content/services/action/action.service';
import { HelpersService } from './main/content/services/helpers/helpers.service';
import { OfficeHelpersService } from './main/content/services/office-helpers/office-helpers.service';
import { ProfileHelperService } from './main/content/services/profile-helper/profile-helper.service';
import { AppDashboardRouteGuard } from './app-dashboard-route.guard';
import { SocketService } from './main/content/services/socket/socket.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SetDefaultLanguageService } from './main/content/services/set-default-language/set-default-language.service';
import { Register2Component } from './main/content/pages/authentication/register-2/register-2.component';
import { ForgotPasswordComponent } from './main/content/pages/authentication/forgot-password/forgot-password.component';
import { OfficeListModule } from './main/content/apps/office-list/office-list.module';
import { FuseNavigationMenuService } from './navigation/navigation';
import { SystemInfoComponent } from './main/content/pages/system-info/system-info.component';
import { MaintenanceModule } from './main/content/pages/maintenance/maintenence.module';
import { ProfileModule } from './main/content/pages/profile/profile.module';

import { CreateWorkspaceModule } from './main/content/apps/workspace-management/create-workspace/create-workspace.module';
import { UserInfoComponent } from './main/content/pages/user-info/user-info.component';
import { ThemeOptionExtensionService } from './main/content/global-component/theme-option-extension/theme-option-extension.service';
import { EventFormModule } from './main/content/apps/calendar-template/event-form/event-form.module';
import { SetPasswordComponent } from './main/content/pages/authentication/set-password/set-password.component';
import { EditFormPopupModule } from './main/content/apps/custom-registries/edit-form-popup/edit-form-popup.module';
import { DateFilterFieldModule } from './main/content/components/date-filter-field/date-filter-field.module';
import { InvitationListService } from './main/content/pages/invitation-list/invitation-list.service';
import { PlatformIssueReporterModule } from './main/content/global-component/platform-issue-reporter/platform-issue-reporter.module';
import { GlobalErrorHandler } from './main/content/services/error-handler/error-handler.service';
import { ErrorLogService } from './main/content/services/error-handler/error.handler-helper.service';
import { FieldOptionUpdateDialogService } from './main/content/components/field-option-update-dialog/field-option-update-dialog.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/pl';
import localeFrExtra from '@angular/common/locales/extra/pl';
import { LimitDecimalPlacesDigitsDirective } from './main/content/directives/limitDecimalPlaceDigits.directive';
import { VersionCheckService } from './version-check.service';

registerLocaleData(localeFr, 'pl', localeFrExtra);

@NgModule({
    declarations: [
        AppComponent,
        Login2Component,
        Register2Component,
        ForgotPasswordComponent,
        SystemInfoComponent,
        UserInfoComponent,
        SetPasswordComponent,
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRouting,

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        FuseSharedModule,

        // App modules
        LayoutModule,
        OfficeListModule,
        MaintenanceModule,
        ProfileModule,
        CreateWorkspaceModule,
        EventFormModule,
        EditFormPopupModule,
        PlatformIssueReporterModule

    ],
    providers: [
        AuthService,
        ActionService,
        HelpersService,
        OfficeHelpersService,
        ProfileHelperService,
        AppDashboardRouteGuard,
        SocketService,
        FuseNavigationService,
        SetDefaultLanguageService,
        FuseNavigationMenuService,
        ThemeOptionExtensionService,
        InvitationListService,
        ErrorLogService,
        FieldOptionUpdateDialogService,
        VersionCheckService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
          },
          { provide: LOCALE_ID, useValue: 'pl-PL'},
    ],
    entryComponents: [
        UserInfoComponent,
        SystemInfoComponent
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
