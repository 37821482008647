import { Component, OnInit, Inject } from '@angular/core';
import icons from './icons';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-select-icon',
  templateUrl: './select-icon.component.html',
  styleUrls: ['./select-icon.component.scss']
})
export class SelectIconComponent implements OnInit {

  icons;
  query;
  
  constructor(public dialogRef: MatDialogRef<SelectIconComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.icons = icons || [];
  }



}
