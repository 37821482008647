<div *ngIf="!loc2.includes('/changepassword')" class="main-container w-100-p">
  <div
    style="width:100%;table-layout:fixed;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;background-color:#e1e5e8;">
    <div style="max-width:600px;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;">
      <table align="center" cellpadding="0"
        style="border-spacing:0;font-family:'Muli',Arial,sans-serif;color:#333333;Margin:0 auto;width:100%;max-width:600px;">
        <tbody>
          <tr>
            <td align="center" class="vervelogoplaceholder" height="143"
              style="padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;height:143px;vertical-align:middle;"
              valign="middle">
              <h2 class="font-size-32 font-weight-600 happ-heading">Pinbox24.com</h2>
            </td>
          </tr>
          <!-- Start of Email Body-->
          <tr>
            <td class="one-column"
              style="padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;background-color:#ffffff;">
              <table *ngIf="!setPassView && !setPasswordLoading" style="border-spacing:0;" width="100%">
                <tbody>
                  <tr>
                    <td align="center" class="inner"
                      style="padding-top:15px;padding-bottom:15px;padding-right:30px;padding-left:30px;"
                      valign="middle"><img
                          alt="Forgot Password" class="banner" height="93"
                          src="https://marketing-image-production.s3.amazonaws.com/uploads/35c763626fdef42b2197c1ef7f6a199115df7ff779f7c2d839bd5c6a8c2a6375e92a28a01737e4d72f42defcac337682878bf6b71a5403d2ff9dd39d431201db.png"
                          style="border-width: 0px; margin-top: 30px; width: 255px; height: 93px;" width="255">
                    </td>
                  </tr>
                  <tr>
                    <td class="inner contents center"
                      style="padding-top:15px;padding-bottom:15px;padding-right:30px;padding-left:30px;text-align:left;">
                      <div>
                        <p class="h1 center"
                          style="Margin:0;text-align:center;font-family:'flama-condensed','Arial Narrow',Arial;font-weight:100;font-size:30px;Margin-bottom:26px;">
                          <!-- You are About to Join Pinbox24.com? -->
                          {{ 'setPassword.you_are_about_to_join_pinbox24' | translate }}
                        </p>
                        <p class="description center"
                          style="font-family:'Muli','Arial Narrow',Arial;Margin:0;text-align:center;max-width:320px;color:#a1a8ad;line-height:24px;font-size:15px;Margin-bottom:10px;margin-left: auto; margin-right: auto;">
                          <span
                            style="color: rgb(161, 168, 173); font-family: Muli, &quot;Arial Narrow&quot;, Arial; font-size: 15px; text-align: center; background-color: rgb(255, 255, 255);">
                            <!-- Clicking the Set Password to join the Pinbox24 platform. The will create new account for you. -->
                            {{ 'setPassword.clicking_this_info' | translate }}
                            </span></p>
                        <span class="sg-image"
                          data-imagelibrary="%7B%22width%22%3A%22260%22%2C%22height%22%3A54%2C%22alt_text%22%3A%22Reset%20your%20Password%22%2C%22alignment%22%3A%22%22%2C%22border%22%3A0%2C%22src%22%3A%22https%3A//marketing-image-production.s3.amazonaws.com/uploads/c1e9ad698cfb27be42ce2421c7d56cb405ef63eaa78c1db77cd79e02742dd1f35a277fc3e0dcad676976e72f02942b7c1709d933a77eacb048c92be49b0ec6f3.png%22%2C%22link%22%3A%22%23%22%2C%22classes%22%3A%7B%22sg-image%22%3A1%7D%7D">
                          <div fxLayoutAlign="center center" (click)="setPassView=true">
                            <p class="happ-button" >{{ 'setPassword.forward' | translate }}</p>
                          </div></span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div fxLayout="column" class="p-32" *ngIf="dateDiff > 0 && setPassView && !setPasswordLoading">
                <div fxLayout="row">
                  <mat-icon class="font-size-32 mt-16 mr-16 hint-text ml-8">vpn_key</mat-icon>
                  <h2 class="hint-text mb-28">
                    {{ 'setPassword.set_password' | translate }}
                  </h2>
                </div>
                  <mat-form-field appearance="outline" fxFlex>
                      <mat-label> {{ 'setPassword.email' | translate }} </mat-label>
                      <input type="text" matInput [disabled]="true" [(ngModel)]="email">
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex>
                      <mat-label> {{ 'setPassword.password' | translate }} </mat-label>
                      <input type="password" matInput [(ngModel)]="password">
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex>
                      <mat-label> {{ 'setPassword.confirmPassword' | translate }} </mat-label>
                      <input type="password" matInput (keyup)="checkPassword()" [(ngModel)]="confirmPassword">
                      <div class="error-message" *ngIf="errMess">
                          {{ errMess | translate }}
                      </div>
                  </mat-form-field>
                  <div>
                    <span fxFlex></span>
                    <div *ngIf="allData && allData.buttons">
                      <button class="m-4" [disabled]="(errMess === '' || errMess) ? true : false" color="success" *ngFor="let button of allData.buttons" mat-raised-button (click)="buttonClick((button && button.dest && button.dest.id))" >
                          {{ (button && button.dest && button.dest.label) | translate }}
                      </button>
                    </div>
                  </div>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" class="h-400" *ngIf="dateDiff > 0 && setPasswordLoading">
                <mat-spinner></mat-spinner>
                <h2 class="hint-text text-center mt-20">
                  <!-- We are Creating Your account you will automatically logged in after creating account please wait. -->
                  {{ 'setPassword.creating_and_will_login_after_account_creation' | translate }}
                </h2>
              </div>
              <div *ngIf="dateDiff <= 0">
                <h2>
                  {{ 'setPassword.like_already_expired' | translate }}
                </h2>
              </div>
            </td>
          </tr>
          <tr>
            <td height="40">
              <p style="line-height: 40px; padding: 0 0 0 0; margin: 0 0 0 0;">&nbsp;</p>

              <p>&nbsp;</p>
            </td>
          </tr>
          <!-- Social Media -->
          <tr>
            <td align="center" style="padding-bottom:0;padding-right:0;padding-left:0;padding-top:0px;" valign="middle">
              <span class="sg-image"
                data-imagelibrary="%7B%22width%22%3A%228%22%2C%22height%22%3A18%2C%22alt_text%22%3A%22Facebook%22%2C%22alignment%22%3A%22%22%2C%22border%22%3A0%2C%22src%22%3A%22https%3A//marketing-image-production.s3.amazonaws.com/uploads/0a1d076f825eb13bd17a878618a1f749835853a3a3cce49111ac7f18255f10173ecf06d2b5bd711d6207fbade2a3779328e63e26a3bfea5fe07bf7355823567d.png%22%2C%22link%22%3A%22%23%22%2C%22classes%22%3A%7B%22sg-image%22%3A1%7D%7D"><a
                  href="https://www.facebook.com/" target="_blank"><img alt="Facebook" height="18"
                    src="https://marketing-image-production.s3.amazonaws.com/uploads/0a1d076f825eb13bd17a878618a1f749835853a3a3cce49111ac7f18255f10173ecf06d2b5bd711d6207fbade2a3779328e63e26a3bfea5fe07bf7355823567d.png"
                    style="border-width: 0px; margin-right: 21px; margin-left: 21px; width: 8px; height: 18px;"
                    width="8"></a></span>
              <!--[if gte mso 9]>&nbsp;&nbsp;&nbsp;<![endif]--><span class="sg-image"
                data-imagelibrary="%7B%22width%22%3A%2223%22%2C%22height%22%3A18%2C%22alt_text%22%3A%22Twitter%22%2C%22alignment%22%3A%22%22%2C%22border%22%3A0%2C%22src%22%3A%22https%3A//marketing-image-production.s3.amazonaws.com/uploads/6234335b200b187dda8644356bbf58d946eefadae92852cca49fea227cf169f44902dbf1698326466ef192bf122aa943d61bc5b092d06e6a940add1368d7fb71.png%22%2C%22link%22%3A%22%23%22%2C%22classes%22%3A%7B%22sg-image%22%3A1%7D%7D"><a
                  href="https://twitter.com" target="_blank"><img alt="Twitter" height="18"
                    src="https://marketing-image-production.s3.amazonaws.com/uploads/6234335b200b187dda8644356bbf58d946eefadae92852cca49fea227cf169f44902dbf1698326466ef192bf122aa943d61bc5b092d06e6a940add1368d7fb71.png"
                    style="border-width: 0px; margin-right: 16px; margin-left: 16px; width: 23px; height: 18px;"
                    width="23"></a></span>
              <!--[if gte mso 9]>&nbsp;&nbsp;&nbsp;&nbsp;<![endif]--><span class="sg-image"
                data-imagelibrary="%7B%22width%22%3A%2218%22%2C%22height%22%3A18%2C%22alt_text%22%3A%22Instagram%22%2C%22alignment%22%3A%22%22%2C%22border%22%3A0%2C%22src%22%3A%22https%3A//marketing-image-production.s3.amazonaws.com/uploads/650ae3aa9987d91a188878413209c1d8d9b15d7d78854f0c65af44cab64e6c847fd576f673ebef2b04e5a321dc4fed51160661f72724f1b8df8d20baff80c46a.png%22%2C%22link%22%3A%22%23%22%2C%22classes%22%3A%7B%22sg-image%22%3A1%7D%7D"><a
                  href="https://www.instagram.com/" target="_blank"><img alt="Instagram" height="18"
                    src="https://marketing-image-production.s3.amazonaws.com/uploads/650ae3aa9987d91a188878413209c1d8d9b15d7d78854f0c65af44cab64e6c847fd576f673ebef2b04e5a321dc4fed51160661f72724f1b8df8d20baff80c46a.png"
                    style="border-width: 0px; margin-right: 16px; margin-left: 16px; width: 18px; height: 18px;"
                    width="18"></a></span></td>
          </tr>
          <!-- whitespace -->
          <tr>
            <td height="25">
              <p style="line-height: 25px; padding: 0 0 0 0; margin: 0 0 0 0;">&nbsp;</p>

              <p>&nbsp;</p>
            </td>
          </tr>
          <!-- Footer -->
          <tr>
            <td
              style="padding-top:0;padding-bottom:0;padding-right:30px;padding-left:30px;text-align:center;Margin-right:auto;Margin-left:auto;">
              <div>
                <p
                  style="font-family:'Muli',Arial,sans-serif;Margin:0;text-align:center;Margin-right:auto;Margin-left:auto;font-size:15px;color:#a1a8ad;line-height:23px;">
                  <!-- Problems or questions? Call us at -->
                  {{ 'setPassword.problem_or_question_call_us' | translate }}
                </p>

                <p
                  style="font-family:'Muli',Arial,sans-serif;Margin:0;text-align:center;Margin-right:auto;Margin-left:auto;font-size:15px;color:#a1a8ad;line-height:23px;">
                  {{ 'setPassword.or_email_at' | translate }} <a href="mailto:hello@vervewine.com" style="color:#a1a8ad;text-decoration:underline;"
                    target="_blank">info@eat.pl</a></p>

                <p
                  style="font-family:'Muli',Arial,sans-serif;Margin:0;text-align:center;Margin-right:auto;Margin-left:auto;padding-top:10px;padding-bottom:0px;font-size:15px;color:#a1a8ad;line-height:23px;">
                  © Pinbox24.com <span style="white-space: nowrap">{{ 'setPassword.company_street' | translate }}</span>, <span
                    style="white-space: nowrap">{{ 'setPassword.company_city' | translate }},</span> <span style="white-space: nowrap">{{ 'setPassword.county_and_pin' | translate }}</span>
                </p>
              </div>
            </td>
          </tr>
          <!-- whitespace -->
          <tr>
            <td height="40">
              <p style="line-height: 40px; padding: 0 0 0 0; margin: 0 0 0 0;">&nbsp;</p>

              <p>&nbsp;</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>
<div *ngIf="loc2.includes('/changepassword')" class="main-container w-100-p">
  <div id="login" fxLayout="row" fxLayoutAlign="start">

    <div id="login-intro" fxFlex fxHide fxShow.gt-xs>

    </div>

    <div id="login-form-wrapper" fusePerfectScrollbar *fuseIfOnDom [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div>
            <img style="width: 352px; margin: 24px;" src="assets/images/header-logo.jpg" alt="header logo">
        </div>

        <div id="login-form">

            <div fxLayout="row" class="mb-24" fxLayoutGap="10px" fxLayoutAlign="center space-around">
                <!-- <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang);">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <img class="flag mr-16" [src]="'assets/images/flags/'+lang.flag+'.png'">
                    <span class="iso">{{lang.title}}</span>
                </div>
            </button> -->
                <mat-select aria-label="login_language_selection" (selectionChange)="setLanguage(selectedLangCode)" [(ngModel)]="selectedLangCode" class="w-100-p" placeholder="{{ 'auth.select_language' | translate }}">
                  <mat-option [attr.aria-label]="lang.code" *ngFor="let lang of languages" [value]="lang.code"  >
                    {{lang.title}}
                  </mat-option>
                </mat-select>
            </div>

            <div aria-label="login_to_account" class="title">{{ 'auth.reset_your_password' | translate }}</div>

            <div fxLayout="column" class="happ-form">

              <mat-form-field>
                <input matInput type="password" [(ngModel)]="password" placeholder="{{ 'auth.password' | translate }}" >                
              </mat-form-field>

              <mat-form-field>
                <input matInput type="password" (input)="checkPassword()" [(ngModel)]="confirmPassword" placeholder="{{ ('auth.confirm' | translate )+ ('auth.password' | translate) }}" >  
              </mat-form-field>

              <div class="remember-forgot-password warn-500-fg mb-12" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" *ngIf="errMess">
                  {{ errMess | translate }}
              </div>

              <button mat-raised-button color="accent" (click)="sendRequest()" class="submit-button">
                  {{ 'auth.set_password' | translate }}
              </button>
            </div>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
              <span class="text">
                  {{ 'auth.already_have_account' | translate }} ?
              </span>
              <a class="link" [routerLink]="'/login'">
                {{ 'auth.login' | translate }}
              </a>
          </div>
            <div>
                <img src="assets/images/footer-logo.jpg" alt="footer logo">
            </div>
        </div>
    </div>
    <p style="position: fixed;bottom: 0px;right: 150px;font-size: 18px; color: rgb(3,155,229)"> {{ version }} </p>
</div>
</div>