import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { Message } from './model/message';
import { Event } from './model/event';
import { environment } from 'environments/environment';

import * as socketIo from 'socket.io-client';

@Injectable()
export class SocketService {
    private socket;

    public initSocket(): void {
        this.socket = socketIo(environment.socketUrl);
    }

    public send(message: Message): void {
        this.socket.emit('message', message);
    }

    public usersNotifyToReload( users: any[] ): void{
        this.socket.emit('usersNotifyToReload', users);
    }

    public electronicSender( users: any[] ): void{
        this.socket.emit('electronicSenderData', users);
    }

    public packageSenderFilter(packageFilter: any , params: any ): void{
        this.socket.emit('packageSenderFilterData', {packageFilter : packageFilter , params : params });
    } 

    public authenticate(token: string): void {
        this.socket.on('authenticated', () => {
        }).emit('authenticate', {token: token});
    }
    
    public onMessage(): Observable<Message> {
        return new Observable<Message>(observer => {
            this.socket.on('message', (data: Message) => observer.next(data));
        });
    }

    public userNotifyToUpdate(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('userNotifyToUpdate', () => observer.next());
        });
    }

    public electronicReciver(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('electronicReceiverData', (data) => observer.next(data));
        });
    }

    public packageSenderReciver(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('packageSenderReciverData', (data) => observer.next(data));
        });
    }

    public onEvent(event: Event): Observable<any> {
        return new Observable<Event>(observer => {
            this.socket.on(event, () => observer.next());
        });
    }

    public inviationNotify(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('inviationNotify', (data) => observer.next(data));
        });
    } 
}
