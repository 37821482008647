import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpModule } from '@angular/http';
import { EditFormPopupModule } from 'app/main/content/apps/custom-registries/edit-form-popup/edit-form-popup.module';
// import { FormViewComponent } from 'app/main/content/global-component/one-recordview/form-view/form-view.component';
import { ControlTemplateModule } from 'app/main/content/global-component/control-template/control-template.module';
import { PositionModule } from 'app/main/content/global-component/control-template/position/position.module';
import { NgSummernoteModule } from 'app/main/content/global-component/ng-summernote/ng-summernote.module';
import { TranslateModule } from '@ngx-translate/core';
import { InputDialogComponent } from 'app/main/content/global-component/input-dialog/input-dialog.component';
import { ConfirmChangesComponent } from 'app/main/content/global-component/confirm-changes/confirm-changes.component';
import { FormViewComponent } from 'app/main/content/global-component/one-recordview/form-view/form-view.component';
import { InputDialogModule } from 'app/main/content/global-component/input-dialog/input-dialog.module';
import { FuseSidebarModule } from './components';
// import { FormViewService } from 'app/main/content/global-component/one-recordview/form-view/form-view.service';
// import { EditorModule } from '@tinymce/tinymce-angular';


@NgModule({
    declarations   : [
        FormViewComponent,
        ConfirmChangesComponent
    ],
    imports        : [
        ControlTemplateModule,
        CommonModule,
        MaterialModule,
        PositionModule,
        FlexLayoutModule,
        HttpModule,
        InputDialogModule,
        NgSummernoteModule,
        FuseSidebarModule
        // EditFormPopupModule,
        
        // EditorModule,
    ],
    exports        : [
        FormViewComponent,
        CommonModule,
        MaterialModule,
        PositionModule,
        FlexLayoutModule,
        ControlTemplateModule,
        HttpModule,
        NgSummernoteModule,
        ConfirmChangesComponent,
        FuseSidebarModule
        // EditFormPopupModule,
        // EditorModule,
        // TranslateModule
    ],
    entryComponents: [
    ],
    providers      : [
        // FormViewService
    ]
})

export class GlobalModule
{

}
