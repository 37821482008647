import { element } from 'protractor';
import { ProfileHelperService } from './../main/content/services/profile-helper/profile-helper.service';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AuthService } from '../main/content/pages/authentication/auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { IMainMenuItem } from 'app/main/content/interfaces/mainMenuItem.interface';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { IOfficeData, IOfficeUser } from 'app/main/content/interfaces/office.interface';
import { FuseNavigation } from '@fuse/types';
import { IProfileWorkspace, IProfile } from 'app/main/content/interfaces/profile.interface';
import { CreateWorkspaceComponent } from 'app/main/content/apps/workspace-management/create-workspace/create-workspace.component';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { MatDialog } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
// import { FuseThemeOptionsComponent } from '../core/components/theme-options/theme-options.component';

export const navigation: FuseNavigation[] = [];

@Injectable()
export class FuseNavigationMenuService {
    public updateMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public model: any[];
    public selectedOffice: string;
    public selectedOfficeObject: any;
    public isAdmin = false;
    public officeId: string;
    public menuId: string;
    public currentNavigation = [];
    public currentLang: string;
    public langIndex: object = {
        en: 0,
        pl: 1
    };

    customFunctionNavItem = {
        'id': 'custom-function',
        'title': 'Custom Function',
        'type': 'group',
        'icon': 'settings',
        'children': [
            {
                'id': 'customize',
                'title': 'Customize',
                'type': 'item',
                'icon': 'settings',
                'function': () => {
                    this._fuseSidebarService.getSidebar('themeOptionsPanel').toggleOpen();
                }
            }
        ]
    };
    constructor(
        private authService: AuthService,
        private translate: TranslateService,
        private officeHelpersService: OfficeHelpersService,
        public dialog: MatDialog,
        private helperService: HelpersService,
        private fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _profileHelper: ProfileHelperService

    ) {
        this.model = [];
        this.checkAdmin();
        this.authService.loadUserCredentials();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.currentLang = event['lang'] || 'en';
        });
    }


    checkAdmin() {
        this.isAdmin = false;
        let profileData: IProfile;
        this._profileHelper.getLocalProfileData()
        .then((profileData2: IProfile) => {
            profileData = profileData2;
            return this.officeHelpersService.getLocalOfficeData()
        }).then((officeData: IOfficeData) => {
            if (officeData && officeData.users) {
                this.isAdmin = false;
                officeData.users = officeData.users || [];
                officeData.users.forEach((user: IOfficeUser) => {
                    if (user.id === profileData.profileId) {
                        this.isAdmin = user['isAdmin'];
                    }
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    public menuItems() {
        this.checkAdmin();
        this.model = [];
        let tempModel = [];
        return new Promise((resolve, reject) => {
            try {
                this.fuseNavigationService.setCurrentNavigation('loading');
                this.officeHelpersService.getLocalOfficeData().then((officeData: IOfficeData) => {
                    this.selectedOfficeObject = officeData;
                    const officeApps = this.officeHelpersService.getOfficeApps();
                    this.officeId = (this.officeHelpersService.officeData && this.officeHelpersService.officeData._id);
                    if (officeApps && officeApps['mainMenu'] && officeApps['mainMenu']['id']) {
                        this.menuId = officeApps['mainMenu']['id'];
                        if (this.officeId && this.menuId) {
                            this.officeHelpersService.getOfficeMainMenu()
                                .then((mainMenu: Array<IMainMenuItem>) => {
                                    tempModel = [];
                                    tempModel = [];
                                    this.createWorkspaceMenu()
                                        .then((tempWSMenu) => {
                                            if (tempWSMenu && tempWSMenu.children && tempWSMenu.children.length) {
                                                tempModel.push(tempWSMenu);
                                            }

                                            let menuList: Array<IMainMenuItem> = mainMenu;
                                            menuList = menuList.sort((a, b) => {
                                                return (a && b && a['weight'] > b['weight']) ? 1 : ((a && b && b['weight'] > a['weight']) ? -1 : 0);
                                            });
                                            menuList.forEach(element => {
                                                if (this.isGroupType(element)) {
                                                    const tempObj = this.getGroupData(element);

                                                    menuList.forEach(ele => {
                                                        if (this.isSubGroupType(ele)) {
                                                            if (ele['subGroup']) {

                                                                const SubGroup = this.getSubGroupData(ele);
                                                                menuList.forEach(subGroupElement => {

                                                                    let params = '';
                                                                    // tslint:disable-next-line:max-line-length
                                                                    if (subGroupElement && (subGroupElement['state'] === 'app.customregistries.registriesList' || subGroupElement['state'] === 'app.stepperList' || subGroupElement['state'] === 'app.processInstances.dashboards')) {
                                                                        const temp = [];
                                                                        // tslint:disable-next-line:max-line-length
                                                                        if (subGroupElement['fuse2Url'] && (subGroupElement['state'] === 'app.customregistries.registriesList' || subGroupElement['state'] === 'app.stepperList')) {
                                                                            const viewType = (subGroupElement['registerViewType']) ? subGroupElement['registerViewType'] : 'card';
                                                                            params = '/' + viewType;
                                                                            // const expanded = (ele['expanded']) ? ele['expanded'] : 'expanded';
                                                                            // params = '/' + expanded;
                                                                        }
                                                                        subGroupElement['registerParams'] = subGroupElement['registerParams'] || [];
                                                                        subGroupElement['processParams'] = subGroupElement['processParams'] || [];

                                                                        // tslint:disable-next-line:max-line-length
                                                                        if (subGroupElement['state'] === 'app.customregistries.registriesList' || subGroupElement['state'] === 'app.stepperList') {
                                                                            subGroupElement['registerParams'].map(register => {
                                                                                if (register.selected) {
                                                                                    temp.push(register.key);
                                                                                }
                                                                            });
                                                                        }

                                                                        if (subGroupElement['state'] === 'app.processInstances.dashboards') {
                                                                            subGroupElement['processParams'].map(process => {
                                                                                if (process.selected) {
                                                                                    temp.push(process.key);
                                                                                }
                                                                            });
                                                                        }

                                                                        if (subGroupElement['state'] === 'app.stepperList') {
                                                                            params = '';
                                                                        }


                                                                        params += (temp && temp.length ? '/' + temp.join(',') : '/all');
                                                                    }

                                                                    if (subGroupElement && (subGroupElement['state'] === 'app.gdprResources')) {
                                                                        const regGroups = [];
                                                                        subGroupElement['registerGroups'].map(register => {
                                                                            if (register.selected) {
                                                                                regGroups.push(register.key);
                                                                            }
                                                                        });

                                                                        params = `/gdpr-resources/${regGroups.join(',')}`;
                                                                    }


                                                                    // tslint:disable-next-line:max-line-length
                                                                    if (subGroupElement && (subGroupElement['state'] === 'app.pinboxOfficeContacts2.contacts') && this.selectedOfficeObject && this.selectedOfficeObject['apps'] && this.selectedOfficeObject['apps']['office_addressbook'] && this.selectedOfficeObject['apps']['office_addressbook']['id']) {
                                                                        params = `/${this.selectedOfficeObject['apps']['office_addressbook']['id']}`;
                                                                    }

                                                                    // tslint:disable-next-line:max-line-length
                                                                    if (subGroupElement && (subGroupElement['state'] === 'app.globalCalendar') && this.selectedOfficeObject && this.selectedOfficeObject['apps'] && this.selectedOfficeObject['apps']['Agenda'] && this.selectedOfficeObject['apps']['Agenda']['id']) {
                                                                        params = `/${this.selectedOfficeObject['apps']['Agenda']['id']}`;
                                                                    }

                                                                    // tslint:disable-next-line:max-line-length
                                                                    if (ele && ele['type'] && subGroupElement && !subGroupElement['subGroup'] && !subGroupElement['hidden'] && subGroupElement['navigation'] && subGroupElement['navigation'].split('.').length >= 3 && subGroupElement['navigation'].split('.').includes(ele['type'])) {
                                                                        SubGroup['children'].push(this.getMenuItemChildren(subGroupElement, params));
                                                                    }


                                                                });

                                                                // tslint:disable-next-line:max-line-length
                                                                if (ele && !ele['hidden'] && ele['navigation'] && ele['navigation'].split('.').length > 1 && ele['navigation'].split('.').includes(element['navigation'])) {

                                                                    tempObj['children'].push(SubGroup);
                                                                }
                                                            }

                                                        } else {
                                                            let params = '';
                                                            // tslint:disable-next-line:max-line-length
                                                            if (ele && (ele['state'] === 'app.customregistries.registriesList' || ele['state'] === 'app.stepperList' || ele['state'] === 'app.processInstances.dashboards')) {
                                                                const temp = [];
                                                                // tslint:disable-next-line:max-line-length
                                                                if (ele['fuse2Url'] && (ele['state'] === 'app.customregistries.registriesList' || ele['state'] === 'app.stepperList')) {
                                                                    const viewType = (ele['registerViewType']) ? ele['registerViewType'] : 'card';
                                                                    params = '/' + viewType;

                                                                }
                                                                ele['registerParams'] = ele['registerParams'] || [];
                                                                ele['processParams'] = ele['processParams'] || [];

                                                                if (ele['state'] === 'app.customregistries.registriesList' || ele['state'] === 'app.stepperList') {
                                                                    ele['registerParams'].map(register => {
                                                                        if (register.selected) {
                                                                            temp.push(register.key);
                                                                        }
                                                                    });
                                                                }

                                                                if (ele['state'] === 'app.processInstances.dashboards') {
                                                                    ele['processParams'].map(process => {
                                                                        if (process.selected) {
                                                                            temp.push(process.key);
                                                                        }
                                                                    });
                                                                }
                                                                if (ele['state'] === 'app.stepperList') {
                                                                    params = '';
                                                                }
                                                                params += (temp && temp.length ? '/' + temp.join(',') : '/all');
                                                            }

                                                            if (ele && (ele['state'] === 'app.gdprResources')) {
                                                                const regGroups = [];
                                                                ele['registerGroups'].map(register => {
                                                                    if (register.selected) {
                                                                        regGroups.push(register.key);
                                                                    }
                                                                });

                                                                params = `/gdpr-resources/${regGroups.join(',')}`;
                                                            }
                                                            // tslint:disable-next-line:max-line-length
                                                            if (ele && (ele['state'] === 'app.pinboxOfficeContacts2.contacts') && this.selectedOfficeObject && this.selectedOfficeObject['apps'] && this.selectedOfficeObject['apps']['office_addressbook'] && this.selectedOfficeObject['apps']['office_addressbook']['id']) {
                                                                params = `/${this.selectedOfficeObject['apps']['office_addressbook']['id']}`;
                                                            }

                                                            // tslint:disable-next-line:max-line-length
                                                            if (ele && (ele['state'] === 'app.globalCalendar') && this.selectedOfficeObject && this.selectedOfficeObject['apps'] && this.selectedOfficeObject['apps']['Agenda'] && this.selectedOfficeObject['apps']['Agenda']['id']) {
                                                                params = `/${this.selectedOfficeObject['apps']['Agenda']['id']}`;
                                                            }

                                                            // tslint:disable-next-line:max-line-length
                                                            if (ele && !ele['hidden'] && ele['navigation'] && ele['navigation'].split('.').length > 1 && ele['navigation'].split('.')[0].includes(element['navigation'])) {
                                                                tempObj['children'].push(this.getMenuItemChildren(ele, params));
                                                            }
                                                        }
                                                    });
                                                    if (tempObj && tempObj.children && tempObj.children.length) {
                                                        tempModel.push(tempObj);
                                                    }
                                                }
                                            });
                                            if (this.isAdmin) {
                                                tempModel.push(this.customFunctionNavItem);
                                            }
                                            this.model = tempModel;
                                            this.currentNavigation = this.model;
                                            resolve(tempModel);
                                        }).catch(err => {
                                            console.log(err);
                                            reject(err);
                                        });
                                }).catch(err => {
                                    console.log(err);
                                    reject(err);
                                });
                        }

                    } else {
                        this.currentNavigation = [];
                        reject([]);
                    }
                }).catch(err => {
                    console.log(err);
                });
            } catch (err) {
                console.log(err);
                reject(err);
            }
        });
    }

    isGroupType(menuItem: any): boolean {
        return (menuItem && menuItem['navigation'] && !menuItem['navigation'].includes('.'));
    }

    getGroupData(menuItem: any) {
        return {
            'id': menuItem['type'],
            'title': menuItem['title'],
            'translate': this.getTranslatedTitle(menuItem),
            'type': 'group',
            'icon': (menuItem['icon']) ? menuItem['icon'] : '',
            'children': [],
            'sort': menuItem['weight'],
            'params': {}
        };
    }

    isSubGroupType(menuItem: any): boolean {
        return (menuItem && menuItem['navigation'] && (menuItem['navigation'].split('.').length >= 3 || menuItem['subGroup']));
    }

    getSubGroupData(menuItem: any): any {
        return {
            'id': menuItem['type'],
            'title': menuItem['title'],
            'translate': this.getTranslatedTitle(menuItem),
            'type': (menuItem['subGroupCollapse'] ? 'collapse' : 'group'),
            'sort': menuItem['weight'],
            'icon': menuItem['icon'] || '',
            'children': []
        };;
    }

    getMenuItemChildren(menuItem: any, params: any): any {
        menuItem = menuItem || {};
        return {
            'id': menuItem['type'],
            'title': menuItem['title'],
            'translate': this.getTranslatedTitle(menuItem),
            'type': 'item',
            'heading': menuItem['heading'],
            'headingDesc': menuItem['headingDesc'],
            'sort': menuItem['weight'],
            'icon': menuItem['icon'] || '',
            'url': (menuItem['fuse2Url']) ? (menuItem['fuse2Url'].replace(':officeId', this.officeId) + params) : ''
        };
    }

    refreshMenuItems() {
        try {
            this.fuseNavigationService.unregister('main');
            this.fuseNavigationService.register('main', []);
            this.fuseNavigationService.setCurrentNavigation('main');
            this.menuItems().then((response) => {
                this.fuseNavigationService.unregister('main');
                this.fuseNavigationService.register('main', response);
                this.fuseNavigationService.setCurrentNavigation('main');
            }).catch((error) => {
                this.helperService.openSnackBar(error || 'Error occured while pulling menu');
            });
        } catch (err) {
            console.log(err);
        }
    }

    createWorkspaceMenu(): Promise<any> {
        return new Promise((resolve, reject) => {
            try {

                if (!this.selectedOfficeObject.showWorkspace || this.selectedOfficeObject.showWorkspace === 'false') {
                    resolve([]);
                } else {
                    const workspaceMenuItems = {
                        'id': 'Workspaces',
                        'title': 'Obszar roboczy',
                        'translate': 'Obszar roboczy',
                        'type': 'group',
                        'children': [],
                        'sort': 0,
                        'params': {}
                    };
                    const functionItem = {
                        'id': 'add-workspace',
                        'title': 'Dodaj obszar roboczy',
                        'translate': 'Dodaj obszar roboczy',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'sort': 0,
                        'icon': 'add',
                        'actionType': 'function',
                        'current': this,
                        'function': this.openWorkspace
                    };
                    
                    this.helperService.getRequest('api/profile/workspaces')
                        .then((data) => {
                            data = data || [];
                            data.forEach((workspace: IProfileWorkspace) => {
                                if (workspace && workspace.slug && !workspace.archive) {
                                    workspaceMenuItems.children.push({
                                        'id': workspace.workspaceId,
                                        'title': workspace.name,
                                        'translate': workspace.name,
                                        'type': 'item',
                                        'heading': '',
                                        'headingDesc': '',
                                        'sort': workspace.sort,
                                        'icon': workspace.icon,
                                        'url': `/workspace/${workspace.slug}/dashboard`
                                    });
                                }
                            });
                            if (this.isAdmin) {
                                workspaceMenuItems.children.push(functionItem);
                            }
                            resolve(workspaceMenuItems);
                        }).catch(err => {
                            if (this.isAdmin) {
                                workspaceMenuItems.children.push(functionItem);
                            }
                            console.log(err);
                            resolve(workspaceMenuItems);
                        });
                }

            } catch (err) {
                console.log(err);
                reject(err);
            }
        });
    }

    openWorkspace(current: any) {
        const tt: any = this;
        const dialogRef = tt.current.dialog.open(CreateWorkspaceComponent, {
            width: '500px',
            height: 'max-content',
            panelClass: 'happ-form-builder-dialog'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                tt.current.refreshMenuItems();
                // this.menuItems().then( (response) => {
                //     this.fuseNavigationService.setNavigationModel({'model': response});
                //     this.helperService.openSnackBar('Workspace Created');
                // }).catch( (error) => {
                //     console.log(error);
                //     this.helperService.openSnackBar(error || 'Error occured while creating workspace');
                // });
                // this.helperService.openSnackBar('Workspace Created');
            }
        });
    }

    getTranslatedTitle(ele: IMainMenuItem): string {
        return (ele && ele.translations && ele['translations'][this.langIndex[this.currentLang]] &&
            ele['translations'][this.langIndex[this.currentLang]][this.currentLang]) || ele.title || '';
    }

}
