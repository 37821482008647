import * as tslib_1 from "tslib";
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { OnInit, OnChanges } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { MatDialog } from '@angular/material';
import { AddRecordComponent } from './add-record/add-record.component';
import { BarButtonOptions } from 'app/main/content/consts/progressButtonOptions.const';
var RelatedRecordListComponent = /** @class */ (function () {
    function RelatedRecordListComponent(translationLoader, helperService, dialog, _officeHelper) {
        this.translationLoader = translationLoader;
        this.helperService = helperService;
        this.dialog = dialog;
        this._officeHelper = _officeHelper;
        this.loading = false;
        this.isAddressBookReg = false;
    }
    RelatedRecordListComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.translateData && changes.translateData.currentValue) {
            this.translateData = changes.translateData.currentValue;
            this.translationLoader.loadTranslationsV2(this.translateData);
        }
    };
    RelatedRecordListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translationLoader.loadTranslationsV2(this.translateData);
        this.btnOpts = BarButtonOptions;
        this.getList(this.contactId);
        this.isAddressBookRegister()
            .then(function (isAddressBookReg) {
            _this.isAddressBookReg = isAddressBookReg;
        }).catch(function (err) {
            console.log(err);
        });
    };
    RelatedRecordListComponent.prototype.getList = function (contactId) {
        var _this = this;
        this.list = [];
        this.loading = true;
        this.helperService.getAllRegisterRecord({ contactId: contactId, mqFilter: this.field.mqFilter, regType: this.field.regType })
            .then(function (data) {
            _this.list = (data) || [];
            _this.loading = false;
        }).catch(function (err) {
            console.log(err);
            _this.loading = false;
            _this.helperService.openSnackBar(err);
        });
    };
    RelatedRecordListComponent.prototype.someFunc2 = function () {
        var _this = this;
        this.btnOpts.active = true;
        this.btnOpts.text = 'Saving Data...';
        setTimeout(function () {
            _this.btnOpts.active = false;
            _this.btnOpts.text = 'Progress Bar Button';
        }, 3500);
    };
    RelatedRecordListComponent.prototype.openAddRecord = function () {
        var _this = this;
        // check if only one register then create record without popup
        if (this.field['selectedRegister'] && this.field['selectedRegister'].length === 1 && !this.field.show_responsible_field && !this.field.show_group_field) {
            this.btnOpts.active = true;
            this.helperService.createRegisterRecord(this.field['selectedRegister'][0]['_id'], { contactId: this.contactId })
                .then(function (data) {
                var linkData = {
                    src_reg: _this.regId,
                    src_rec: _this.contactId,
                    dest_reg: data.regId,
                    dest_rec: data._id,
                    main: true
                };
                _this.helperService.createNewLink(linkData)
                    .then(function () {
                    _this.btnOpts.active = false;
                    _this.helperService.openSnackBar('Record Added');
                    _this.getList(_this.contactId);
                }).catch(function (err) {
                    _this.btnOpts.active = false;
                    console.log(err);
                });
            }).catch(function (err) {
                _this.btnOpts.active = false;
                _this.helperService.openSnackBar(err);
            });
        }
        else {
            var addressBookData = {};
            if (this.isAddressBookReg) {
                addressBookData['companyAdress'] = this.recData['companyAdress'] || '';
                addressBookData['companyCity'] = this.recData['companyCity'] || '';
                addressBookData['shortName'] = this.recData['shortName'] || '';
                addressBookData['longName'] = this.recData['longName'] || '';
                addressBookData['postalcode'] = this.recData['postalcode'] || '';
                addressBookData['companyName'] = this.recData['companyName'] || '';
            }
            var dialogRef = this.dialog.open(AddRecordComponent, {
                width: '850px',
                height: 'max-content',
                panelClass: 'happ-form-builder-dialog',
                data: {
                    contactId: this.contactId,
                    addressBookData: addressBookData,
                    regId: this.regId,
                    registerList: (this.field && this.field['selectedRegister']) || [],
                    field: this.field
                }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    _this.getList(_this.contactId);
                }
                // this.workspaceService.onEditForm.next('');
                // this.workspaceService.refreshView.next(true);
            });
        }
    };
    RelatedRecordListComponent.prototype.isAddressBookRegister = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var officeData, officeApps, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this._officeHelper.getLocalOfficeData()];
                    case 1:
                        officeData = _a.sent();
                        officeApps = officeData.apps || {};
                        if (officeApps.office_addressbook && officeApps.office_addressbook.id === this.regId) {
                            return [2 /*return*/, true];
                        }
                        else {
                            return [2 /*return*/, false];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        return [2 /*return*/, err_1];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return RelatedRecordListComponent;
}());
export { RelatedRecordListComponent };
