<div class="pb-12" *ngIf="(!field.hide || field.showdisablecontroltype || builder)">
    <div class="happ-list-field" [class]="field.class" >
        <div class="happ-default-title happ-title">
            <!-- <mat-icon *ngIf="error" class="ml-8 mt-8 happ-error-default happ-error-icon">warning</mat-icon> -->
            <div class="w-100-p" fxLayout="column" >
                <div class="w-100-p" fxLayout="row wrap" fxLayoutAlign="center center">
                    <p *ngIf="!hideTitle" class="happ-field-title">{{ field.title }} </p>
                    <span fxFlex></span>
                    <div>
                        <button *ngIf="field.showEdit" style="margin-right: -8px !important; color: #827c7c;" (click)="editOptions()" [matTooltip]="'ct.edit_options' | translate" mat-icon-button>
                            <mat-icon>settings</mat-icon>
                        </button>
                    </div>
                </div>
                <p *ngIf="errorMessage" class="warn-500-fg m-0 ml-8">
                    {{ errorMessage | translate  }}
                </p>
            </div>
        </div>
        <div class="happ-list happ-content" fxLayout="{{field.flex}} wrap" *ngIf="model">
            <mat-checkbox [disabled]="(dtTable && !field.inline_edit)" class="p-4" style="margin: 5px;" *ngFor="let option of field.options" [(ngModel)]="model[option.name]" (change)="onChange()"> {{ option.name }} </mat-checkbox>
        </div>
    </div>
    <div *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">
            
    </div>
</div>