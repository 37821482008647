<div class="dialog-content-wrapper" style="background: #fafafa;">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">{{dialogTitle}}</span>
        <button mat-button class="mat-icon-button"
                (click)="dialogRef.close()"
                aria-label="Close dialog">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  
    <div class="p-24 m-0" fusePerfectScrollbar>
      <app-form-component></app-form-component>
    </div>
  </div>
  