<div *ngIf="!field.hide || builder">
  <p *ngIf="!hideTitle" class="happ-field-title m-0 mb-12">{{ field.title }} </p>    
  <div *ngIf="!model || edit">

      <div class="w-100-p" fxLayout="row wrap">
        <div fxFlex>
          <mat-form-field appearance="outline" fxFlex>
              <mat-label> {{ 'deliveryAddress.companyName' | translate }} </mat-label>
              <input matInput [disabled]="!regId" (keydown.Tab)="search($event)" placeholder="{{ 'deliveryAddress.companyName' | translate }}" [(ngModel)]="searchMultiFileds">
          </mat-form-field>
      </div>
      <button mat-icon-button class="mt-16" (click)="search($event)">
          <mat-icon>search</mat-icon>
      </button>
      </div>
  </div>

  <h4 matTooltip="Click to edit" (click)="edit=!edit" *ngIf="model">
      {{ model }}
  </h4>

  <p style="text-align: center;" *ngIf="!model || edit">
      {{ message }}
  </p>
  <div class="happ-list-field" fxLayout="column" *ngIf="address && address.length && !model || edit">
      <div>
          <div *ngIf="address && address.length > 1" fxLayout="row" fxLayoutAlign="start center">
              <label for="search" class="mr-8">
                  <mat-icon>search</mat-icon>
              </label>
              <mat-form-field>
                  <mat-label> {{ 'deliveryAddress.search' | translate }} </mat-label>
                  
                  <input matInput placeholder="{{ 'deliveryAddress.search' | translate }}" [(ngModel)]="query">
              </mat-form-field>
          </div>
          <mat-list role="list" fxLayout="column">
              <mat-list-item role="listitem" *ngFor="let option of address | fullTextSearch:query; let i=index" [style.background]="(model == option)? 'gray': ''" [style.color]="(model == option)? 'white': ''">
                  <button mat-button fxFlex (click)="model=option;onKeyPress(i)">
                      {{ option }}
                  </button>
              </mat-list-item>
          </mat-list>
      </div>
  </div>
</div>