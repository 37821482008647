 
<div fxFlex>
    <div fxLayout="row" *ngIf="!field.defaultEdit && !errorMessage && field.showEdit" (click)="field.defaultEdit=!field.defaultEdit">
        <button class="mr-4" mat-icon-button>
            <mat-icon>edit</mat-icon>
        </button>
        <p class="font-size-18 mt-12">
            {{ (model) | date: 'yyyy-MM-dd' }}
        </p>
    </div>
    <div fxLayout="column wrap" *ngIf="field.defaultEdit || errorMessage || !field.showEdit">
        <mat-form-field appearance="outline" *ngIf="!field.hide || builder">
                <mat-label> {{ field.title }} </mat-label>

            <input matInput [matDatepicker]="picker" [placeholder]="field.title" [disabled]="field.disable" (dateChange)="ondateChange()" [hidden]="field.hidden" [(ngModel)]="date">
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <div class="error-message" *ngIf="errorMessage">
                    {{ errorMessage | translate }}
                </div>
        </mat-form-field>
    </div>
    <div *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">

    </div>
    <!-- <div style="color: red">
        <p *ngIf="errorMessage"> {{ errorMessage }} </p>
    </div> -->
</div>