import * as tslib_1 from "tslib";
import { HelpersService } from '../../services/helpers/helpers.service';
import * as _ from 'lodash';
import { ProfileHelperService } from '../../services/profile-helper/profile-helper.service';
var WorkspaceManagementService = /** @class */ (function () {
    function WorkspaceManagementService(helperService, profileHelper) {
        this.helperService = helperService;
        this.profileHelper = profileHelper;
        this.loading = false;
    }
    /**
     * The register list App Main Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    WorkspaceManagementService.prototype.resolve = function (route, state) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            Promise.all([
                _this.getWorkspaceList()
            ]).then(function () {
                resolve();
            }, reject);
        });
    };
    WorkspaceManagementService.prototype.getWorkspaceList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.helperService.getWorkspaceList()];
                    case 1:
                        data = _a.sent();
                        this.workspaceList = data || [];
                        return [2 /*return*/, this.workspaceList];
                    case 2:
                        error_1 = _a.sent();
                        throw error_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WorkspaceManagementService.prototype.getAllWorkspaceList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var profileDoc, data, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.profileHelper.getLocalProfileData()];
                    case 1:
                        profileDoc = _a.sent();
                        this.currentUser = profileDoc.email;
                        return [4 /*yield*/, this.helperService.getRequest('api/offices/work-space/list')];
                    case 2:
                        data = _a.sent();
                        this.officeWorkspaceList = this.filterOutUninvitedWorkspace(data);
                        return [2 /*return*/, this.officeWorkspaceList];
                    case 3:
                        error_2 = _a.sent();
                        throw error_2;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    WorkspaceManagementService.prototype.joinMe = function (wsSlug) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.loading = true;
                        return [4 /*yield*/, this.helperService.postRequest("api/offices/work-space/" + wsSlug + "/users/new", {
                                role: 'admin',
                                users: [this.currentUser]
                            })];
                    case 1:
                        _a.sent();
                        this.loading = false;
                        this.helperService.openSnackBar('User added to workspace');
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.log(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WorkspaceManagementService.prototype.filterOutUninvitedWorkspace = function (workspaceList) {
        var _this = this;
        if (workspaceList === void 0) { workspaceList = []; }
        try {
            return workspaceList.filter(function (reg) {
                if (reg && reg.users) {
                    reg.users = reg.users || [];
                    var index = _.findIndex(reg.users, { userEmail: _this.currentUser });
                    if (index === -1) {
                        return true;
                    }
                    return false;
                }
                else {
                    return false;
                }
            });
        }
        catch (error) {
            throw error;
        }
    };
    WorkspaceManagementService.prototype.removeWorkspace = function (workspace) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.helperService.deleteRequest("api/offices/work-space/" + workspace.slug)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 2:
                        error_4 = _a.sent();
                        throw error_4;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WorkspaceManagementService.prototype.leaveWorkspace = function (workspace) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.helperService.leaveWorkspace(workspace.slug)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 2:
                        error_5 = _a.sent();
                        throw error_5;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WorkspaceManagementService.prototype.unarchiveWorkspace = function (workspace) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.helperService.updateWorkspaceData(workspace.slug, { archive: false })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 2:
                        error_6 = _a.sent();
                        throw error_6;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WorkspaceManagementService.prototype.archiveWorkspace = function (workspace) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.helperService.updateWorkspaceData(workspace.slug, { archive: true })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 2:
                        error_7 = _a.sent();
                        throw error_7;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WorkspaceManagementService.prototype.createWorkspace = function (workspace) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, ''];
            });
        });
    };
    WorkspaceManagementService.prototype.getLocalWorkspace = function () {
        if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('selectedOfficeId'))) {
            var profile = JSON.parse(localStorage.getItem('ngStorage-profile'));
            var officeId = JSON.parse(localStorage.getItem('selectedOfficeId'));
            var index = _.findIndex(profile['offices'] || [], { 'officeId': officeId });
            return profile['offices'][index].workspaces || [];
        }
        else {
            return [];
        }
    };
    return WorkspaceManagementService;
}());
export { WorkspaceManagementService };
