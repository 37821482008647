import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog} from '@angular/material';
import { environment } from 'environments/environment.prod';
import { HttpClient } from '@angular/common/http';
// import { fuseAnimations } from '../../../../../../../core/animations';
import { TemplateModalComponent } from '../../../../template-modal/template-modal.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../i18n/en';
import { locale as polish } from '../../i18n/pl';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { fuseAnimations } from '@fuse/animations';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-add-new-document',
  templateUrl: './add-new-document.component.html',
  styleUrls: ['./add-new-document.component.scss'],
  animations   : fuseAnimations

})
export class AddNewDocumentComponent implements OnInit, AfterViewInit {


  ṭemplates: Array<any> = [];
  constructor(
    private http: HttpClient,
    public dialogRef: MatDialogRef<AddNewDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private translationLoader: FuseTranslationLoaderService,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    // this.translationLoader.loadTranslations(english, polish);
    this.helperService.getTrans('control-template')
      .then((data2) => {
        this.translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
        console.log(err);
      });
  }


  ngAfterViewInit(): void {
    this.ṭemplates = this.data['ṭemplateList'] || [];
  }
  // ṭemplateList

  closeDialog(data): void {
    if (data) {
    this.dialogRef.close(data);
    }
    else {
    this.dialogRef.close();
    }
  }

  newTemplate(template) {
    let dialogRef;
    const officeId = localStorage.getItem('selectedOfficeId');

    if (template) {
      this.getTemplate(template['_id'])
      .then(data => {
        dialogRef = this.dialog.open(TemplateModalComponent, {
          width: '100%',
          height: '100vh',
          data: { data: data, operation: 'edit', formVar: [], selectedFormName: '' },
          panelClass: 'happ-form-builder-dialog'
        });
          }).catch(err => {
            console.warn(err);
          });
      } else {
        
        dialogRef = this.dialog.open(TemplateModalComponent, {
          width: '100%',
          height: '100vh',
          data: { data: null, operation: 'add', formVar: [], selectedFormName: '' },
          panelClass: 'happ-form-builder-dialog'
        });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.snackBar.open('Updated', 'Okay', {
            duration: 2000,
          });
        } else {
          this.snackBar.open('Error occurred', 'Okay', {
            duration: 2000,
          });
        }
      });
  
      }
    }

    getTemplate(templateId: string): Promise<any> {
      return this.helperService.getTemplate(templateId);
    }

    generateDoc(ele: any) {
      this.closeDialog({ _id: ele._id, showAsMainDocument: ele.showAsMainDocument});
    }
}
