<div>
  <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <button class="mr-8" (click)="dialogRef.close(null)" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
      <span class="title dialog-title"> {{ 'ct.update_field_dialog' | translate }} </span>
      <span fxFlex></span>
      <div fxLayout="row"  fxLayoutAlign="start center">
       
        <button aria-label="add_options"  class="mx-4" (click)="addOption()" mat-button mat-raised-button>
          {{ 'ct.add_option' | translate }} 
        </button>
      </div>
    </div>
  </mat-toolbar>
  <div class="p-12" *ngIf="data" fxLayout="column">
      <mat-nav-list class="p-0 happ-list" *ngFor="let option of data.fieldOptions; let i=index">
          <a class="p-4" mat-list-item style=" border: 1px solid rgba(0, 0, 0, 0.12); border-bottom: 0px;text-decoration: none;" mat-list-item>
            <p>
              {{ option.title || option.name }} <span *ngIf="option.label">({{ option.label }})</span>
            </p>
            <span fxFlex></span>
            <div style="margin-top: -8px;">
              <button class="mx-4"  mat-button (click)="data.fieldOptions.splice(i, 1)">
                {{ 'ct.remove' | translate }}
              </button>        
            </div>
          </a>
        </mat-nav-list>
  </div>
  <div *ngIf="!data">
    {{ 'ct.no_option_present' | translate }}
  </div>
  <div class="p-24 pt-20 pb-20">
    <span fxFlex></span>
    <button (click)="dialogRef.close(null)" aria-label="custom_app_cancel_button" mat-button class="mr-4">{{ 'ct.cancel' | translate }}</button>
    <button mat-raised-button [disabled]="loading" aria-label="custom_app_create_button" (click)="update()">{{ 'ct.update' | translate }}</button>
  </div>
</div>