<div *ngIf="!loadingIndicator" style="position: relative; top: -40px;">
    <div *ngIf="allData.filters && !allData.filters.disableInsert" fxLayout="column"
        style="position: relative;top: 45px; background: white;" [ngClass]="{'p-12 pr-40': (datatableService && datatableService.applicationType !== 'position'), 'pr-16': (datatableService && datatableService.applicationType === 'position')}" >
        <div class="filter ml-24 mb-8" fxLayout="column" *ngIf="allData && allData.showSuperFilter">
            <p *ngIf="appData && appData.showHardFilterText">{{ (appData && appData.hardFilter) }}</p>
            <!-- MQ filter header buttons -->
            <div fxLayout="row" *ngIf="allData.dtTableAdvanceFilters && allData.dtTableAdvanceFilters.length > 0 && allData.filters && !allData.filters.noAdvanceFiltersSelected">
                <p  class="m-0 mr-12 w-100 font-weight-600 font-size-14"
                style="margin: auto 12px auto 0px !important"> {{ 'dttable.hard_filter' | translate }} </p>
                <div fxLayout="row wrap" class="my-8">
                    <button class="happ-button mx-4 m-4"
                        [color]="(requestData['currentMQFilter']==filter.value || defaultHardFilter === filter.value)?'accent': ''" style="height: max-content;"
                        *ngFor="let filter of allData.dtTableAdvanceFilters"
                        (click)="resetValues();fastQuery='';requestData['currentMQFilter']=filter.value; getServerSideRecord()"
                        mat-raised-button>
                        {{filter.name}}
                    </button>
                </div>
            </div>
            <div fxLayout="column" *ngFor="let filterOption of allData.superFilter">
                <div fxLayout="row" class="my-8" *ngIf="filterOption && filterOption.options && filterOption.options.length">
                    <p class="m-0 mr-12 w-100 font-weight-600 font-size-14"
                        style="margin: auto 12px auto 0px !important"> {{ filterOption['title'] }} </p>
                    <div fxLayout="row wrap">
                        <button class="m-4 happ-small-button happ-button-list happ-button font-size-14"
                            *ngFor="let buttonInfo of filterOption.options"
                            [color]="(valueFilter[filterOption['label']] && valueFilter[filterOption['label']].$in && ((buttonInfo.title && valueFilter[filterOption['label']].$in.includes(buttonInfo.title)) || (buttonInfo.value && valueFilter[filterOption['label']].$in.includes(buttonInfo.value)))) ? 'primary' : ''"
                            mat-raised-button (click)="superFilter(filterOption, buttonInfo)">
                            {{ buttonInfo.title }}
                        </button>
                        <button class="m-4 happ-small-button happ-button-list happ-button font-size-14"
                            [color]="(valueFilter[filterOption['label']] && valueFilter[filterOption['label']].$in && (valueFilter[filterOption['label']].$in.includes(null))) ? 'primary' : ''"
                            mat-raised-button (click)="superFilter(filterOption, {}, true)">
                            {{ 'dttable.empty' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div fxLayout="row" *ngIf="appData && appData.officeFilters && appData.officeFilters.length">
                <p  class="m-0 mr-12 w-100 font-weight-600 font-size-14"
                style="margin: auto 12px auto 0px !important"> {{ 'dttable.office_filters' | translate }} </p>
                    <div fxLayout="row wrap">
                        <div *ngFor="let officeFilter of officeFilters">
                            <button *ngIf="appData && appData.officeFilters && appData.officeFilters.includes(officeFilter.name)" class="m-4 happ-small-button happ-button-list happ-button font-size-14"
                            mat-raised-button (click)="officeFilterClicked(officeFilter);selectedOfficeFilter=officeFilter.name" [color]="(selectedOfficeFilter === officeFilter.name) ? 'primary' : ''" > {{ officeFilter.name }} </button>
                        </div>
                    </div>
            </div>

        </div>
        <!-- filter and action buttons on header -->
        <div fxLayout="row wrap">
            <!-- Advance filter and search options buttons -->
            <div fxFlex class="ml-20" style="margin-top: -10px;" fxLayout="row">
                <mat-form-field *ngIf="!allData.fastSearchWithField && allData.filters && !allData.filters.noAdvanceFiltersSelected"
                    class="happ-dt-table-filter-input">
                    <input matInput aria-label="record_fast_search_input" (keyup.enter)="filter(fastQuery, allData.fastSearchWithField)"
                        placeholder="{{ 'dttable.search' | translate }}" [(ngModel)]="fastQuery">
                </mat-form-field>
                <div fxLayout="row" *ngIf="allData.fastSearchWithField">
                    <mat-form-field class="happ-dt-table-filter-input mr-8" style="width: 200px;">
                        <mat-label>{{ 'dttable.select_field' | translate }}</mat-label>
                        <mat-select [(ngModel)]="fastSearchField">
                          <mat-option *ngFor="let filterOption of allData.filters.values" [value]="filterOption.label">
                            {{filterOption.title | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    <mat-form-field *ngIf="allData.fastSearchWithField && allData.filters && !allData.filters.noAdvanceFiltersSelected"
                        class="happ-dt-table-filter-input">
                        <input matInput aria-label="record_fast_search_input" (keyup.enter)="filter(fastQuery, allData.fastSearchWithField)"
                            placeholder="{{ 'dttable.search' | translate }}" [(ngModel)]="fastQuery2">
                    </mat-form-field>
                </div>
                <button aria-label="record_fast_search_button"
                    *ngIf="allData && allData.filters && !allData.filters.noAdvanceFiltersSelected"
                    style="margin: auto 2px;" class="ml-8" matTooltip="{{ 'dttable.search' | translate }}"
                    (click)="filter(fastQuery, allData.fastSearchWithField)" mat-icon-button>
                    <mat-icon style="color: black;">search</mat-icon>
                </button>
                <button style="margin: auto 2px;" matTooltip="{{ 'dttable.reset' | translate }}"
                    (click)="reset();fastQuery=''; resetLocalData()" mat-icon-button>
                    <mat-icon style="color: black">sync</mat-icon>
                </button>
                <div fxLayout="row" *ngIf="!(allData && allData.filters && allData.filters.noFilters)">
                    <button [hidden]="allData.filters && allData.filters.noAdvanceFilters" style="margin: auto 2px;"
                        matTooltip="{{ 'dttable.show_advance_filter' | translate }}" (click)="filterIconClicked()"
                        mat-icon-button>
                        <mat-icon style="color: black;">filter_list</mat-icon>
                    </button>
                </div>
                <div *ngIf="(allData && allData.superFilter && allData.superFilter.length) || 
                (allData && allData.dtTableAdvanceFilters && allData.dtTableAdvanceFilters.length > 0 && allData.filters && !allData.filters.noAdvanceFiltersSelected) || 
                (appData && appData.officeFilters && appData.officeFilters.length)" fxLayout="row">
                    <button style="margin: auto 2px;" matTooltip="{{ 'dttable.show_super_filter' | translate }}"
                        (click)="allData.showSuperFilter=!allData.showSuperFilter" mat-icon-button>
                        <mat-icon style="color: black;">tune</mat-icon>
                    </button>
                </div>
                <button aria-label="datatable_excel_button" style="margin: auto 2px;" 
                    matTooltip="{{ 'dttable.excel_report' | translate }}" (click)="excel()" mat-icon-button>
                    <mat-icon style="color: black">chrome_reader_mode</mat-icon>
                </button>
                <button aria-label="datatable_save_filter_button" style="margin: auto 2px;" 
                    matTooltip="{{ 'dttable.save_filter_to_office_doc' | translate }}" (click)="saveFilterToOffice()" mat-icon-button>
                    <mat-icon style="color: black">save</mat-icon>
                </button>
                <button *ngIf="datatableService.regData && datatableService.regData.report_settings" aria-label="datatable_pdf_button" style="margin: auto 2px;" 
                    matTooltip="{{ 'dttable.pdf_report' | translate }}" (click)="pdf()" mat-icon-button>
                    <mat-icon style="color: black">description</mat-icon>
                </button>
                <button *ngIf="appData && appData.stepDescription" aria-label="datatable_info_button" style="margin: auto 2px;" 
                    matTooltip="{{ 'dttable.app_info' | translate }}" (click)="openInstructionPopup((appData && appData.stepDescription))" mat-icon-button>
                    <mat-icon style="color: black">info</mat-icon>
                </button>
                <button aria-label="datatable_settings_button" style="margin: auto 2px;" *ngIf="isAdmin && allData"
                    matTooltip="{{ 'dttable.settings' | translate }}" (click)="openWSAppsSettings(0)" mat-icon-button>
                    <mat-icon style="color: black">settings</mat-icon>
                </button>
                <p class="font-size-14 ml-8" style="cursor: pointer; color: brown; margin: auto;" *ngIf="lastSearchPersisted" (click)="lastSearchPersisted=false; requestData={}; getServerSideRecord()" >
                    {{ 'dttable.last_search_persisted_clear_all' | translate }}
                </p>
            </div>
            <div fxFlex>
                <div fxFlex fxLayout="row" style="justify-content: flex-end;">
                    <!-- left side action buttons -->
                    <div fxLayout="row wrap" fxLayoutAlign="end center" *ngIf="datatableService && datatableService.applicationType !== 'workspace'">
                        <div *ngFor="let action of allData.headerActions">
                            <button
                                [attr.aria-label]="((action.translations)? action.translations.pl :action.default_label) | uppercase"
                                *ngIf="action.placement.headerActions && (!action.appType || (action.appType && action.appType !== 'position'))"
                                mat-raised-button style="text-transform: capitalize;" style="margin-left: 12px;"
                                (click)="actionMoveTo(rowIndex, action.application, null, action, action.fuse2App)" color="warn">
                                {{ ((action.translations)? (action.translations.pl | translate) : (action.default_label | translate)) }}
                            </button>
                            <button
                                [attr.aria-label]="((action.translations)? action.translations.pl :action.default_label) | uppercase"
                                *ngIf="action.placement.headerActions && allData.appType && allData.appType === 'position' && action.appType === 'position'"
                                mat-raised-button style="text-transform: capitalize;" style="margin-left: 12px;"
                                (click)="actionMoveTo(rowIndex, action.application, null, action, action.fuse2App)" color="warn">
                                {{ ((action.translations)? (action.translations.pl | translate) : (action.default_label | translate)) }}
                            </button>
                        </div>
                    </div>
                    <!-- left side required option in workspace -->
                    <div *ngIf="appData && !wsRequiredFieldDone" style="font-size: 18px; margin: auto 5px; color: red;">
                        {{ 'dttable.please_fill_the_required_fields' | translate }}
                        <span style="cursor: pointer;"
                            (click)="openWSAppsSettings(2)">{{ 'dttable.click_here' | translate }}</span>
                    </div>
                    <div fxLayoutAlign="end center" *ngIf="datatableService && datatableService.applicationType !== 'position' && appData && appData.showBulkAction">
                        <button aria-label="dttable_barcode_single" mat-raised-button style="text-transform: capitalize;" style="margin-left: 12px;"
                            (click)="openBulActionPopup(null)" color="warn">
                            {{ 'dttable.open_bulk_action' | translate }}
                        </button>
                    </div>
                    <!-- left side action buttons in workspace -->
                    <div fxLayout="row wrap" fxLayoutAlign="end center" *ngIf="datatableService && datatableService.applicationType === 'workspace' && appData && !appData.hideHeaderActions && wsRequiredFieldDone">
                        <!-- <button aria-label="dttable_process_Settings" *ngIf="isAdmin && appData && appData.integratedProcessId"
                            mat-raised-button style="text-transform: capitalize;" style="margin-left: 12px;"
                            (click)="moveToProcessSettings(appData.integratedProcessId)" color="warn">
                            {{ 'dttable.process_settings' | translate }}
                        </button> -->
                            
                        <div fxLayout="row wrap" *ngFor="let action of appData.actionList">
                            <button mat-raised-button *ngIf="action.placement && action.placement.headerActions" style="text-transform: capitalize;" style="margin-left: 12px;"
                                (click)="openBulActionPopup(action)" color="warn">
                                {{ action.default_label | translate }}
                            </button>
                        </div>
                        <button aria-label="dttable_barcode_single" *ngIf="appData && appData.barcode_single"
                            mat-raised-button style="text-transform: capitalize;" style="margin-left: 12px;"
                            (click)="moveTo('./barcode/single/'+appData.single_barcode_params)" color="warn">
                            {{ 'dttable.barcode_single' | translate }}
                        </button>
                        <button aria-label="dttable_barcode_package" *ngIf="appData && appData.barcode_package"
                            mat-raised-button style="text-transform: capitalize;" style="margin-left: 12px;"
                            (click)="moveTo('./barcode/package/'+appData.package_barcode_params)" color="warn">
                            {{ 'dttable.barcode_package' | translate }}
                        </button>
                        <button aria-label="dttable_open_scanque"
                            *ngIf="appData && appData.scanqueue && appData.scanqueue.isChecked" mat-raised-button
                            style="text-transform: capitalize;" style="margin-left: 12px;"
                            (click)="moveTo('./sq/'+appData.scanqueue.sqObj)" color="warn">
                            {{ 'dttable.open_scanque' | translate }}
                        </button>
                        <button aria-label="dttable_open_postbook" *ngIf="appData && appData.showPostbook"
                            mat-raised-button style="text-transform: capitalize;" style="margin-left: 12px;"
                            (click)="moveTo('./postbook')" color="warn">
                            {{ 'dttable.open_postbook' | translate }}
                        </button>
                        <!-- <button aria-label="dttable_instance_view" *ngIf="allData && allData['integratedProcessId']" mat-raised-button style="text-transform: capitalize;" style="margin-left: 12px;" (click)="moveToInstanceList('./processId/'+allData['integratedProcessId'])" color="warn">
                            Instance View
                        </button> -->
                        <button aria-label="dttable_insert_with_process"
                            *ngIf="allData && allData['integratedProcessId']" mat-raised-button
                            style="text-transform: capitalize;" style="margin-left: 12px;"
                            (click)="moveTo('./recWithInstance')" color="warn">
                            {{ (appData && appData['itemName']) || 'Dodaj' }}
                        </button>
                        <button aria-label="dttable_insert_simple" *ngIf="allData && !allData['integratedProcessId']"
                            mat-raised-button style="text-transform: capitalize;" style="margin-left: 12px;"
                            (click)="moveTo('./new')" color="warn">
                            {{ (appData && appData['itemName']) || 'Dodaj' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- filter and action buttons on header ends -->
    </div>
    <div fxLayout="column">
        <div class="fuse-white-bg">
            <div class="mb-24 mt-12" [ngClass]="{'px-24': (datatableService && datatableService.applicationType !== 'position')}">
                <!-- field filter in advance options -->
                <div class="filter mt-40" fxLayout="row wrap" fxLayoutAlign="center center"
                    *ngIf="allData.filters && allData.filters.noAdvanceFiltersSelected">
                    <div *ngFor="let filterOption of allData.filters.values">
                        <div fxLayoutAlign="center center"
                            *ngIf="filterOption.control_type !== 'date' && filterOption.control_type !== 'date-time'">
                            <mat-form-field class="m-8">
                                <input matInput placeholder="{{filterOption['title']}}"
                                    [(ngModel)]="valueFilter[filterOption['label']]">
                            </mat-form-field>
                        </div>
                        <div
                            *ngIf="filterOption.control_type === 'date' || filterOption.control_type === 'date-time' && filterOption.category !== 'radio'">
                            <app-date-filter-field class="m-8" [(model)]="valueFilter[filterOption['label']]"
                                [fieldTitle]="filterOption['title']"></app-date-filter-field>
                        </div>
                    </div>
                </div>
                <div *ngIf="allData && allData.filters && allData.filters.noAdvanceFiltersSelected"
                    fxLayoutAlign="center center">
                    <mat-checkbox *ngIf="appData && appData.filters && !appData.hideDateRange" [(ngModel)]="allData.filters.hideDateRange">
                        {{ ((allData.filters.hideDateRange)? 'dttable.hide_date_time_filter' : 'dttable.show_date_time_filter') | translate }}
                    </mat-checkbox>
                </div>
                <!-- date range filter in advance options -->
                <div fxLayout="row"  fxLayoutAlign="center space-around" class="date mt-12" fxLayout="row wrap" *ngIf="allData.filters && allData.filters.noAdvanceFiltersSelected && allData.filters.hideDateRange">
                    <app-date-filter-field class="m-8" [(model)]="dateTimeFilterType"
                        [fieldTitle]="'dttable.registredDataTime' | translate"></app-date-filter-field>
                    <div fxLayout="row" *ngIf="dateTimeFilterType === 'customValue'" fxLayoutAlign="center space-around">
                        <div class="m-12">
                            <mat-form-field  class="mr-12">
                                <input matInput [matDatepicker]="picker2" [(ngModel)]="timeFilter['startDate']"
                                placeholder="{{ 'dttable.choose_start_date' | translate }}">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="w-44">
                                <input matInput [placeholder]="'dttable.start_time' | translate" [textMask]="{mask: mask}" [(ngModel)]="timeFilter['startTime']" type="text"/>
                            </mat-form-field>
                        </div>
                        <div  class="m-12">
                            <mat-form-field class="mr-12">
                                <input matInput [matDatepicker]="picker" [(ngModel)]="timeFilter['endDate']"
                                placeholder="{{ 'dttable.choose_end_date' | translate }}">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="w-44">
                                <input matInput [placeholder]="'dttable.end_time' | translate" [textMask]="{mask: mask}" [(ngModel)]="timeFilter['endTime']" type="text"/>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <!-- advance filter buttons -->
                <div class="mb-44"
                    *ngIf="allData.filters && allData.filters.noAdvanceFiltersSelected"
                    fxLayout="row" fxLayoutAlign="center center">
                    <button class="m-16 pl-10" mat-raised-button color="warn" (click)="reset()">
                        <mat-icon style="color: white;" aria-label="reset">sync</mat-icon>
                        {{ 'dttable.reset' | translate }}
                    </button>
                    <button mat-raised-button class="m-16 pl-10" (click)="search()">
                        <mat-icon aria-label="reset">search</mat-icon>
                        {{ 'dttable.search' | translate }}
                    </button>
                    <button mat-raised-button class="m-16 pl-10" (click)="excel()">
                        <mat-icon aria-label="reset">chrome_reader_mode</mat-icon>
                        EXCEL
                    </button>
                    <button mat-raised-button class="m-16 pl-10" (click)="pdf()">
                        <mat-icon aria-label="reset">description</mat-icon>
                        PDF
                    </button>
                    <button *ngIf="datatableService && datatableService.applicationType !== 'workspace'"
                        mat-raised-button class="m-16 pl-10" (click)="savePrivateFilter()">
                        <mat-icon aria-label="reset">add</mat-icon>
                        {{ 'dttable.add_private_filter' | translate }}
                    </button>
                    <app-private-filter *ngIf="datatableService && datatableService.applicationType !== 'workspace'"
                        #privateFilter [regId]="allData['regId']" (privateFilterChange)="triggerPrivateFilter($event)">
                    </app-private-filter>
                </div>
                <!-- datatable header card view -->
                <div *ngIf="chartData && chartData.length > 0"
                    style="background: white; margin-bottom: -44px; margin-top: 20px">
                    <div fxFlex="100" fxLayout="column" style="width: 100%; margin: 20px;">
                        <div fxLayout="row" *ngFor="let i of cardVarsArray">
                            <div *ngFor="let j of i.options"
                                [fxFlex]="(i.options && i.options.length>0)? (100/i.options.length): '100'">
                                <div fxLayout="row"
                                    style="padding: 8px; margin: 2px; font-size: 25px; border-top: 15px solid;"
                                    class="my-4" [style.border-color]="(j.background)? j.background: 'white'">
                                    <div fxHide.lt-md="true" style="font-size: 18px; margin: auto;" fxFlex="49">
                                        {{ j.value || j.title }}
                                    </div>
                                    <div fxFlex.lt-md="100" style="font-size: 35px;" fxLayoutAlign="center center"
                                        fxFlex="49">
                                        {{ cardVars[i.label][j.value || j.title].count }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
                <div *ngIf="preview" [innerHTML]="preview | htmlWithStyle">
                </div>
                <!-- data table grid starts -->
                <div *ngIf="!loadingIndicator && allData && !allData.groupBy" class="w-100-p"
                    style="overflow: auto;margin-top: 44px; padding-right: 5px;">
                    <ngx-datatable [messages]="{emptyMessage: 'dttable.empty_message' | translate, totalMessage: 'dttable.total' | translate}"
                        [@animateStagger]="{value:'50'}" class="bootstrap" [rows]="rows" [rowClass]="getRowClass" [columnMode]="allData.columnMode" [headerHeight]="allData.headerHeight" [footerHeight]="allData.footerHeight"
                        [rowHeight]="allData.rowHeight" [externalPaging]="allData.externalPaging" [count]="page.totalElements" [offset]="page.pageNumber" (sort)="onSort($event)" [sorts]="allData.sort || [{ prop: '_id' }]" [limit]="page.size" (page)='setPage($event)'>

                        <ngx-datatable-column [flexGrow]="(col.control_type && col.control_type.cell_width)? col.control_type.cell_width: ((col.name=='Actions' && allData.actionWidth)? allData.actionWidth: ((col.name=='subAction' && allData.subActionWidth)? allData.subActionWidth: 1)) " *ngFor="let col of allData.columns; let colIndex=index" fxFlex [prop]="col.prop" [name]="col.title">

                            <!-- Header Template Starts -->
                            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                <div [class]="'happ-header-data-align-' + ((col && col.control_type && col.control_type.columnAlign) || 'left')" (click)="sort();" *ngIf="column.name != '_id' && column.name !== 'subAction'">{{column.name}}</div>
                                <div (click)="sort();" class="happ-header-data-align-right" *ngIf="column.name === 'subAction'"> {{ 'dttable.subAction' | translate }} </div>
                                <div (click)="sort();" class="happ-header-data-align-right" *ngIf="column.name == '_id'"> {{ 'dttable.actions' | translate }} </div>
                            </ng-template>
                            <!-- Header Template Ends -->

                            <!-- Row and cell Template Starts -->
                            <ng-template let-value="value" class="happ-dummy" let-rowIndex="rowIndex" let-row="row" style="color: blue;" ngx-datatable-cell-template>
                                <!-- for category radio type NON EDITING MODE -->

                                <!-- templating other constoll type -->
                                <div class="happ-full-area" [class]="'happ-data-align-' + ((col && col.control_type && col.control_type.columnAlign) || 'left')" *ngIf="col && col.control_type && col.control_type.control_type && (!inlineEditCell[col.control_type.label+colIndex+rowIndex])">
                                    <div class="happ-full-area" [ngSwitch]="col.control_type.control_type">

                                        <div *ngSwitchCase="'date'">
                                            <p class="happ-full-area" *ngIf="value" style="width: 100%; height: 80%;">
                                                {{ value | date: 'yyyy-MM-dd' }}
                                            </p>
                                        </div>

                                        <div *ngSwitchCase="'date-time'">
                                            <p style="width: 100%; height: 100%;" *ngIf="value">
                                                {{ value | date: 'yyyy-MM-dd HH:mm' }}
                                            </p>
                                        </div>

                                        <div *ngSwitchCase="'time'">
                                            <p class="happ-full-area" *ngIf="value" style="width: 100%; height: 80%;">
                                                <!-- {{ value | date: 'HH:mm' }} -->
                                                {{ value }}
                                            </p>
                                        </div>

                                        <div class="happ-full-area" *ngSwitchCase="'Editor'">
                                            <div *ngIf="!col.control_type.isPopup" style="width: 100%; height: 80%;" [innerHTML]="value | htmlWithStyle">
                                            </div>
                                            <div *ngIf="col.control_type.isPopup" style="width: 100%; height: 80%;">
                                                <button *ngIf="value" (click)="openDescriptionPopup(col.control_type, value)" mat-icon-button>
                                                    <mat-icon>info</mat-icon>
                                                </button>
                                            </div>
                                        </div>

                                        <div *ngSwitchCase="'dt_table_group_field'">
                                            <div *ngIf="!col.control_type.isPopup" style="width: 100%; height: 80%;" >
                                                <div class="pad-left" [innerHTML]="value | htmlWithStyle"></div>
                                            </div>
                                            <div *ngIf="col.control_type.isPopup" style="width: 100%; height: 80%;">
                                                <button *ngIf="value" (click)="openDescriptionPopup(col.control_type, value)" mat-icon-button>
                                                    <mat-icon>info</mat-icon>
                                                </button>
                                            </div>
                                        </div>

                                        <div *ngSwitchCase="'number'" class="happ-full-area" (click)="inlineEdit(row, col.control_type.label+colIndex+rowIndex, true, col)" >
                                            <p *ngIf="value && col.control_type.decimal_places" class="happ-full-area" style="width: 100%; height: 80%; min-width: 35px;">
                                                {{ value | number:'1.'+col.control_type.decimal_places+'-'+col.control_type.decimal_places:'pl' }}
                                            </p>
                                            <p *ngIf="!value || !col.control_type.decimal_places" class="happ-full-area" style="width: 100%; height: 80%; min-width: 35px;">
                                                {{ value }}
                                            </p>
                                        </div>

                                        <div *ngSwitchCase="'currency'" class="happ-full-area" (click)="inlineEdit(row, col.control_type.label+colIndex+rowIndex, true, col)" >
                                            <p *ngIf="value && col.control_type.decimal_places" class="happ-full-area" style="width: 100%; height: 80%; min-width: 35px;">
                                                {{ value | currency:(col.control_type.currencyType || 'USD'):'symbol':'1.'+col.control_type.decimal_places+'-'+col.control_type.decimal_places:'pl' }}
                                            </p>
                                            <p *ngIf="!value || !col.control_type.decimal_places" class="happ-full-area" style="width: 100%; height: 80%; min-width: 35px;">
                                                {{ value }}
                                            </p>
                                        </div>

                                        <div *ngSwitchCase="'text'"  class="happ-full-area" (click)="inlineEdit(row, col.control_type.label+colIndex+rowIndex, true, col)" >
                                            <div class="m-0 w-100-p h-100-p" *ngIf="col.control_type && col.control_type.colorOptions && !col.control_type.isPopup">
                                                <p style="min-width: 35px;" [class]="(col.control_type.colorOptions[value] && col.control_type.colorOptions[value].bgColor)+'-bg '+(col.control_type.colorOptions[value] && col.control_type.colorOptions[value].color)+'-fg m-0 w-100-p h-100-p text-center'"
                                                    fxLayoutAlign="center center">
                                                    {{ value }}
                                                </p>
                                            </div>
                                            <p style="min-width: 35px;" *ngIf="col.control_type && !col.control_type.colorOptions && !col.control_type.isPopup">
                                                {{ value }}
                                            </p>
                                            <div *ngIf="col.control_type.isPopup" style="width: 100%; height: 80%;">
                                                <button *ngIf="value" (click)="openDescriptionPopup(col.control_type, value)" mat-icon-button>
                                                    <mat-icon>info</mat-icon>
                                                </button>
                                            </div>
                                        </div>

                                        <div class="happ-full-area" class="happ-full-area" *ngSwitchCase="'button-list'">
                                            <div class="happ-display-value" style="width: 100%; height: 80%;" *ngIf="!col.control_type.inline_edit && col.control_type.multiple">
                                                {{ value | json }}
                                                <div fxLayout="row wrap" *ngFor="let tt of value | keys; let i=index">
                                                    <div *ngIf="tt.value" style="border: 1px solid rgba(220,220,220,0.5); padding: 3px; margin: 3px; border-radius: 2px;">
                                                        {{ tt.key }} 
                                                    </div>
                                                </div>
                                            </div>
                                            <p fxLayoutAlign="start center" class="happ-display-value" style="width: 100%; height: 80%;" *ngIf="!col.control_type.inline_edit && !col.control_type.multiple">
                                                <span *ngFor="let i of col.control_type.options">
                                                    <span *ngIf="i.value === value || i.title === value">
                                                        {{ i.title || i.value }}
                                                    </span>
                                                </span>
                                            </p>
                                            <div class="happ-full-area" (click)="inlineEdit(row, col.control_type.label+colIndex+rowIndex, true, col)" *ngIf="col.control_type.inline_edit">
                                                <div class="happ-full-area happ-inline-button" [class]="(value.background)+'-bg '+(value.color)+'-fg'">
                                                    {{ value.value || value.title }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="happ-full-area" class="happ-full-area" *ngSwitchCase="'calculation_on_save'">
                                            <div class="w-100-p" *ngIf="col.control_type.range && col.control_type.range.length > 0">
                                                <div *ngFor="let i of col.control_type.range">
                                                    <div class="happ-full-area" *ngIf="value >= i.min && value <= i.max">
                                                        <div class="happ-full-area happ-inline-button" [class]="(i.background)+'-bg '+(i.color)+'-fg'">
                                                            {{ i.title || value | number:'1.0-2':'pl' }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p *ngIf="!col.control_type.range || (col.control_type.range && col.control_type.range.length === 0)"
                                                class="happ-display-value align-txt-ab happ-full-area" style="width: 100%; height: 80%;">
                                                {{ value | number:'1.0-2':'pl' }}
                                            </p>
                                        </div>

                                        <div class="happ-full-area" class="happ-full-area" *ngSwitchCase="'calculation'">
                                            <div class="w-100-p" *ngIf="col.control_type.range && col.control_type.range.length > 0">
                                                <div *ngFor="let i of col.control_type.range">
                                                    <div class="happ-full-area" *ngIf="value >= i.min && value <= i.max">
                                                        <div class="happ-full-area happ-inline-button" [class]="(i.background)+'-bg '+(i.color)+'-fg'">
                                                            {{ i.title || value }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p *ngIf="!col.control_type.range || (col.control_type.range && col.control_type.range.length === 0)"
                                                class="happ-display-value align-txt-ab happ-full-area" style="width: 100%; height: 80%;">
                                                {{ value }}
                                            </p>
                                        </div>

                                        <div class="happ-full-area" class="happ-full-area" *ngSwitchCase="'List'">
                                            <div class="happ-display-value" style="width: 100%; height: 80%;" *ngIf="!col.control_type.inline_edit && col.control_type.multiple">
                                                <div fxLayout="row wrap" *ngFor="let tt of value | keys; let i=index">
                                                    <div *ngIf="tt.value" style="border: 1px solid rgba(220,220,220,0.5); padding: 3px; margin: 3px; border-radius: 2px;">
                                                        {{ tt.key }} 
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="happ-display-value" style="width: 100%; height: 80%;" *ngIf="!col.control_type.inline_edit && !col.control_type.multiple">
                                                {{ value }}
                                            </p>
                                            <div class="happ-full-area" (click)="inlineEdit(row, col.control_type.label+colIndex+rowIndex, true, col)" *ngIf="col.control_type.inline_edit">
                                                <div class="happ-full-area happ-inline-button" [class]="(value.background)+'-bg '+(value.color)+'-fg'">
                                                    {{ value.value || value.title }}
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngSwitchCase="'reg_value_selection'">
                                            <p fxLayoutAlign="start center" class="happ-display-value" style="width: 100%; height: 100%;" *ngIf="!col.control_type.inline_edit && !col.control_type.multiSelect">
                                                <span *ngFor="let i of value.value | keys">
                                                    {{ i.value }}
                                                    <br>
                                                </span>
                                            </p>
                                            <p fxLayoutAlign="start center" class="happ-display-value" style="width: 100%; height: 100%;" *ngIf="!col.control_type.inline_edit && col.control_type.multiSelect && isArray(value)">
                                                <span style="background: rgba(220,220,220,0.5); margin-right: 5px; padding: 5px" *ngFor="let val of value">
                                                    <span *ngFor="let i of val.value | keys">
                                                        {{ i.value }} -
                                                    </span>
                                                </span>
                                            </p>
                                        </div>

                                        <div class="happ-full-area" *ngSwitchCase="'checkbox'">
                                            <app-checkbox [dtTable]='true' [field]="col.control_type" #val [model]="value"></app-checkbox>
                                        </div>

                                        <div class="happ-full-area" *ngSwitchCase="'text_description'">
                                            <p *ngIf="col.control_type && !col.control_type.isPopup">
                                                {{ value }}
                                            </p>
                                            <div *ngIf="col.control_type.isPopup" style="width: 100%; height: 80%;">
                                                <button *ngIf="value" (click)="openDescriptionPopup(col.control_type, value)" mat-icon-button>
                                                    <mat-icon>info</mat-icon>
                                                </button>
                                            </div>
                                        </div>

                                        <div class="happ-full-area" *ngSwitchCase="'file_preview'">
                                            <button *ngIf="value" (click)="downloadPdf(value)" mat-icon-button>
                                                <mat-icon style="color: red;">description</mat-icon>
                                            </button>
                                        </div>

                                        <div class="happ-full-area" *ngSwitchDefault>
                                            <p fxLayoutAlign="start center" class="happ-display-value" style="display: flex; width: 100%; min-height: 40px; margin: 0px;" *ngIf="col.prop != '_id' && col.control_type && col.control_type.category != 'radio' && !col.control_type.inline_edit">
                                                <span *ngIf="(row.allSum && col.control_type.allTotal)? true: false">
                                                    {{ 'dttable.sum' | translate }} 
                                                </span> 
                                                {{ (value || value == 0)? value: col.defaultContent }}
                                            </p>
                                            <div class="w-100-p" *ngIf="col.prop != '_id' && col.control_type && col.control_type.category == 'radio'">
                                                {{ value }}
                                            </div>
                                            <p class="happ-display-value"
                                                style="display: flex; width: 100%; min-height: 40px; margin: 0px;"
                                                (click)="inlineEdit(row, col.control_type.label+colIndex+rowIndex, true, col)"
                                                *ngIf="col.prop != '_id' && col.control_type && col.control_type.category != 'radio' && col.control_type.inline_edit && !inlineEditCell[col.control_type.label+colIndex+rowIndex]">
                                                <span *ngIf="(row.allSum && col.control_type.allTotal)? true: false">
                                                    {{ 'dttable.sum' | translate }}  
                                                </span> 
                                                {{ (value || value == 0)? value: col.defaultContent }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- ENDS templating other constoll type -->

                                <!-- templating in edit mode -->
                                <div style="width: 100%;" *ngIf="col && col.control_type && col.control_type.label  && inlineEditCell[col.control_type.label+colIndex+rowIndex]">
                                    <div [ngSwitch]="col.control_type.control_type">

                                        <div *ngSwitchCase="'text'">
                                            <input *ngIf="col.control_type.inline_edit" #inputVal autofocus class="happ-input"
                                                (keyup.enter)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), true, col)"
                                                (blur)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), false, col)"
                                                [value]="value" type="text">
                                        </div>

                                        <div *ngSwitchCase="'number'">
                                            <input #inputVal autofocus (keypress)="validateNumber($event, inputVal, col.control_type)" type="number" class="happ-full-area happ-input"
                                                (keyup.enter)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), true, col)"
                                                (blur)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), false, col)"
                                                [value]="value">
                                        </div>

                                        <div *ngSwitchCase="'currency'">
                                            <input #inputVal autofocus (keypress)="validateNumber($event, inputVal, col.control_type)" type="number" class="happ-full-area happ-input"
                                                (keyup.enter)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), true, col)"
                                                (blur)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), false, col)"
                                                [value]="value">
                                        </div>

                                        <div style="width: 100%;" *ngSwitchCase="'textarea'">
                                            <textarea *ngIf="inlineEditCell[col.control_type.label+colIndex+rowIndex]"
                                                class="happ-input" style="width: 100%;" fxFlex #inputVal autofocus
                                                (blur)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), false, col)"
                                                (keyup.enter)="updateReg(row._id, inputVal.value, value, col.control_type.label, rowIndex, row); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), true, col)"
                                                style="resize: none;">{{value}} </textarea>
                                        </div>

                                        <div class="happ-content" *ngSwitchCase="'button-list'">
                                            <app-button-list [hideTitle]="true" [field]="col.control_type"
                                                (valChange)="updateReg(row._id, inputVal.model, value, col.control_type.label, rowIndex); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), true, col);"
                                                #inputVal [model]="value"></app-button-list>
                                        </div>

                                        <div class="happ-content" *ngSwitchCase="'List'">
                                            <app-list [hideTitle]="true" [field]="col.control_type"
                                                (valChange)="updateReg(row._id, inputVal.model, value, col.control_type.label, rowIndex); inlineEdit(row, col.control_type.label+colIndex+(rowIndex+1), true, col);"
                                                #inputVal [model]="value"></app-list>
                                        </div>
                                    </div>
                                </div>
                                <!-- ENDS templating in edit mode -->

                                <div fxLayout="column" style="width: 100%; margin-right: 15px; justify-content: flex-end;" *ngIf="datatableService && datatableService.applicationType === 'workspace' && (col.name=='subAction' || col.title === 'subAction')">
                                    <div  *ngFor="let action of appData.subActionList">
                                        <button class="my-4" mat-raised-button *ngIf="action.placement && action.placement.recordActions && action.actionType === 'subAction' && action.typeOfView === 'text'" style="text-transform: capitalize;" style="margin-left: 12px;"
                                            (click)="actionMoveTo(rowIndex, action.application,row, action, action.fuse2App)">
                                            {{ action.default_label | translate }}
                                        </button>
                                        <button class="my-4 happ-icon-raised" [matTooltip]="action.default_label | translate" mat-icon-button *ngIf="action.placement && action.placement.recordActions && action.actionType === 'subAction' && action.typeOfView === 'icon'" style="text-transform: capitalize;" style="margin-left: 12px;"
                                            (click)="actionMoveTo(rowIndex, action.application,row, action, action.fuse2App)">
                                            <mat-icon>{{ action.icon || 'apps'}}</mat-icon>

                                        </button>
                                        <button class="my-4" mat-raised-button *ngIf="action.placement && action.placement.recordActions && action.actionType === 'subAction' && action.typeOfView === 'textAndIcon'" style="text-transform: capitalize;" style="margin-left: 12px;"
                                            (click)="actionMoveTo(rowIndex, action.application,row, action, action.fuse2App)">
                                            <mat-icon class="mr-4">{{ action.icon || 'apps' }}</mat-icon>
                                            {{ action.default_label | translate }}
                                        </button>
                                    </div>
                                </div>
                                <div style="width: 100%; margin-right: 15px; justify-content: flex-end;" *ngIf="col.name=='Actions' || col.title === 'Actions'" fxLayout="row">
                                    <div  fxLayout="row wrap" *ngIf="datatableService && datatableService.applicationType === 'workspace'" class="my-4">
                                            <div  *ngFor="let action of appData.mainActionList">
                                                <button class="my-4" mat-raised-button *ngIf="action.placement && action.placement.recordActions && action.actionType === 'action' && action.typeOfView === 'text'" style="text-transform: capitalize;" style="margin-left: 12px;"
                                                    (click)="actionMoveTo(rowIndex, action.application,row, action, action.fuse2App)">
                                                    {{ action.default_label | translate }}
                                                </button>
                                                <button class="my-4 happ-icon-raised" [matTooltip]="action.default_label | translate" mat-icon-button *ngIf="action.placement && action.placement.recordActions && action.actionType === 'action' && action.typeOfView === 'icon'" style="text-transform: capitalize;" style="margin-left: 12px;"
                                                    (click)="actionMoveTo(rowIndex, action.application,row, action, action.fuse2App)">
                                                    <mat-icon>{{ action.icon || 'apps'}}</mat-icon>
        
                                                </button>
                                                <button class="my-4" mat-raised-button *ngIf="action.placement && action.placement.recordActions && action.actionType === 'action' && action.typeOfView === 'textAndIcon'" style="text-transform: capitalize;" style="margin-left: 12px;"
                                                    (click)="actionMoveTo(rowIndex, action.application,row, action, action.fuse2App)" 
                                                    >
                                                    <mat-icon class="mr-4">{{ action.icon || 'apps' }}</mat-icon>
                                                    {{ action.default_label | translate }}
                                                </button>
                                            </div>
                                        <!-- <div  fxLayout="row wrap" *ngFor="let action of appData.actionList">
                                            <button mat-raised-button *ngIf="action.placement && action.placement.recordActions && !action.icon" style="text-transform: capitalize;" style="margin-left: 12px;"
                                                (click)="actionMoveTo(rowIndex, action.application,row, action, action.fuse2App)">
                                                {{ action.default_label | translate }}
                                            </button>
                                            <button [matTooltip]="action.default_label | translate" mat-icon-button *ngIf="action.placement && action.placement.recordActions && action.icon" style="text-transform: capitalize;" style="margin-left: 12px;"
                                                (click)="actionMoveTo(rowIndex, action.application,row, action, action.fuse2App)">
                                                <mat-icon>{{action.icon}}</mat-icon>                                                
                                            </button>
                                        </div> -->
                                        <button *ngIf="appData && !appData.hideEditButton" aria-label="datable_workspace_edit" mat-raised-button style="text-transform: capitalize;" style="margin-left: 12px;" (click)="moveTo('./'+row._id)">
                                            {{ 'dttable.edit' | translate }}
                                        </button>
                                    </div>
                                    <div style="width: 100%; margin-right: 15px; justify-content: flex-end;" *ngIf="datatableService && datatableService.applicationType !== 'workspace'" fxLayout="row">
                                        <div style="width: 100%; justify-content: flex-end;" *ngIf="col.name=='Actions' || col.title === 'Actions'" fxLayout="row">
                                            <div *ngFor="let button of col.render">
                                                <div *ngIf="button.application !== 'app.pinboxOfficeContacts2.dashboards.crm' && button.placement.recordActions">
                                                    <div *ngIf="button.icon">
                                                        <button mat-icon-button *ngIf="button.icon" style="color: black" (click)="actionMoveTo(rowIndex, button.application,row, button, button.fuse2App)" color="primary">
                                                            <mat-icon>{{button.icon}}</mat-icon>
                                                        </button>
                                                    </div>
                                                    <div *ngIf="!button.icon">
                                                        <button class="m-8 ml-4" [attr.aria-label]="button.translations[currentLang] || button.default_label" mat-raised-button
                                                            (click)="actionMoveTo(rowIndex, button.application,row, button, button.fuse2App)">
                                                            {{ (button.translations[currentLang] | translate) || (button.default_label | translate)}}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="enableMoreActionIcon">
                                            <button mat-icon-button [matMenuTriggerFor]="actions">
                                                <mat-icon> more_vert </mat-icon>
                                            </button>
                                            <mat-menu #actions="matMenu">
                                                <div *ngFor="let button of col.render">
                                                    <div *ngIf="button.application !== 'app.pinboxOfficeContacts2.dashboards.crm' && button.placement.dropdownActions">
                                                        <div *ngIf="button.icon">
                                                            <button mat-icon-button *ngIf="button.icon" style="color: black" (click)="actionMoveTo(rowIndex, button.application,row, button, button.fuse2App)" color="primary">
                                                                <mat-icon>{{button.icon}}</mat-icon>
                                                            </button>
                                                        </div>
                                                        <div *ngIf="!button.icon">
                                                            <button mat-menu-item [attr.aria-label]="button.translations[currentLang] || button.default_label" color="primary"
                                                                (click)="actionMoveTo(rowIndex, button.application,row, button, button.fuse2App)">
                                                                {{ (button.translations[currentLang] | translate) || (button.default_label | translate)}}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-menu>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <!-- Row and cell Template Ends -->
                        </ngx-datatable-column>
                    </ngx-datatable>

                    <div *ngIf="allData && allData.summingColumns && allData.summingColumns.length > 0">

                        <ngx-datatable class="bootstrap" [headerHeight]="0" [rows]="summaryArray"
                            [rowClass]="getRowClass" [loadingIndicator]="loadingIndicator" [columns]="allData.columns"
                            [columnMode]="allData.columnMode" [headerHeight]="allData.headerHeight"
                            [rowHeight]="allData.rowHeight" [externalPaging]="allData.externalPaging"
                            [count]="page.totalElements" [offset]="page.pageNumber" (sort)="onSort($event)"
                            [sorts]="allData.sort" [limit]="(showTotalRow)? (page.size+1): page.size"
                            [footerHeight]="0">

                            <ngx-datatable-column
                                [flexGrow]="(col.control_type && col.control_type.cell_width)? col.control_type.cell_width: ((col.name=='Actions' && allData.actionWidth)? allData.actionWidth: 1) "
                                *ngFor="let col of allData.columns; let colIndex=index" fxFlex [prop]="col.prop"
                                [name]="col.title">
                                <ng-template let-value="value" class="happ-dummy" let-rowIndex="rowIndex" let-row="row"
                                    style="color: blue;" ngx-datatable-cell-template>
                                    <p *ngIf="(value || value === 0)? true : false" class="happ-full-area"
                                        [ngClass]="{'happ-cell-summary': (value || value === 0)? true : false}">

                                        {{ 'dttable.sum' | translate }} {{value}}
                                    </p>

                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                    class="happ-hide-footer" let-pageSize="pageSize" let-selectedCount="selectedCount"
                                    let-curPage="curPage" let-offset="offset">
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
                <div *ngIf="loadingIndicator" fxLayoutAlign="center center">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="display: flex; height: 70vh; justify-content: center; align-items: center; background: white;"
    *ngIf="loadingIndicator">
    <mat-spinner></mat-spinner>
</div>
<app-side-menu-edit></app-side-menu-edit>