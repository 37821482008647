﻿import { Component, Inject, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CalendarEvent } from 'angular-calendar';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CalendarEventModel } from '../event.model';
import { FormComponentService } from '../../../global-component/form-component/form-component.service';
import { MatColors } from '@fuse/mat-colors';

@Component({
  selector     : 'fuse-calendar-event-form-dialog',
  templateUrl  : './event-form.component.html',
  styleUrls    : ['./event-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FuseCalendarEventFormDialogComponent implements OnInit, AfterViewInit {
  
  event: CalendarEvent;
  dialogTitle: string;
  eventForm: FormGroup;
  action: string;
  presetColors = MatColors.presets;

  constructor(
    public dialogRef: MatDialogRef<FuseCalendarEventFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: FormBuilder,
    private formComponentService: FormComponentService,

  )
  {
    this.event = data.event;
    this.action = data.action;
    
    this.formComponentService.onFormDataChanged.next({
      regId: data['regId'] || '',
      saveButton: true,
      operation: this.action,
      defaultValue: data['defaultValue'],
      popupOptions: {
        dialogRef,
        // courseSteps: this.course['appList'],
        // stepNo: step
      }
    });

    if ( this.action === 'edit' )
    {
      this.dialogTitle = this.event.title;
      if (data && data['event'] && data['event']['_id']) {
        this.formComponentService.onRecIdChanged.next(data['event']['_id']);
      }
    }
    else
    {
      this.dialogTitle = 'New Event';
      this.event = new CalendarEventModel({
        start: data.date,
        end  : data.date
      });
    }

  }

  ngOnInit()
  {
  }

  ngAfterViewInit(): void {
  }

}
