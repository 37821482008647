import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { IOfficeData } from 'app/main/content/interfaces/office.interface';

@Component({
  selector: 'app-excel-import',
  templateUrl: './excel-import.component.html',
  styleUrls: ['./excel-import.component.scss']
})
export class ExcelImportComponent implements OnInit {
  @Input() model: any;
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();

  importRegId: string;
  regRecList = [];
  constructor(
    private helperService: HelpersService,
    private officeHelper: OfficeHelpersService
  ) { }

  ngOnInit() {
    this.officeHelper.getLocalOfficeData()
    .then((officeData: IOfficeData) => {
      officeData.apps = officeData.apps || {};
      this.importRegId = (officeData.apps.import && officeData.apps.import.id) || '';
      this.getRegRecList();
    }).catch(err => {
      console.log(err);
      this.helperService.openSnackBar(err);
    });
  }

  getRegRecList() {
    this.helperService.getRequest(`api/reg/${this.importRegId}/list`)
    .then((data) => {
      this.regRecList = data || [];
    }).catch(err => {
      this.helperService.openSnackBar(err);
      console.log(err);
    });
  }

  onSelect(rec: any) {
    this.model = {
      importRecId: (rec && rec['_id']),
      importRegId: this.importRegId,
      name: (rec && rec.label)
    };
    this.modelChange.next(this.model);
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
  }
  
}
