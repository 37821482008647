import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss']
})
export class DeliveryAddressComponent implements OnInit, OnDestroy, OnChanges {

  @Input() field;
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() regRec;
  @Input() builder;
  @Input() model: string; 
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() updateAddress: EventEmitter<Object> = new EventEmitter();
   
    address;
    query = '';
    result;
    city;
    street;
    nip;
    regId;
    searchMultiFileds;
    ext_nip_no;
    filteredData;
    officeId;
    edit = false;
    message;
    manualData = {
      shortName:      '',
      longName:       '',
      companyName:    '',
      companyAdress:  '',
      companyCity:    '',
      postalcode:     '',
    };
  loading = false;
    constructor(
      private translationLoader: FuseTranslationLoaderService,
      private helperService: HelpersService
    ) { }
    
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);
      if (!this.model) {
        this.edit = true;
      }
    }
  }
  
    ngOnInit() {
      this.translationLoader.loadTranslationsV2(this.translateData);

      this.regRec = this.regRec || {};

      this.manualData = {
        shortName:      this.regRec['shortName'] || '',
        longName:       this.regRec['longName'] || '',
        companyName:    this.regRec['companyName'] || '',
        companyAdress:  this.regRec['companyAdress'] || '',
        companyCity:    this.regRec['companyCity'] || '',
        postalcode:     this.regRec['postalcode'] || '',
      };

      if (localStorage.getItem('ngStorage-selectedOffice') &&
        JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && 
        JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps'] && 
        JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps']['office_addressbook'] &&
        JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps']['office_addressbook']['id']
        ) {
          this.officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
          this.regId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps']['office_addressbook']['id'];
      } else {
        console.log('No office Data found');
      }

      if (!this.model) {
        this.edit = true;
      }
    }
  
    ngOnDestroy(): void {
      this.result = [];
      this.edit = false;
    }

  onKeyPress(option) {
    if (option !== 'manual' && option && Object.keys(option).length) {
      this.edit = false;
      this.updateAddress.emit({
        companyAdress:  (option['companyAdress'] || ''),
        companyCity:    (option['companyCity'] || ''),
        shortName:      (option['shortName'] || ''),
        longName:       (option['longName'] || ''),
        postalcode:     (option['postalcode'] || ''),
        companyName:    (option['companyName'] || ''),
      });
      this.manualData['companyAdress'] = (option['companyAdress'] || '');
      this.manualData['companyCity'] = (option['companyCity'] || '');
      this.manualData['shortName'] = (option['shortName'] || '');
      this.manualData['longName'] = (option['longName'] || '');
      this.manualData['postalcode'] = (option['postalcode'] || '');
      this.manualData['companyName'] = (option['companyName'] || '');
    } else {
      if (this.field.deliveryAddressSelection === 'manualDeliveryAddress') {
      // tslint:disable-next-line:max-line-length
      this.model = (this.manualData['companyName'] || '') + ' ' + (this.manualData['postalcode'] || '') + ' ' + (this.manualData['longName'] || '') + ' ' + (this.manualData['shortName'] || '') + ' ' + (this.manualData['companyCity'] || '') + ' ' + (this.manualData['companyAdress'] || '');
      this.modelChange.next(this.model);
        this.updateAddress.emit({
          companyAdress:  (this.manualData['companyAdress'] || ''),
          companyCity:    (this.manualData['companyCity'] || ''),
          shortName:      (this.manualData['shortName'] || ''),
          longName:       (this.manualData['longName'] || ''),
          postalcode:     (this.manualData['postalcode'] || ''),
          companyName:    (this.manualData['companyName'] || ''),
        });
      }
    }
    this.modelChange.next(this.model);
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
  }

  search() {
    if (this.regId && this.officeId) {
      this.loading = true;
      this.helperService.getRequest(this.getURL())
        .then((data) => {
          this.loading = false;
          this.result = data || [];
          if (!this.result) {
            this.message = 'Nie znaleziono danych tego wyszukiwania';
            return;
          } else {
            this.message = this.result.length + ' znalezionych danych';
          }
          this.result = this.result.map((element: any) => {
            element = element || {};
            // tslint:disable-next-line:max-line-length
            element.allAddress = `${element['companyName'] || ''} ${element['postalcode'] || ''} ${element['longName'] || ''} ${element['shortName'] || ''} ${element['companyCity'] || ''} ${element['companyAdress'] || ''}`;
            // tslint:disable-next-line:max-line-length
            // return (element['companyName'] || '') + ' ' + (element['postalcode'] || '') + ' ' + (element['longName'] || '') + ' ' + (element['shortName'] || '') + ' ' + (element['companyCity'] || '') + ' ' + (element['companyAdress'] || '');
            return element;
          });
        }).catch(err => {
          this.loading = false;
          this.message = err;
          console.log(err);
        });
    } else {
      console.log('No office or reg Id found');
      this.message = 'No office or reg Id found';
    }
  }

  getURL(): string {
    if (this.field.deliveryAddressSelection === 'cityAndStreet') {
      return `api/offices/contacts/lists/search?companyAdress=${this.street}&companyCity=${this.city}&officeId=${this.officeId}&regId=${this.regId}`;
    } else if (this.field.deliveryAddressSelection === 'searchNip') {
      return `api/offices/contacts/lists/search?nip=${this.nip}&officeId=${this.officeId}&regId=${this.regId}`;
    } else if (this.field.deliveryAddressSelection === 'searchMultiFileds') {
      return `api/offices/contacts/lists/search?searchMultiFields=${this.searchMultiFileds}&officeId=${this.officeId}&regId=${this.regId}`;
    } else if (this.field.deliveryAddressSelection === 'ext_nip_no') {
      return '';
    }
  }

  validateNip(e, value) {
    const specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
    const current = value.value || '';
    const next = current + e.key;
    if (specialKeys.includes(e.key)) {
      return;
    }
    if (this.field && this.field.onlyNumber) {
      const reg = new RegExp(`^(0|[1-9][0-9]*)$`);
      if (!reg.test(next)) {
        e.preventDefault();
      }
    }
    if (this.field && (this.field.maxDigit || this.field.maxDigit === 0)) {
      if (next && next.length > this.field.maxDigit) {
        e.preventDefault();
      }
    }
  }

}
