import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation, FuseNavigationMenuService } from 'app/navigation/navigation';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AuthService } from 'app/main/content/pages/authentication/auth.service';
import { MatDialog } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import { SystemInfoComponent } from 'app/main/content/pages/system-info/system-info.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { version } from 'environments/version';
import { SocketService } from 'app/main/content/services/socket/socket.service';
import { InvitationListService } from 'app/main/content/pages/invitation-list/invitation-list.service';
import { PlatformIssueReporterComponent } from 'app/main/content/global-component/platform-issue-reporter/platform-issue-reporter.component';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
declare var test: any;

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    invitationCount = 0;
    navigation: any;
    selectedLanguage: any;
    ADMIN_EMAILS = [
        'luk.gaw2@gmail.com',
        'suryanshsinghstudy@gmail.com',
        'radieu@gmail.com',
        'sv.sunnysharma@gmail.com',
        'f.giera@microsystem.com.pl'
    ];
    userStatusOptions: any[];

    // Private
    private _unsubscribeAll: Subject<any>;

    
    showLoadingBar: boolean;
    horizontalNav: boolean;
    username: any;
    selectedOfficeName: String;
    home = '/dashboard';
    officeList: Array<object>;
    toolBarShortcuts = [];

    subscription: Subscription;
    version: any;
    error: any;
    changedOfficeName = null;

    constructor(
        private router: Router,
        private authService: AuthService,
        private translate: TranslateService,
        private navigationService: FuseNavigationMenuService,
        public dialog: MatDialog,
        private helperService: HelpersService,
        private profileHelperService: ProfileHelperService,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private fuseConfig: FuseConfigService,
        private translationLoader: FuseTranslationLoaderService,
        private socketService: SocketService,
        private _invitationService: InvitationListService,
        private _officeHelper: OfficeHelpersService
    )
    {
        const loc = window.location.pathname;
        this.authService.loadUserCredentials();
        test['tt'] = this;
        // Set the defaults
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon' : 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon' : 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon' : 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon' : 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon' : 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = [
            {
                'id': 'en',
                'title': 'English',
                'flag': 'us'
            },
            {
                'id': 'pl',
                'title': 'Polish',
                'flag': 'pl'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        if (loc.includes('external-widget')) {
            console.log('nothing to check');
        } else {
            if (!this.authService.isLoggedIn()) {
                if (!(loc === '/login' || loc === '/register' || loc === '/forgot-password' || loc.includes('/set-password') || loc.includes('/changepassword'))) {
                    router.navigate(['/login']);
                }
            } else {
                if (loc === '/login' || loc === '/register' || loc === '/forgot-password' || loc.includes('/set-password') || loc.includes('/changepassword')) {
                    if (!localStorage.getItem('ngStorage-token')) {
                        router.navigate(['/login']);
                    }
                    if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
                        const lastOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
                        this.changeOffice(lastOffice);
                    } else {
                        router.navigate(['/dashboard']);
                    }
                }
            }

            // profile info
            if (localStorage.getItem('ngStorage-profile')) {
                const profile = JSON.parse(localStorage.getItem('ngStorage-profile'));
                this.username = profile['email'];
                if (localStorage.getItem('ngStorage-selectedOffice')) {
                    this.selectedOfficeName = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['name'];
                    if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['admins']) {
                        this.navigationService.isAdmin = false;
                        JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['admins'].forEach(user => {
                        if (profile && user['id'] === profile['profileId'] ) {
                            // is admin
                            this.navigationService.isAdmin = true;
                        }
                    });
                    }
                }
            }

        }
        // this.subscription    = this.authService.getUsername()
        // .subscribe(username => {
        //     alert(username);
        // })
 
        this.subscription = this.authService.onUserNameChanged
        .subscribe(username => {
             if (username){
                const lastOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
            }

            if (localStorage.getItem('ngStorage-token')) {
                this.username = JSON.parse(localStorage.getItem('ngStorage-token'))['username'];
            }

            // tslint:disable-next-line:max-line-length
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['users'] && JSON.parse(localStorage.getItem('ngStorage-profile'))) {
                const profileInfo = JSON.parse(localStorage.getItem('ngStorage-profile'));            
                const officeUsers = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['users'];   
                const userIndex = _.findIndex(officeUsers, { id: profileInfo['profileId']});
                if (officeUsers && (userIndex || userIndex === 0) && officeUsers[userIndex]) {
                    this.toolBarShortcuts = officeUsers[userIndex]['toolbarShortcut'] || [];
                }
            }

            if (localStorage.getItem('ngStorage-profile')) {
                const profile = JSON.parse(localStorage.getItem('ngStorage-profile'));
                this.username = profile['email'];
                if (localStorage.getItem('ngStorage-selectedOffice')) {
                    this.selectedOfficeName = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['name'];
                }
            }

             });

        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon' : 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon' : 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon' : 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon' : 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon' : 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];


        // localStorage.setItem("selectedLanguage", lang.id);
        this.selectedLanguage = this.languages[0];
        if (localStorage.getItem('selectedLanguage')) {
            this.languages.forEach(lang => {
                if (lang.id === localStorage.getItem('selectedLanguage')) {
                    this.selectedLanguage = lang;
                }
            });
        }
        // this.selectedLanguage = localStorage.getItem("selectedLanguage");

        router.events.subscribe(
            (event) => {
                if ( event instanceof NavigationStart )
                {
                    this.showLoadingBar = true;
                }
                if ( event instanceof NavigationEnd )
                {
                    this.showLoadingBar = false;
                }
            });

        // this.fuseConfig.onSettingsChanged.subscribe((settings) => {
        //     this.horizontalNav = settings.layout.navigation === 'top';
        // });

        
        this.selectedLanguage = {
            'id': 'en',
            'title': 'English',
            'flag': 'us'
        };

        this.getActiveLangs()
        .then((data) => {
            data = data.map(ele => {
                return {
                    id: ele.code,
                    title: ele.name,
                    flag: ele.code
                };
            });
            this.languages = data || [];
            
            if (localStorage.getItem('selectedLanguage')) {
                this.languages.forEach(lang => {
                    if (lang.id === localStorage.getItem('selectedLanguage')) {
                        this.selectedLanguage = lang;
                    }
                });
            }

        }).catch(err => {
            console.log(err);
        });

        router.events.subscribe(
            (event) => {
                if (event instanceof NavigationStart) {
                    this.showLoadingBar = true;
                }
                if (event instanceof NavigationEnd) {
                    this.showLoadingBar = false;
                }
            });

        // this.fuseConfig.onSettingsChanged.subscribe((settings) => {
        //     this.horizontalNav = settings.layout.navigation === 'top';
        // });

        // this.translationLoader.loadTranslations(english, polish);
        this.helperService.getTrans('toolbar')
        .then((data2) => {
            this.translationLoader.loadTranslationsV2(data2);
        }).catch(err => {
            console.log(err);
        });

        this._invitationService.invitationCountChanged.subscribe((data) => {
            if (data) {
                this.invitationCount = data;
            }
        });

        this.socketService.inviationNotify().subscribe( (data) => {
            this.invitationCount = (data && data.inviatationCount) || 0;
        });

        this.invitationCount = this.getInitInvitationCount();

    }

    getActiveLangs(): Promise<any> {
        return this.helperService.getRequest(`api/i18n/langs`)
        .then((data) => {
            data = data || [];
            data = data.filter(ele => ele.active);
            return data;
        });
    }

    ngOnInit() {

        window.onfocus = () => {
            this._officeHelper.getLocalOfficeData().then((officeData) => {
                if (this.selectedOfficeName !== officeData.name) {
                    this.changedOfficeName = officeData.name;
                    this._fuseConfigService.config = {
                        layout: {
                            navbar: {
                                hidden: true
                            },
                            toolbar: {
                                hidden: false
                            }
                        }
                    };
                    console.log('office Changed in some tab');
                } else {
                    this.changedOfficeName = null;
                }
            }).catch(err => {
                this.changedOfficeName = null;
                console.log(err);
            });
        };
        
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

            const langId = localStorage.getItem('selectedLanguage');

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {'id': langId || this._translateService.currentLang});

        this.version = version;
        this.authService.onOfficeChange
        .subscribe(() => {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['name']) {
            this.selectedOfficeName = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['name'];
            }
        });

    }

    switchOffice() {
        this._officeHelper.getLocalOfficeData().then((officeData) => {
            this.changedOfficeName = null;
            this.changeOffice(officeData);
        }).catch(err => {
            console.log(err);
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    logOut() {
        this.username = undefined;
        this.authService.logOut();
        this.router.navigate(['/login']);
    }

    getInitInvitationCount(): any {
        if (localStorage.getItem('ngStorage-profile') && JSON.parse(localStorage.getItem('ngStorage-profile'))) {
            return JSON.parse(localStorage.getItem('ngStorage-profile')).inviatationCount || 0;
        } else {
            return 0;
        }
    }

    addIssue(type): void {
        this.dialog.open(PlatformIssueReporterComponent, {
          width: 'max-content',
          maxWidth: '80%',
          height: 'max-content',
          restoreFocus: false,
          panelClass: 'happ-form-builder-dialog',
          data: { reporterType: type }
        });
      }

    changeOffice(office) {
        if (office) {
            // this.selectedOfficeName = office['name'];
            this.authService.storeOffice(office['_id'] || office['officeId'])
            .then(data => {
                if (office['_id'] || office['officeId']) {
                    this.router.navigate(['/offices/' + (office['_id'] || office['officeId']) + '/dashboard']);
                    this.officeChanged( office['_id'] || office['officeId']);
                }
                // tslint:disable-next-line:max-line-length
                if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['users'] && JSON.parse(localStorage.getItem('ngStorage-profile'))) {
                    const profileInfo = JSON.parse(localStorage.getItem('ngStorage-profile'));            
                    const officeUsers = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['users'];   
                    const userIndex = _.findIndex(officeUsers, { id: profileInfo['profileId']});
                    if (officeUsers && (userIndex || userIndex === 0) && officeUsers[userIndex]) {
                        this.toolBarShortcuts = officeUsers[userIndex]['toolbarShortcut'] || [];
                    }
                }
                // this.navigationService.getSideMenuList();
            })
            .catch(err => {
                console.log(err);
            });
        }
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
        this.updateProfileForLang(lang);
    }

    updateProfileForLang(lang) {
        this.helperService.updateProfileData({ defaultLang: lang.id })
        .catch(err => {
            this.helperService.openSnackBar(err);
        });
    }
    
    getOfficeList(): void
    {
        //   this.http.get(`${environment.serverUrl}api/offices/list`)
        //   .subscribe((data) => {
              if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['offices']) {
                this.officeList = JSON.parse(localStorage.getItem('ngStorage-profile'))['offices'];
              } else {
                this.error = 'some error occured';
              }
        //   });
    }

    systemInfo(): void {
        this.dialog.open(SystemInfoComponent, {
            width: '60%',
            height: '60%',
              panelClass: 'happ-form-builder-dialog',
            data: { }
        });
    }

    officeChanged(officeId): Promise<any> {
        return this.profileHelperService.updateProfileDoc({lastSelectedOffice: officeId});    
    }

     /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void
    {
        this._fuseSidebarService.getSidebar('navbar').open();
    }
}
