/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./position.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../datatable/datatable.component.ngfactory";
import * as i5 from "../../datatable/datatable.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../../../../@fuse/services/translation-loader.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../datatable/datatable.service";
import * as i10 from "@angular/material/snack-bar";
import * as i11 from "@angular/material/dialog";
import * as i12 from "../../../services/helpers/helpers.service";
import * as i13 from "../../../services/office-helpers/office-helpers.service";
import * as i14 from "@angular/common";
import * as i15 from "./position.component";
var styles_PositionComponent = [i0.styles];
var RenderType_PositionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PositionComponent, data: {} });
export { RenderType_PositionComponent as RenderType_PositionComponent };
function View_PositionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["fxFlex", ""], ["style", "width: 100%;"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-datatable", [], null, [[null, "updateOnSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updateOnSave" === en)) {
        var pd_0 = (_co.updateOnSave($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_DatatableComponent_0, i4.RenderType_DatatableComponent)), i1.ɵdid(3, 4964352, null, 0, i5.DatatableComponent, [i6.Router, i6.ActivatedRoute, i7.FuseTranslationLoaderService, i8.TranslateService, i9.DatatableService, i10.MatSnackBar, i11.MatDialog, i12.HelpersService, i1.ChangeDetectorRef, i13.OfficeHelpersService], { regId: [0, "regId"], fieldData: [1, "fieldData"], calculateOnSave: [2, "calculateOnSave"] }, { updateOnSave: "updateOnSave" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.positionRegId; var currVal_2 = _co.field; var currVal_3 = _co.field.calculateOnSave; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PositionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 2, 0, currVal_0); }); }
function View_PositionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PositionComponent_2)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PositionComponent_3)), i1.ɵdid(5, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.options; _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.options && !_co.loading); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_PositionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PositionComponent_1)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.field.hide || (_co.builder && _co.options)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PositionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-position", [], null, null, null, View_PositionComponent_0, RenderType_PositionComponent)), i1.ɵdid(1, 4308992, null, 0, i15.PositionComponent, [i9.DatatableService, i11.MatDialog, i10.MatSnackBar, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PositionComponentNgFactory = i1.ɵccf("app-position", i15.PositionComponent, View_PositionComponent_Host_0, { field: "field", builder: "builder", regRec: "regRec", recId: "recId", signature: "signature", regId: "regId", model: "model", translateData: "translateData" }, { onFieldValueUpdated: "onFieldValueUpdated", modelChange: "modelChange", updateParentOnSave: "updateParentOnSave", getRecord: "getRecord" }, []);
export { PositionComponentNgFactory as PositionComponentNgFactory };
