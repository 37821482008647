<!-- <mat-form-field class="mx-4" fxFlex *ngIf="resData">
    <mat-select (selectionChange)="onChange()" [(ngModel)]="model" placeholder="{{field.title}}">
        <div *ngFor="let regItem of resData">
            <mat-option [value]="regItem._id">
                {{ regItem[field['field_key']] }}
            </mat-option>
        </div>
    </mat-select>
</mat-form-field> -->
<div *ngIf="!recId || recId === 'new'">
    <h3>first save record then edit to have options.</h3>
</div>
<div *ngIf="recId && recId !== 'new'" fxLayout="column">
    <div *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">
    </div>
    <div fxLayout="row">
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{field.title}}</mat-label>

            <input matInput (keydown.Tab)="getDataFromServer(searchMultiFileds)" placeholder="{{field.title}}" [(ngModel)]="searchMultiFileds">
        </mat-form-field>
        <!-- <button (click)="getDataFromServer(searchMultiFileds)" mat-icon-button color="primary">
            <mat-icon>search</mat-icon>
        </button> -->
        <mat-spinner-button style="margin: auto; margin-top: 16px; margin-left: 5px;"
            [options]="buttonOptions"
            (btnClick)="getDataFromServer(searchMultiFileds)">
        </mat-spinner-button>
    </div>

    <div fxLayout="column">
        <h4 style="display: inline" matTooltip="Click to edit" (click)="showList=!showList" *ngIf="model && field && !field.multiSelect">
            <span *ngIf="field && field['field_to_show']">
                    <span *ngFor="let i of field['field_to_show']">
                        {{(model['value'] && model['value'][i])}} ,
                    </span>
            </span>
        </h4>
        <div>
        </div>
        <div class="happ-list-field" fxLayout="column" *ngIf="resData && showList">
            <div *ngIf="(resData && resData.length === 0)">
                <h3>Brak rekordów o takiej nazwie</h3>
            </div>
            <div style="overflow: auto; height: 500px;" *ngIf="resData">
                <mat-nav-list>
                    <a  (click)="selectVal(option)" *ngFor="let option of resData" mat-list-item>
                        <mat-icon style="font-size: 32px; color: #16a716" class="mr-8" *ngIf="model == option || allPushed[option._id]">check_circle</mat-icon>

                        <span *ngIf="field && field['field_to_show']">
                            <span *ngFor="let i of field['field_to_show']; let tt=index">
                                {{option[i]}}
                                {{((field['field_to_show'].length - 1) === tt)? '' : ', ' }}
                            </span>
                        </span>
                    </a>
                </mat-nav-list>
                <!-- <mat-list role="list" fxLayout="column">
                    <mat-list-item style="cursor: pointer;" role="listitem" (click)="selectVal(option)" *ngFor="let option of resData">
                            <mat-icon style="font-size: 32px; color: #16a716" class="mr-8" *ngIf="model == option || allPushed[option._id]">check_circle</mat-icon>

                            <span *ngIf="field && field['field_to_show']">
                                <span *ngFor="let i of field['field_to_show']; let tt=index">
                                    {{option[i]}}
                                    {{((field['field_to_show'].length - 1) === tt)? '' : ', ' }}
                                </span>
                            </span>
                    </mat-list-item>
                </mat-list> -->
            </div>
        </div>
    </div>
</div>