import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FieldOptionUpdateDialogComponent } from 'app/main/content/components/field-option-update-dialog/field-option-update-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-option-based-reg-value-multi-selection',
  templateUrl: './option-based-reg-value-multi-selection.component.html',
  styleUrls: ['./option-based-reg-value-multi-selection.component.scss']
})
export class OptionBasedRegValueMultiSelectionComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() field;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() model;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Input() translateData: any;
  @Input() hideTitle: boolean;
  @Input() builder;
  @Input() formData: any;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  loading = false;
  error = false;
  BACKGROUND_COLOR = 'white';
  FONT_COLOR = 'black';
  selectedValues = [];
  errorMessage = '';
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private _helperService: HelpersService

  ) {
    this.model = this.model || '';
  }

  
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

  getOptions() {
    if (this.field.regId && this.field.optionTitle) {
      const cols = [
        {
          prop: this.field.optionTitle,
          title: this.field.optionTitle
        },
        {
          prop: '_id',
          title: '_id'
        }
      ];

      this.loading = true;
      this._helperService.getDataForOptionBasedField(this.field.regId, cols, this.field.hardFilter, this.field.sortBy)
      .then((data) => {
        data = data || {};
        data['dttable'] = data['dttable'] || {};
        data['dttable']['data'] = data['dttable']['data'] || [];
        this.field.options = data['dttable']['data'].map((ele) => ele[this.field.optionTitle]) || [];
        this.loading = false;
        this.changeDetectorRef.detectChanges();

      }).catch(err => {
        console.log(err);
        this.loading = false;
        this.changeDetectorRef.detectChanges();

      });
    } else {
      this._helperService.openSnackBar('regId and fieldTitle is reqiured for option base reg value multi select field to work');
    }
    
  }

  onChange(option: string) {
    if (this.selectedValues.includes(option)) {
      this.selectedValues.splice(this.selectedValues.indexOf(option), 1);
    } else {
      this.selectedValues.push(option);
    }
    this.model = this.selectedValues.join(',');
    this.modelChange.next(this.model);

    if (this.field['required'] && (this.model === null || this.model === undefined || this.model === '')) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    } else {
      this.errorMessage = '';
      this.error = false;
      this.onError.emit({
        error: false,
        label: this.field['label']
      });
    }
    if (this.field.haveRules) {
      this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
      this.changeDetectorRef.detectChanges();

    }
  }
  
  ngAfterViewInit() {
    this.model = this.model || '';
    this.selectedValues = this.model.split(',');
    this.selectedValues = this.selectedValues.filter(ele => (ele && ele.trim()));
  }

  ngOnInit() {
    this.getOptions();
    
    this.changeDetectorRef.markForCheck();
    this.translationLoader.loadTranslationsV2(this.translateData);
    if (this.field['required'] && (this.model === null || this.model === undefined || this.model === '')) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    }
  }
}
