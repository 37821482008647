import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router, Params } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from 'environments/environment';
import { IRegister, IWorkspaceUser } from '../../interfaces/register.interface';
import { ProfileHelperService } from '../../services/profile-helper/profile-helper.service';
import { IProfile } from '../../interfaces/profile.interface';
import { HelpersService } from '../../services/helpers/helpers.service';

@Injectable()
export class WorkspaceService implements Resolve<any>
{
  onWorkspaceChanged: BehaviorSubject<any> = new BehaviorSubject({});
  onWorkspaceAppSettingsChanged: BehaviorSubject<any> = new BehaviorSubject({});
  onFormIdChanged: BehaviorSubject<any> = new BehaviorSubject('');
  onstepChanged: BehaviorSubject<any> = new BehaviorSubject('');
  onEditForm: BehaviorSubject<any> = new BehaviorSubject(false);
  refreshView: BehaviorSubject<any> = new BehaviorSubject(false);
  params: Params;
  workspaceData: IRegister;
  isAdmin = false;
  currentUserId: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private profileHelperService: ProfileHelperService,
    private helperService: HelpersService
  )
  { }

  /**
   * The Academy App Main Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
      return new Promise((resolve, reject) => {
        this.params = route.params;
        this.getWorkspace(route.params.workspace)
        .then((data) => {
          this.checkAdmin();
          resolve(data);
        }).catch(err => {
          reject(err);
        });
      });
  }

  getWorkspace(workspaceSlug: string): Promise<object>
  {
      return new Promise((resolve, reject) => {
        this.helperService.getRequest(`api/offices/work-space/${workspaceSlug}`)
        .then((data) => {
          data['appList'] = this.transformSort(data['appList'], 'stepNumber');
          this.workspaceData = data || {};
          this.onWorkspaceChanged.next(data);
          resolve(data);
        }).catch(reject);
      });
  }

  transformSort(array: any[], field: string): any[] {
    if (!array || array && array.length === 0) {
    return [];
    }
    array.sort((a: any, b: any) => {
      if (a && b && a[field] < b[field]) {
        return -1;
      } else if (a && b && a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

  getCurrentUser(): Promise<string> {
    return this.profileHelperService.getLocalProfileData()
    .then((profileData: IProfile) => profileData.profileId);
  }

  checkAdmin() {
    this.isAdmin = false;
    this.getCurrentUser()
    .then(profileId => {
      this.currentUserId = profileId;
      this.workspaceData.users = this.workspaceData.users || [];
      this.workspaceData.users.forEach((workspaceUser: IWorkspaceUser) => {
        // if (workspaceUser.userId === this.currentUserId && (workspaceUser.role === 'admin' || workspaceUser.role === 'standard')) {
        if (workspaceUser.userId === this.currentUserId && (workspaceUser.role === 'admin')) {
          this.isAdmin = true;
        }
      });
    }).catch(err => {
      console.log(err);
    });
  }
}
