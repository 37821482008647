
<div fxLayout="column" *ngIf="!field.hide || builder">
    <div fxLayout="row">
        <mat-form-field appearance="outline" fxFlex>
            <mat-label> {{ field.title }} </mat-label>
            <input type="{{field.inputType}}" [attr.aria-label]="field.label" autofocus="true" matInput minlength="{{field.min_length}}" maxlength="{{field.max_length}}" [disabled]="field.showdisablecontroltype=='true' || field.disable" (paste)="pasteIt($event)" (keyup)="onKeyPress()" [hidden]="field.hidden"
            [(ngModel)]="model">
          <mat-hint align="start"> 
              {{ field.description }}
            </mat-hint>
            <div class="error-message" *ngIf="errorMessage && (!min_error && !max_error)">
                {{ errorMessage | translate }}
            </div>
            <div class="error-message" *ngIf="min_error">
                {{ errorMessage | translate }} {{ field.min_length }}
            </div>
            <div class="error-message" *ngIf="max_error">
                {{ errorMessage | translate }} {{ field.max_length }}
            </div>
        </mat-form-field>
    </div>
    
    <div fxLayout="row" style="color: #cc3333;">
        <!-- <mat-icon *ngIf="error" style="color: #cc3333;" class="ml-12">warning</mat-icon> -->
        <p *ngIf="min_error"> Min length Allowed is {{ field.min_length }} </p>
        <p *ngIf="max_error"> Max length Allowed is {{ field.max_length }} </p>
        <!-- <p *ngIf="errorMessage"> {{ errorMessage }} </p> -->
    </div>
</div>