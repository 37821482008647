import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { md5 } from '../md5';
import { LanguagesInterface } from 'app/main/content/interfaces/languages.interface';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { SetDefaultLanguageService } from 'app/main/content/services/set-default-language/set-default-language.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { LanguagesModel } from 'app/main/content/models/languages.model';
import { confirmPasswordValidator } from '../register/register.component';
import { version } from 'environments/version';

@Component({
    selector     : 'register-2',
    templateUrl  : './register-2.component.html',
    styleUrls    : ['./register-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class Register2Component implements OnInit, OnDestroy
{
    private _unsubscribeAll: Subject<any>;
    registerForm: FormGroup;
    registerFormErrors: any;
    selectedLanguage: any;
    errMess: String = '';
    version = '';
    selectedLangCode;
    languages: Array<LanguagesInterface>;
    
    constructor(
        private authService: AuthService,
        private router: Router,
        public snackBar: MatSnackBar,
        private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
        private setDefaultLanguageService: SetDefaultLanguageService,
        private helperService: HelpersService,
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder
    )
    {
        this.version = version;

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.registerFormErrors = {
            email          : {},
            password       : {},
            passwordConfirm: {}
        };

        this.languages = LanguagesModel;
        this.helperService.getDefaultLangs()
        .then((lang) => {
            this.languages = lang || [];
        }).catch(err => {
            console.log(err);
        });
        this.setDefaultLanguageService.setDefaultLanguage();
      
        this.selectedLanguage = this.languages[0];
          this.selectedLangCode = this.selectedLanguage['id'];

        if (localStorage.getItem('selectedLanguage')) {
            this.languages.forEach(lang => {
                if (lang.id === localStorage.getItem('selectedLanguage')) {
                    this.selectedLanguage = lang;
                  this.selectedLangCode = this.selectedLanguage['id'];

                }
            });
        }

        // this.translationLoader.loadTranslations(english, polish);
        this.helperService.getTrans('authentication')
        .then((data2) => {
            this.translationLoader.loadTranslationsV2(data2);
        }).catch(err => {
            console.log(err);
        });
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void
    {
        this.registerForm = this._formBuilder.group({
            email          : ['', [Validators.required, Validators.email]],
            password       : ['', Validators.required],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });

        this.registerForm.valueChanges.subscribe(() => {
            this.onRegisterFormValuesChanged();
        }, err => {
            console.log(err);
          });
    }

    onRegisterFormValuesChanged(): void
    {
        for ( const field in this.registerFormErrors )
        {
            if ( !this.registerFormErrors.hasOwnProperty(field) )
            {
                continue;
            }

            // Clear previous errors
            this.registerFormErrors[field] = {};

            // Get the control
            const control = this.registerForm.get(field);

            if ( control && control.dirty && !control.valid )
            {
                this.registerFormErrors[field] = control.errors;
            }
        }
    }

    signup(): void {
        const user = {
            'email': this.registerForm.get('email').value,
            'password': md5(this.registerForm.get('password').value)
        };
        this.authService.signUp(user)
        .subscribe(res => {
            if (!res['error']) {
                this.snackBar.open('Register Success', 'Login', {
                    duration: 1500,
                });
                this.router.navigate(['/login']);
            } else {
                this.errMess = res['message'];
            }
        },
        error => {
            console.log(error);
            this.errMess = error;
        });
    }


 
    setLanguage(lang): void
    {
        this.languages.map((ele) => {
            if (lang === ele.id) {
                this.selectedLanguage = ele;
            }
        });
        // Set the selected language for toolbar
        // this.selectedLanguage = lang;

        localStorage.setItem('selectedLanguage', this.selectedLanguage.id);
        
        this.translate.setDefaultLang(this.selectedLanguage.id);
        this.translate.use(localStorage.getItem('selectedLanguage') || this.translate.getBrowserLang() || 'en');        
    }

}

function confirmPassword(control: AbstractControl): any
{
    if ( !control.parent || !control )
    {
        return;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if ( !password || !passwordConfirm )
    {
        return;
    }

    if ( passwordConfirm.value === '' )
    {
        return;
    }

    if ( password.value !== passwordConfirm.value )
    {
        return {
            passwordsNotMatch: true
        };
    }
}
