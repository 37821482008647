<div class="page-layout simple fullwidth p-16" fusePerfectScrollbar>
    <div fxLayout="row" class="px-8 py-4" fxLayoutAlign="start center">
        <div>
            <h2>{{ 'document.published_template' | translate }}</h2>
        </div>
        <span fxFlex></span>
        <button class="mx-4" mat-raised-button (click)="closeDialog(null)">
            {{ 'document.cancel' | translate }}
      </button>
    </div>
    <div class="content p-24 fuse-white-bg">
        <div fxLayout="row">
            <div fxFlex *ngIf="data && data['templateList'] && data['templateList'].length > 0">
                <mat-list role="list">
                    <mat-list-item role="listitem" *ngFor="let temp of data['templateList']">
                        <div fxFlex="100">
                            {{ temp.templateId.title }}
                        </div>
                        <span fxFlex></span>
                        <button mat-raised-button class="mr-12" (click)="generateDoc(temp['templateId'])" color="warn">{{ 'document.genrate' | translate }}</button>
                        <button mat-raised-button (click)="newTemplate(temp['templateId'])" color="warn">{{ 'document.edit' | translate }}</button>
                    </mat-list-item>
                </mat-list>
            </div>
            <!-- {{ ṭemplates | json }} -->
            <!-- <div *ngIf="" fxFlex="50">
              <iframe [src]="pdfURL" style="height: 100vh; width: 100%;" frameborder="0"></iframe>
            </div> -->
        </div>
    </div>
</div>