import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import { HelpersService } from './../../../services/helpers/helpers.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IUser } from 'app/main/content/interfaces/office.interface';
import { IProfile } from 'app/main/content/interfaces/profile.interface';

@Component({
  selector: 'app-record-comment',
  templateUrl: './record-comment.component.html',
  styleUrls: ['./record-comment.component.scss']
})
export class RecordCommentComponent implements OnInit, OnChanges {

  @Input() field: any;
  @Input() translateData: any;
  @Input() recId: any;
  @Input() regId: any;

  commentList = [];
  comment = '';
  currentUser: IUser;
  loading = false;
  
  constructor(
    private _translationLoader: FuseTranslationLoaderService,
    private _helperService: HelpersService,
    private _profileHelper: ProfileHelperService
  ) { }

  
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this._translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

  ngOnInit() {
    this._translationLoader.loadTranslationsV2(this.translateData);
    this.getLoggedUser();
    this.getComments();
  }

  keyTest(eve) {
    console.log(eve);
  }

  getLoggedUser() {
    this._profileHelper.getLocalProfileData()
    .then((data: IProfile) => {
      this.currentUser = {
        id: data.profileId,
        email: data.email
      };
    }).catch(err => {
      console.log(err);
    });
  }

  getComments() {
    this.loading = true;
    this._helperService.getRegisterRecordData(this.regId, this.recId)
    .then((data) => {
      data = (data && data[0]) || {};
      this.commentList = data[this.field.label] || [];
      this.commentList = this.transformSortDes(this.commentList, 'creatingTime');
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      console.log(err);
    });
  }

  createNewComment() {
    if (!this.comment) {
      return;
    }
    if (this.currentUser && this.currentUser.email && this.currentUser.id) {
      const commentData = {
        description: this.comment,
        creatorId: this.currentUser.id,
        creator: this.currentUser.email,
        creatingTime: new Date().getTime()
      };
      this.commentList.push(commentData);
      this.commentList = this.transformSortDes(this.commentList, 'creatingTime');
      this.comment = '';
      this.updateComments();
    } else {
      this._helperService.openSnackBar('No User Found');
    }
  }

  removeComment(comment: any, i: number) {
    this._helperService.openConfirmPopUp('popup.confirm_delete_comment_message')
    .subscribe(data => {
      if (data) {
        if (comment && this.currentUser && comment.creatorId === this.currentUser.id) {
          this.commentList.splice(i, 1);
          this.updateComments();
        }
      }
    });
  }

  updateComments() {
    const tt = {};
    tt[this.field.label] = this.commentList || [];
    this._helperService.updateRegisterRecord(this.regId, this.recId, tt)
    .then(() => {
      this._helperService.openSnackBar('Comment Updated');
    }).catch(err => {
      this._helperService.openSnackBar(err);
    });
  }

  transformSortDes(array: any[], field: string): any[] {
    if (!array || array && array.length === 0) {
    return [];
    }
    array.sort((a: any, b: any) => {
      if (a && b && a[field] > b[field]) {
        return -1;
      } else if (a && b && a[field] < b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
