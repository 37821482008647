import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: any;
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  error = false;
  dateData: Date | string;
  errorMessage = '';
  date: Date | string;
  constructor(
    private cdr: ChangeDetectorRef,
    private adapter: DateAdapter<any>,
    private translationLoader: FuseTranslationLoaderService,
    private translationService: TranslateService
    ) {
      this.translationLoader.loadTranslationsV2(this.translateData);

      this.adapter.setLocale(this.translationService.getDefaultLang());
      this.translationService.onLangChange.subscribe((lang) => {
        if (lang && lang.lang) {
          this.adapter.setLocale(lang.lang);
        }
      });
  }

  
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

  ngOnInit() {
    if (this.field['required'] && (!this.model)) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    }
  }

  ondateChange() {
    if (this.date) {
      this.model = moment(this.date).format('YYYY-MM-DD');
      if (!this.field.setTimeDefault) { 
        if (this.field.endDateTime) {
          this.model = this.model + ' 23:59:59';
        } else if (this.field.startDateTime){
          this.model = this.model + ' 00:00:01';        
        }
      }
       this.modelChange.next(this.model);
    }
    if (this.field['required'] && (!this.model)) {
      this.errorMessage = 'ct.field_is_required';
      this.error = true;
      this.onError.emit({
        error: true,
        label: this.field['label']
      });
    } else {
      this.errorMessage = '';
      this.error = false;
      this.onError.emit({
        error: false,
        label: this.field['label']
      });
    }
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
  }

  ngAfterViewInit() {
    if (!this.model) {
      if (this.field.default_value === 'now') {
        this.model = new Date();
        this.date = new Date();
      } else if (this.field.default_value === 'empty') {
        this.model = null;
      }
      this.ondateChange();
    } else {
      this.model = new Date(this.model);
      this.date = new Date(this.model);
    }
    this.dateData = this.model;
    this.cdr.detectChanges();
  }
  
}
