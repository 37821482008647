import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-create-branch-popup',
  templateUrl: './create-branch-popup.component.html',
  styleUrls: ['./create-branch-popup.component.scss']
})
export class CreateBranchPopupComponent implements OnInit {

  GITLAB_PERSONAL_ACCESS_TOKEN = 'zsScqHtB8AmfHbt5GsS2';
  PROJECT_URL = 'https://gitlab.com';
  SOURCE_BRANCH = 'master';
  PROJECT_Id = '11345552';
  errorMsg;
  allData: any = {
    type: 'feature',
    project: []
  };
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<CreateBranchPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _helperService: HelpersService,
    private _translationLoader: FuseTranslationLoaderService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.allData = this.data.otherInfo || this.allData || {};
    this.allData.branchName = this.data.branchName;
    if (this.allData.branchName) {
      // this.getAllData();
    } else {
      this.keyChanged(null, {value: ''});
    }
  }

  keyChanged(eve, temp) {
    let tt = temp.value || '';
    tt = tt.replace(/[^A-Z0-9]/ig, '_').toLowerCase();
    this.allData.branchName = `${this.allData.type}/#${this.data.signatura}#_${tt}`;
  }

  getAllData() {
    this.allData.branchName = this.allData.branchName || '';
    this.allData.feature = this.allData.branchName.substr(0, this.allData.branchName.indexOf('/'));
    this.allData.humanReadablePart = this.allData.branchName.substring(this.allData.branchName.indexOf('/') + 1, this.allData.branchName.indexOf('_'));
  }

  createBranch() {
    this.allData.project = this.allData.project || [];
    this.allData.project.forEach((project) => {
      this.errorMsg = '';
      // tslint:disable-next-line:max-line-length
      const url = `${this.PROJECT_URL}/api/v4/projects/${project}/repository/branches?branch=${encodeURIComponent(this.allData.branchName)}&ref=${encodeURIComponent(this.SOURCE_BRANCH)}`;
      const headers = new HttpHeaders({
        'PRIVATE-TOKEN': this.GITLAB_PERSONAL_ACCESS_TOKEN
      });
      this.loading = true;
      this.http.post(url, {  }, { headers })
      .toPromise()
      .then((data) => {
        console.log(data);
        this._helperService.openSnackBar('Branch created');
        this.dialogRef.close(this.allData);
        this.loading = false;
      }).catch(err => {
        console.log(err);
        this.errorMsg = (err && err.error && err.error.message) || '';
        this.loading = false;
      });
    });
  }

  deleteBranch() {
    this._helperService.openConfirmPopUp(`popup.are_you_sure_you_want_to_delete_the_branch`)
    .subscribe((data) => {
      if (data) {
        this.deleteGitBranch();
      }
    });
  }

  deleteGitBranch() {
    this.errorMsg = '';
    this.allData.branchName = this.allData.branchName || '';
    const url = `${this.PROJECT_URL}/api/v4/projects/${this.allData.project}/repository/branches/${encodeURIComponent(this.allData.branchName)}`;
    const headers = new HttpHeaders({
      'PRIVATE-TOKEN': this.GITLAB_PERSONAL_ACCESS_TOKEN
    });
    this.loading = true;
    this.http.delete(url, { headers })
    .toPromise()
    .then((data) => {
      console.log(data);
      this._helperService.openSnackBar('Branch Deleted');
      this.dialogRef.close({deleted: true});
      this.loading = false;
    }).catch(err => {
      console.log(err);
      this.errorMsg = (err && err.error && err.error.message) || '';
      this.loading = false;
    });
  }

  selectProject(projectId: string) {
    if (this.allData && this.allData.project) {
      if (this.allData.project.includes(projectId)) {
        this.allData.project.splice(this.allData.project.indexOf(projectId), 1);
      } else {
        this.allData.project.push(projectId);
      }
    }
  }
}
