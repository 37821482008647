import { FuseSidebarModule } from '@fuse/components/index';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponentComponent } from './form-component.component';
import { FormComponentService } from './form-component.service';
import { ControlTemplateModule } from '../control-template/control-template.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgSummernoteModule } from '../ng-summernote/ng-summernote.module';
import { PositionModule } from '../control-template/position/position.module';
import { MaterialModule } from '@fuse/material.module';
import { AllFieldsViewModule } from '../all-fields-view/all-fields-view.module';
import { FormRulesService } from '../../services/rules/form-rules.service';
// import { BreadcrumbModule } from 'angular2-crumbs/lib';


@NgModule({
  imports: [
    CommonModule,
    ControlTemplateModule,
   FuseSharedModule,
    MaterialModule,
    NgSummernoteModule,
    PositionModule,
    FuseSidebarModule,
    AllFieldsViewModule
    // CalenderModule    
    // BreadcrumbModule,
  ],
  declarations: [
    FormComponentComponent
  ],
  providers: [
    FormComponentService,
    FormRulesService
  ],
  exports: [
    FormComponentComponent
  ]
})
export class FormComponentModule { }
