<div *ngIf="!field.hide || builder">
    <div *ngIf="field.view_type == 'autocomplete' ">
        <mat-form-field appearance="outline" fxFlex class="example-full-width">
            <mat-label>{{field.title}}</mat-label>
            <input matInput placeholder="{{field.title}}" [(ngModel)]="emailModel" (blur)="onKeyPress()" fxFlex #temp (keyup)="changed(temp.value)" [disabled]="field.showdisablecontroltype=='true' || field.disable" [hidden]="field.hidden" aria-label="State" [matAutocomplete]="auto">
            <mat-autocomplete (optionSelected)="selectUser()" #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredNames" [value]="option.email">
                    <span>{{ option.email }}</span>
                </mat-option>
            </mat-autocomplete>
            <div class="error-message" *ngIf="errorMessage && (!min_error && !max_error)">
                {{ errorMessage | translate }}
            </div>
        </mat-form-field>
    </div>
    <div *ngIf="field.view_type == 'dropdown' ">
        <mat-form-field fxFlex>
            <mat-select placeholder="{{field.title}}" fxFlex (selectionChange)="selectUser()" [(ngModel)]="emailModel" [disabled]="field.showdisablecontroltype=='true' || field.disable" [hidden]="field.hidden">
                <!-- <mat-option>  </mat-option> -->
                <mat-optgroup *ngFor="let group of groups" [label]="group.name">
                    <mat-option *ngFor="let user of group.users" [value]="user.email">
                        {{ user.email }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
            <div class="error-message" *ngIf="errorMessage && (!min_error && !max_error)">
                {{ errorMessage | translate }}
            </div>
        </mat-form-field>
    </div>
</div>
