/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/htmlWithStyle.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./confirm-dialog.component";
var styles_FuseConfirmDialogComponent = [i0.styles];
var RenderType_FuseConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FuseConfirmDialogComponent, data: {} });
export { RenderType_FuseConfirmDialogComponent as RenderType_FuseConfirmDialogComponent };
export function View_FuseConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.HtmlWithStylePipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h1", [["class", "mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i4.MatDialogTitle, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], null, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i4.MatDialogContent, [], null, null), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(8, 1), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [["class", "pt-24 mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["aria-label", "confirm_popup_cancel_button"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(13, 0, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 3, "button", [["aria-label", "confirm_popup_confirm_button"], ["class", "mat-accent mr-16"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(17, 0, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("popup.confirm")); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 7).transform(_co.confirmMessage)))); _ck(_v, 5, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 12).disabled || null); var currVal_4 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("popup.cancel")); _ck(_v, 13, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 16).disabled || null); var currVal_7 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_6, currVal_7); var currVal_8 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("popup.confirm")); _ck(_v, 17, 0, currVal_8); }); }
export function View_FuseConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-confirm-dialog", [], null, null, null, View_FuseConfirmDialogComponent_0, RenderType_FuseConfirmDialogComponent)), i1.ɵdid(1, 49152, null, 0, i11.FuseConfirmDialogComponent, [i4.MatDialogRef], null, null)], null, null); }
var FuseConfirmDialogComponentNgFactory = i1.ɵccf("fuse-confirm-dialog", i11.FuseConfirmDialogComponent, View_FuseConfirmDialogComponent_Host_0, {}, {}, []);
export { FuseConfirmDialogComponentNgFactory as FuseConfirmDialogComponentNgFactory };
