<div id="login" fxLayout="row" fxLayoutAlign="start">

    <div id="login-intro" fxFlex fxHide fxShow.gt-xs>

    </div>

    <div id="login-form-wrapper" fusePerfectScrollbar *fuseIfOnDom [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div>
            <img style="width: 352px; margin: 24px;" src="assets/images/header-logo.jpg" alt="header logo">
        </div>

        <div id="login-form">

            <div fxLayout="row" class="mb-24" fxLayoutGap="10px" fxLayoutAlign="center space-around">
                <!-- <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang);">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <img class="flag mr-16" [src]="'assets/images/flags/'+lang.flag+'.png'">
                    <span class="iso">{{lang.title}}</span>
                </div>
            </button> -->
                <mat-select aria-label="login_language_selection" (selectionChange)="setLanguage(selectedLangCode)" [(ngModel)]="selectedLangCode" class="w-100-p" placeholder="{{ 'auth.select_language' | translate }}">
                  <mat-option [attr.aria-label]="lang.code" *ngFor="let lang of languages" [value]="lang.code"  >
                    {{lang.title}}
                  </mat-option>
                </mat-select>
            </div>

            <div aria-label="login_to_account" class="title">{{ 'auth.login_to_you_account' | translate }}</div>

            <form name="loginForm" [formGroup]="loginForm" novalidate>

                <mat-form-field>
                    <input matInput placeholder="Email" formControlName="email">
                    <mat-error *ngIf="loginFormErrors.email.required">
                        {{ 'auth.email_is_required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="!loginFormErrors.email.required && loginFormErrors.email.email">
                        {{ 'auth.please_enter_valid_email_address' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input matInput type="password" placeholder="{{ 'auth.password' | translate }}" formControlName="password">
                    <mat-error *ngIf="loginFormErrors.password.required">
                        {{ 'auth.password_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                    <mat-checkbox class="remember-me" aria-label="Remember Me">
                        {{ 'auth.remember_me' | translate }}
                    </mat-checkbox>

                    <a class="forgot-password" [routerLink]="'/forgot-password'">
                      {{ 'auth.forgot_password' | translate }}
                    </a>

                </div>

                <div aria-label="login_error_msg" class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" *ngIf="errMess">
                    {{errMess || errMess['message'] }}
                </div>

                <button mat-raised-button color="accent" class="submit-button" (click)="login()" aria-label="LOGIN" [disabled]="loginForm.invalid || loader">
                    {{ 'auth.login' | translate }}
                </button>

            </form>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">
                    {{ 'auth.dont_have_account' | translate }} ?
                  </span>
                <a class="link" [routerLink]="'/register'">
                  {{ 'auth.create_an_account' | translate }}
                </a>
            </div>
            <div>
                <img src="assets/images/footer-logo.jpg" alt="footer logo">
            </div>
        </div>
    </div>
    <p style="position: fixed;bottom: 0px;right: 150px;font-size: 18px; color: rgb(3,155,229)"> {{ version }} </p>
</div>