import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { environment } from 'environments/environment.prod';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-form-builder-modal',
  templateUrl: './form-builder-modal.component.html',
  styleUrls: ['./form-builder-modal.component.scss']
})
export class FormBuilderModalComponent implements OnInit {

  regList: any;
  dataSource: any;
  paginator: any;
  officeId: String;

  constructor(
    private http: HttpClient,    
    public dialogRef: MatDialogRef<FormBuilderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

}
