import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { FormsModule } from '@angular/forms';
import { FileUploadComponent, DateComponent, TemplateModalModule, InputTextModule, 
        ListModule, RadioGroupModule, NumberModule, SliderComponent, DropdownComponent, CheckboxComponent, EmailComponent, CurrencyComponent, 
        PhoneComponent, ButtonListComponent, DeliveryAddressComponent, UserSelectionComponent, OneRecordDocumentComponent, AddNewDocumentComponent, 
         ProcessStatusComponent, TimeComponent, RegValueSelectionComponent, FilePreviewComponent, 
        DateTimeComponent, CaseToRecIdComponent, GroupSelectionComponent, OrgSelectionComponent, 
        RwaInputComponent, ContactSearchComponent, CalendarRangeComponent, StandaloneButtonsComponent } from '.';
import { FileUploadKeyComponent } from './file-upload-key/file-upload-key.component';
import { InstanceTimelineModule } from './instance-timeline/instance-timeline.module';
import { ButtonListWithTextComponent } from './button-list-with-text/button-list-with-text.component';
import { FileUploadModule } from './file-upload/file-upload.module';
import { ExcelImportComponent } from './excel-import/excel-import.component';
import { MaterialModule } from '@fuse/material.module';
import { DateRangeComponent } from './date-range/date-range.component';
import { MAT_DATE_LOCALE } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ContactPickComponent } from './contact-pick/contact-pick.component';
import { RelatedRecordListComponent } from './related-records-list/related-records-list.component';
import { AddRecordComponent } from './related-records-list/add-record/add-record.component';
import { FieldOptionUpdateDialog } from '../../components/field-option-update-dialog/field-option-update-dialog.module';
import { RecordCommentComponent } from './record-comment/record-comment.component';
import { OptionBasedRegValueSelectionComponent } from './option-based-reg-value-selection/option-based-reg-value-selection.component';
import { CreateBranchComponent } from './create-branch/create-branch.component';
import { CreateBranchPopupComponent } from './create-branch/create-branch-popup/create-branch-popup.component';
import { ElectronicSenderCountryComponent } from './electronic-sender-country/electronic-sender-country.component';
import { OptionBasedRegValueMultiSelectionComponent } from './option-based-reg-value-multi-selection/option-based-reg-value-multi-selection.component';
// import { StandaloneButtonsComponent } from './standalone-buttons/standalone-buttons.component';

@NgModule({
    imports     : [
       FuseSharedModule,
        MaterialModule,
        FormsModule,
        TemplateModalModule,
        InputTextModule,
        ListModule,
        RadioGroupModule,
        NumberModule,
        InstanceTimelineModule,
        FileUploadModule, 
        TranslateModule,
        FieldOptionUpdateDialog
    ],
    declarations: [
        // FileUploadComponent,
        DateComponent,
        SliderComponent,
        DropdownComponent,
        CheckboxComponent,
        EmailComponent,
        CurrencyComponent,
        PhoneComponent,
        ButtonListComponent,
        DeliveryAddressComponent,
        UserSelectionComponent,
        OneRecordDocumentComponent,
        AddNewDocumentComponent,
        // FilePreviewDialog,
        ProcessStatusComponent,
        TimeComponent,
        RegValueSelectionComponent,
        FilePreviewComponent,
        DateTimeComponent,
        DateRangeComponent,
        CaseToRecIdComponent,
        GroupSelectionComponent,
        OrgSelectionComponent,
        RwaInputComponent,
        ContactSearchComponent,
        CalendarRangeComponent,
        FileUploadKeyComponent,
        ButtonListWithTextComponent,
        ExcelImportComponent,
        ContactPickComponent,
        RelatedRecordListComponent,
        AddRecordComponent,
        StandaloneButtonsComponent,
        RecordCommentComponent,
        OptionBasedRegValueSelectionComponent,
        CreateBranchComponent,
        CreateBranchPopupComponent,
        ElectronicSenderCountryComponent,
        OptionBasedRegValueMultiSelectionComponent
    ],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'pl-PL'}
    ],
    exports: [
        // FileUploadComponent,
        DateComponent,
        SliderComponent,
        DropdownComponent,
        CheckboxComponent,
        EmailComponent,
        CurrencyComponent,
        PhoneComponent,
        ButtonListComponent,
        DeliveryAddressComponent,
        UserSelectionComponent,
        OneRecordDocumentComponent,
        AddNewDocumentComponent,
        // FilePreviewDialog,
        ProcessStatusComponent,
        TimeComponent,
        RegValueSelectionComponent,
        FilePreviewComponent,
        InputTextModule,
        ListModule,
        RadioGroupModule,
        NumberModule,
        DateTimeComponent,
        DateRangeComponent,
        CaseToRecIdComponent,
        GroupSelectionComponent,
        OrgSelectionComponent,
        RwaInputComponent,
        ContactSearchComponent,
        CalendarRangeComponent,
        FileUploadKeyComponent,
        InstanceTimelineModule,
        ButtonListWithTextComponent,
        FileUploadModule,
        ExcelImportComponent,
        ContactPickComponent,
        RelatedRecordListComponent,
        AddRecordComponent,
        StandaloneButtonsComponent,
        RecordCommentComponent,
        OptionBasedRegValueSelectionComponent,
        CreateBranchComponent,
        CreateBranchPopupComponent,
        ElectronicSenderCountryComponent,
        OptionBasedRegValueMultiSelectionComponent
    ],
    entryComponents: [
        AddNewDocumentComponent,
        AddRecordComponent,
        CreateBranchPopupComponent
        // FilePreviewDialog,
    ]
})
export class ControlTemplateModule
{
}
