import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { LanguagesInterface } from 'app/main/content/interfaces/languages.interface';
import { Router } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { SetDefaultLanguageService } from 'app/main/content/services/set-default-language/set-default-language.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { AuthService } from '../auth.service';
import { LanguagesModel } from 'app/main/content/models/languages.model';
import { MaintenanceComponent } from '../../maintenance/maintenance.component';
import { md5 } from '../md5';
import * as $ from 'jquery';
import { version } from 'environments/version';

@Component({
    selector: 'login-2',
    templateUrl: './login-2.component.html',
    styleUrls: ['./login-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class Login2Component implements OnInit, OnDestroy {

    COMPKEY = 'authentication';
    version = '';
    loginForm: FormGroup;
    loginFormErrors: any;
    selectedLanguage: any;
    selectedLangCode: any;
    errMess: String = '';
    loader = false;
    loading = false;
    languages: Array<LanguagesInterface>;
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
        public dialog: MatDialog,
        private setDefaultLanguageService: SetDefaultLanguageService,
        private fuseSplashScreenService: FuseSplashScreenService,
        private profileHelperService: ProfileHelperService,
        private helperService: HelpersService
    ) {
        
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.loginFormErrors = {
            email: {},
            password: {}
        };

        this.version = version;
        this.languages = LanguagesModel;
        
        this.selectedLanguage = this.languages[0];
        this.selectedLangCode = this.selectedLanguage['id'];
        if (localStorage.getItem('selectedLanguage')) {
            this.languages.forEach((lang, i) => {
                if (lang.id === localStorage.getItem('selectedLanguage')) {
                    this.selectedLanguage = lang;
                    this.selectedLangCode = this.selectedLanguage['id'];
                }
            });
        }
    }

    ngOnInit(): void {
        this.setDefaultLanguageService.setDefaultLanguage();

        this.helperService
        .getDefaultLangs()
        .then(lang => {
            this.languages = lang || [];
            this.selectedLangCode = this.translate.currentLang;
        })
        .catch(err => {
            console.log(err);
        });

        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this.loginForm.valueChanges.subscribe(
            () => {
                this.onLoginFormValuesChanged();
            },
            err => {
                console.log(err);
            }
        );
        this.helperService
        .getTrans(this.COMPKEY)
        .then(data2 => {
            this.translationLoader.loadTranslationsV2(data2);
        })
        .catch(err => {
            console.log(err);
        });
    }

    ngOnDestroy(): void {
        this.loader = false;
    }

    login(): void {
        this.loader = true;
        const user = {
            login: this.loginForm.get('email').value,
            password: md5(this.loginForm.get('password').value),
            appType: 'ng5'
        };
        this.authService.logIn(user).then(cred => {
                this.loader = false;
                this.fuseSplashScreenService.show();
                this.helperService.openSnackBar('Login Success', 'Home');

                // this.initNotification();
                // this.showNotificationPopUp((cred && cred['profile'] && cred['profile']['_id']));
                // console.clear();
                $('#splash-content').html('<p>Trwa logowanie...</p>');
                if (this.authService.getLastSelectedWorkspace()) {
                    this.helperService.getRegisterData(this.authService.getLastSelectedWorkspace())
                    .then((data) => {
                        this.router.navigate([`/workspace/${(data && data[0] && data[0]['slug'])}/dashboard`]);
                        setTimeout(() => {
                            if (this.fuseSplashScreenService.isShowing) {
                                this.fuseSplashScreenService.hide();
                            }
                        }, 3500);
                    }).catch(err => {
                        console.log(err);
                        this.moveToOffice();
                    });
                } else {
                    this.moveToOffice();
                }
            })
            .catch(error => {
                this.loader = false;
                setTimeout(() => {
                    if (this.fuseSplashScreenService.isShowing) {
                        this.fuseSplashScreenService.hide();
                    }
                }, 3500);
                if (error['status'] === 0) {
                    this.dialog.open(MaintenanceComponent, {
                        width: '100%',
                        height: '100%',
                        panelClass: 'happ-form-builder-dialog',
                        disableClose: true,
                        data: { error }
                    });
                }
                console.log(error);
                this.errMess = (error && error.message) || (error && error.error && error.error.txt) || error || 'Some error occured.';
            });
    }

    onLoginFormValuesChanged(): void {
        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }

    moveToOffice() {
        if (this.authService.getLastSelectedOffice()) {
            this.router.navigate([`/offices/${this.authService.getLastSelectedOffice()}/dashboard`]);
            setTimeout(() => {
                if (this.fuseSplashScreenService.isShowing) {
                    this.fuseSplashScreenService.hide();
                }
            }, 3500);
        } else {
            this.router.navigate(['/dashboard']);
            setTimeout(() => {
                if (this.fuseSplashScreenService.isShowing) {
                    this.fuseSplashScreenService.hide();
                }
            }, 3500);
        }
    }

    setLanguage(lang: any): void {
        this.languages.map(ele => {
            if (lang === ele.id) {
                this.selectedLanguage = ele;
            }
        });
        // Set the selected language for toolbar
        // this.selectedLanguage = lang;

        localStorage.setItem('selectedLanguage', this.selectedLanguage.id);

        this.translate.setDefaultLang(this.selectedLanguage.id);
        this.translate.use(localStorage.getItem('selectedLanguage') || this.translate.getBrowserLang() || 'en');
    }

    // initNotification(): void {
    //     const oneSignal = (window && window['OneSignal']) || [];
    //     oneSignal.push(function() {
    //         OneSignal.init({
    //             appId: '193b89fd-4b08-4a89-910c-adee9633799a'
    //         });
    //     });
    // }

    // showNotificationPopUp(profileId): void {
    //     OneSignal.push(function() {
    //         OneSignal.isPushNotificationsEnabled()
    //             .then(isEnabled => {
    //                 if (!isEnabled) {
    //                     OneSignal.showHttpPrompt();
    //                 } else {
    //                     console.log('Already enabled');
    //                 }
    //             })
    //             .catch(err => {
    //                 console.log(err);
    //             });
    //         // .then(data => console.log(data))
    //         // .catch(err => console.log(err));
    //     });
    //     OneSignal.push(() => {
    //         // Occurs when the user's subscription changes to a new value.
    //         OneSignal.on('subscriptionChange', isSubscribed => {
    //             console.log(
    //                 'The user\'s subscription state is now:',
    //                 isSubscribed
    //             );
    //             OneSignal.getUserId().then(userId => {
    //                 this.profileHelperService
    //                     .getUserProfile(profileId)
    //                     .then(profileData => {
    //                         if (profileData) {
    //                             profileData['notificationIds'] =
    //                                 profileData['notificationIds'] || [];
    //                             if (
    //                                 isSubscribed &&
    //                                 !profileData['notificationIds'].includes(
    //                                     userId
    //                                 )
    //                             ) {
    //                                 profileData['notificationIds'].push(userId);
    //                                 this.updateProfile(
    //                                     profileId,
    //                                     profileData['notificationIds']
    //                                 );
    //                                 console.log('User ID is', userId);
    //                             } else {
    //                                 if (
    //                                     profileData['notificationIds'].includes(
    //                                         userId
    //                                     )
    //                                 ) {
    //                                     const index = profileData[
    //                                         'notificationIds'
    //                                     ].indexOf(userId);
    //                                     if (index >= 0) {
    //                                         profileData[
    //                                             'notificationIds'
    //                                         ].splice(index, 1);
    //                                         this.updateProfile(
    //                                             profileId,
    //                                             profileData['notificationIds']
    //                                         );
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                     })
    //                     .catch(err => {
    //                         console.log(err);
    //                     });
    //             });
    //         });
    //     });
    // }

    updateProfile(profileId, updatedDoc): void {
        if (profileId) {
            this.profileHelperService
                .updateProfile(profileId, { notificationIds: updatedDoc || [] })
                .then(data => {
                    this.helperService.openTranslatedSnackBar(this.COMPKEY, 'notification_subscribed', 'okay');
                    console.log('updated');
                })
                .catch(err => {
                    this.helperService.openTranslatedSnackBar(this.COMPKEY, err, 'okay');
                    console.log(err);
                });
        }
    }
}
