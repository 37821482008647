<div *ngIf="fileId && pdfURL">
    <div *ngIf="isScanque" style="cursor: pointer; background: aliceblue; padding: 5px; text-align: center; font-size: 18px; border: 1px solid rgba(200,200,200,0.5);" (click)="moveTo(scanqueEditUrl)">
        <!-- Edit Scanque Document -->
        {{ 'ct.edit_scanque_document' | translate }}
    </div>
    <iframe [src]="pdfURL" frameborder="0" style="height: 70vh; width: 100%;"></iframe>
</div>
<div fxLayout="column" style="height: 70vh; width: 100%;background: white;justify-content: center; align-items: center; border: 1px solid gray; display: flex;" *ngIf="!pdfURL || !fileId ">
    <mat-icon *fuseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" class="s-128 mb-16 hint-text select-message-icon">
        attach_file
    </mat-icon>
    <h2 *fuseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" class="select-message-text hint-text">
        <!-- No File to preview -->
        {{ 'ct.no_file_to_preview' | translate }}
    </h2>
</div>