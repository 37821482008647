/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit-form-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@angular/material/toolbar";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/common";
import * as i7 from "@angular/flex-layout/flex";
import * as i8 from "@angular/flex-layout/core";
import * as i9 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i14 from "@angular/material/icon";
import * as i15 from "../../../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import * as i16 from "../../../../../../@fuse/services/config.service";
import * as i17 from "@angular/router";
import * as i18 from "../../../global-component/form-component/form-component.component.ngfactory";
import * as i19 from "../../../global-component/form-component/form-component.component";
import * as i20 from "../../../global-component/form-component/form-component.service";
import * as i21 from "@angular/material/snack-bar";
import * as i22 from "../../../services/helpers/helpers.service";
import * as i23 from "../../../services/rules/form-rules.service";
import * as i24 from "./edit-form-popup.component";
var styles_EditFormPopupComponent = [i0.styles];
var RenderType_EditFormPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditFormPopupComponent, data: {} });
export { RenderType_EditFormPopupComponent as RenderType_EditFormPopupComponent };
export function View_EditFormPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "dialog-content-wrapper"], ["style", "background: #fafafa;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "mat-toolbar", [["class", "mat-accent m-0 mat-dialog-title mat-toolbar"], ["matDialogTitle", ""]], [[8, "id", 0], [2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(2, 81920, null, 0, i3.MatDialogTitle, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], null, null), i1.ɵdid(3, 4243456, null, 1, i4.MatToolbar, [i1.ElementRef, i5.Platform, i6.DOCUMENT], null, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(5, 0, null, 0, 10, "div", [["fxFlex", ""], ["fxLayout", "row"], ["fxLayoutAlign", "space-between center"]], null, null, null, null, null)), i1.ɵdid(6, 671744, null, 0, i7.DefaultLayoutDirective, [i1.ElementRef, i8.StyleUtils, [2, i7.LayoutStyleBuilder], i8.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(7, 671744, null, 0, i7.DefaultLayoutAlignDirective, [i1.ElementRef, i8.StyleUtils, [2, i7.LayoutAlignStyleBuilder], i8.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(8, 671744, null, 0, i7.DefaultFlexDirective, [i1.ElementRef, i8.StyleUtils, i8.LAYOUT_CONFIG, i7.FlexStyleBuilder, i8.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "title dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["aria-label", "Close dialog"], ["class", "mat-icon-button"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i10.MatButton, [i1.ElementRef, i5.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i13.View_MatIcon_0, i13.RenderType_MatIcon)), i1.ɵdid(14, 9158656, null, 0, i14.MatIcon, [i1.ElementRef, i14.MatIconRegistry, [8, null], [2, i14.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "p-24 m-0"], ["fusePerfectScrollbar", ""]], null, [["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17)._updateOnResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 17).documentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 4341760, null, 0, i15.FusePerfectScrollbarDirective, [i1.ElementRef, i16.FuseConfigService, i5.Platform, i17.Router], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-form-component", [], null, null, null, i18.View_FormComponentComponent_0, i18.RenderType_FormComponentComponent)), i1.ɵdid(19, 114688, null, 0, i19.FormComponentComponent, [i20.FormComponentService, i6.Location, i21.MatSnackBar, i17.Router, i3.MatDialog, i22.HelpersService, i23.FormRulesService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); var currVal_3 = "row"; _ck(_v, 6, 0, currVal_3); var currVal_4 = "space-between center"; _ck(_v, 7, 0, currVal_4); var currVal_5 = ""; _ck(_v, 8, 0, currVal_5); _ck(_v, 14, 0); var currVal_11 = ""; _ck(_v, 17, 0, currVal_11); _ck(_v, 19, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; var currVal_1 = (i1.ɵnov(_v, 3)._toolbarRows.length > 0); var currVal_2 = (i1.ɵnov(_v, 3)._toolbarRows.length === 0); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = _co.dialogTitle; _ck(_v, 10, 0, currVal_6); var currVal_7 = (i1.ɵnov(_v, 12).disabled || null); var currVal_8 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_7, currVal_8); var currVal_9 = i1.ɵnov(_v, 14).inline; var currVal_10 = (((i1.ɵnov(_v, 14).color !== "primary") && (i1.ɵnov(_v, 14).color !== "accent")) && (i1.ɵnov(_v, 14).color !== "warn")); _ck(_v, 13, 0, currVal_9, currVal_10); }); }
export function View_EditFormPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit-form-popup", [], null, null, null, View_EditFormPopupComponent_0, RenderType_EditFormPopupComponent)), i1.ɵdid(1, 4308992, null, 0, i24.EditFormPopupComponent, [i3.MatDialogRef, i3.MAT_DIALOG_DATA, i20.FormComponentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditFormPopupComponentNgFactory = i1.ɵccf("app-edit-form-popup", i24.EditFormPopupComponent, View_EditFormPopupComponent_Host_0, {}, {}, []);
export { EditFormPopupComponentNgFactory as EditFormPopupComponentNgFactory };
