import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { MaterialModule } from './material.module';
import { TranslateModule } from '@ngx-translate/core';
import { GravatarModule, GravatarConfig, RATING } from 'ngx-gravatar';
import { CKEditorModule } from 'ngx-ckeditor';
import { FuseConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { InputDialogComponent } from 'app/main/content/global-component/input-dialog/input-dialog.component';
import { FuseConfirmDialogModule } from './components';

const gravatarConfig: GravatarConfig = {
    rating: RATING.pg
};
  

@NgModule({
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,

        MaterialModule,
        GravatarModule.forRoot(gravatarConfig),
        TranslateModule,
        CKEditorModule,
        FuseConfirmDialogModule
    ],
    declarations: [
        InputDialogComponent
    ],
    entryComponents: [
        InputDialogComponent
    ],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,
        MaterialModule,
        GravatarModule,
        TranslateModule,
        CKEditorModule,
        InputDialogComponent,
        FuseConfirmDialogModule
    ]
})
export class FuseSharedModule
{
}
