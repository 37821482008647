<mat-toolbar class="p-0 mat-elevation-z1">

    <!-- <mat-progress-bar *ngIf="showLoadingBar" class="loading-bar" color="accent" mode="indeterminate"></mat-progress-bar> -->

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-button class="toggle-button-navbar mat-icon-button" (click)="toggleSidebarFolded()" fuseNavbarVertical="toggleBar" fxHide.gt-md>
                <mat-icon>menu</mat-icon>
            </button>
            <div>
                <button mat-button [matMenuTriggerFor]="officeMenu" aria-label="open_office_dropdown" (click)="getOfficeList()" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <!-- {{  }} -->
                    <span class="username mr-12" fxHide fxShow.gt-sm> {{selectedOfficeName }} </span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>


                <mat-menu #officeMenu="matMenu" [overlapTrigger]="false">
 

                    <div *ngIf="officeList">
                        <div *ngFor="let office of officeList">
                            <button mat-menu-item *ngIf="office && office['starred']" (click)="changeOffice(office)">
                                <span> {{ office['name'] || office['officeId'] }} </span>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!officeList">
                        <button mat-menu-item>
                                <span> {{ 'toolbar.loading' | translate }} </span>
                        </button>
                    </div>
                    <mat-divider></mat-divider>
                    <button aria-label="open_office_list" mat-menu-item [routerLink]="['dashboard']">
                        <span> {{ 'toolbar.all_offices' | translate }} </span>
                    </button>
                </mat-menu>
            </div>
            <!-- <breadcrumb></breadcrumb> -->
            <div class="px-8 px-mat-16">
                <fuse-shortcuts [navigation]="{}" ></fuse-shortcuts>
            </div>
            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button *ngIf="ADMIN_EMAILS && username && ADMIN_EMAILS.includes(username)" [matTooltip]="'toolbar.bug_report' | translate" (click)="addIssue('user')" mat-icon-button class="mat-icon-button quick-panel-toggle-button" aria-label="Toggle quick panel">
                <mat-icon class="icon font-size-32 h-24 w-32">bug_report</mat-icon>
            </button>
            <div *ngIf="ADMIN_EMAILS && username && ADMIN_EMAILS.includes(username)" class="toolbar-separator" fxHide fxShow.gt-xs></div>
            <button *ngIf="ADMIN_EMAILS && username && ADMIN_EMAILS.includes(username)" [matTooltip]="'toolbar.bug_report_admin' | translate" color="warn" (click)="addIssue('admin')" mat-icon-button class="mat-icon-button quick-panel-toggle-button" aria-label="Toggle quick panel">
                <mat-icon class="icon font-size-32 h-24 w-32" style="color: #f44336;">bug_report</mat-icon>
            </button>
            <div *ngIf="ADMIN_EMAILS && username && ADMIN_EMAILS.includes(username)" class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <div class="toolbar-separator" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNav">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/fuse.svg">
                </div>
            </div>

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <div *ngIf="invitationCount" class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button *ngIf="invitationCount" [matTooltip]="'toolbar.invitation' | translate" [routerLink]="'/invitation/list'" mat-icon-button class="mat-icon-button quick-panel-toggle-button happ-notification-count-container" aria-label="Toggle quick panel">
                <span class="heartbit"></span>
                <mat-icon class="icon font-size-32 h-24 w-32">mood</mat-icon>
                <div class="unread-count" *ngIf="invitationCount"> {{ invitationCount }} </div>
            </button>
            <div *ngIf="invitationCount" class="toolbar-separator" fxHide fxShow.gt-xs></div>
            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <!-- <img class="avatar" src="assets/images/avatars/profile.jpg"> -->
                    <img class="avatar" *ngIf="username" ngxGravatar [email]="username" >
                    <span aria-label="user_email" class="username mr-12" fxHide fxShow.gt-sm> {{username}} </span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button [routerLink]="'/profile'" mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>{{ 'toolbar.my_profile' | translate }}</span>
                </button>

                <button mat-menu-item class="">
                    <mat-icon>mail</mat-icon>
                    <span>{{ 'toolbar.inbox' | translate }}</span>
                </button>

                <button mat-menu-item class="" (click)="logOut()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>{{ 'toolbar.logout' | translate }}</span>
                </button>

                <button mat-menu-item class="" (click)="systemInfo()">
                        <mat-icon>ll</mat-icon>
                        <span style="color: rgb(3,155,229)"> {{ version }} </span>
                    </button>
            </mat-menu>

            <div class="toolbar-separator"></div>

            <!-- <fuse-search-bar (onInput)="search($event)"></fuse-search-bar> -->

            <!-- <div class="toolbar-separator"></div> -->

            <button mat-button fxHide *ngIf="selectedLanguage" aria-label="toolbar_language_change_button" fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8 w-20 h-20" [src]="'assets/images/flags/'+selectedLanguage.flag+'.png'">
                    <span aria-label="current_selected_lang" class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button [attr.aria-label]="(lang && lang.flag)" mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16 w-24 h-24" [src]="'assets/images/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </div>
                </button>

            </mat-menu>

            <!-- <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="mat-icon-button quick-panel-toggle-button" fuseMatSidenavToggler="quick-panel" aria-label="Toggle quick panel">
                <mat-icon class="icon">format_list_bulleted</mat-icon>
            </button> -->

        </div>
    </div>
    <div fxLayoutAlign="center center" fxLayout="column" *ngIf="changedOfficeName !== null" class="office-changed-info">
        <h2> {{ 'toolbar.office_has_been_changed_in_some_other_tab_continue_working_by_switching_the_office' | translate }} </h2>
        <button mat-raised-button (click)="switchOffice()" > {{ 'toolbar.switch_office_to' | translate }} {{ changedOfficeName }} </button>
    </div>

</mat-toolbar>