<div class="px-24">

    <div fxLayout="row" class="mt-8">
        <div>
            <button mat-raised-button (click)="closeDialog()">
                Cancel
            </button>
        </div>
        <span fxFlex></span>
        <div class="mr-40" *ngIf="data['selectedFormName']">
            <h4>Selected Form: <strong>{{ data['selectedFormName'] }}</strong> </h4>
        </div>
        <div *ngIf="regList && regList.length > 0" class="ml-4">
            <mat-form-field>
                <mat-select placeholder="Select Form" (change)="formChanged()" [(ngModel)]="selectedFormId">
                    <mat-option *ngFor="let reg of regList" [value]="reg._id">
                        {{ reg.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <span fxFlex></span>
        <div>
            <button mat-raised-button color="warn" (click)="saveData()">
                {{ (operation == "add")? 'Add New': 'Update'}}
            </button>
        </div>
    </div>

    <div fxLayout="row" class="my-20">
        <mat-form-field fxFlex="33">
            <input matInput [(ngModel)]="templateData.title" placeholder="Template Name">
        </mat-form-field>

        <mat-form-field fxFlex="33">
            <input matInput [(ngModel)]="templateData.category" placeholder="Template Category">
        </mat-form-field>

        <mat-form-field fxFlex="33">
            <input matInput [(ngModel)]="templateData.shortSymbol" placeholder="Template Short Symbol">
        </mat-form-field>
    </div>

    <mat-tab-group class="demo-tab-group">
        <mat-tab label="Content">
            <div>
                <!-- <ng-summernote [height]="800" ngDefaultControl [(ngModel)]="templateData.mainContent.content" placeholder="" lang="pl-PL"></ng-summernote> -->
                <ck-editor name="editor1" [(ngModel)]="templateData.mainContent.content" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>

            </div>
        </mat-tab>
        <mat-tab label="Header">
            <div>
                <!-- <ng-summernote [height]="800" ngDefaultControl [(ngModel)]="templateData.header.content" placeholder="" lang="pl-PL"></ng-summernote> -->
                <ck-editor name="editor1" [(ngModel)]="templateData.header.content" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>

            </div>
        </mat-tab>
        <mat-tab label="Footer">
            <div>
                <!-- <ng-summernote [height]="800" ngDefaultControl [(ngModel)]="templateData.footer.content" placeholder="" lang="pl-PL"></ng-summernote> -->
                <ck-editor name="editor1" [(ngModel)]="templateData.footer.content" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>

            </div>
        </mat-tab>
        <mat-tab label="Form Variable">
            <div class="p-12">
            </div>
        </mat-tab>
        <mat-tab label="Global Variable">
            <div class="p-12" *ngIf="globalVars">
                <p>
                    ##processRecordReport##
                    <br>
                    ##pageBreak##
                    <br>
                    ##page##
                    <br>
                    ##v2Page##
                    <br>
                    ##v2toPage##
                    <br>
                    ##toPage##
                    <br>
                    ##RESPONSIBLE.SHORTNAME##
                    <br>
                    ##RESPONSIBLE.LONGNAME##
                    <br>
                    ##CLIENTNAME##
                    <br>
                    ##REPORT_NAME##
                    <br>
                    ##REGISTER_NAME##
                    <br>
                    ##PANEL_NAME##
                    <br>
                    ##CREATED_BY##
                    <br>
                    ##REPORT_TIME##
                    <br>
                    ##REPORT_DATE##
                    <br>
                    ##OFFICE_NAME##
                    <br>
                    ##CREATED_BY##
                    <br>
                    ##postBookTemplate##
                    <br>
                    ##positionTable_abc##
                    <br>
                    ##recCommentHTML##
                </p>
            </div>
        </mat-tab>
        <mat-tab label="Custom Variable">
            <div class="p-12">
                Dummy content
            </div>
        </mat-tab>
        <mat-tab label="Preview">
            <div class="p-12">
                <button mat-raised-button (click)="updatePDF()">
                    Update
                </button>
                <div *ngIf="pdfURL">
                    <iframe [src]="pdfURL" frameborder="0" style="height: 100vh; width: 100%;"></iframe>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Settings">
            <div class="p-24" fxFlex>
                <div fxLayout="row" *ngIf="(templateData && templateData._id)">
                    <h3>
                        {{ (templateData && templateData._id) || '' }}
                    </h3>
                </div>
                <div>
                    <mat-form-field class="w-300">
                        <mat-label>Type of generator to use</mat-label>
                        <mat-select [(ngModel)]="templateData.typeOfGenerator">
                          <mat-option value="puppeter">
                                New (Puppeter)
                          </mat-option>
                          <mat-option value="wkhtml">
                                Old (Wkhtml)
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                    <div fxFlex="90">
                        <mat-checkbox [(ngModel)]="templateData.showAsMainDocument">Set Document as main document on creation</mat-checkbox>
                    </div>
                    <div fxFlex="45">
                        <!-- <mat-checkbox [(ngModel)]="templateData.footer.show">Use the footer</mat-checkbox> -->
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="45">
                        <mat-checkbox [(ngModel)]="templateData.header.show">Use the header</mat-checkbox>
                    </div>
                    <div fxFlex="45">
                        <mat-checkbox [(ngModel)]="templateData.footer.show">Use the footer</mat-checkbox>
                    </div>
                </div>
                <div fxLayout="row" *ngIf="templateData.printringVarialbles && templateData.printringVarialbles.line">
                    <div fxFlex="45">
                        <mat-checkbox [(ngModel)]="templateData.printringVarialbles.line.header">Use line in header</mat-checkbox>
                    </div>
                    <div fxFlex="45">
                        <mat-checkbox [(ngModel)]="templateData.printringVarialbles.line.footer">Use line in footer</mat-checkbox>
                    </div>
                </div>
                <div fxLayout="row" *ngIf="templateData.printringVarialbles && templateData.printringVarialbles.margin">
                    <div fxFlex="45">
                        <mat-form-field class="example-full-width">
                            <input matInput [(ngModel)]="templateData.printringVarialbles.margin.top" placeholder="Margin Top (mm)">
                        </mat-form-field>
                    </div>
                    <div fxFlex="45">
                        <mat-form-field class="example-full-width">
                            <input matInput [(ngModel)]="templateData.printringVarialbles.margin.bottom" placeholder="Margin Bottom (mm)">
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" *ngIf="templateData.printringVarialbles && templateData.printringVarialbles.spacing">
                    <div fxFlex="45">
                        <mat-form-field class="example-full-width">
                            <input matInput [(ngModel)]="templateData.printringVarialbles.spacing.header" placeholder="Spacing header (mm)">
                        </mat-form-field>
                    </div>
                    <div fxFlex="45">
                        <mat-form-field class="example-full-width">
                            <input matInput [(ngModel)]="templateData.printringVarialbles.spacing.footer" placeholder="Spacing footer (mm)">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>