<div fxLayout="column" *ngIf="!field.hide || builder">

    <div fxLayout="row">
        <p class="happ-symbol"> {{ field.prefix }} </p>
        <mat-form-field appearance="outline" fxFlex>
            <mat-label> {{ field.title }} </mat-label>
<!-- matSuffix -->
            <!-- <p style="font-size: 28px; margin: 0px;" matPrefix class="secondary-text">{{ field.prefix }}</p> -->
            <input type="number" (paste)="pasteIt($event)" matInput minlength="{{field.min_length}}" maxlength="{{field.max_length}}" placeholder="{{field.title}}" [disabled]="field.showdisablecontroltype=='true' || field.disable" (keyup)="onKeyPress()" [hidden]="field.hidden" [(ngModel)]="model">

              <mat-hint align="start"> {{ field.description }} </mat-hint>
        </mat-form-field>
    </div>

    <div style="color: red">
        <p *ngIf="min_error"> Min Number Allowed is {{ field.min }} </p>
        <p *ngIf="max_error"> Max Number Allowed is {{ field.max }} </p>
        <p *ngIf="neg_error"> Negative Number not Allowed </p>
        <p *ngIf="dec_error"> Decimal number not Allowed </p>
    </div>
</div> 