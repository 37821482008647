import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { InputDialogComponent } from './input-dialog.component';



@NgModule({
    declarations: [
      // InputDialogComponent
    ],
    imports     : [
       FuseSharedModule
    ],
    exports: [
        // InputDialogComponent
    ]
})

export class InputDialogModule
{

}
