import { HelpersService } from './../../../services/helpers/helpers.service';
import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatDialog } from '@angular/material';
import { FieldOptionUpdateDialogComponent } from 'app/main/content/components/field-option-update-dialog/field-option-update-dialog.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-button-list',
  templateUrl: './button-list.component.html',
  styleUrls: ['./button-list.component.scss']
})
export class ButtonListComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() field;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() model;
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Input() hideTitle: boolean;
  @Input() builder;
  @Input() formData: any;
  @Output() valChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  loading = false;
  error = false;
  errorMessage = '';
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private _helperService: HelpersService

  ) {
    this.model = this.model || '';
  }

  
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }
  editOptions() {
    const dialogRef = this.dialog.open(FieldOptionUpdateDialogComponent, {
      width: '850px',
      height: 'max-content',
      restoreFocus: false,
      data: { fieldLabel: this.field.label, formData: this.formData, fieldOptions: JSON.parse(JSON.stringify(this.field.options || [])) || [], field: this.field },
      panelClass: 'happ-form-builder-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.field.options = result;
        this.changeDetectorRef.detectChanges();
      }
    }); 
  }


  getOptions() {
    if (this.field.regId && this.field.optionTitle) {
      const cols = [
        {
          prop: this.field.optionTitle,
          title: this.field.optionTitle
        },
        {
          prop: '_id',
          title: '_id'
        }
      ];
      if (this.field.optionValue) {
        cols.push(this.field.optionValue);
      }

      this.loading = true;
      this._helperService.getDataForOptionBasedField(this.field.regId, cols, this.field.hardFilter, this.field.sortBy)
      .then((data) => {
        const tt = [];
        data = data || {};
        data['dttable'] = data['dttable'] || {};
        data['dttable']['data'] = data['dttable']['data'] || [];
        data['dttable']['data'].forEach((ele) => {
          if (this.field.optionValue) {
            tt.push({
              title: ele[this.field.optionTitle],
              value: ele[this.field.optionValue]
            });
          } else {
            tt.push({
              title: ele[this.field.optionTitle]
            });
          }
        });
        this.field.options = tt || [];
        this.loading = false;
        this.changeDetectorRef.detectChanges();

      }).catch(err => {
        console.log(err);
        this.loading = false;
        this.changeDetectorRef.detectChanges();

      });
    }
    
  }

  onChange(name) {
    console.log(name, this.model);
    if (this.field && this.field.typeNumber) {
      this.model = parseFloat(this.model);
    }
    if (name) {
      this.model[name] = !this.model[name];
    } 
    this.valChange.emit({
      model: this.model
    });
    this.modelChange.next(this.model);

    if (this.field['required'] && (this.model === null || this.model === undefined || this.model === '')) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    } else {
      this.errorMessage = '';
      this.error = false;
      this.onError.emit({
        error: false,
        label: this.field['label']
      });
    }
    if (this.field.haveRules) {
      this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
      this.changeDetectorRef.detectChanges();

    }
  }
  
  ngAfterViewInit() {
    const temp = {};
    if (this.field.multiple) {
      this.field['options'].forEach(element => {
        temp[element.name] = false;
      });
      this.model = (this.model) ? this.model : temp;          
    } else {
      if (typeof(this.model) === 'object') {
        this.model = '';
      } else {
        this.model = this.model || '';
      }
    }
 
  
    if (this.field['preselectedValue'] && this.model && this.model.length === 0){
      this.model = this.field['preselectedValue'];
      this.onChange(null);
    }
  }

  ngOnInit() {
    if (this.field.userRegister) {
      this.getOptions();
    }
    this.changeDetectorRef.markForCheck();
    this.translationLoader.loadTranslationsV2(this.translateData);
    if (this.field['required'] && (this.model === null || this.model === undefined || this.model === '')) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    }
  }
}
