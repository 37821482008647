import { version } from './version';
import { branch } from './branch';
export const environment = {
    branch: branch,
    version: "3.1.1",
    production: true,
    hmr       : false,
    // serverUrl: 'https://dev-release-p24back.unicloud.pl/'    
    // serverUrl: 'https://dev-stage-p24backend-2.unicloud.pl/',
    socketUrl: 'https://socket.w3.pinbox24.com/',
    serverUrl: 'https://api.w3.pinbox24.com/',
    versionCheckURL : 'version.json'
};
