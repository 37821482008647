<div class="pb-12" *ngIf="!field.hide || builder">
    <div class="happ-list-field" [class]="field.class" >
        <div class="happ-default-title happ-title">
            <div class="w-100-p" fxLayout="column" [ngClass]="{'ml-20': error}">
                <div class="w-100-p" fxLayout="row wrap" fxLayoutAlign="center center">
                    <p *ngIf="!hideTitle" class="happ-field-title">{{ field.title }} </p>
                    <span fxFlex></span>
                    <div>
                        <button *ngIf="field.showEdit" style="margin-right: -8px !important; color: #827c7c;" (click)="editOptions()" [matTooltip]="'ct.edit_options' | translate" mat-icon-button>
                            <mat-icon>settings</mat-icon>
                        </button>            
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="happ-list happ-content" *ngIf="!field.multiple" fxLayout="{{field.flex}}">
            <button class="happ-button-list" [ngClass]="{'happ-small-button': field.smallButton}" fxLayoutAlign="{{(field.align)? field.align : 'center'}} center" mat-raised-button (click)="model=option.value || option.title; onChange()" role="listitem" *ngFor="let option of field.options" [style.background]="(model == option.value || model == option.title)? 'gray': option.background"
                [style.color]="(model == option.value || model == option.title)? 'white': option.color">
                {{ option.title || option.value }}
            </button>
        </div> -->

        <div class="happ-list happ-content" fxLayout="{{field.flex}} wrap">
          <button class="happ-button-list" mat-raised-button [ngClass]="{'happ-small-button': field.smallButton}" fxLayoutAlign="{{(field.align)? field.align : 'center'}} center" (click)="onChange(option.value || option.title)" role="listitem" *ngFor="let option of field.options" 
          style="background: white; color: black;">
              {{ option.title || option.value }}
          </button>
        </div>
        <h2 *ngIf="errorMes" style="text-align: center;">
           {{ errorMessage | translate }}
        </h2>
        <mat-form-field class="w-100-p m-4 mt-8">
          <textarea rows="{{ field.no_of_rows}}" class="w-100-p" matInput placeholder="{{field.title}}" [disabled]="field.showdisablecontroltype=='true' || field.disable" (keyup)="onKeyPress()" [hidden]="field.hidden" [(ngModel)]="model" placeholder="Textarea">{{ model }} </textarea>
          <mat-hint align="start"> {{ field.description }} </mat-hint>
        </mat-form-field>
    </div>
    <!-- <div class="happ-desc" *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">
    
    </div> -->
</div>