import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import {MatDialog, MatSnackBar } from '@angular/material';
import { AddNewDocumentComponent } from './dialogs/add-new-document/add-new-document.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { fuseAnimations } from '@fuse/animations';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-one-record-document',
  templateUrl: './one-record-document.component.html',
  styleUrls: ['./one-record-document.component.scss'],
  animations   : fuseAnimations
})
export class OneRecordDocumentComponent implements OnInit {
  pdfURL: any;
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: string; 
@Input() translateData: any;
  @Input() templates: any;
  @Input() recId: any;
  @Input() formId: any;
  @Input() regId: any;
  @Output() updateRecord: EventEmitter<any> = new EventEmitter();
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Input() recordMainDocument: string;
  @Output() recordMainDocumentChange: EventEmitter<Object> = new EventEmitter();



  public selectedOffice: object;
  public params = {};
  public registerRecordDocument: any = [];


  officeId;
  selected;
  templateId;

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private translationLoader: FuseTranslationLoaderService,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    this.translationLoader.loadTranslationsV2(this.translateData);
    // this.selectedOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
    const selectedOffice = localStorage.getItem('ngStorage-selectedOffice') ;
        let officeId;
        if (selectedOffice) {
            const temp = JSON.parse(selectedOffice);
            officeId = temp['_id'];
            this.officeId = temp['_id'];
        } else {
          return;
        }
        this.getData();
  }

  deleteRecord(id) {
    if (id && JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
      this.helperService.deleteRequest(`api/files/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/${id}`)
      .then((data) => {
        this.snackBar.open('Deleted', 'done', {
          duration: 2000,
        });
        this.getData();
        this.selected = null;
        this.pdfURL = null;
      }).catch(err => {
        this.snackBar.open(err, 'done', {
          duration: 2000,
        });
      });
    }
  }

  getData() {
    this.params = Object.assign({}, this.activatedRoute.snapshot.params);
    // this.params['officeId'] = this.selectedOffice['_id'];
    this.params['documentType'] = 'oneRecordDocument';
    this.params['recId'] = this.recId;
    this.params['regId'] = this.regId;
    const params = {
      documentType: 'oneRecordDocument',
      recId: this.recId,
      regId: this.regId
    };
    this.helperService.getSearchedFiles(params)
    .then(docList => {
      this.registerRecordDocument = docList || [];
    }).catch(err => {
      console.log(err);
      this.snackBar.open(err || 'Error Occurred While getting data', 'done', {
        duration: 2000,
      });
    });
  }

updatePDF(id, fileId) {
  return new Promise((resolve, reject) => {
      if (id) {
        this.updateRecord.emit(() => {
          this.helperService.getRequest(`api/offices/${this.officeId}/registries/${this.regId}/records/${this.recId}/one-record-report-documents/${id}?fileId=${fileId}`)
          .then((data) => {
            if (data && data['reportData']) {
              this.selected = data['reportData'];
              const base64 = data['reportData']['base64'];
                const blob = this.b64toBlob(base64, 'application/pdf', null);
                const blobUrl = URL.createObjectURL(blob);
                this.pdfURL =  this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
                // resolve(pdfURL);
              }
          }).catch(err => {
            console.log(err);
          });
      });
      resolve('done');
    } else {
      this.snackBar.open('No template id', 'done', {
        duration: 2000,
      });
      console.log('no Template Id');
    }
  });
}


selectPDF(base64) {
  const blob = this.b64toBlob(base64, 'application/pdf', null);
  const blobUrl = URL.createObjectURL(blob);
  this.pdfURL =  this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
}
 

b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}


  addNewDocument(){
    this.dialog.open(AddNewDocumentComponent, {
      width: '100%',
      data: { templateList: this.templates, formId: this.formId},
      panelClass: 'happ-form-builder-dialog'
    }).afterClosed().subscribe(result => {

      if (result){
        this.updateRecord.emit(() => {
          this.helperService.getRequest(`api/offices/${this.officeId}/registries/${this.regId}/records/${this.recId}/one-record-report-documents/${result._id}`)
          .then((data) => {
            if (data && data['reportData']) {
              this.selected = data['reportData'];
              const blob = this.b64toBlob(data['reportData']['base64'], 'application/pdf', null);
              const blobUrl = URL.createObjectURL(blob);
              this.pdfURL =  this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
              this.getData();
              if (result.showAsMainDocument) {
                this.saveAsMainDoc(this.selected._id);
              }
            }
          }).catch(err => {
            console.log(err);
          });
        });
        this.templateId = result._id;
      }

    }, err => {
      console.log(err);
    });
  }


  publishDocument(id: string) {
    if (id && this.officeId) {
      this.helperService.putRequest(`api/files/${this.officeId}/${id}`, {published: true})
      .then((data) => {
        this.snackBar.open('Updated', 'done', {
          duration: 2000,
        });
        this.selected['published'] = true;
      }).catch(err => {
        this.snackBar.open(err, 'done', {
          duration: 2000,
        });
        console.log(err);
      });
    } else {
      // error
    }
  }

  saveAsMainDoc(id: string) {
    if (id) {
      this.recordMainDocument = id;
      this.recordMainDocumentChange.next(this.recordMainDocument);
      this.updateRecord.emit();
    }
  }
}
