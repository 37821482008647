<div class="pb-12" *ngIf="!field.hide || builder">
    <div class="happ-list-field" [class]="field.class" >
        <div class="happ-default-title happ-title">
            <!-- <mat-icon *ngIf="error" class="ml-8 mt-8 happ-error-default happ-error-icon">warning</mat-icon> -->
            <div class="w-100-p" fxLayout="column">
                <div class="w-100-p" fxLayout="row wrap" fxLayoutAlign="center center">
                    <p *ngIf="!hideTitle" class="happ-field-title">{{ field.title }} </p>
                    <span fxFlex></span>
                    <div>
                        <button *ngIf="field.showEdit" style="margin-right: -8px !important; color: #827c7c;" (click)="editOptions()" [matTooltip]="'ct.edit_options' | translate" mat-icon-button>
                            <mat-icon>settings</mat-icon>
                        </button>
                    </div>
                </div>
                <p *ngIf="errorMessage" class="warn-500-fg m-0 ml-8">
                    {{ errorMessage | translate }}
                </p>
            </div>
        </div>
        <div *ngIf="!loading" fxLayout="{{field.flex}} wrap">
            <mat-radio-group class="happ-list happ-content" fxLayout="{{field.flex}} wrap" [(ngModel)]="model" (change)="onChange()">
                <mat-radio-button [disabled]="field.showdisablecontroltype || field.disable" style="margin: 5px;" *ngFor="let option of field.options" [value]="option.value || option.title">{{ option.title || option.value }}</mat-radio-button>
            </mat-radio-group>
            <mat-form-field class="mt-4" *ngIf="field.showExtra" fxFlex>
                <input matInput [disabled]="field.showdisablecontroltype || field.disable" placeholder="{{field.extraFieldTitle}}" (paste)="pasteIt($event)" (keyup)="onChange()" [(ngModel)]="model">
            </mat-form-field>
        </div>
        <div fxLayoutAlign="center center" *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <div *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">
    </div>
</div>