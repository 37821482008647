<div fxLayout="column" *ngIf="!field.hide || builder">
    <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{field.title}}</mat-label>
        <input type="number" (paste)="pasteIt($event)" matInput min="{{field.min}}" max="{{field.max}}" placeholder="{{field.title}}" [disabled]="field.showdisablecontroltype=='true' || field.disable" (keyup)="onKeyPress()" [hidden]="field.hidden" [(ngModel)]="model">
        <mat-hint align="start"> {{ field.description }} </mat-hint>
        <div class="error-message" *ngIf="errorMessage && (!min_error && !max_error)">
            {{ errorMessage | translate }}
        </div>
        <div class="error-message" *ngIf="min_error">
            {{ errorMessage | translate }} {{ field.min }}
        </div>
        <div class="error-message" *ngIf="max_error">
            {{ errorMessage | translate }} {{ field.max }}
        </div>
    </mat-form-field>
</div>