import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material';
import { EditNg5Service } from '../../one-recordview/edit-ng5/edit-ng5.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-process-status',
  templateUrl: './process-status.component.html',
  styleUrls: ['./process-status.component.scss']
})
export class ProcessStatusComponent implements OnInit {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model;
  @Input() regId;
  @Input() recId;
  @Input() translateData: any;
  @Output() buttonChange: EventEmitter<any> = new EventEmitter();
  @Output() updateRecord: EventEmitter<any> = new EventEmitter();
  public officeId: string;
  public buttons = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    public snackBar: MatSnackBar,
    private helperService: HelpersService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {

  }

  ngOnInit() {
    this.getButtons();
  }

  getButtons(){
    const selectedOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
    if (selectedOffice && selectedOffice['_id'] && this.model && this.model['_id'] &&  this.model['regId']){
      this.officeId = selectedOffice['_id'];
      this.helperService.getRequest(`api/reg-process/${this.model['regId']}/${this.model['_id']}/getButtons`)
      .then((data) => {
        this.buttons = data || [];
        this.buttons = this.transformSort(this.buttons);
        this._changeDetectorRef.detectChanges();
      }).catch(err => {
        console.log(err);
      });
    }
  }

  onChange(button) {
    if (this.officeId && this.model['procesId'] && this.model['instanceId']  && button && button.id){   

      this.updateRecord.emit({
        isUpdateRecord: !button.dont_update_on_transition,
        updatedData: this.model,
        regId: this.regId,
        initBehaviour: '',
        recId: this.recId,
        buttonId: button.id,
        type: 'actButton',
        navigateTo: `oneRecordView/process/${this.model['procesId']}/instances/${this.model['instanceId']}/processing/${button.id}/${decodeURI(button.label.replace(/\s+/g, '-'))}`
      });
      // this.editNg5Service.updateData(this.model, this.regId, this.recId).then(updateRegister => {
      //   // tslint:disable-next-line:max-line-length
      //   this.router.navigate([]);
      // }).catch(err => {
      //   this.snackBar.open(err, 'done', {
      //     duration: 2000,
      //   });
      // });
      if (this.field.haveRules) {
        this.onFieldValueUpdated.emit({
          label: this.field.label,
          value: this.model,
          ruleIndex: this.field.ruleIndex,
          field: this.field
        });
      }
    }
  }

  transformSort(array: any[]): any[] {
    if (!array || array && array.length === 0) {
      return [];
    }
    array.sort((a: any, b: any) => {
      if (a && b && a && b && a['sort'] < b['sort']) {
        return -1;
      } else if (a && b && a && b && a['sort'] > b['sort']) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
