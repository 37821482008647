<div class="happ-list-field" *ngIf="buttons && buttons.length">
    <!-- <p class="happ-field-title">{{ field.title }} </p> -->
    <div class="happ-list" fxFlex="100" [fxLayout]="(field && field.showOnForm)? 'column' : 'row'" [ngClass]="{'formButton': (field && field.showOnForm), 'header-or-footer-button': (!field || (field && !field.showOnForm))}">
        <button [attr.aria-label]="(button.dest && button.dest.id)" [ngClass]="{'happ-small-button': (field && field.smallButton)}" class="happ-button-list" mat-raised-button (click)="onChange(button.dest)" role="listitem" *ngFor="let button of buttons">
            <p fxLayout="row" fxLayoutAlign="center center" class="m-0">
                {{(button.dest && button.dest.label) || ''}}
                <span *ngIf="field && field.showOnForm" fxFlex></span>
                <mat-icon style="font-weight: 700; margin: auto; margin-left: 4px; margin-right: 5px;">arrow_forward</mat-icon>
            </p>
        </button>
    </div>
</div>