<div class="fuse-white-bg happ-left-border" fxLayout="column" *ngIf="!field.hide">
  <mat-form-field appearance="outline" [class]="(errorMessage) ? 'mb-12' : ''" fxFlex>
      <mat-label>{{field.title}}</mat-label>
      <mat-select (selectionChange)="onChange(model)" fxFlex placeholder="{{field.title}}" [disabled]="field.showdisablecontroltype=='true' || field.disable" [hidden]="field.hidden" [(ngModel)]="model">
          <mat-option *ngFor="let option of field.options" [value]="option.value || option.title">
              {{ option.title || option.value }}
          </mat-option>
      </mat-select>
      <mat-hint>
        <div class="error-message" *ngIf="errorMessage && (!min_error && !max_error)">
          {{ errorMessage | translate }}
        </div>
        <div class="error-message" *ngIf="min_error">
            {{ errorMessage | translate }} {{ field.min_length }}
        </div>
        <div class="error-message" *ngIf="max_error">
            {{ errorMessage | translate }} {{ field.max_length }}
        </div>
      </mat-hint>
  </mat-form-field>
  <div>
  </div>
</div>