<div *ngIf="!field.hide || builder">
    <p *ngIf="!hideTitle" class="happ-field-title ml-0">{{ field.title }} </p>    
    <div *ngIf="!model || edit">
        <div class="m-4 mb-8">
            <mat-radio-group (change)="address=[];message=''" [(ngModel)]="field.deliveryAddressSelection">
                <mat-radio-button class="mr-12" value="searchMultiFileds">
                    {{ 'deliveryAddress.name' | translate }}                
                </mat-radio-button>
                <mat-radio-button class="mr-12" value="searchNip">
                    {{ 'deliveryAddress.nipNumber' | translate }}                
                </mat-radio-button>
                <mat-radio-button class="mr-12" value="cityAndStreet">
                        {{ 'deliveryAddress.cityOrStreet' | translate }}
                </mat-radio-button>
                <mat-radio-button class="mr-12" disabled value="by_nip_number_ext_api">
                    {{ 'deliveryAddress.externalNip' | translate }}                
                </mat-radio-button>
                <mat-radio-button class="mr-12" value="manualDeliveryAddress">
                    {{ 'deliveryAddress.manual' | translate }}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="w-100-p" fxLayout="row wrap">
            <div fxFlex *ngIf="field.deliveryAddressSelection == 'cityAndStreet'" fxLayout="column">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.city' | translate }} </mat-label>
                    <input matInput [disabled]="!regId" placeholder="{{ 'deliveryAddress.city' | translate }}" [(ngModel)]="city">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.street' | translate }} </mat-label>
                    <input matInput [disabled]="!regId" (keydown.Tab)="search($event)" placeholder="{{ 'deliveryAddress.street' | translate }}" [(ngModel)]="street">
                </mat-form-field>
            </div>
            <div fxFlex *ngIf="field.deliveryAddressSelection == 'searchNip'">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.nipNumber' | translate }} </mat-label>
                    <input matInput [disabled]="!regId" #nipVal  (keydown)="validateNip($event, nipVal)" (keydown.Tab)="search($event)" placeholder="{{ 'deliveryAddress.nipNumber' | translate }}" [(ngModel)]="nip">
                </mat-form-field>
            </div>
            <div fxFlex *ngIf="field.deliveryAddressSelection == 'searchMultiFileds'">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.companyName' | translate }} </mat-label>
                    <input matInput [disabled]="!regId" (keydown.Tab)="search($event)" placeholder="{{ 'deliveryAddress.companyName' | translate }}" [(ngModel)]="searchMultiFileds">
                </mat-form-field>
            </div>
            <div fxFlex *ngIf="field.deliveryAddressSelection == 'ext_nip_no'">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.externalNip' | translate }} </mat-label>
                    <input matInput [disabled]="!regId" (keydown.Tab)="search($event)" placeholder="{{ 'deliveryAddress.externalNip' | translate }}" [(ngModel)]="ext_nip_no">
                </mat-form-field>
            </div>
            <div fxFlex *ngIf="field.deliveryAddressSelection == 'manualDeliveryAddress'">
                <!-- <mat-form-field appearance="outline" fxFlex>
                    <mat-label> {{ field.title }} </mat-label>
                    <textarea matInput [disabled]="!regId" row="5" [(ngModel)]="model" (keydown)="onKeyPress();edit=true"  placeholder="Klient: ">{{model}}</textarea>
                </mat-form-field> -->
                <div fxLayoutGap="5px" fxLayout="row wrap">
                    <mat-form-field appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.shortName' | translate }} </mat-label>
                        <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId" placeholder="{{ 'deliveryAddress.shortName' | translate }}" [(ngModel)]="manualData.shortName">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.longName' | translate }} </mat-label>
                        <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId" placeholder="{{ 'deliveryAddress.longName' | translate }}" [(ngModel)]="manualData.longName">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.companyName' | translate }} </mat-label>
                        <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId" placeholder="{{ 'deliveryAddress.companyName' | translate }}" [(ngModel)]="manualData.companyName">
                    </mat-form-field>
                </div>
                <div fxLayoutGap="5px" fxLayout="row wrap">
                    <mat-form-field appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.companyAdress' | translate }} </mat-label>
                        <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId" placeholder="{{ 'deliveryAddress.companyAdress' | translate }}" [(ngModel)]="manualData.companyAdress">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.companyCity' | translate }} </mat-label>
                        <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId" placeholder="{{ 'deliveryAddress.companyCity' | translate }}" [(ngModel)]="manualData.companyCity">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.postalcode' | translate }} </mat-label>
                        <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId" placeholder="{{ 'deliveryAddress.postalcode' | translate }}" [(ngModel)]="manualData.postalcode">
                    </mat-form-field>
                </div>
                <!-- <div fxLayout="row wrap">
                    <span fxFlex></span>
                    <button mat-raised-button color="warn" (click)="onKeyPress('manual')" >
                        Done
                    </button>
                </div> -->
            </div>
            <button *ngIf="field.deliveryAddressSelection !== 'manualDeliveryAddress'" mat-icon-button (click)="search($event)">
                <mat-icon>search</mat-icon>
            </button>
        </div>
    </div>
    <p matTooltip="Click to edit" style="margin: 0px; font-weight: 700; font-size: 16px; margin-bottom: 16px;" (click)="edit=!edit" *ngIf="model && !edit">
        {{ model }}
    </p>

    <p style="text-align: center;" *ngIf="field.deliveryAddressSelection != 'manualDeliveryAddress' && !model || edit">
        {{ message }}
    </p>
    <div class="happ-list-field" fxLayout="column" *ngIf="!loading && (result && result.length && field.deliveryAddressSelection != 'manualDeliveryAddress' && !model || edit)">
        <div>
            <div *ngIf="result && result.length > 1" fxLayout="row" fxLayoutAlign="start center">
                <label for="search" class="mr-8">
                    <mat-icon>search</mat-icon>
                </label>
                <mat-form-field>
                    <mat-label> {{ 'deliveryAddress.search' | translate }} </mat-label>
                    <input matInput placeholder="{{ 'deliveryAddress.search' | translate }}" [(ngModel)]="query">
                </mat-form-field>
            </div>
            <mat-list role="list" fxLayout="column">
                <mat-list-item role="listitem" *ngFor="let option of result | fullTextSearch:query:['allAddress']; let i=index" [style.background]="(model == option.allAddress)? 'gray': ''" [style.color]="(model == option.allAddress)? 'white': ''">
                    <button mat-button fxFlex (click)="model=option.allAddress;onKeyPress(option)">
                        {{ option.allAddress }}
                    </button>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    <div *ngIf="loading">
        <mat-spinner>

        </mat-spinner>
    </div>
</div>
