<div class="page-layout simple fullwidth" fusePerfectScrollbar>
    <div class="top-bg accent"></div>
    <div class="center" style="height: -webkit-fill-available;">
        <div style="height: 50vh" *ngIf="formComponentService.loading" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
        <div fxLayout="row" *ngIf="!formComponentService.loading && formComponentService.form" style="margin: auto; height: -webkit-fill-available;"
            [style.width]="(formComponentService.ngForm && formComponentService.ngForm.formWidth)? (formComponentService.ngForm.formWidth + '%') : '100%' "
            class="content-card mat-elevation-z4 pt-8 pb-16">

            <fuse-sidebar *ngIf="!formComponentService.loading && formComponentService.ngForm.leftSideTemplate" class="sidebar"
            name="contacts-main-sidebar" position="left" lockedOpen="gt-sm">

            <!-- SIDEBAR CONTENT -->
            <div class="content" fusePerfectScrollbar>
                <!-- <contacts-main-sidebar [@animate]="{value:'*'}"></contacts-main-sidebar> -->
                <div class="sidebar-content">

                    <div class="card fuse-white-bg">
                        <div *ngIf="formComponentService.ngForm && !formComponentService.ngForm['hideUpdateAndSave']" class="mt-16"
                            fxLayoutAlign="space-between center" fxLayout="row">

                            <button (click)="goBack()" class="back-and-save-btn" [ngClass]="{'active warn':(true)}"
                                mat-icon-button>
                                <mat-icon class="white-cl" aria-label="Example icon-button with a heart icon">arrow_back
                                </mat-icon>
                            </button>
                            <div class="nav material2 w-85-p">
                                <div class="nav-item" aria-label="inbox">
                                    <a class="nav-link w-200" [ngClass]="{'active warn':(true)}" matRipple
                                        (click)="formComponentService.saveFormData()">
                                        <span class="title">
                                            {{((operation == 'new')? 'ONE_REC.SAVE_AND_BACK':'ONE_REC.UPDATE_AND_BACK') | translate }}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="formComponentService.ngForm && this.ngForm && this.ngForm.saveWithoutMove"
                            class="mt-16 mt-12" fxLayoutAlign="space-between center" fxLayout="row">

                            <button (click)="goBack()" class="back-and-save-btn" [ngClass]="{'active warn':(true)}"
                                mat-icon-button>
                                <mat-icon class="white-cl" aria-label="Example icon-button with a heart icon">arrow_back
                                </mat-icon>
                            </button>
                            <div class="nav material2 w-85-p">
                                <div class="nav-item" aria-label="inbox">
                                    <a class="nav-link w-200" [ngClass]="{'active warn':(true)}" matRipple
                                        (click)="formComponentService.saveFormData()">
                                        <span class="title">
                                            {{((operation == 'new')? 'ONE_REC.SAVE':'ONE_REC.UPDATE') | translate }}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <!-- SIDEBAR CONTENT -->
                        <div class="content py-16" fusePerfectScrollbar>

                            <div class="nav material2">

                                <div *ngFor="let tab of formComponentService.form; let i=index" class="nav-item"
                                    aria-label="inbox">
                                    <a class="nav-link" matRipple (click)="changeTab(i)"
                                        [ngClass]="{'active accent':(formComponentService.selectedTab == i)}">
                                        <span class="title">{{tab.tabTitle}}</span>
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                <!-- / SIDEBAR CONTENT -->

            </div>
            <!-- / SIDEBAR CONTENT -->
            <div class="sidebar-content pt-16">
                <!-- <div class="card fuse-white-bg">


                    <div class="header px-4 py-12"
                        *ngIf="isAdmin || (formComponentService.ngForm && (formComponentService.ngForm.saveAndNew || formComponentService.formData['parentId'] || formComponentService.ngForm.showAddressBookPopup || formComponentService.ngForm.showDeleteButton))"
                        fxLayout="row" fxLayoutAlign="start center">

                        <div>
                            <button aria-label="workspace_dashboard_settings_button" [matMenuTriggerFor]="menu"
                                mat-icon-button>
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button aria-label="workspace_dashboard_user_management_button" mat-menu-item
                                    (click)="deleteRecordConfirm()"
                                    *ngIf="this.isAdmin || (formComponentService && formComponentService.ngForm && formComponentService.ngForm.showDeleteButton)">{{ 'ONE_REC.DELETE' | translate }}</button>
                            </mat-menu>
                        </div>




                        <button [matTooltip]="'ONE_REC.SAVE_AND_NEW' | translate" aria-label="save_and_new"
                            *ngIf="formComponentService.ngForm && formComponentService.ngForm.saveAndNew" (click)="saveAndNew()"
                            mat-icon-button>
                            <mat-icon aria-label="Example icon-button with a heart icon">save</mat-icon>
                        </button>
                        <button [matTooltip]="'ONE_REC.EDIT_FORM' | translate" aria-label="edit_form" *ngIf="isAdmin"
                            (click)="editForm()" mat-icon-button>
                            <mat-icon aria-label="Example icon-button with a heart icon">edit</mat-icon>
                        </button>
                        <button [matTooltip]="'ONE_REC.OPEN_PARENT_RECORD' | translate" aria-label="edit_parent"
                            *ngIf="formComponentService.formData && formComponentService.formData['parentId']"
                            mat-icon-button>
                            <mat-icon aria-label="Example icon-button with a heart icon">open_in_new</mat-icon>
                        </button>
                        <button [matTooltip]="'ONE_REC.OPEN_ADDRESS_BOOK' | translate" aria-label="open_address_book"
                            *ngIf="formComponentService.ngForm && formComponentService.ngForm.showAddressBookPopup"
                            (click)="openAddressBook()" mat-icon-button>
                            <mat-icon aria-label="Example icon-button with a heart icon">contacts</mat-icon>
                        </button>


                    </div>
                </div> -->
                <!-- / SIDEBAR HEADER -->
            </div>
        </fuse-sidebar>

          <div class="ml-20 mt-20 w-100-p">
            <div class="happ-action-header">
                <div *ngIf="formComponentService.processStatusField && formComponentService.processStatusField.showOnHeader"
                    class="mb-24">
                    <div class="my-8"
                        *ngIf="!formComponentService.processStatusField.hide && formComponentService.error && formComponentService.error.length === 0">
                        <app-process-status [(model)]="formComponentService.formData"
                            (updateRecord)="processUpdateRecord($event)"
                            [field]="formComponentService.processStatusField"></app-process-status>
                    </div>
                    <div fxLayoutAlign="center center"
                        *ngIf="!formComponentService.processStatusField.hide && formComponentService.error && formComponentService.error.length > 0">
                        <h3>
                            Please Remove all the Errors to have button
                        </h3>
                    </div>
                </div>
            </div>

            <div class="happ-tab-view fuse-white-bg" fxLayout="column">

                <!-- <div class="toolbar px-24 py-8 pb-5" fxLayout="row">
                    <div *ngFor="let tab of formComponentService.form; let i=index">
                        <button mat-raised-button [color]="(currentView == i)? 'primary': ''" *fuseIfOnDom
                            [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}" (click)="changeTab(i)">
                            {{tab.tabTitle}}
                        </button>
                    </div>
                    <span fxFlex></span>
                    <div
                        *ngIf="formComponentService && formComponentService.formData && formComponentService.formData['parentId']">
                        <button class="mr-8" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',y:'-25px'}}"
                            mat-raised-button (click)="openParentRec()">
                            Open parent Record
                        </button>
                    </div>

                    <div
                        *ngIf="formComponentService && formComponentService.ngForm && formComponentService.ngForm.showDeleteButton">
                        <button mat-raised-button class="mr-8" *fuseIfOnDom
                            [@animate]="{value:'*',params:{delay:'100ms',y:'-25px'}}" (click)="deleteRecordConfirm()">
                            Usuń
                        </button>
                    </div>
                    <div *ngIf="formComponentService && formComponentService.applicationType === 'externalWidget'">
                        <button mat-raised-button [color]="'warn'" *fuseIfOnDom
                            [@animate]="{value:'*',params:{delay:'100ms',y:'-25px'}}"
                            (click)="formComponentService.externalWidgetSaveData()">
                            Zapisz
                        </button>
                    </div>
                    <div *ngIf="formComponentService && formComponentService.saveButton">
                        <button mat-raised-button [color]="'warn'" *fuseIfOnDom
                            [@animate]="{value:'*',params:{delay:'100ms',y:'-25px'}}"
                            (click)="formComponentService.saveFormData()">
                            Zapisz
                        </button>
                    </div>
                </div> -->

                <div *ngIf="!formComponentService.loading && !formComponentService.ngForm.leftSideTemplate"
                    class="toolbar px-24 py-8 pb-0 mb-8 pb-4">
                    <div *ngFor="let tab of formComponentService.form; let i=index">
                        <button mat-raised-button [color]="(currentView == i)? 'primary': ''" *fuseIfOnDom
                            [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}" (click)="changeTab(i)">
                            {{tab.tabTitle}}
                        </button>
                    </div>
                    <span fxFlex></span>
                    <div *ngIf="formComponentService && formComponentService.formData && formComponentService.formData['parentId']">
                        <button class="mr-8" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',y:'-25px'}}"
                            mat-raised-button (click)="openParentRec()">
                            {{ 'ONE_REC.OPEN_PARENT_RECORD' | translate }}
                        </button>
                    </div>
                    <button class="mx-8" aria-label="back_button" style="color: black;" mat-raised-button
                        (click)="goBack()">
                        {{ 'ONE_REC.BACK' | translate }}
                    </button>
                    <div *ngIf="formComponentService && formComponentService.ngForm && formComponentService.ngForm.showDeleteButton">
                        <button aria-label="delete_record" mat-raised-button class="mr-8" *fuseIfOnDom
                            [@animate]="{value:'*',params:{delay:'100ms',y:'-25px'}}" (click)="deleteRecordConfirm()">
                            {{ 'ONE_REC.DELETE' | translate }}
                        </button>
                    </div>
                    <button aria-label="open_address_book"
                        *ngIf="formComponentService.ngForm && formComponentService.ngForm.showAddressBookPopup" mat-raised-button
                        (click)="openAddressBook()">
                        {{ 'ONE_REC.OPEN_ADDRESS_BOOK' | translate }}
                    </button>
                    <!-- <button aria-label="save_and_new" *ngIf="formComponentService.ngForm && formComponentService.ngForm.saveAndNew"
                        mat-raised-button (click)="saveAndNew()">
                        {{ 'ONE_REC.SAVE_AND_NEW' | translate }}
                    </button> -->
                    <button aria-label="update_form"
                        [disabled]="formComponentService.error && formComponentService.error.length > 0" class="mx-4"
                        *ngIf="formComponentService.ngForm && this.ngForm && this.ngForm.saveWithoutMove" mat-raised-button
                        color="warn" (click)="formComponentService.saveFormData(false)">
                        {{((operation == 'new')? 'ONE_REC.SAVE':'ONE_REC.UPDATE') | translate }}
                    </button>


                    <button aria-label="update_form"
                        [disabled]="formComponentService.error && formComponentService.error.length > 0" class="mx-4"
                        *ngIf="formComponentService.ngForm && !formComponentService.ngForm['hideUpdateAndSave']" mat-raised-button
                        color="warn" (click)="formComponentService.saveFormData()">
                        {{((operation == 'new')? 'ONE_REC.SAVE_AND_BACK':'ONE_REC.UPDATE_AND_BACK') | translate }}
                    </button>
                </div>
                <div *ngIf="preview" class="p-12 m-16 mat-elevation-z1 fuse-white-bg"
                    [innerHTML]="preview | htmlWithStyle">
                </div>
                <!-- {{ formComponentService.formData | json }} -->
                <div class="content p-24 pt-4">
                    <div *ngFor="let tab of formComponentService.form; let i=index" style="width: 100%;"
                        [style.display]="(currentView == i)?'block':'none'">
                        <div *ngIf="currentView == i" [style.display]="(currentView == i)?'block':'none'" class="py-4">
                            <div *ngFor="let row of tab.tabContent" fxLayout="row" fxFlex>

                                <div [ngClass]="{'happ-error-border': (isArrayFields && isArrayFields[row.label])}"
                                    *fuseIfOnDom [@animate]="{value:'*', duration: '1000ms', params:{y:'100%'}}"
                                    [style.display]="(row.hide)?'none': ''" fxFlex>

                                    <app-all-fields-view (onFieldValueUpdated)="onFieldValueUpdate($event)"
                                        [translateData]="translateData" (getRecord)="getRecord($event)"
                                        (processUpdateRecord)="processUpdateRecord($event)"
                                        (updateAddress)="updateAddress($event)"
                                        (updateParentOnSave)="updateParentOnSave($event)"
                                        (updateRecord)="updateRecord($event)" *ngIf="row.control_type !== 'section'"
                                        [tempService]="formComponentService" (onError)="checkError($event)"
                                        [field]="row"></app-all-fields-view>

                                    <!-- <<<<<<<<<<<<<<<  SECTION   >>>>>>>>>>>>>>>>> -->
                                    <div *ngIf="row.control_type === 'section'">

                                        <div class=" fuse-white-bg  happ-left-border" *ngIf="!row.hide">
                                            <div *ngFor="let col of row.children" class="mr-16"
                                                fxFlex="{{ 100/row.cols }}" fxLayout.lt-md="column wrap"
                                                fxLayout="column wrap">

                                                <div *ngFor="let field of col">
                                                    <div
                                                        [ngClass]="{'happ-error-border': (isArrayFields && isArrayFields[field.label])}">
                                                        <app-all-fields-view
                                                            (onFieldValueUpdated)="onFieldValueUpdate($event)"
                                                            [translateData]="translateData"
                                                            (getRecord)="getRecord($event)"
                                                            (processUpdateRecord)="processUpdateRecord($event)"
                                                            (updateAddress)="updateAddress($event)"
                                                            (updateParentOnSave)="updateParentOnSave($event)"
                                                            (updateRecord)="updateRecord($event)"
                                                            *ngIf="field.control_type !== 'section'"
                                                            [tempService]="formComponentService"
                                                            (onError)="checkError($event)" [field]="field">
                                                        </app-all-fields-view>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <!-- <<<<<<<<<<<<<<<  SECTION ENDS   >>>>>>>>>>>>>>>>> -->

                                </div>
                                <!-- <mat-divider></mat-divider></mat-divider> -->
                                <!-- </mat-card> -->
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div class="happ-action-header">
                <div
                    *ngIf="formComponentService.processStatusField && formComponentService.processStatusField.showOnFooter">
                    <div class="my-8"
                        *ngIf="!formComponentService.processStatusField.hide && formComponentService.error && formComponentService.error.length === 0">
                        <app-process-status [(model)]="formComponentService.formData"
                            (updateRecord)="processUpdateRecord($event)"
                            [field]="formComponentService.processStatusField"></app-process-status>
                    </div>
                    <div fxLayoutAlign="center center"
                        *ngIf="formComponentService.error && formComponentService.error.length > 0">
                        <h3>
                            Please Remove all the Errors to have button
                        </h3>
                    </div>
                </div>
            </div>

          </div>
        </div>
    </div>

</div>