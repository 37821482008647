export const ContactModel = {
    'address': '',
    'tel': [],
    'websites': [],
    'emails': [],
    'gravatarEmail': ''
};
  

export const GeneralModel = {
    'name': '',
    'gender': '',
    'birthday': '',
    'locations': '',
    'about': ''
};

export const WorkModel  = {
    'occupation': '',
    'skills': '',
    'jobs': []
};

