<div class="dialog-content-wrapper">
  <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title"> {{ 'add_rec.add_new_record' | translate }} </span>
      <span fxFlex></span>
      <button (click)="dialogRef.close(null)" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div *ngIf="!field.default_record" class="p-24" style="overflow: auto;" fxLayout="column">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label> {{ 'add_rec.select_register' | translate }} </mat-label>
      <mat-select fxFlex [(ngModel)]="selectedReg">
          <mat-option *ngFor="let option of registerList" [value]="option._id">
              {{ option.name }}
          </mat-option>
      </mat-select>
  </mat-form-field>
    <mat-form-field *ngIf="field.show_responsible_field" appearance="outline" fxFlex>
      <mat-label> {{ 'add_rec.responsible' | translate }} </mat-label>
      <mat-select fxFlex [(ngModel)]="responsibleEmail">
          <mat-option *ngFor="let option of officeUsersList" [value]="option.email">
              {{ option.email }}
          </mat-option>
      </mat-select>
  </mat-form-field>
    <mat-form-field *ngIf="field.show_responsible_field" appearance="outline" fxFlex>
      <mat-label> {{ 'add_rec.groups' | translate }} </mat-label>
      <mat-select fxFlex [(ngModel)]="managerGroup">
          <mat-option *ngFor="let option of officeGroupsList" [value]="option">
              {{ option }}
          </mat-option>
      </mat-select>
  </mat-form-field>
  </div>
  <div class="p-12" *ngIf="field.default_record">
    <h2>You are about to create new Record with Regsiter - {{ selectedRegister }} </h2>
  </div>
  <div class="p-24 pt-20 pb-20">
    <p class="font-size-14" style="color: red;" *ngIf="errorMsg"> {{ errorMsg }} </p>
    <span fxFlex></span>
    <button (click)="dialogRef.close(null)" aria-label="custom_app_cancel_button" mat-button class="mr-4">{{ 'add_rec.cancel' | translate }}</button>
    <button mat-raised-button [disabled]="loading" aria-label="custom_app_create_button" (click)="createNewRecord()" color="primary">{{ 'add_rec.create_record' | translate }}</button>
  </div>
</div>
