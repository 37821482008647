<div class="mb-24 happ-toolbar">
  <h3 class="ml-8"> Select Task Category <span *ngIf="selectedCategory">(selectedCategory)</span> </h3>
  <span fxFlex></span>
  <div style="margin: auto; margin-right: 10px;">
      <button mat-raised-button (click)="dialogRef.close(null)">Cancel</button>
  </div>
</div>
<div>
    <!-- <mat-nav-list *ngIf="tasksInfo && tasksInfo['taskCategories']">
        <a style="margin: 10px 0px;" [style.color]="(selectedCategory === temp.key)? 'black': 'gray'" *ngFor="let temp of tasksInfo['taskCategories'];" (click)="openTaskSelection(temp.key)" fxLayout="row" mat-list-item>
            <mat-icon  matListIcon>{{ temp.icon }}</mat-icon>
            <h3 matLine> {{ temp.title }} </h3>
            <p matLine>
              <span> {{ 'processSettings.'+temp.description | translate }}</span>
            </p>
        </a>
    </mat-nav-list> -->


    <div style="margin: auto;" fxLayout="row wrap" *ngIf="tasksInfo && tasksInfo['taskCategories']" class="p-12">
        <mat-card style="cursor: pointer;" fxFlex="22" class="m-12 w-400" fxLayoutAlign="center center" fxLayout="column" [style.color]="(selectedCategory === temp.key)? 'black': 'gray'" *ngFor="let temp of tasksInfo['taskCategories'];" (click)="openTaskSelection(temp.key)">

            <mat-icon class="font-size-40 w-40 h-40">
                {{ temp.icon }}
            </mat-icon>
            <h3> {{ temp.title }} </h3>

            <p class="m-0"> {{ 'processSettings.'+temp.description | translate }} </p>
        </mat-card>
    </div>
</div>