<div class="pb-12" *ngIf="!field.hide || builder">
    <div class="happ-list-field" [class]="field.class" >
        <div class="happ-default-title happ-title">
            <div class="w-100-p" fxLayout="column">
                <div class="w-100-p" fxLayout="row wrap" fxLayoutAlign="center center">
                    <p *ngIf="!hideTitle" class="happ-field-title" >{{ field.title }} </p>
                    <span fxFlex></span>
                    <div>
                        <button *ngIf="field.showEdit" style="margin-right: -8px !important; color: #827c7c;" (click)="editOptions()" [matTooltip]="'ct.edit_options' | translate" mat-icon-button>
                            <mat-icon>settings</mat-icon>
                        </button>
                    </div>
                </div>
                <p *ngIf="errorMessage" class="warn-500-fg m-0 ml-8">
                    {{ errorMessage | translate  }}
                </p>
        </div>
        </div>
        <mat-list class="happ-list happ-content" *ngIf="!field.multiple && !loading" role="list" fxLayout="{{field.flex}} wrap">
            <mat-nav-list role="listitem" class="happ-list-item" fxLayoutAlign="{{(field.align)? field.align : 'center'}} center" [style.background]="option.background" [style.color]="option.color" *ngFor="let option of field.options" [style.background]="((option.value && model == option.value) || (option.title && model == option.title))? '#e1f5fe': option.background"[style.color]="((option.value && model == option.value) || (option.title && model == option.title))? 'black': option.color">
                <a mat-list-item fxFlex style="text-decoration: none;" (click)="model=option.value || option.title; onChange()">
                    <span class="text-center">{{ option.title || option.value }}</span>                    
                </a>
            </mat-nav-list>
        </mat-list>

        <mat-list class="happ-list happ-content" *ngIf="field.multiple && !loading" role="list" fxLayout="{{field.flex}} wrap">
            <mat-nav-list role="listitem" class="happ-list-item" fxLayoutAlign="{{(field.align)? field.align : 'center'}} center" [style.background]="option.background" [style.color]="option.color" *ngFor="let option of field.options" [style.background]="(model && (model[option.value] || model[option.title]))? '#e1f5fe': option.background"
                [style.color]="(model && model[option.value] && model[option.title])? 'black': option.color">
                <a mat-list-item fxFlex style="text-decoration: none;" (click)="onChange(option.value || option.title)">
                    <span class="text-center">{{ option.title || option.value }}</span>
                </a>
            </mat-nav-list>
        </mat-list>
        <div fxLayoutAlign="center center" *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <div *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">

    </div>
</div>