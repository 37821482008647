export const SelectFormField = {
    'gdpr-persona-approvalRequest': [
        {
            key: 'emailField',
            value: 'Email Field'
        }
    ],
    'gdpr-persona-appeal-request': [
        {
            key: 'appealType',
            value: 'Appeal Type'
        }
    ],
    'gdpr-persona-infoProcessing': [
        {
            key: 'emailField',
            value: 'Email Field'
        }
    ],
    'calendar-create-event': [
        {
            key: 'start',
            value: 'Start Date Field'
        }, 
        {
            key: 'end',
            value: 'End Date Field'
        }, 
        {
            key: 'info_responsible',
            value: 'Responsible Person'
        }, 
        {
            key: 'info_status',
            value: 'Status (default: ongoing)'
        },
        {
            key: 'info_subject',
            value: 'Subject'
        },
        {
            key: 'allDay',
            value: 'All Day field (optional & default-no)'
        },
        {
            key: 'info_contact',
            value: 'Contact (Optional)'
        },
        {
            key: 'info_des',
            value: 'Description (Optional)'
        },
        {
            key: 'info_finishdate',
            value: 'Finish Date Optional'
        },
        {
            key: 'info_id',
            value: 'Contact Id (Optional)'
        },
        {
            key: 'info_location',
            value: 'Location (Optional)'
        },
        {
            key: 'info_percent',
            value: 'Percentage (Optional)'
        },
        {
            key: 'info_priority',
            value: 'Priority (OptionalP'
        },
        {
            key: 'info_type',
            value: 'Type (Optional)'
        },
    ],
    'cron_task': [
        {
            key: 'start',
            value: 'Start Date Field'
        }, 
        {
            key: 'filterString',
            value: 'Filter String',
            description: 'Unique string to filter out the cron task while removing the cron task'
        }
    ],
    'send_email': [
        {
            key: 'subject',
            value: 'Subject'
        }, 
        {
            key: 'cc',
            value: 'cc'
        }, 
        {
            key: 'body',
            value: 'Body'
        }, 
        {
            key: 'email',
            value: 'Email'
        }, 
        {
            key: 'footer',
            value: 'Footer'
        }, 
    ],
    'generate_record_report': [
        {
            key: 'recordId',
            value: 'Record Id'
        }, 
        {
            key: 'templateId',
            value: 'Template Id'
        }, 
        {
            key: 'keyName',
            value: 'Key Name'
        }
    ],
    'generate-record-report': [
        {
            key: 'recordId',
            value: 'Record Id'
        }, 
        {
            key: 'templateId',
            value: 'Template Id'
        }, 
        {
            key: 'keyName',
            value: 'Key Name'
        }
    ],
    'invite-user-to-office': [
        {
            key: 'email',
            value: 'Email'
        }
    ],
    'send-message-back-to-parent': [
        {
            key: 'parentMessage',
            value: 'Parent Message'
        }
    ],
    'invite-user-platfrom-office': [
        {
            key: 'officeId',
            value: 'Office Id'
        },
        {
            key: 'officeGroups',
            value : 'office Group'
        }, 
        {
            key: 'email',
            value: 'Email'
        }, 
        {
            key: 'sendEmailTemplateId',
            value: 'Send Email Template Id'
        }
    ],
    'user_invitation': [
        {
            key: 'officeId',
            value: 'Office Id'
        },
        {
            key: 'officeGroups',
            value : 'office Group'
        }, 
        {
            key: 'email',
            value: 'Email'
        }, 
        {
            key: 'sendEmailTemplateId',
            value: 'Send Email Template Id'
        },
        {
            key: 'subject',
            value: 'Subject'
        }
    ],
    'check_user_in_profile': [
        {
            key: 'email',
            value: 'Email Id'
        },
        {
            key: 'emailCheck',
            value : 'Email Check (yes/no)'
        }
    ],
    'sms_one_way': [
        {
            key: 'to',
            value: 'Send To'
        },
        {
            key: 'message',
            value : 'Message'
        }
    ],
    'create_user_profile': [
        {
            key: 'email',
            value: 'Email'
        }
    ],
    'invite_instance_to_profile': [
        {
            key: 'email',
            value: 'Email'
        }
    ],
    'invitation_response_trigger': [
        {
            key : 'invitationResponse',
            value: 'Invitation Response'
        },
        {
            key : 'email',
            value : 'Email'
        }
    ],
    'invite_user_to_workspace': [
        {
            key : 'email',
            value: 'email'
        },
        {
            key : 'InvitedOfficeId',
            value: 'InvitedOfficeId'
        },
        {
            key : 'workSpaces',
            value: 'workSpaces'
        }
    ],
    'invite_user_to_office': [
        {
            key : 'email',
            value: 'email'
        },
        {
            key : 'InvitedOfficeId',
            value: 'InvitedOfficeId'
        }, 
        {
            key : 'groups',
            value: 'groups'
        }
    ],
    'send_notification': [
        {
            key: 'to',
            value: 'Send To (default is sender)'
        },
        {
            key: 'heading',
            value : 'Heading'
        },
        {
            key: 'body',
            value : 'Body'
        }
    ],
    'messaging_jabber': [
        {
            key: 'to',
            value: 'Send To (default is sender)'
        },
        {
            key: 'message',
            value : 'Message'
        }
    ],
    'doller_calc_value': [
        {
            key: 'preFix',
            value: 'Pre-fix on the field'
        },
        {
            key: 'keyName',
            value : 'Key Name to store sum'
        }
    ],
    'register-record-report': [
        {
            key: 'keyName',
            value: 'Key name to save in'
        }
    ],
    'install_office_plugin': [
        {
            key: 'officeId',
            value: 'Office Id'
        },
        {
            key: 'pluginName',
            value: 'Plugin Name'
        }
    ],
    'create_personal_office': [
        {
            key: 'officeName',
            value: 'Office Name'
        },
        {
            key: 'showWorkspace',
            value: 'Show Workspace'
        },
        {
            key: 'userEmail',
            value: 'User Email to give access'
        }
    ],
    'uninstall_office_plugin': [
        {
            key: 'officeId',
            value: 'Office Id'
        },
        {
            key: 'pluginName',
            value: 'Plugin Name'
        }
    ],
    'register_rec_validation': [
        {
            key: 'destinationField',
            description: `This is the field keyName(label) in the destination register to match with.`,
            value: 'Destination Field Keyname to check with'
        },
        {
            key: 'sourceRecordField',
            description: `This is the field keyName(label) in the source register to get Value.`,            
            value: 'Value to check with'
        },
        {
            key: 'keyName',
            description: `This is the field keyName(label) in the source register to store the result (true or false)`,
            value: 'Key Name to store the result (true/false) (optional)'
        },
        {
            key: 'recordCount',
            description: `This is the field recordCount(label) in the source register to store the count of matching records`,
            value: 'Key Name to store the count of record (optional)'
        }
    ],
    'register_record_report': [
        {
            key: 'keyName',
            value: 'Key name to save in'
        }
    ],
    'update_register_record': [],
    'jump_to_another_activity': [
        {
            key: 'activity',
            description: `this is the Jump Activity`,
            value: 'Jump Activity',
            optionType: 'activity'
        }
    ],
    'move_position_child_records': [
        // {
        //     key: 'fromActivity',
        //     description: `From activity to filter out the record`,
        //     value: 'From Activity',
        //     optionType: 'activity'
        // },
        // {
        //     key: 'toActivity',
        //     description: `To activity `,
        //     value: 'To Activity',
        //     optionType: 'activity'
        // },
        // {
        //     key: 'positionRegId',
        //     description: `Position Used to move the child record`,
        //     value: 'Position To Use',
        //     optionType: 'position'
        // }
    ],
    'cron_task_for_actions': [
        {
            key: 'timeDiff',
            description: 'Time difference value (Ex. 5)',
            value: 'Time difference value'
        },
        {
            key: 'task',
            description: 'Select Task',
            value: 'Select task'
        },
        {
            key: 'filterString',
            value: 'Filter String',
            description: 'Unique string to filter out the cron task while removing the cron task'
        }
    ],    
    'remove_cron_task': [
        {
            key: 'filterString',
            description: 'Unique string to filter out the cron task while removing the cron task',
            value: 'Filter String',
            optionType: 'defaultText'
        }
    ],    
    'create_branch': [
        {
            key: 'projectId',
            description: 'Project Id of the project that you want to make merge request to.',
            value: 'Project Id'
        },
        {
            key: 'source_branch',
            description: 'Source branch or ref branch from which you want to create branch',
            value: 'Source Branch'
        },
        {
            key: 'task_type',
            description: 'Task Type',
            value: 'Task Type'
        },
        {
            key: 'human_readable_text',
            description: 'Human Readable Text.',
            value: 'Branch name to create'
        }
    ],    
    'create_merge_request': [
        {
            key: 'title',
            description: 'Title of the merge to make',
            value: 'Merge title'
        },
        {
            key: 'projectId',
            description: 'Project Id of the project that you want to make merge request to.',
            value: 'Project Id'
        },
        {
            key: 'source_branch',
            description: 'Source branch from which you want to make merge request',
            value: 'Source Branch'
        },
        {
            key: 'target_branch',
            description: 'Branch to which you want to make merge to.',
            value: 'Target Branch'
        }
    ],
    'calendar_create_event': [
        {
            key: 'start',
            value: 'Start Date Field'
        }, 
        {
            key: 'end',
            value: 'End Date Field'
        }, 
        {
            key: 'info_responsible',
            value: 'Responsible Person'
        }, 
        {
            key: 'info_status',
            value: 'Status (default: ongoing)'
        },
        {
            key: 'info_subject',
            value: 'Subject'
        },
        {
            key: 'allDay',
            value: 'All Day field (optional & default-no)'
        },
        {
            key: 'info_contact',
            value: 'Contact (Optional)'
        },
        {
            key: 'info_des',
            value: 'Description (Optional)'
        },
        {
            key: 'info_finishdate',
            value: 'Finish Date Optional'
        },
        {
            key: 'info_id',
            value: 'Contact Id (Optional)'
        },
        {
            key: 'info_location',
            value: 'Location (Optional)'
        },
        {
            key: 'info_percent',
            value: 'Percentage (Optional)'
        },
        {
            key: 'info_priority',
            value: 'Priority (OptionalP'
        },
        {
            key: 'info_type',
            value: 'Type (Optional)'
        },
    ],
};

export const SelectionType = [
    {
        key: 'formField',
        value: 'Form Field'
    },
    {
        key: 'instanceRecordField',
        value: 'Instance record field'
    },
    {
        key: 'defaultDate',
        value: 'Default (Date)'
    },
    {
        key: 'defaultText',
        value: 'Default (Text)'
    },
    {
        key: 'defaultTextArea',
        value: 'Default (TextArea)'
    },
    {
        key: 'defaultEditor',
        value: 'Default (Editor)'
    },
    {
        key: 'now',
        value: 'Now'
    },
    {
        key: 'activity',
        value: 'Activity List'
    },
    {
        key: 'position',
        value: 'PositionFields'
    },
    {
        key: 'task',
        value: 'tasks List'
    }
];
