import { Routes } from '@angular/router';
import { Login2Component } from './main/content/pages/authentication/login-2/login-2.component';
import { Register2Component } from './main/content/pages/authentication/register-2/register-2.component';
import { ForgotPasswordComponent } from './main/content/pages/authentication/forgot-password/forgot-password.component';
import { OfficeListService } from './main/content/apps/office-list/office-list.service';
import { OfficeListComponent } from './main/content/apps/office-list/office-list.component';
import { ProfileComponent } from './main/content/pages/profile/profile.component';
import { AppDashboardRouteGuard } from './app-dashboard-route.guard';
import { SetPasswordComponent } from './main/content/pages/authentication/set-password/set-password.component';
var ɵ0 = { breadcrumb: 'Workspace' }, ɵ1 = { breadcrumb: 'Persona' }, ɵ2 = { breadcrumb: 'Invitation' }, ɵ3 = { breadcrumb: 'Plugins' }, ɵ4 = { breadcrumb: 'Forms' }, ɵ5 = { breadcrumb: 'Agenda View' }, ɵ6 = { breadcrumb: 'Translate' }, ɵ7 = { breadcrumb: 'Address book' }, ɵ8 = { breadcrumb: 'Templates' }, ɵ9 = { breadcrumb: 'Register' }, ɵ10 = { breadcrumb: 'Department Settings' }, ɵ11 = { breadcrumb: 'Users Settings' }, ɵ12 = { breadcrumb: 'Process List' }, ɵ13 = { breadcrumb: 'Process Setting List' }, ɵ14 = { breadcrumb: 'Academy' }, ɵ15 = { breadcrumb: 'Persona Stepper' }, ɵ16 = { breadcrumb: 'Apps Setting' }, ɵ17 = { breadcrumb: 'One View' }, ɵ18 = { breadcrumb: 'Office Configration' }, ɵ19 = { breadcrumb: 'Calendar' };
var routes = [
    {
        path: 'register',
        component: Register2Component
    },
    {
        path: 'profile',
        component: ProfileComponent
    },
    {
        path: 'login',
        component: Login2Component
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'changepassword/:token',
        component: SetPasswordComponent
    },
    {
        path: 'set-password/:token',
        component: SetPasswordComponent
    },
    {
        path: 'dashboard',
        resolve: {
            office: OfficeListService
        },
        component: OfficeListComponent
    },
    {
        path: 'workspace',
        data: ɵ0,
        loadChildren: 'app/main/content/apps/workspace/workspace.module#WorkspaceModule'
    },
    {
        path: 'persona24',
        data: ɵ1,
        loadChildren: 'app/main/content/apps/persona/persona.module#PersonaModule'
    },
    {
        path: 'invitation',
        data: ɵ2,
        loadChildren: './main/content/pages/invitation-list/invitation-list.module#InvitationListModule'
    },
    {
        path: 'workspace-management',
        loadChildren: './main/content/apps/workspace-management/workspace-management.module#WorkspaceManagementModule',
    },
    {
        path: 'plugins',
        data: ɵ3,
        loadChildren: 'app/main/content/apps/plugin/plugin.module#PluginModule'
    },
    {
        path: 'offices/:officeId/dashboard',
        loadChildren: 'app/main/content/apps/dashboard/dashboard.module#DashboardModule',
    },
    {
        path: 'offices/:officeId/customDashboard',
        loadChildren: 'app/main/content/apps/dashboard/dashboard.module#DashboardModule',
    },
    {
        path: 'offices/:officeId/postBook',
        loadChildren: './main/content/apps/post-book/post-book.module#PostBookModule',
    },
    {
        path: 'office-dashoboard',
        canActivate: [AppDashboardRouteGuard],
        loadChildren: 'app/main/content/apps/dashboard/dashboard.module#DashboardModule',
    },
    {
        path: 'offices/:officeId/general-forms',
        data: ɵ4,
        loadChildren: 'app/main/content/apps/global-form/global-form.module#GlobalFormModule'
    },
    //     {
    //         path        : 'offices/:officeId/org-chart',
    //         data: { breadcrumb: 'Org-chart'},
    //         loadChildren: 'app/main/content/apps/org-chart-settings/org-chart-settings.module#OrgChartSettingsModule'
    //     },
    {
        path: 'offices/:officeId/agenda-view',
        data: ɵ5,
        loadChildren: 'app/main/content/apps/agenda-view/agenda-view.module#AgendaViewModule'
    },
    {
        path: 'translate',
        data: ɵ6,
        loadChildren: 'app/main/content/apps/language/language-trans.module#LanguageTransModule'
    },
    {
        path: 'offices/:officeId/address-book',
        data: ɵ7,
        loadChildren: 'app/main/content/apps/addressbook/addressbook.module#AddressbookModule'
    },
    {
        path: 'offices/:officeId/general-templates',
        data: ɵ8,
        loadChildren: 'app/main/content/apps/global-template/global-template.module#GlobalTemplateModule'
    },
    //     {
    //         path        : 'external-widget',
    //         loadChildren: 'app/main/content/apps/external-widget/external-widget.module#ExternalWidgetModule'        
    //     },
    {
        path: 'offices/:officeId/registries',
        data: ɵ9,
        loadChildren: 'app/main/content/apps/custom-registries/custom-registries.module#CustomRegistriesModule'
    },
    {
        path: 'offices/:officeId/department-settings',
        data: ɵ10,
        loadChildren: 'app/main/content/apps/department-settings/department-settings.module#DepartmentSettingsModule'
    },
    {
        path: 'offices/:officeId/users-settings',
        data: ɵ11,
        loadChildren: 'app/main/content/apps/users-settings/users-settings.module#UsersSettingsModule'
    },
    {
        path: 'offices/:officeId/processes',
        data: ɵ12,
        loadChildren: 'app/main/content/apps/processes/processes.module#ProcessesModule'
    },
    {
        path: 'offices/:officeId/process-settings',
        data: ɵ13,
        loadChildren: 'app/main/content/apps/processes-settings/process-settings.module#ProcessSettingsModule'
    },
    {
        path: 'offices/:officeId/academy',
        data: ɵ14,
        loadChildren: 'app/main/content/apps/academy/academy.module#FuseAcademyModule'
    },
    {
        path: 'personaStepper',
        data: ɵ15,
        loadChildren: 'app/main/content/apps/academy/academy.module#FuseAcademyModule'
    },
    //     {
    //         path        : 'books',
    //         loadChildren: 'app/main/content/apps/books/books.module#BooksModule'
    //     },
    {
        path: 'offices/:officeId/application-settings',
        data: ɵ16,
        loadChildren: 'app/main/content/apps/settings/settings.module#SettingsModule'
    },
    {
        path: 'oneRecordView',
        data: ɵ17,
        loadChildren: 'app/main/content/global-component/one-recordview/one-recordview.module#OneRecordViewModule'
    },
    //     {
    //         path : 'offices/:officeId/gdpr-resources',
    //         data: { breadcrumb : 'records' },
    //         loadChildren: 'app/main/content/apps/gdpr-resources/gdpr-resources.module#GdprResourcesModule'
    //     },
    { path: 'offices/:officeId/office-configuration',
        data: ɵ18,
        loadChildren: 'app/main/content/apps/office-configuration/office-configuration.module#OfficeConfigurationModule'
    },
    {
        path: 'offices/:officeId/calendar',
        data: ɵ19,
        loadChildren: './main/content/apps/calendar/calendar.module#CalenderModule'
    },
    //     {
    //         path    : 'error',
    //         loadChildren: 'app/main/content/global-component/404/error-404.module#Error404Module'
    //     },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'office-dashoboard'
    },
    {
        path: '**',
        redirectTo: 'office-dashoboard'
    }
];
var AppRouting = /** @class */ (function () {
    function AppRouting() {
    }
    return AppRouting;
}());
export { AppRouting };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19 };
