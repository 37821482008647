<div fxLayout="column" *ngIf="!field.hide || builder">
    <mat-form-field appearance="outline" *ngIf="field.view_type == 'standard'" fxFlex>
        <mat-label>{{field.title}}</mat-label>
        <input type="number" maxlength="10" id="phone_number" (paste)="pasteIt($event)" matInput placeholder="{{field.title}}" [disabled]="field.showdisablecontroltype=='true' || field.disable" (keyup)="onKeyPress()" [hidden]="field.hidden" [(ngModel)]="model">
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="field.view_type == 'international'" fxFlex>
        <mat-label>{{field.title}}</mat-label>
        <input matInput placeholder="{{field.title}}" [disabled]="field.showdisablecontroltype=='true' || field.disable" (keyup)="onKeyPress()" [hidden]="field.hidden" (paste)="pasteIt($event)" [(ngModel)]="model">
    </mat-form-field>

    <div *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">

    </div>
</div>