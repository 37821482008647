import { Component, OnInit } from '@angular/core';
import { FormComponentService } from './form-component.service';
import { MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { Location } from '@angular/common';

import { fuseAnimations } from '@fuse/animations';
import { HelpersService } from '../../services/helpers/helpers.service';
import { Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FormRulesService } from '../../services/rules/form-rules.service';

@Component({
  selector: 'app-form-component',
  templateUrl: './form-component.component.html',
  styleUrls: ['./form-component.component.scss'],
  animations : fuseAnimations
})
export class FormComponentComponent implements OnInit {

  currentView: string;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  preview;
  isArrayFields = {};
  translateData;
  constructor(
    public formComponentService: FormComponentService,
    private location: Location,
    public snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private helperService: HelpersService,
    private formRuleService: FormRulesService

  ) { }

  ngOnInit() {
    this.helperService.getTrans('control-template')
      .then((data2) => {
        this.translateData = data2;
      }).catch(err => {
        console.log(err);
      });
    const tabId = localStorage.getItem(this.formComponentService.regId);
    if (tabId){
      this.currentView = tabId;
      localStorage.removeItem(this.formComponentService.regId);
    }
      else {
      this.currentView = '0';
        // localStorage.setItem("backPath", this.router.url);
        // this.changeTab(0);
      }
  }



  changeTab(i) {
    const prev = this.currentView;
    this.currentView = i;
    localStorage.setItem(this.formComponentService.regId, i);
  }

  goBack() {
      this.location.back();
  }


  updateRecord(updated) {
    this.formComponentService.updateData(this.formComponentService.formData)
      .then(data => {
        // success
          this.snackBar.open('updated', 'done', {
            duration: 2000,
        });
        updated();
      })
      .catch(err => {
        // error
        this.snackBar.open(err, 'done', {
          duration: 2000,
      });
      });
  }

  checkError(e) {
    if (e['error'] && e['label']) {
      if ( this.formComponentService.error && !this.formComponentService.error.includes(e['label'])) {
        this.formComponentService.error.push(e['label']);
        this.isArrayFields[e['label']] = true;
      }
    } else {
      if (this.formComponentService.error && this.formComponentService.error.includes(e['label'])) {
        this.formComponentService.error.splice(this.formComponentService.error.indexOf(e['label']), 1);
        this.isArrayFields[e['label']] = false;
      }
    }
  }

  saveFormData() {
    this.formComponentService.saveFormData()
    .catch(err => {
      this.snackBar.open(err || 'Some error occured while updating', 'done', {
        duration: 2000,
      });
    });
  }

  
  processUpdateRecord(event) {
    if (event && event.isUpdateRecord) {
      this.formComponentService.updateData(this.formComponentService.formData, event.regId, event.recId)
      .then((updated) => {
        this.snackBar.open('Updated', 'Done', {
          duration: 2000,
        });
        // this.router.navigate([event.navigateTo]);
        // refresh the form and record
        this.refreshData(event.buttonId, event.type);
      }).catch(err => {
        console.log(err);
      });
    } else {
      this.refreshData(event.buttonId, event.type);
    }
  }
 
  refreshData (buttonId: string, type: string) {
    try {
      this.formComponentService.loading = true;
      this.formComponentService.makeInit();
      if (type === 'standalone') {
        this.helperService.postRequest(`api/reg-process/${this.formComponentService.regId}/${this.formComponentService.recId}/standalone/${buttonId}`, {}).then((data) => {
          this.processNextInitBehaviour(data);
        }).catch(err => {
          console.log(err);
          this.errorOccured(err);
        });
      } else {
        this.helperService.getProcessActivityData(this.formComponentService.regId, this.formComponentService.recId, buttonId)
        .then(activityData => {
          this.processNextInitBehaviour(activityData);
        }).catch(err => {
          this.errorOccured(err);
          console.log(err);
        });
      }
    } catch (err) {
      this.errorOccured(err);
      console.log(err);
    }
  }

  
  errorOccured(err) {
    this.snackBar.open(err || 'Error Occured while processing.', 'Okay', {
      duration: 2000,
    });
    this.goBack();
    this.formComponentService.loading = false;
  }


  processNextInitBehaviour(activityData) {
    if (activityData && activityData.activite && activityData.activite.initStatusBehaviour === 'useSatusForm') {
      if (activityData.activite.formType === 'training') {
        this.formComponentService.loading = false;
        this.router.navigate([`/oneRecordView/training/${this.formComponentService.regId}/${this.formComponentService.recId}`]);
      } else {
        this.formComponentService.formId = activityData['ng5FormId'];
        this.formComponentService.getRecordData()
        .then(() => {
          this.formComponentService.afterGetFormOperation(activityData['ng5FormDoc'])
          .catch(err => {
            console.log(err);
          });
          // this.formComponentService.onRecordRefresh.next(true);
          this.formComponentService.formId = activityData['ng5FormId'];
          this.formComponentService.loading = false;
        }).catch(err => {
          this.errorOccured(err);
          console.log(err);
        });
      }
    } else {
      // this.location.back();
      // if (this.ngForm && this.ngForm.saveWithoutMove) {
      //   console.log('do not move back');
      // } else {
        // }
      this.goBack();
      this.formComponentService.loading = false;
    }
  }


  updateAddress(event) {
    if (this.formComponentService && this.formComponentService.formData) {
      this.formComponentService.formData['companyAdress'] = (event && event.companyAdress) || '';
      this.formComponentService.formData['companyCity'] = (event && event.companyCity) || '';
      this.formComponentService.formData['shortName'] = (event && event.shortName) || '';
      this.formComponentService.formData['longName'] = (event && event.longName) || '';
      this.formComponentService.formData['postalcode'] = (event && event.postalcode) || '';
      this.formComponentService.formData['companyName'] = (event && event.companyName) || '';
    }
  }

  
  updateParentOnSave(event) {
    // this.editNg5Service.formData
    event = event || {};
    // tslint:disable-next-line:forin
    for (const i in event) {
      this.formComponentService.formData[i] = event[i];
    }
  }

  openParentRec() {
    // [routerLink]="['/oneRecordView/record/'+editNg5Service.formData['parentId']]" 
    if (this.formComponentService && this.formComponentService.formData && this.formComponentService.formData['parentId']) {
      // tslint:disable-next-line:max-line-length
      if (this.formComponentService.changedFormData && this.formComponentService.changedFormData['popupOptions'] && this.formComponentService.changedFormData['popupOptions']['dialogRef']) {
        this.formComponentService.changedFormData['popupOptions']['dialogRef'].close(null);
      }
      this.router.navigate([`/oneRecordView/record/${this.formComponentService.formData['parentId']}`]);
    }
  }

  deleteRecordConfirm() {
    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
  });

  this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

  this.confirmDialogRef.afterClosed().subscribe(result => {
      if ( result ) {
        this.deleteRecord();
      } else {
        this.snackBar.open('You cancelled Deleting Record', 'Okay', {
          duration: 2000,
        });
      }
      this.confirmDialogRef = null;
  }, err => {
    console.log(err);
  });
  }


  deleteRecord() {
    if (this.formComponentService && this.formComponentService.recId && this.formComponentService.regId) {
      this.helperService.deleteRegisterRecordData(this.formComponentService.regId, this.formComponentService.recId)
      .then((data) => {
        this.snackBar.open('Record Deleted', 'Done', {
          duration: 2000,
        });
        this.formComponentService.closePopup(true);
      }).catch(err => {
        console.log(err);
        this.snackBar.open(err || 'Error occured while getting data', 'Done', {
          duration: 2000,
        });
      });
    }
  }

  onFieldValueUpdate(eve) {
    // this.form
    if (eve && eve.field && eve.field.haveRules) {
      // tslint:disable-next-line:max-line-length
      this.formComponentService.form = this.formRuleService.onValueUpdate(this.formComponentService.form, this.formComponentService.ngForm.rules, this.formComponentService.formData, eve.ruleIndex);
    }
  }
}
