import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, Inject, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, AfterViewInit, OnDestroy {
  
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: string; 
@Input() translateData: any;
  @Input() recordMainDocument: string;
  @Input() recId: string;
  @Input() regId: string;
  @Input() appId: string;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() recordMainDocumentChange: EventEmitter<Object> = new EventEmitter();
  // @ViewChild(DataTableDirective)
  // dtElement: DataTableDirective;

  officeId;
  creatorId;
  creatorLogin;
  fileList;
  docBase;
  loading = false;
  downloadLoading = false;
  selectedFiles: Array<String> = [];

  // dtOptions: DataTables.Settings = {};
  formList = [];
  dtTrigger: Subject<any> = new Subject();


  constructor(
    private sanitizer: DomSanitizer,
    private translationLoader: FuseTranslationLoaderService,
    private helperService: HelpersService
  ) {
    this.translationLoader.loadTranslationsV2(this.translateData);

    const office = (localStorage.getItem('ngStorage-selectedOffice')) ? JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) : null;
    if (office) {
      this.officeId = office['_id'];
    }

    const profile = (localStorage.getItem('ngStorage-profile')) ? JSON.parse(localStorage.getItem('ngStorage-profile')) : null;


    if (profile) {
      this.creatorId = profile['profileId'];
      this.creatorLogin = profile['email'];
    }
  }

  fileChanged(file) {
    const files = file.files;
    this.selectedFiles = [];
    for (let i = 0; i < files.length; i++ ) {
      this.selectedFiles.push(files[i]['name']);
    }
  }


  onKeyPress() {
    this.modelChange.next(this.model);
  }

  deleteFile(id: string) {
    this.helperService.openConfirmPopUp('popup.confirm_delete_file_message')
    .subscribe((res) => {
      if (res) {
        if (this.recordMainDocument === id) {
          this.removeMainDoc();
        }
        this.helperService.deleteRequest(`api/files/${this.officeId}/${id}`)
        .then(() => {
          this.fetchData();

        }).catch(err => {
          console.log(err);
          this.helperService.openSnackBar(err);
        });
      }
    });
  }

  removeMainDoc() {
      this.recordMainDocument = '';
      this.recordMainDocumentChange.next(this.recordMainDocument);
  }

  saveAsMainDoc(id: string) {
    if (id) {
      this.recordMainDocument = id;
      this.recordMainDocumentChange.next(this.recordMainDocument);
    }
  }

  uploadFile(file) {
    const files = file.files;
    const formData = new FormData();
    let url = '';
    for (let i = 0; i < files.length; i++ ) {
      formData.append('uplFile', files[i], files[i].name);
    }
    if (this.regId && this.recId) {
      // tslint:disable-next-line:max-line-length
      url = `api/files/${this.officeId}/upload?creatorId=${this.creatorId}&creatorLogin=${this.creatorLogin}&officeId=${this.officeId}&recId=${this.recId}&regId=${this.regId}`;
    } else if (this.appId) {
      // tslint:disable-next-line:max-line-length
      url = `api/files/${this.officeId}/upload?creatorId=${this.creatorId}&creatorLogin=${this.creatorLogin}&officeId=${this.officeId}&appId=${this.appId}`;
    }
    this.loading = true;
    this.helperService.postRequest(url, formData)
    .then((data) => {
      this.loading = false;
      if (data && data.length) {
        this.fetchData();
      }
    }).catch(err => {
      this.loading = false;
      console.log(err);
    });
  }

  fetchData( ) {
    if (this.recId && this.regId) {
      this.helperService.getRequest(`api/files/${this.officeId}/list?officeId=${this.officeId}&recId=${this.recId}`)
      .then((data) => {
        this.fileList = data || [];
        this.rerender();
      }).catch(err => {
        console.log(err);
      });
    } if (this.appId) {
      this.helperService.getRequest(`api/files/${this.officeId}/list?officeId=${this.officeId}&appId=${this.appId}`)
      .then((data) => {
        this.fileList = data || [];
        this.rerender();
      }).catch(err => {
        console.log(err);
      });
    }
  }

  ngOnInit() {
    this.fetchData();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  openPreview(id: string): void {
    this.downloadLoading = true;
    this.helperService.getRequest(`api/files/${id}/getBase64File`)
    .then((data) => {
      this.downloadLoading = false;
      if (data) {
        this.helperService.downloadDocument(data);
        // const base64 = data['base64'];
        // const blob = this.b64toBlob(base64, data['mimetype'], null);
        // const blobUrl = URL.createObjectURL(blob);
        // this.docBase =  this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
      }
    }).catch(err => {
      console.log(err);
      this.downloadLoading = false;
    });
  }

  rerender(): void {
    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.destroy();
    //   if (this.dtTrigger) {
    //     this.dtTrigger.next();
    //   }
    // });
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }


  ngAfterViewInit(): void {
    this.dtTrigger.next();
    // this.rerender();
}
}
