<div fxLayout="column" *ngIf="!field.hide || builder">

    <div fxLayout="row">

        <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{field.title}}</mat-label>
            <input type="{{field.inputType}}" (paste)="pasteIt($event)" matInput placeholder="{{field.title}}" [disabled]="field.showdisablecontroltype=='true' || field.disable" (keyup)="onKeyPress()" [hidden]="field.hidden" [(ngModel)]="model">
            <div class="error-message" *ngIf="errorMessage">
                {{ errorMessage | translate }}
            </div>
        </mat-form-field>
        <!-- <mat-icon *ngIf="error" style="color: red; font-size: 50px; margin: auto; margin-right: 32px;" class="ml-12">warning</mat-icon> -->

    </div>
    <!-- <div fxLayout="row" *ngIf="error" style="color: red;">
        <p> {{ errorMessage }} </p>
    </div> -->
    <div *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">

    </div>
</div>